import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { useAuth } from "hooks";
import { IRecoverPassword } from "interfaces";
import logincss from "../../styles/scss/components/login.module.scss";
import { useState } from "react";
import { getForgotPwdFormSchema } from "views/users/validations";
import LiveFooter from "components/page/footer";
import RecoverImage from "../../images/recover_live_agro.svg";
import { Box } from "@mui/system";
import { InputText } from "@alb/live-lib";

const LiveForgotPassword = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { RecoverPassword } = useAuth();

	const [showMsg, setShowMsg] = useState<boolean>(false);

	const navigateToLogin = () => navigate("/login", { replace: true });

	const methods = useForm<IRecoverPassword>({
		mode: "onSubmit",
		resolver: yupResolver(getForgotPwdFormSchema()),
	});

	const formSubmitHandler: SubmitHandler<IRecoverPassword> = (
		formData: IRecoverPassword
	) => {
		// console.log("formData", formData);
		RecoverPassword(formData, () => {
			setShowMsg(true);
		});
	};

	const { control } = methods

	return (
		<>
			<div className="pagesDiv">
				<Container sx={{ paddingTop: 8, flex: " 1" }} maxWidth={"md"}>
					<Box maxWidth={"sm"}>
						<FormProvider {...methods}>
							<form onSubmit={methods.handleSubmit(formSubmitHandler)}>
								<Typography className={logincss.typographyHeader_} variant="h4">
									{t("loginForm.forgotPasswordTitle")}
								</Typography>

								{!showMsg && (
									<Typography mt={1.5} variant="subtitle1" color="black">
										{t("loginForm.insertEmail")}
									</Typography>
								)}

								{!showMsg && (
									<>
										<Grid
											container
											mt={9}>
											<Grid item xs={12}>
												<Typography
													gutterBottom
													color="text.black"
													variant="subtitle2"
												>
													{t("loginForm.emailLabel")}
												</Typography>
												<InputText
													name="email"
													placeholder={t("loginForm.emailPlaceholder")}
													control={control}
													type="email"
												/>
											</Grid>
										</Grid>

										<Stack
											mt={4}
											direction="row"
											justifyContent="flex-start"
											spacing={1}>
											<Button
												color="primary"
												type="button"
												variant="outlined"
												startIcon={<ArrowBackRoundedIcon />}
												onClick={navigateToLogin}
												disabled={methods.formState.isSubmitting}
											>
												{t("common.goBack")}
											</Button>


											<Button
												color="primary"
												variant="contained"
												type="submit"
											>
												{t("common.send")}
											</Button>
										</Stack>
									</>
								)}
								{showMsg && (
									<>
										<Typography variant="subtitle1" mt={2}>
											{t("loginForm.forgotPasswordMsg")}
										</Typography>

										<Stack
											mt={4}
											direction="row"
											justifyContent="flex-start"
										>
											<Button
												color="secondary"
												type="button"
												variant="outlined"
												startIcon={<ArrowBackRoundedIcon />}
												onClick={navigateToLogin}
												disabled={methods.formState.isSubmitting}
											>
												{t("common.goBack")}
											</Button>
										</Stack>
									</>
								)}
							</form>
						</FormProvider>
					</Box>
				</Container>

				<LiveFooter backgroundImage={RecoverImage} maxWidth={"lg"} />
			</div>
		</>
	);
};

export default LiveForgotPassword;
