/* eslint-disable react-hooks/exhaustive-deps */
//material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
//traduçoes
import { useTranslation } from "react-i18next";
import { IUser } from "interfaces";
import { useEffect } from "react";
import { useCreate } from "hooks";

import { ServiceApiUrl } from "services/ServiceApiUrl";
import { useErrorHandler } from "hooks/useErrorHandler";
import { LoadingButton } from "@mui/lab";
import { useFeedback } from "@alb/live-lib";

export default function UserReactivate(props: any) {
  //traduções
  const { t } = useTranslation();

  const { user } = props;


  //alert
  const { addFeedback } = useFeedback();

  //########## SERVIÇOS
  const { loading, error, refetch: reactivateUser } = useCreate<IUser[]>(
    ServiceApiUrl.userURL
  );

  //para lançar o erro, caso exista.
  const { sendErrorMessage } = useErrorHandler();


  //sucesso
  const handlerSuccess = () => {
    props.handlerClose(); //fecha modal
    addFeedback({ message: t("user.userReactivated"), severity: "success" });
    props.updateGetUsers();
  };

  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error]);



  const ReactivateUser = async (user: IUser) => {
    try {
      await reactivateUser({
        data: {
          email: user.email
        }
      });
      await handlerSuccess();
    } catch (error) { }
  };


  return (
    <Dialog maxWidth="md" open={props.open} onClose={props.handlerClose}>
      <DialogTitle id="alert-dialog-title">
        <Typography component={'span'} color="text.black" gutterBottom={true} variant="h5" sx={{ fontWeight: 'bold' }} noWrap>
          {t('user.reactivateUser')}
        </Typography>
      </DialogTitle>

      <DialogContent>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography mb={1}>{t('confirmations.userReactivateConfirmation')}</Typography>

            <Typography variant='subtitle2'>{t('user.institutionalEmail')}: <b>{user.email}</b></Typography>
            <Typography variant='subtitle2'>{t('user.name')}: <b>{user.first_name} {user.last_name} ({user.username})</b></Typography>

          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={props.handlerClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          onClick={() => { ReactivateUser(user) }}
        >
          {t('user.reactivate')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
