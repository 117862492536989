import { createSlice } from "@reduxjs/toolkit";

import useLocalStorageV2 from "hooks/useLocalStorageV2";
import { IAuth } from "interfaces";
import { RootState } from "store/store";
import { AUTH_KEY, AUTH_TOKEN_KEY, I18NEXTLNG_KEY, THEME_KEY } from "utils";
import { setCookie } from "utils/cookie";
import { getUserPermissions } from "utils/permissions/getUserPermissions";

// eslint-disable-next-line react-hooks/rules-of-hooks
const { getLocalStorage, setLocalStorage } = useLocalStorageV2();

const initialState: IAuth = {
  roles: getLocalStorage(AUTH_KEY)?.roles || null,
  user: getLocalStorage(AUTH_KEY)?.user || null,
  // auth_token: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth(state, action) {
      const permissions = getUserPermissions(action.payload.user);
      const response: IAuth = {
        user: action.payload.user,
        roles: permissions,
        // auth_token: action.payload.auth_token,
      };
      const appTheme = response.user.extra_params?.dark_mode ? "dark" : "light";
			setCookie<string>(AUTH_TOKEN_KEY, action.payload.auth_token);
			setCookie<string>(I18NEXTLNG_KEY, response.user.language.toLowerCase(), {maxAge: Number.MAX_SAFE_INTEGER});
			setCookie<string>(THEME_KEY, appTheme);
      setLocalStorage(AUTH_KEY, response); // store auth info on the localStorage

      state.roles = response.roles;
      state.user = response.user;
    },
    setUser(state, action) {
      const valuesLS = getLocalStorage(AUTH_KEY);
      const updatedUser = {
        ...valuesLS.user,
        ...action.payload,
      };
      const newValues: any = {
        ...valuesLS,
        user: updatedUser,
      };
      setLocalStorage(AUTH_KEY, newValues);
      state.user = updatedUser;
    },
    setRoles(state, action) {
      const valuesLS = getLocalStorage(AUTH_KEY);
      const permissions = getUserPermissions(action.payload);
      const newValues: any = {
        ...valuesLS,
        roles: permissions,
      };
      setLocalStorage(AUTH_KEY, newValues);
      state.roles = permissions;
    },
    setClient(state, action) {
      const valuesLS = getLocalStorage(AUTH_KEY);
      // change values
      const updatedClient = {
        ...valuesLS.user.client,
        ...action.payload,
      };
      const newValues: IAuth = {
        ...valuesLS,
        user: {
          ...valuesLS.user,
          client: updatedClient,
        },
      };
      // save new values
      setLocalStorage(AUTH_KEY, newValues);
      state.user.client = updatedClient;
    },
  },
});

export const { setAuth, setUser, setRoles, setClient } = authSlice.actions;

export const getUser = (state: RootState) => state.auth.user;
export const getRoles = (state: RootState) => state.auth.roles;
export const getNameClient = (state: RootState) =>
  state.auth?.user?.client.name;

export default authSlice.reducer;
