/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useDelete } from "hooks";
import { ConfirmDialog, useFeedback } from "@alb/live-lib";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { useEffect } from "react";
import { useErrorHandler } from "hooks/useErrorHandler";
import { ILocation } from "interfaces/ILocation";
import { getSelectedModule } from "utils/permissions/getSelectedModule";
import { useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";

export default function DeleteLocation(props: any) {

	const { t } = useTranslation();
	const { addFeedback } = useFeedback();
	const { sendErrorMessage } = useErrorHandler();
	const auth = useSelector(getUser)
	const moduleID = auth && getSelectedModule(auth, 'dados_morfologicos')
	const headers = {
		headers: {
			'MODULE-ID': moduleID
		}
	}

	const { loading, error, refetch: deleteLocation } = useDelete<ILocation>(ServiceApiUrl.placesListURL, props.location.id, headers);

	//sucesso
	const handlerSuccess = () => {
		props.handlerClose()
		addFeedback({ message: t("morphologicalData.locations.locationDeleted"), severity: 'success' })
		props.updateGet();
	};
	//erro
	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);


	//apaga location
	const handleDeleteLocation = async () => {
		try {
			await deleteLocation()
			await handlerSuccess();
		}
		catch {
		}
	}


	return (
		<ConfirmDialog
			open={props.open}
			type="error"
			title={`${t("morphologicalData.locations.deleteLocation")} ${props.location.name}`}
			message={t('morphologicalData.locations.locationDeleteConfirmation')}
			actionConfirmText={t("common.delete")}
			actionCancelText={t("common.cancel")}
			loading={loading}
			onCancel={props.handlerClose}
			onConfirm={handleDeleteLocation}
		/>
	);
}


