/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useDelete } from "hooks";

import { ServiceApiUrl } from "services/ServiceApiUrl";
import { useEffect } from "react";
import { useErrorHandler } from "hooks/useErrorHandler";
import { ISample } from "interfaces/ISample";
import { getSelectedModule } from "utils/permissions/getSelectedModule";
import { ConfirmDialog, useFeedback } from "@alb/live-lib";
import { useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";



export default function SampleDelete(props: any) {

	const { t } = useTranslation();
	const { addFeedback } = useFeedback();
	const { sendErrorMessage } = useErrorHandler();
	const auth = useSelector(getUser)
	const moduleID = auth && getSelectedModule(auth, 'dados_morfologicos')
	const headers = {
		headers: {
			'MODULE-ID': moduleID
		}
	}

	const { loading, error, refetch: deleteSample } = useDelete<ISample[]>(ServiceApiUrl.sampleURL, props.sample.id, headers);

	//sucesso
	const handlerSuccess = () => {
		props.handlerClose()
		addFeedback({ message: t("morphologicalData.sample.sampleDeleted"), severity: 'success' })
		props.updateGetSamples();
	};
	//erro
	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);


	//apaga sample
	const handleDeleteSample = async () => {
		try {
			await deleteSample()
			await handlerSuccess();
		}
		catch {
		}
	}

	return (
		<ConfirmDialog
			open={props.open}
			type="error"
			title={`${t("morphologicalData.sample.deleteSample")} ${props.sample.name}`}
			message={t('morphologicalData.sample.sampleDeleteConfirmation')}
			actionConfirmText={t("common.delete")}
			actionCancelText={t("common.cancel")}
			loading={loading}
			onCancel={props.handlerClose}
			onConfirm={handleDeleteSample}
		/>
	);
}


