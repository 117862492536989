import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CssBaseline from "@mui/material/CssBaseline";
import SearchOffIcon from '@mui/icons-material/SearchOff';

const NotFound = (props: any) => {

    console.log('props', props);

    // const { error } = props;
    const { t } = useTranslation();

    // const [active, setActive] = useState(false)



    // console.log(error);


    return (
        <>
            <CssBaseline />
            <Grid container sx={{ textAlign: 'center' }} >
                <Grid item xs={true} mt={12}>


                    <SearchOffIcon color="error" sx={{ fontSize: 80 }} />


                    <Typography mt={2} variant="h3">{t("errors.somethingWentWrong")}</Typography>


                    <Typography mb={2} mt={6} component={'span'} variant="h5">{t('errors.notFound')}</Typography>



                    <Grid container alignItems="center" rowSpacing={2}>
                        <Grid item xs>
                        </Grid>

                        <Grid mb={0.5} item xs='auto'>
                         404     {/*o que colocar aqui? */}
                        </Grid>

                        <Grid item xs sx={{ textAlign: 'start' }} >
                            {/* <Tooltip placement='right' title={t("errors.seeErrorDetails")+''}>
              <IconButton sx={{ marginLeft: '4%' }}
                className="outlined"
                color='primary'
                size="small"
                onClick={(e: any) => { setActive(!active) }}>
                {active ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Tooltip> */}
                        </Grid>

                    </Grid>


                    {/* {active &&
          <Typography color="text.secondary" variant="subtitle2">descrição do erro</Typography>
        }*/}
                    <Box mt={10}>
                        <Button onClick={event => window.location.href = '/'} size="large" variant="contained">{t('errors.goToHome')}</Button>
                    </Box>

                </Grid>
            </Grid>
        </>
    );
}
export default NotFound;