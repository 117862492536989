import { ILoginFormInput, Login as LoginUI } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAuth } from "hooks";
import { getLoginFormSchema } from "views/users/validations";

import loginBG from "../../../images/login_live_agro.svg";
import logo from "../../../images/logo.svg";

const LiveLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Login } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const customTranslations = {
    requiredEmail: t("loginForm.emailRequired"),
    matchesEmail: t("loginForm.emailValid"),
    requiredPassword: t("loginForm.passwordRequired"),
  }
  
  function onSubmitLogin(data: ILoginFormInput) {
    setIsLoading(true);
    Login(
      data,
      () => {
        navigate("/map", { replace: true });
      },
      () => {
        setIsLoading(false);
      }
    );
  }

  const onForgotClick = () => {
    navigate("/forgot");
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={8}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoginUI
          logo={logo}
          alt="live!GREEN"
          title={t("loginForm.welcome")}
          recoverLabel={t("loginForm.forgotPasswordLabel")}
          buttonLabel={t("loginForm.login")}
          validator={yupResolver(getLoginFormSchema(customTranslations))}
          onSubmit={onSubmitLogin}
          loadingSubmit={isLoading}
          onClick={onForgotClick}
        />
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          backgroundImage: `url(${loginBG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "right",
        }}
      />
    </Grid>
  );
};

export default LiveLogin;
