import { InputDateTime, InputSelect, InputText, Loading, RequiredField, useFeedback } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RequiredFields } from "components/utils/forms/RequiredFields";
import { useCreate, useGetAll } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import i18n from "i18n/config";
import { IApiResponse, IDialogProps, IHarvest, IPulpColor, ISample, ISelectOption, ITexture } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { convertCmToMm, formatDate, nowDateTime } from "utils";
import { getSelectedModule } from "utils/permissions/getSelectedModule";

import { getSampleAddHarvestSchema } from "../validations";

export default function HarvestMaturationAdd({ data, open, onClose, onSuccess }: IDialogProps<ISample>) {
	const { t } = useTranslation();
	const { addFeedback } = useFeedback();
	const { sendErrorMessage } = useErrorHandler();
	const user = useSelector(getUser)
	const auth = useSelector(getUser)
	const moduleID = auth && getSelectedModule(auth, 'dados_morfologicos')
	const headers = {
		headers: {
			'MODULE-ID': moduleID
		}
	}

	//pedido para adicionar harvest
	const {
		loading,
		error,
		refetch: createHarvest,
	} = useCreate<IHarvest[]>(ServiceApiUrl.harvestURL, '', headers);

	//pedidos para obter os dados pré-definidos
	const { data: pulpColorsList, loading: loadingPulpColors } = useGetAll<IApiResponse<IPulpColor>>(
		ServiceApiUrl.pulpColorsListURL,
		undefined,
		undefined,
		headers
	);
	const { data: texturesList, loading: loadingTextures } = useGetAll<IApiResponse<ITexture>>(
		ServiceApiUrl.texturesListURL,
		undefined,
		undefined,
		headers
	);

	const [pulpColors, setPulpColors] = useState<ISelectOption[]>();
	useEffect(() => {
		if (pulpColorsList) {
			const pOpts = pulpColorsList?.data?.map((p: IPulpColor) => ({ label: p.name, value: p.id }));
			setPulpColors(pOpts);
		}
	}, [pulpColorsList])

	const [textures, setTextures] = useState<ISelectOption[]>();
	useEffect(() => {
		if (texturesList) {
			const tOpts = texturesList?.data?.map((p: ITexture) => ({ label: p.name, value: p.id }));
			setTextures(tOpts);
		}
	}, [texturesList])

	useEffect(() => {
		methodsAdd.reset();
	}, [onClose]);

	//sucesso
	const handlerSuccess = () => {
		onClose && onClose(); //fecha modal
		addFeedback({
			message: t("morphologicalData.harvestMatur.harvestMaturationAdded"),
			severity: "success",
		});
		onSuccess && onSuccess();
	};
	//erro
	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);

	const methodsAdd = useForm<IHarvest>({
		mode: "onChange",
		defaultValues: {
			user: user.id,
			sample_id: data.id,
			registration_date: nowDateTime(),
			pulp_color_maturity: '',
			flesh_texture: '',
			crop_cycle_days: undefined,
			pedicels_number: undefined,
			fruits_number: undefined,
			fruit_length: undefined,
			fruit_diameter: undefined,
			fruit_weight: undefined,
			fruit_peel_thickness: undefined,
		},
		resolver: yupResolver(getSampleAddHarvestSchema()),
	});

	//ao submeter o formulário
	const formSubmitHandler: SubmitHandler<IHarvest> = async (
		payLoad: IHarvest
	) => {

		payLoad.registration_date = formatDate(
			payLoad.registration_date,
			t("calendar.dateTimeFormatLocalSeconds")
		);
		payLoad.fruit_diameter = convertCmToMm(payLoad.fruit_diameter);
		payLoad.fruit_length = convertCmToMm(payLoad.fruit_length);
		payLoad.fruit_peel_thickness = convertCmToMm(payLoad.fruit_peel_thickness);
		// console.log(payLoad);
		try {
			await createHarvest({ data: payLoad });
			await handlerSuccess();
		} catch (error) { }
	};

	return (
		<Dialog maxWidth="md" open={open} fullWidth={true}>
			<DialogTitle id="alert-dialog-title">
				<Typography
					component={"span"}
					color="text.black"
					gutterBottom={true}
					variant="h5"
					noWrap
				>
					{t("morphologicalData.harvestMatur.addHarvestMaturation")}
				</Typography>
			</DialogTitle>

			<DialogContent>
				<FormProvider {...methodsAdd}>
					<form
						id="add-user-form"
						onSubmit={methodsAdd.handleSubmit(formSubmitHandler)}
					>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography gutterBottom color="text.black" variant="h6">
									{t("morphologicalData.sample.selectSample")}
								</Typography>
								<label>{data.name}</label>
							</Grid>

							<Grid item xs={12}>
								<RequiredField title={t("morphologicalData.registrationDate")} />
								<InputDateTime
									control={methodsAdd.control}
									name="registration_date"
									placeholder={t(
										"morphologicalData.registrationDatePlaceholder"
									)}
									labelBtnCancel={t("common.cancel")}
									labelBtnConfirm={t("common.apply")}
									locale={i18n.language}
									maxDate={new Date()}
								/>
							</Grid>

							<Grid item xs={12}>
								<RequiredField title={t("morphologicalData.harvestMatur.cropCycle")} />
								<InputText
									control={methodsAdd.control}
									type="number"
									name="crop_cycle_days"
									endAdornment={t('common.days')}
									placeholder={t(
										"morphologicalData.harvestMatur.cropCyclePlaceholder"
									)}
								/>
							</Grid>

							<Grid item xs={12}>
								<RequiredField title={t("morphologicalData.harvestMatur.numberHands")} />
								<InputText
									control={methodsAdd.control}
									type="number"
									name="pedicels_number"
									placeholder={t(
										"morphologicalData.harvestMatur.numberHandsPlaceholder"
									)}
								/>
							</Grid>

							<Grid item xs={12}>
								<RequiredField title={t("morphologicalData.harvestMatur.numberFruitsHands")} />
								<InputText
									control={methodsAdd.control}
									type="number"
									name="fruits_number"
									placeholder={t(
										"morphologicalData.harvestMatur.numberFruitsHandsPlaceholder"
									)}
								/>
							</Grid>

							<Grid item xs={12}>
								<RequiredField title={t("morphologicalData.harvestMatur.fruitLength")} />
								<InputText
									control={methodsAdd.control}
									type="number"
									endAdornment={"cm"}
									name="fruit_length"
									placeholder={t(
										"morphologicalData.harvestMatur.fruitLengthPlaceholder"
									)}
								/>
							</Grid>

							<Grid item xs={12}>
								<RequiredField title={t("morphologicalData.harvestMatur.fruitDiameter")} />
								<InputText
									control={methodsAdd.control}
									type="number"
									endAdornment={"cm"}
									name="fruit_diameter"
									placeholder={t(
										"morphologicalData.harvestMatur.fruitDiameterPlaceholder"
									)}
								/>
							</Grid>

							<Grid item xs={12}>
								<RequiredField title={t("morphologicalData.harvestMatur.fruitWeight")} />
								<InputText
									control={methodsAdd.control}
									endAdornment={"g"}
									type="number"
									name="fruit_weight"
									placeholder={t(
										"morphologicalData.harvestMatur.fruitWeightPlaceholder"
									)}
								/>
							</Grid>

							<Grid item xs={12}>
								<RequiredField title={t("morphologicalData.harvestMatur.barkThickness")} />
								<InputText
									control={methodsAdd.control}
									type="number"
									endAdornment={"cm"}
									name="fruit_peel_thickness"
									placeholder={t(
										"morphologicalData.harvestMatur.barkThicknessPlaceholder"
									)}
								/>
							</Grid>

							<Grid item xs={12}>
								<RequiredField title={t("morphologicalData.harvestMatur.PulpColor")} />
								{pulpColors && pulpColors?.length > 0 && (
									<InputSelect
										placeholder={t("morphologicalData.pulpColors.pulpColorPlaceholder")}
										name="pulp_color_maturity"
										control={methodsAdd.control}
										options={pulpColors}
									/>
								)}
								{<Loading show={loadingPulpColors} />}
							</Grid>

							<Grid item xs={12}>
								<RequiredField title={t("morphologicalData.harvestMatur.texture")} />
								{textures && textures?.length > 0 && (
									<InputSelect
										placeholder={t("morphologicalData.textures.texturePlaceholder")}
										name="flesh_texture"
										control={methodsAdd.control}
										options={textures}
									/>
								)}
								{<Loading show={loadingTextures} />}
							</Grid>
						</Grid>
					</form>
				</FormProvider>
				<RequiredFields />
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" color="primary" onClick={onClose}>
					{t("common.cancel")}
				</Button>

				<LoadingButton
					loading={loading}
					variant="contained"
					color="primary"
					disabled={!methodsAdd.formState.isValid}
					type={"submit"}
					form={"add-user-form"}
				>
					{t("common.add")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}
