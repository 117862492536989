import { TFunction } from "i18next";

import { ISelectOption } from "interfaces";

const languages = [
  { labelKey: "common.portuguese", value: "pt" },
  { labelKey: "common.english", value: "en" },
];

export const getLanguages = (
  t: TFunction,
): ISelectOption[] => {
  const langOptions = languages.map((l) => {
    return { label: t(l.labelKey), value: l.value };
  });

  return langOptions;
};
