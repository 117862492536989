import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, Typography } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

import PhotosList from "components/utils/PhotosList/PhotosList";
import { useErrorHandler, useGetAll } from "hooks";
import { useHasPermissions } from "hooks/usePermissions";
import { IApiResponse, IPicture, ISample } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty, PERMISSIONS } from "utils";
import AddPicture from "views/morphologicaldata/picture/addPicture";
import PictureDelete from "views/morphologicaldata/picture/deletePicture";
import { getSelectedModule } from "utils/permissions/getSelectedModule";
import { useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";
import { Loading } from "@alb/live-lib";

interface PicturesProps {
  sample: ISample;
}

export default function Pictures({ sample }: PicturesProps) {
  const { sendErrorMessage } = useErrorHandler();
  const { t } = useTranslation();

  const [openDialogAddPic, setOpenDialogAddPic] = React.useState(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [pictureSelected, setPictureSelected] = React.useState<IPicture>();

  const { hasPermission } = useHasPermissions();
  const canList = hasPermission([
    PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.VIEW,
  ]);
  const canAdd = hasPermission([
    PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.ADD,
  ]);
  const canDelete = hasPermission([
    PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.DELETE,
  ]);
    const auth = useSelector(getUser)
  const moduleID = auth && getSelectedModule(auth, "dados_morfologicos");
  const headers = {
    headers: {
      "MODULE-ID": moduleID,
    },
  };
  //pedido das fotos da amostra
  const {
    data: pictures,
    loading,
    error,
    refetch,
  } = useGetAll<IApiResponse<IPicture>>(
    ServiceApiUrl.pictureURL,
    {
      sample: sample.id,
    },
    undefined,
    headers
  );

  //erro
  React.useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handlerDialogAddPicState = () => {
    setOpenDialogAddPic(!openDialogAddPic);
  };
  const onAddClick = (event: React.MouseEvent<HTMLElement>) => {
    setOpenDialogAddPic(true);
  };

  const updatePictures = () => {
    refetch();
  };

  //atualiza o estado do modal de remover
  const handlerDialogDeleteState = (open = false, picture: IPicture) => {
    if (open === true) {
      setPictureSelected(picture);
    }
    setOpenDialogDelete(!openDialogDelete);
  };

  return (
    <>
      <Grid
        container
        mt={1}
        mb={2}
        alignItems="center"
        direction="row"
        sx={{ justifyContent: "end" }}
      >
        <Grid item xs={true}>
        <Typography variant="h3">
            {t("morphologicalData.pictures")}
          </Typography>
        </Grid>

        {canAdd && (
          <Grid item>
            <Button
              color="primary"
              onClick={onAddClick}
              variant="contained"
              size="medium"
              startIcon={<AddIcon />}
            >
              {t("common.add")}
            </Button>
          </Grid>
        )}
      </Grid>

      <Loading show={loading} />

      {arrayIsEmpty(pictures?.data) && !loading && canList && (
        <Typography mt={4} mb={2} variant="body1">
          {t("morphologicalData.noRecordsYet")}
        </Typography>
      )}

      {canList && pictures && !arrayIsEmpty(pictures?.data) && !loading && (
        <PhotosList
          images={pictures.data}
          onDelete={(image) => handlerDialogDeleteState(true, image)}
        />
      )}

      {openDialogAddPic && (
        <AddPicture
          data={sample.id}
          open={openDialogAddPic}
          onClose={handlerDialogAddPicState}
          onSuccess={updatePictures}
        />
      )}

      {canDelete && openDialogDelete && pictureSelected && (
        <PictureDelete
          data={pictureSelected}
          open={openDialogDelete}
          onClose={() => setOpenDialogDelete(false)}
          onSuccess={updatePictures}
        />
      )}
    </>
  );
}
