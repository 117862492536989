/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHasPermissions } from "hooks/usePermissions";
import { IAccount } from "interfaces";
import { IAdapterConfigurator } from "interfaces";
import { arrayIsEmpty, formatDate, PERMISSIONS, statusColor } from "utils";
import { ChipStatus } from "components/utils/ChipStatus";
interface IAccountGateways {
  account: IAccount;
}

function AccountGateways({ account }: IAccountGateways) {
  const { t } = useTranslation();

  const { hasPermission } = useHasPermissions();
  const canList = hasPermission([PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.VIEW]);

  return (
    <div>
      <Grid
        container
        mt={1}
        alignItems="center"
        direction="row"
        sx={{ justifyContent: "end" }}
      >
        <Grid item xs={true}>
        <Typography variant="h3">
            {t("accounts.adapters")}
          </Typography>
        </Grid>
      </Grid>

      {arrayIsEmpty(account?.adapter_configurators) && canList && (
        <Typography mt={4} mb={2} variant="body1">
          {t("adapters.thereAreNotAdapters")}
        </Typography>
      )}

      {account && !arrayIsEmpty(account?.adapter_configurators) && canList && (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("common.name")}</TableCell>
                  <TableCell>{t("common.statusLabel")}</TableCell>
                  <TableCell>{t("common.createdAt")}</TableCell>
                  <TableCell>{t("common.updatedAt")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {account.adapter_configurators.map(
                  (a: IAdapterConfigurator) => (
                    <TableRow key={a.id}>
                      <TableCell>{a.name}</TableCell>
                      <TableCell>
                        <ChipStatus
                          label={
                            a.is_active
                              ? t("common.active")
                              : t("common.inactive")
                          }
                          color={
                            a.is_active
                              ? statusColor("active")
                              : statusColor("non-active")
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {formatDate(
                          a.created_at,
                          t("calendar.dateTimeFormatLocal")
                        )}
                      </TableCell>
                      <TableCell>
                        {formatDate(
                          a.updated_at,
                          t("calendar.dateTimeFormatLocal")
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
}

export default AccountGateways;
