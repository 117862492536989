import { InputDateTime, useFeedback } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FileUpload, IFileUploadProps } from "components/utils/FileUpload/FileUpload";
import { useCreate, useErrorHandler } from "hooks";
import i18n from "i18n/config";
import { IDialogProps, IPictureForm } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { formatDate, nowDateTime } from "utils";
import { getSelectedModule } from "utils/permissions/getSelectedModule";

import { getSampleAddPictureSchema } from "../validations";

const AddPicture = ({
	data: id,
	open,
	onClose,
	onSuccess,
}: IDialogProps<string>) => {
	const { t } = useTranslation();
	const { addFeedback } = useFeedback();
	const { sendErrorMessage } = useErrorHandler();
	const auth = useSelector(getUser)
	const moduleID = auth && getSelectedModule(auth, 'dados_morfologicos')
	const headers = {
		headers: {
			'Content-Type': undefined,
			'MODULE-ID': moduleID
		}
	}

	//pedido para criar vegetative
	const {
		loading,
		error,
		refetch: uploadPhoto,
	} = useCreate<IPictureForm>(ServiceApiUrl.pictureURL, '', headers);

	const methodsAdd = useForm<IPictureForm>({
		mode: "onChange",
		defaultValues: { sample_id: id, registration_date: nowDateTime() },
		resolver: yupResolver(
			getSampleAddPictureSchema(
				t("validations.requiredPhoto"),
				t("validations.invalidFileSizePhoto"),
				t("validations.invalidFileFormat")
			)
		),
	});

	//sucesso
	const handlerSuccess = () => {
		onClose && onClose(); //fecha modal
		addFeedback({
			message: t("morphologicalData.picture.pictureAdded"),
			severity: "success",
		});
		onSuccess && onSuccess();
	};
	//erro
	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error]);

	//ao submeter o formulário
	const formSubmitHandler: SubmitHandler<IPictureForm> = async (
		payLoad: IPictureForm
	) => {
		payLoad.registration_date = formatDate(
			payLoad.registration_date,
			t("calendar.dateTimeFormatLocalSeconds")
		);

		// console.log("payLoad ", payLoad);

		const formData = new FormData();
		formData.append("registration_date", payLoad.registration_date);
		formData.append("sample_id", payLoad.sample_id);
		payLoad.files_upload?.forEach((file) => {
			formData.append("file", file);
		});

		try {
			await uploadPhoto({ data: formData });
			await handlerSuccess();
			// console.log("formData", formData);
		} catch (error) { }
	};

	const fileUploadProp: IFileUploadProps = {
		name: "files_upload",
		// ATENÇÃO se alterar o accept deve ser alterado a mensagem de validação common.allowedImages
		accept: { "image/png": [], "image/jpg": [], "image/jpeg": [] }
	};

	return (
		<Dialog maxWidth="md" fullWidth open={open}>
			<DialogTitle id="picture-dialog-title">
				<Typography
					component={"span"}
					color="text.black"
					gutterBottom={true}
					variant="h5"
					noWrap
				>
					{t("morphologicalData.picture.addPicture")}
				</Typography>
			</DialogTitle>

			<DialogContent>
				<FormProvider {...methodsAdd}>
					<form
						id="add-picture-form"
						onSubmit={methodsAdd.handleSubmit(formSubmitHandler)}
					>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography gutterBottom color="text.black" variant="h6">
									{t("morphologicalData.picture.date")}
								</Typography>
								<InputDateTime
									control={methodsAdd.control}
									name="registration_date"
									placeholder={t(
										"morphologicalData.picture.datePlaceholder"
									)}
									labelBtnCancel={t("common.cancel")}
									labelBtnConfirm={t("common.apply")}
									locale={i18n.language}
									maxDate={new Date()}
								/>
							</Grid>

							<Grid item xs={12}>
								<FileUpload {...fileUploadProp} />
							</Grid>
						</Grid>
					</form>
				</FormProvider>
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" color="primary" onClick={onClose} disabled={loading}>
					{t("common.cancel")}
				</Button>

				<LoadingButton
					loading={loading}
					variant="contained"
					color="primary"
					disabled={!methodsAdd.formState.isValid}
					type={"submit"}
					form={"add-picture-form"}
				>
					{t("common.add")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default AddPicture;
