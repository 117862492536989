/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useDelete } from "hooks";

import { ServiceApiUrl } from "services/ServiceApiUrl";
import { useEffect } from "react";
import { useErrorHandler } from "hooks/useErrorHandler";
import { IClient } from "interfaces/IClient";
import { ConfirmDialog, useFeedback } from "@alb/live-lib";

export interface IClientsDelete {
  client: IClient;
  open: boolean;
  handlerClose: () => void;
  updateList: () => void;
}

const ClientsDelete = ({
  client,
  open,
  handlerClose,
  updateList,
}: IClientsDelete) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const { sendErrorMessage } = useErrorHandler();

  const {
    loading,
    error,
    refetch: deleteClient,
  } = useDelete<IClient[]>(ServiceApiUrl.clientURL, client.id);

  //sucesso
  const handlerSuccess = () => {
    handlerClose();
    addFeedback({
      message: t("clients.clientDeleted"),
      severity: "success",
    });
    updateList();
  };
  //erro
  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error]);

  //eliminar client
  const handleDeleteClient = async () => {
    try {
      await deleteClient();
      await handlerSuccess();
    } catch {}
  };

  return (
		<ConfirmDialog
			open={open}
			type="error"
			title={`${t("clients.deleteClient")} ${client.name}`}
			message={t("clients.clientDeleteConfirmation")}
			actionConfirmText={t("common.delete")}
			actionCancelText={t("common.cancel")}
			loading={loading}
			onCancel={handlerClose}
			onConfirm={handleDeleteClient}
		/>
  );
};

export default ClientsDelete;
