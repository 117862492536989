import { Construction } from "@mui/icons-material";
import AppsIcon from '@mui/icons-material/Apps';
import BarChartIcon from '@mui/icons-material/BarChart';
import DashboardIcon from "@mui/icons-material/Dashboard";
import InfoIcon from "@mui/icons-material/Info";
import MapIcon from "@mui/icons-material/Map";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import { TFunction } from "i18next";

import { arrayIsEmpty, PERMISSIONS } from "utils";

import logo from '../images/logo.svg'
import smallLogo from '../images/small_logo.svg'

const sidebar = (
    t: TFunction,
    user: any | null,
    hasManyPermissions: Function,
    onClickLogout: Function,
    fromLocation: any | null,
    publicPortal: boolean,
) => {

    const menuItems = [
        {
            title: t("sidebar.menu.map"),
            tooltip: t("sidebar.menu.map"),
            path: "/",
            icon: <MapIcon />,
            permissions: [PERMISSIONS.MAP.VIEW],
          },
          {
            title: t("sidebar.menu.dashboard"),
            tooltip: t("sidebar.menu.dashboard"),
            path: "/dashboard",
            icon: <DashboardIcon />,
            permissions: [PERMISSIONS.DASHBOARD.VIEW],
          },
          {
            title: t("sidebar.menu.comparativeAnalysis"),
            tooltip: t("sidebar.menu.comparativeAnalysis"),
            path: "/comparativeanalysis",
            icon: <BarChartIcon />,
            permissions: [PERMISSIONS.DASHBOARD.COMPARATIVE_ANALYSIS.VIEW],
          },
          {
            title: t("sidebar.menu.modules"),
            tooltip: t("sidebar.menu.modules"),
            path: "",
            icon: <AppsIcon />,
            permissions: [PERMISSIONS.MODULES.VIEW],

            subItems: [
              {
                title: t("sidebar.menu.morphologicalData"),
                path: "/morphologicaldata/samples",
                icon: null,
                permissions: [PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.VIEW],
              },
            ],
          },
          {
            title: t("sidebar.menu.management"),
            tooltip: t("sidebar.menu.management"),
            path: "",
            icon: <SettingsIcon />,
            permissions: [PERMISSIONS.MANAGEMENT.VIEW],
            sortedSubItems: true,
            subItems: [
              {
                title: t("sidebar.menu.adapters"),
                path: "/management/adapters",
                permissions: [PERMISSIONS.MANAGEMENT.ADAPTERS.VIEW],
              },
              {
                title: t("sidebar.menu.users"),
                path: "/management/users",
                icon: <PersonIcon />,
                permissions: [PERMISSIONS.MY_PROFILE.VIEW],
              },
              {
                title: t("sidebar.menu.modules"),
                path: "/management/modules",
                permissions: [PERMISSIONS.MANAGEMENT.MODULES.VIEW],
              },
              {
                title: t("sidebar.menu.accounts"),
                path: "/management/accounts",
                permissions: [PERMISSIONS.MANAGEMENT.ACCOUNTS.VIEW],
              },
            ],
          },

          {
            title: t("sidebar.menu.administration"),
            tooltip: t("sidebar.menu.administration"),
            path: "",
            icon: <Construction />,
            permissions: [PERMISSIONS.ADMINISTRATION.VIEW],

            subItems: [
              {
                title: t("sidebar.menu.clients"),
                path: "/administration/clients",
                permissions: [PERMISSIONS.ADMINISTRATION.CLIENTS.VIEW],
              },
            ],
          },

          {
            title: t("sidebar.menu.about"),
            tooltip: t("sidebar.menu.about"),
            path: "/about",
            icon: <InfoIcon />,
            permissions: [PERMISSIONS.ABOUT.VIEW],
          },
    ]

    const menuUser = [
        {
          title: t("user.userSettings"),
          path: "/profile",
          fromLocation: fromLocation,
        },
        // {
        //   title: t("sidebar.notifications"),
        //   path: "",
        // },
        {
          title: t("common.logout"),
          onClick: onClickLogout,
        },
      ];

    function showPublicItems(items: any) {
        return items.filter((item: any) => item.title === t("sidebar.map"));
      }

      function filterHasPermissions(items: any) {
        if (!items) return [];
        // check parent item permissions
        const parentItems = items.filter((item: any) =>
          hasManyPermissions(item.permissions)
        );
        // check child item permissions
        const childItems = parentItems.map((item: any) => {
          if (arrayIsEmpty(item.subItems)) {
            return item;
          } else {
            let finalItem = item;
            //devolve apenas os subitems com permissões.
            let subItemsWithPermissions = finalItem.subItems.filter((e: any) =>
              hasManyPermissions(e.permissions)
            );
            //se tiver pelo menos 1 com permissões, devolve o item, mas apenas com os subitems com permissões.
            if (subItemsWithPermissions.length > 0) {
              finalItem.subItems = subItemsWithPermissions;
              return finalItem;
            }
            return null;
          }
        });
        return childItems.filter((item: any) => item);
      }

      function showInfoUser() {
        if (user) {
          const auth = {
            user: {
              firstName: `${user?.first_name} ${user?.last_name}`,
            },
            userRole: {
              name: user?.is_superadmin
                ? t("user.SuperAdministrator")
                : user?.is_admin
                ? t("user.Administrator")
                : t("user.user"),
            },
          };
          return auth;
        } else {
          return null;
        }
      }

    return {
        logo: logo,
        smallLogo: smallLogo,
        items: publicPortal ? showPublicItems(menuItems)
        : filterHasPermissions(menuItems),
        auth: showInfoUser(),
        menuUser: user ? menuUser : null,
    }

}



export default sidebar;
