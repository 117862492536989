import { BackToButton, EntityInfo, EntityInfoItem, LayoutContext, Loading } from "@alb/live-lib";
import EditIcon from '@mui/icons-material/Edit';
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Tab, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useErrorHandler, useGet, useGetAll, useHasPermissions } from "hooks";
import { IApiResponse, ISelectOption } from "interfaces";
import { IClient } from "interfaces/IClient";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { ContainedTabs, TabPanelContainer } from "styles/css/components";
import { formatDate, PERMISSIONS } from "utils";
import FilterSelect from "utils/FilterSelect";
import history from "utils/history_g";
import { formatRoutePath } from "utils/routePath";

import AccountsListTab from "../accounts/AccountsListTab";
import GatewaysListTab from "../gateways/GatewaysListTab";
import ModulesListTab from "../modules/ModulesListTab";
import UsersListTab from "../users/UsersListTab";
import ClientsEdit from "./ClientsEdit";

const ClientsView = () => {
	// params
	let params = useParams();
	const location = useLocation();

	//traduções
	const { t } = useTranslation();
	const { sendErrorMessage } = useErrorHandler();
	const navigate = useNavigate();

	const [clientsOptions, setClientsOptions] = useState<ISelectOption[]>([]);

	const [currentTab, setCurrentTab] = useState("1")

	const {
		data: client,
		loading,
		error,
		refetch: refetchSingleClient
	} = useGet<IClient>(ServiceApiUrl.clientURL, params.id);

	const { data: allClients, refetch: refetchClients } = useGetAll<
		IApiResponse<IClient>
	>(ServiceApiUrl.clientURL, null, { manual: true });

	useEffect(() => {
		if (client) {
			// console.log("client", client)  ;
			refetchClients();
		}
	}, [client, refetchClients]);

	useEffect(() => {
		if (allClients) {
			const options = allClients?.data?.map((p: IClient) => ({
				label: p.name,
				value: p.id,
			}));
			// remove id from options
			const filteredOptions = options.filter((opt: ISelectOption) => { return opt.value !== params.id });
			setClientsOptions(filteredOptions);
		}
	}, [allClients]);

	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error]);

	useEffect(() => {
		if (params.idx) {
			const index = Number(params.idx);
			if (index >= 0 && index <= 4) setCurrentTab(index.toString());
		}
	}, [params.idx]);

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setCurrentTab(newValue);

		const path = formatRoutePath(location, params, {
			id: params.id,
			idx: newValue
		});
		history.push(path);
	};

	const handleSelectedClient = (selectedOption: any) => {
		// console.log('handleSelectedClient::: selectedOption', selectedOption, location.pathname, path);

		const path = formatRoutePath(location, params, {
			id: selectedOption.value,
			idx: "1",
		});
		navigate(path);
	};

	const customOptions = (data: IApiResponse<any>) => {
		return data.data?.map((c: any) => ({
			label: c.name,
			value: c.id,
		}));

	};
	const theme = useTheme();
	const { hasPermission } = useHasPermissions();
	const canEdit = hasPermission([PERMISSIONS.ADMINISTRATION.CLIENTS.CUD]);
	const [openDialogEdit, setOpenDialogEdit] = useState(false);

	const { addHeader } = useContext(LayoutContext);
	const header = {
		title: client ? client.name : "",
		backTo: (
			<BackToButton
				title={t("goBack")}
				onClick={() => navigate("/administration/clients")}
			/>
		),
		select: (client && <FilterSelect<any>
			apiUrl={ServiceApiUrl.clientURL}
			onSelected={handleSelectedClient}
			selectedValue={params?.id}
			customOptions={customOptions}
		/>),
		action: canEdit && (
			<Button
				color="primary"
				onClick={() => setOpenDialogEdit(true)}
				variant="contained"
				startIcon={<EditIcon />}
			>
				{t("clients.editClient")}
			</Button>)

	}

	useEffect(() => {
		addHeader(header);
	}, [client, clientsOptions])

	useEffect(() => {
		addHeader(header);
	}, [])

	return (
		<>
			<Loading show={loading} />

			{client && !loading && (
				<>
					<EntityInfo title={t("common.details")}>
						<>
							<EntityInfoItem label={t("clients.name")} value={client.name} />
							<EntityInfoItem
								label={t("clients.status")}
								value={
									client.is_active ? t("common.active") : t("common.inactive")
								}
							/>
							<EntityInfoItem
								label={t("common.createdAt")}
								value={formatDate(
									client.created_at,
									t("calendar.dateTimeFullFormat")
								)}
							/>
							<EntityInfoItem
								label={t("common.updatedAt")}
								value={formatDate(
									client.updated_at,
									t("calendar.dateTimeFullFormat")
								)}
							/>
						</>
					</EntityInfo>

					<Box
						mt={4}
						sx={{ borderBottom: 0, borderColor:theme.palette.secondary.main }}
					>

						<TabContext value={currentTab}>
							<ContainedTabs indicatorColor="secondary" onChange={handleChange} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
								<Tab label={t("lists.modulesList")} value="1" />
								<Tab label={t("lists.usersList")} value="2" />
								<Tab label={t("lists.adaptersList")} value="3" />
								<Tab label={t("lists.accountsList")} value="4" />
							</ContainedTabs>

							<TabPanel sx={{ padding: 0, paddingTop: 2 }} value="1">
								<TabPanelContainer>
									<ModulesListTab filters={{ clientID: client.id }} />
								</TabPanelContainer>
							</TabPanel>
							<TabPanel sx={{ padding: 0, paddingTop: 2 }} value="2">
								<TabPanelContainer>
									<UsersListTab filters={{ clientID: client.id }} />
								</TabPanelContainer>
							</TabPanel>
							<TabPanel sx={{ padding: 0, paddingTop: 2 }} value="3">
								<TabPanelContainer>
									<GatewaysListTab filters={{ clientID: client.id }} />
								</TabPanelContainer>
							</TabPanel>
							<TabPanel sx={{ padding: 0, paddingTop: 2 }} value="4">
								<TabPanelContainer>
									<AccountsListTab filters={{ clientID: client.id }} />
								</TabPanelContainer>
							</TabPanel>
						</TabContext>
					</Box>

				</>
			)}

			{canEdit && client && openDialogEdit && (
				<ClientsEdit
					client={client}
					open={openDialogEdit}
					handlerClose={() => setOpenDialogEdit(false)}
					updateList={refetchSingleClient}
				/>
			)}
		</>
	);
};

export default ClientsView;
