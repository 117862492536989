import { adminRoles } from "./adminRoles";
import { PERMISSIONS } from "./permissions";

export const superUserRoles = [
  ...adminRoles,
  


  // PERMISSIONS.USERS.REACTIVATE,
  // PERMISSIONS.USERS.ADMINISTRATION,
  PERMISSIONS.DASHBOARD.ENOANALYTICS.VIEW,
  PERMISSIONS.DASHBOARD.BANANASENSE.VIEW,
    
  PERMISSIONS.ADMINISTRATION.VIEW,
  PERMISSIONS.ADMINISTRATION.CLIENTS.VIEW,
  PERMISSIONS.ADMINISTRATION.CLIENTS.CUD,

  // PERMISSIONS.CLIENTS.ADD,
  // PERMISSIONS.CLIENTS.DELETE,
  // PERMISSIONS.CLIENTS.EDIT,
  // PERMISSIONS.CLIENTS.LIST,
  // PERMISSIONS.CLIENTS.VIEW,

  // PERMISSIONS.MODULES.ADD,
  // PERMISSIONS.MODULES.LIST,
  // PERMISSIONS.MODULES.EDIT,
  // PERMISSIONS.MODULES.DELETE
];
