import { InputDateTime, InputText, RequiredField, useFeedback } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RequiredFields } from "components/utils/forms/RequiredFields";
import { useCreate } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import i18n from "i18n/config";
import { IDialogProps, ISample, IVegetative } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { convertCmToMm, formatDate, nowDateTime } from "utils";
import { getSelectedModule } from "utils/permissions/getSelectedModule";

import { getSampleAddVegetativeSchema } from "../validations";

export default function VegetativeDevAdd({ data, open, onClose, onSuccess }: IDialogProps<ISample>) {
	const { t } = useTranslation();
	const { addFeedback } = useFeedback();
	const { sendErrorMessage } = useErrorHandler();
	const user = useSelector(getUser)
	const auth = useSelector(getUser)
	const moduleID = auth && getSelectedModule(auth, 'dados_morfologicos')
	const headers = {
		headers: {
			'MODULE-ID': moduleID
		}
	}

	//pedido para criar vegetative
	const {
		loading,
		error,
		refetch: createVegetative,
	} = useCreate<IVegetative[]>(ServiceApiUrl.vegetativeURL, '', headers);

	useEffect(() => {
		methodsAdd.reset();
	}, [onClose]);

	const methodsAdd = useForm<IVegetative>({
		mode: "onChange",
		defaultValues: {
			user: user.id,
			sample_id: data.id,
			registration_date: nowDateTime(),
			pseudostem_height: undefined,
			pseudostem_base_diameter: undefined,
			pseudostem_middle_third_perimeter: undefined,
			leaves_number: undefined,
		},
		resolver: yupResolver(getSampleAddVegetativeSchema()),
	});

	//sucesso
	const handlerSuccess = () => {
		onClose && onClose(); //fecha modal
		addFeedback({
			message: t("morphologicalData.vegetativeDev.vegetativeDevAdded"),
			severity: "success",
		});
		onSuccess && onSuccess();
	};
	//erro
	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);

	//ao submeter o formulário
	const formSubmitHandler: SubmitHandler<IVegetative> = async (
		payLoad: IVegetative
	) => {

		payLoad.registration_date = formatDate(
			payLoad.registration_date,
			t("calendar.dateTimeFormatLocalSeconds")
		);
		payLoad.pseudostem_base_diameter = convertCmToMm(
			payLoad.pseudostem_base_diameter
		);
		payLoad.pseudostem_height = convertCmToMm(payLoad.pseudostem_height);
		payLoad.pseudostem_middle_third_perimeter = convertCmToMm(
			payLoad.pseudostem_middle_third_perimeter
		);

		// console.log("payLoad ", payLoad);
		try {
			await createVegetative({ data: payLoad });
			await handlerSuccess();
		} catch (error) { }
	};

	return (
		<Dialog maxWidth="md" open={open} fullWidth={true}>
			<DialogTitle id="alert-dialog-title">
				<Typography
					component={"span"}
					color="text.black"
					gutterBottom={true}
					variant="h5"
					noWrap
				>
					{t("morphologicalData.vegetativeDev.addVegetativeDev")}
				</Typography>
			</DialogTitle>

			<DialogContent>
				<FormProvider {...methodsAdd}>
					<form
						id="add-veg-dev-form"
						onSubmit={methodsAdd.handleSubmit(formSubmitHandler)}
					>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography gutterBottom color="text.black" variant="h6">
									{t("morphologicalData.sampleLabel")}
								</Typography>
								<label>{data.name}</label>
							</Grid>

							<Grid item xs={12}>
								<RequiredField title={t("morphologicalData.registrationDate")} />
								<InputDateTime
									control={methodsAdd.control}
									name="registration_date"
									placeholder={t(
										"morphologicalData.registrationDatePlaceholder"
									)}
									labelBtnCancel={t("common.cancel")}
									labelBtnConfirm={t("common.apply")}
									locale={i18n.language}
									maxDate={new Date()}
								/>
							</Grid>

							<Grid item xs={12}>
								<RequiredField title={t("morphologicalData.vegetativeDev.pseudostemHeight")} />
								<InputText
									control={methodsAdd.control}
									type="number"
									endAdornment={"cm"}
									name="pseudostem_height"
									placeholder={t(
										"morphologicalData.vegetativeDev.pseudostemHeightPlaceholder"
									)}
								/>
							</Grid>

							<Grid item xs={12}>
								<RequiredField title={t("morphologicalData.vegetativeDev.diameterBasePseudostem")} />
								<InputText
									control={methodsAdd.control}
									type="number"
									endAdornment={"cm"}
									name="pseudostem_base_diameter"
									placeholder={t(
										"morphologicalData.vegetativeDev.diameterBasePseudostemPlaceholder"
									)}
								/>
							</Grid>

							<Grid item xs={12}>
								<RequiredField title={t("morphologicalData.vegetativeDev.perimeterMiddleThirdPseudostem")} />
								<InputText
									control={methodsAdd.control}
									type="number"
									endAdornment={"cm"}
									name="pseudostem_middle_third_perimeter"
									placeholder={t(
										"morphologicalData.vegetativeDev.perimeterMiddleThirdPseudostemPlaceholder"
									)}
								/>
							</Grid>

							<Grid item xs={12}>
								<RequiredField title={t("morphologicalData.vegetativeDev.numberLeaves")} />
								<InputText
									control={methodsAdd.control}
									type="number"
									name="leaves_number"
									placeholder={t(
										"morphologicalData.vegetativeDev.numberLeavesPlaceholder"
									)}
								/>
							</Grid>
						</Grid>
					</form>
				</FormProvider>
				<RequiredFields />
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" color="primary" onClick={onClose}>
					{t("common.cancel")}
				</Button>

				<LoadingButton
					loading={loading}
					variant="contained"
					color="primary"
					disabled={!methodsAdd.formState.isValid}
					type={"submit"}
					form={"add-veg-dev-form"}
				>
					{t("common.add")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}
