import { Loading, TableHeadCell, TableHeadOrder, TableHeadSort } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, Collapse, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ChipStatus } from "components/utils/ChipStatus";
import { useErrorHandler, useGetAll } from "hooks";
import { useHasPermissions } from "hooks/usePermissions";
import { IAdapterConfigurator, IApiResponse } from "interfaces";
import { IModule } from "interfaces/IModule";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { TableCellExpanded, TableExpanded, TableRowExpanded } from "styles/components-styles";
import { arrayIsEmpty, formatDate, formatOrderBy, PERMISSIONS, statusColor } from "utils";
import { ROWS_PER_PAGE } from "utils/pagination";
import { CustomTablePagination } from "utils/pagination/CustomTablePagination";

import ModulesAdd from "./ModulesAdd";
import ModulesDelete from "./ModulesDelete";
import ModulesEdit from "./ModulesEdit";

interface IModulesListTab {
  filters: { clientID: string };
}

function Row(props: {
  row: IModule;
  open: boolean;
  onClick: Function;
  actionsClick: {
    editAction: Function;
    deleteAction: Function;
  };
}) {
  const { row, open, onClick, actionsClick } = props;
  const { editAction, deleteAction } = actionsClick;
  const { t } = useTranslation();

  // PERMISSIONS
  const { hasPermission } = useHasPermissions();
  const crudPermissions = hasPermission([
    PERMISSIONS.ADMINISTRATION.CLIENTS.CUD,
  ]);

  const showExpandCell = !arrayIsEmpty(row.adapter_configurators);

  return (
    <>
      <TableRow
        className={clsx({ "no-pointer": !showExpandCell, expanded: open })}
        onClick={() => onClick(open ? "" : row.id)}
      >
        <TableCell sx={{ width: 50 }} size="small">
          {showExpandCell && (
            <IconButton size="small" sx={{padding: 0}}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>

        <TableCell>{row.name}</TableCell>
        <TableCell>
          <ChipStatus
            label={row.is_active ? t("common.active") : t("common.inactive")}
            color={
              row.is_active ? statusColor("active") : statusColor("non-active")
            }
          />
        </TableCell>
        <TableCell>
          {formatDate(row.created_at, t("calendar.dateTimeFormatLocal"))}
        </TableCell>
        <TableCell>
          {formatDate(row.updated_at, t("calendar.dateTimeFormatLocal"))}
        </TableCell>
        <TableCell sx={{textAlign: "right"}}>
          {crudPermissions && (
            <IconButton
              title={t("common.edit")}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                editAction(true, e, row);
              }}
              sx={{padding: 0}}
            >
              <EditIcon />
            </IconButton>
          )}

          {crudPermissions && (
            <IconButton
              title={t("common.delete")}
              aria-label="delete"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                deleteAction(true, e, row);
              }}
              sx={{padding: 0}}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      {showExpandCell && (
        <TableRowExpanded>
          <TableCellExpanded colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <GatewaysList gateways={row.adapter_configurators} />
            </Collapse>
          </TableCellExpanded>
        </TableRowExpanded>
      )}
    </>
  );
}

function GatewaysList(props: { gateways: IAdapterConfigurator[] }) {
  const { t } = useTranslation();
  const { gateways } = props;

  return (
    <>
      <Typography variant="h5" className="bold">
        {t("lists.adaptersList")}
      </Typography>

      <TableContainer>
        <Table sx={{ minWidth: 600, marginTop: 1 }}>
          <TableHead>
            <TableRow>
              <TableCell>{t("common.name")}</TableCell>
              <TableCell>{t("common.statusLabel")}</TableCell>
              <TableCell>{t("common.createdAt")}</TableCell>
              <TableCell>{t("common.updatedAt")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gateways.map((gateway: IAdapterConfigurator) => (
              <TableRow key={gateway.id}>
                <TableCell>{gateway.name}</TableCell>
                <TableCell>
                  <ChipStatus
                    label={
                      gateway.is_active
                        ? t("common.active")
                        : t("common.inactive")
                    }
                    color={
                      gateway.is_active
                        ? statusColor("active")
                        : statusColor("non-active")
                    }
                  />
                </TableCell>
                <TableCell>
                  {formatDate(
                    gateway.created_at,
                    t("calendar.dateTimeFormatLocal")
                  )}
                </TableCell>
                <TableCell>
                  {formatDate(
                    gateway.updated_at,
                    t("calendar.dateTimeFormatLocal")
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function ModulesListTab({ filters }: IModulesListTab) {
  const { sendErrorMessage } = useErrorHandler();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<TableHeadOrder>("asc");
  const [orderBy, setOrderBy] = useState<string>("name");

  const {
    data: modules,
    loading,
    error,
    refetch,
  } = useGetAll<IApiResponse<IModule>>(ServiceApiUrl.moduleURL, {
    client: filters.clientID,
    page: page,
    items: ROWS_PER_PAGE,
    order_by: formatOrderBy(order, orderBy),
  });

  //erro
  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error]);

  //para fazer nova chamada dos Módulos
  const updateModules = () => {
    refetch();
  };

  const [openRow, setOpenRow] = useState("");

  const { hasPermission } = useHasPermissions();
  const crudPermissions = hasPermission([
    PERMISSIONS.ADMINISTRATION.CLIENTS.CUD,
  ]);
  const canList = hasPermission([PERMISSIONS.ADMINISTRATION.CLIENTS.VIEW]);

  const [selectedModule, setSelectedModule] = useState<IModule>();
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  //atualiza o estado do modal de remover
  const handlerDialogDeleteModule = (
    open = false,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    module: IModule
  ) => {
    if (open) {
      e.stopPropagation(); //evitar que abra o event do parent (a row)
      setSelectedModule(module);
    }
    setOpenDialogDelete(!openDialogDelete);
  };

  //atualiza o estado do modal de editar
  const handlerDialogEditModule = (
    open = false,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    module: IModule
  ) => {
    if (open) {
      e.stopPropagation(); //evitar que abra o event do parent (a row)
      setSelectedModule(module);
    }
    setOpenDialogEdit(!openDialogEdit);
  };

  //Trocar página da listagem
  const handleChangePage = (
    newPage: number
  ) => {
    setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
  };

  const [openDialogAddMod, setOpenDialogAddMod] = useState(false);
  const onAddClick = () => {
    setOpenDialogAddMod(true);
  };

  const tableHeads: TableHeadCell[] = [
    {
      id: "expand_cell",
      label: "",
      notSortable: true,
    },
    {
      id: "name",
      label: t("common.name"),
    },
    {
      id: "is_active",
      label: t("common.statusLabel"),
    },
    {
      id: "created_at",
      label: t("common.createdAt"),
    },
    {
      id: "updated_at",
      label: t("common.updatedAt"),
    },
    {
      id: "action_cell",
      label: "",
      notSortable: true,
    },
  ];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div>
      <Grid
        container
        mt={1}
        alignItems="center"
        direction="row"
        sx={{ justifyContent: "end" }}
      >
        <Grid item xs={true}>
          <Typography variant="h3">
            {t("lists.modulesList")}
          </Typography>
        </Grid>
        {crudPermissions && (
          <Grid item>
            <Button
              color="primary"
              onClick={onAddClick}
              variant="contained"
              size="medium"
              startIcon={<AddIcon />}
            >
              {t("common.add")}
            </Button>
          </Grid>
        )}
      </Grid>
      <Loading show={loading} />

      {arrayIsEmpty(modules?.data) && !loading && canList && (
        <Typography mt={4} mb={2} variant="body1">
          {t("modules.noModules")}
        </Typography>
      )}

      {modules && !arrayIsEmpty(modules?.data) && !loading && (
        <>
          <TableContainer>
            <TableExpanded>
              <TableHeadSort
                headCells={tableHeads}
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
              />
              <TableBody>
                {modules?.data?.map((module: IModule, index: number) => (
                  <Row
                    key={module.id}
                    row={module}
                    open={module.id === openRow}
                    onClick={(id: string) => setOpenRow(id)}
                    actionsClick={{
                      editAction: handlerDialogEditModule,
                      deleteAction: handlerDialogDeleteModule,
                    }}
                  />
                ))}
              </TableBody>
            </TableExpanded>
          </TableContainer>

          <CustomTablePagination
            count={modules?.totalCount}
            page={page}
            totalPages={modules?.totalPages}
            onPageChange={handleChangePage}

          />
        </>
      )}

      {crudPermissions && openDialogAddMod && (
        <ModulesAdd
          clientID={filters.clientID}
          open={openDialogAddMod}
          handlerClose={() => setOpenDialogAddMod(false)}
          updateList={updateModules}
        />
      )}

      {crudPermissions && selectedModule && openDialogDelete && (
        <ModulesDelete
          module={selectedModule}
          clientID={filters.clientID}
          open={openDialogDelete}
          handlerClose={() => setOpenDialogDelete(false)}
          updateList={updateModules}
        />
      )}
      {crudPermissions && selectedModule && openDialogEdit && (
        <ModulesEdit
          module={selectedModule}
          clientID={filters.clientID}
          open={openDialogEdit}
          handlerClose={() => setOpenDialogEdit(false)}
          updateList={updateModules}
        />
      )}
    </div>
  );
}

export default ModulesListTab;
