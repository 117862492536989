import { BackToButton, EntityInfo, EntityInfoItem, LayoutContext, Loading } from "@alb/live-lib";
import { TabContext } from "@mui/lab";
import TabPanel from "@mui/lab/TabPanel";
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Tab, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

import { useErrorHandler, useGet, useGetAll } from "hooks";
import { IAccount, IApiResponse, ISelectOption } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { ContainedTabs, TabPanelContainer } from "styles/css/components";
import { formatDate } from "utils";
import FilterSelect from "utils/FilterSelect";
import { formatRoutePath } from "utils/routePath";

import AccountGateways from "./AccountGateways";

export default function AccountsDataView() {
  let params = useParams();
  const { t } = useTranslation();
  const { sendErrorMessage } = useErrorHandler();
  const location = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = useState("1");
  const [, setAccountsOptions] = useState<ISelectOption[]>([]);
  const theme = useTheme();
  const {
    data: account,
    loading,
    error,
  } = useGet<IAccount>(ServiceApiUrl.accountURL, params.id);

  const { data: allAcounts } = useGetAll<IApiResponse<IAccount>>(
    ServiceApiUrl.accountURL,
    null
  );

  //create account option for select
  useEffect(() => {
    if (allAcounts) {
      const options = allAcounts?.data?.map((p: IAccount) => ({
        label: p.name,
        value: p.id,
      }));
      const filteredOptions = options.filter((opt: ISelectOption) => {
        return opt.value !== params.id;
      });
      setAccountsOptions(filteredOptions);
    }
  }, [allAcounts, params.id]);

  //handle select change
  const handleSelectedAccount = (selectedOption: any) => {
    const path = formatRoutePath(location, params, {
      id: selectedOption.value,
      idx: params.idx,
    });
    navigate(path);
  };

  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error]);

  useEffect(() => {
    if (params.idx) {
      const index = Number(params.idx);
      if (index >= 0 && index <= 1) setValue(index.toString());
    }
  }, [params.idx]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue.toString());
  };

  const customOptions = (data: IApiResponse<any>) => {
    return data.data?.map((c: any) => ({
      label: c.name,
      value: c.id,
    }));
  };

  const { addHeader } = useContext(LayoutContext);
  const header = {
    title: account ? account.name : "",
    backTo: (
      <BackToButton
        title={t("goBack")}
        onClick={() => navigate("/management/accounts")}
      />
    ),
      select: account ? <FilterSelect<any>
                apiUrl={ServiceApiUrl.accountURL}
                onSelected={handleSelectedAccount}
                selectedValue={params?.id}
                customOptions={customOptions}
              /> : <></>
  }

  useEffect(() => {
    addHeader(header)
  }, [])

  useEffect(() => {
    addHeader(header)
  }, [account])

  return (
    <>
      <Loading show={loading} />

      {account && !loading && (
        <>
          <EntityInfo title={t("common.details")}>
            <>
              <EntityInfoItem label={t("accounts.name")} value={account.name} />
              <EntityInfoItem
                label={t("accounts.status")}
                value={
                  account.is_active ? t("common.active") : t("common.inactive")
                }
              />
              <EntityInfoItem
                label={t("common.createdAt")}
                value={formatDate(
                  account.created_at,
                  t("calendar.dateTimeFormatLocal")
                )}
              />
              <EntityInfoItem
                label={t("common.updatedAt")}
                value={formatDate(
                  account.updated_at,
                  t("calendar.dateTimeFormatLocal")
                )}
              />
            </>
          </EntityInfo>

          <Box
            mt={4}
            sx={{ borderBottom: 0, borderColor: theme.palette.secondary.main }}
          >

            <TabContext value={value}>
              <ContainedTabs indicatorColor="secondary" onChange={handleChange} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                <Tab label={t("accounts.adapters")} value="1" />
              </ContainedTabs>

              <TabPanel sx={{ padding: 0, paddingTop: 2 }} value="1">
                <TabPanelContainer>
                  <AccountGateways account={account} />
                </TabPanelContainer>
              </TabPanel>

            </TabContext>
          </Box>
        </>
      )}
    </>
  );
}
