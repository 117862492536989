import { Typography } from "@mui/material";

/**
 * 
 * @param props : {children: React.ReactNode}
 * @returns 
 */
export const FieldErrorMessage = (props: {children:React.ReactNode}) => {
  
  return  <Typography variant="caption" color="error">{props.children}</Typography>;
  
};