import useAxios from "axios-hooks";
import { IFetchDataOptions } from "interfaces";
import { AxiosRequestConfig } from "axios";

export function useGet<T = any>(
  url: string,
  id?: string | undefined,
  params?: any,
  options ?: IFetchDataOptions,
  othersConfig?: AxiosRequestConfig<any>
    ) {

  const config: AxiosRequestConfig = {
    ...othersConfig,
    url: id ? url +"/" + id : url,
    params: { ...params } 
  };
   const [{ data, loading, error }, refetch, cancelRequest] = useAxios<T>(
    config,
     { manual: options?.manual }
   );
 
   return { data, loading, error, refetch, cancelRequest };
}