/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useDelete } from "hooks";
import {ConfirmDialog, useFeedback} from "@alb/live-lib";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { useEffect } from "react";
import { useErrorHandler } from "hooks/useErrorHandler";
import { IPulpColor } from "interfaces";
import { getSelectedModule } from "utils/permissions/getSelectedModule";
import { getUser } from "store/slices/authSlice";
import { useSelector } from "react-redux";

export default function DeletePulpColor(props: any) {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const { sendErrorMessage } = useErrorHandler();
  const auth = useSelector(getUser)
  const moduleID = auth && getSelectedModule(auth, 'dados_morfologicos')
  const headers = {
    headers: {
      'MODULE-ID': moduleID
    }
  }

  const {
    loading,
    error,
    refetch: deletePulpColor,
  } = useDelete<IPulpColor>(ServiceApiUrl.pulpColorsListURL, props.item.id, headers);

  //sucesso
  const handlerSuccess = () => {
    props.handlerClose();
    addFeedback({
      message: t("morphologicalData.pulpColors.pulpColorDeleted"),
      severity: "success",
    });
    props.updateGet();
  };
  //erro
  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error]);

  //apaga cor da polpa
  const handleDeletePulpColor = async () => {
    try {
      await deletePulpColor();
      await handlerSuccess();
    } catch {}
  };

  return (
		<ConfirmDialog
			open={props.open}
			type="error"
			title={`${t("morphologicalData.pulpColors.deletePulpColor")} ${props.item.name}`}
			message={t('morphologicalData.pulpColors.pulpColorDeleteConfirmation')}
			actionConfirmText={t("common.delete")}
			actionCancelText={t("common.cancel")}
			loading={loading}
			onCancel={props.handlerClose}
			onConfirm={handleDeletePulpColor}
		/>
  );
}
