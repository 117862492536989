import { Feedback, getTheme, Loading, ThemeNames } from "@alb/live-lib";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getUser } from "store/slices/authSlice";
import { THEME_KEY } from "utils";
import { getCookie } from "utils/cookie";
import LivePage from "views/page";

import "./styles/scss/main.scss";

// loading component for suspense fallback (i18n)
const Loader = () => <Loading show={true} />;

function App() {
  const { i18n } = useTranslation();
  const user = useSelector(getUser);
  var browserLanguage = navigator.language.slice(0, 2); //get browser language
  //theme
  const theme = getCookie<string>(THEME_KEY);
  const darkMode = (theme === "dark" ?? false) || user?.extra_params?.dark_mode;
  const lng = i18n.language || browserLanguage;
  const finalTheme = getTheme(ThemeNames.Green, lng, darkMode);

  /*o atributo "data-theme" teve de ser aplicado ao body, caso contrário, não tem efeito sobre
	o conteúdo dos dialogs, uma vez que os dialogs são gerados numa camada diferente da root e
	este componente diz respeito apenas à root */
  useEffect(() => {
    document.body.setAttribute("data-theme", darkMode ? "dark" : "");
  }, [darkMode]);

  return (
    <ThemeProvider theme={finalTheme}>
      <CssBaseline />
      <Feedback>
        <Suspense fallback={<Loader />}>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <LivePage />
          </Box>
        </Suspense>
      </Feedback>
    </ThemeProvider>
  );
}

export default App;
