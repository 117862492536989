import { FormControl, Select } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import React from "react";
import "./SelectMultipleStreams.scss";


interface ISelectMultipleStreams {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  renderValue: any,
  defaultValue?: any,
  value: any,
  onChange: any,
  children: any,
}

const SelectMultipleStreams = ({
  open,
  setOpen,
  renderValue,
  defaultValue,
  value,
  onChange,
  children,
}: ISelectMultipleStreams) => {

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl
      style={{ minWidth: 100, maxWidth: 350 }}
      className="live__multipleSelect"
    >
      <Select
        displayEmpty
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        renderValue={renderValue}
        defaultValue={defaultValue}
        size="small"
        multiple
        labelId="demo-multiple-select-label"
        id="demo-multiple-select"
        value={value}
        onChange={onChange}
        IconComponent={KeyboardArrowDownRoundedIcon}
      >
        {children}
      </Select>
    </FormControl>
  )
}

export default SelectMultipleStreams;