/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Chip, Grid, Typography } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IAdapterConfigurator, IDevice, ISelectOption, IStream } from "interfaces";
import LiveDoubleSelect from "views/comparativeAnalysis/components/double-select/LiveDoubleSelect";

interface IDoubleSelectDeviceStreams {
  defaultItem?: ISelectedDeviceAndStreams;
  deviceNumber: string;
  adapters: IAdapterConfigurator[];
  setDevicesAndStreams(device: IDevice | undefined, streams: IStream[]): void;
}
interface ISelectedDeviceAndStreams {
  device: IDevice | undefined;
  streams: IStream[];
}

let adapterAlreadyChanged = false;
let selectedStreamsAux: IStream[] = []; //auxiliar

const DoubleSelectDeviceStreams = ({
  defaultItem,
  deviceNumber,
  adapters,
  setDevicesAndStreams,
}: IDoubleSelectDeviceStreams) => {
  const { t } = useTranslation();

  const [selectedStreams, setSelectedStreams] = useState< IStream[]>();
  const [allAdaptersToList, setAllAdaptersToList] = useState<ISelectOption[]>(
    []
  );
  const [allDevices, setAllDevices] = useState<IDevice[]>();
  const [devicesToList, setDevicesToList] = useState<ISelectOption[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<IDevice | undefined>(
    defaultItem ? defaultItem?.device : undefined
  );

  const [selectedAdapter, setSelectedAdapter] = useState<
  IAdapterConfigurator | undefined
  >(
    defaultItem
      ? adapters?.find(
          (g: IAdapterConfigurator) =>
            g.id === defaultItem?.device?.adapter_configurators_id
        )
      : undefined
  );
  const [allStreamsDevice, setAllStreamsDevice] = useState<IStream[]>();
  const [selectedStreamsIDS, setSelectedStreamsIDS] = useState(
    new Set<string>()
  );

  //se existir o defaultItem, ao abrir o componente, vai colocar também as streams
  useEffect(() => {
    if (defaultItem) {
      setAllStreamsDevice(getAdapterStreams(selectedAdapter));
      setSelectedStreamsIDS(new Set(defaultItem?.streams.map((e) => e.id)));
    }
  }, []);

  function setAdaptersToList(adapters: IAdapterConfigurator[]) {
    let adaptersOptions = adapters?.map((g: IAdapterConfigurator) => ({
      label: g.name,
      value: g.id,
    }));
    adaptersOptions = [...adaptersOptions].sort((a, b) =>
      a.label > b.label ? 1 : -1
    );
    setAllAdaptersToList(adaptersOptions);
    return adaptersOptions;
  }

  useEffect(() => {
    adapterAlreadyChanged = false;
  }, []);

  //tratamento inicial dos dados
  useEffect(() => {
    let adaptersOptions = setAdaptersToList(adapters);
    let allDevices_ = Array.prototype.concat.apply(
      [],
      adapters?.map((g: IAdapterConfigurator) =>
        g.devices.map((d) => ({
          ...d,
          adapter_configurators_id: g.id,
        }))
      )
    );
    setAllDevices(allDevices_);

    //se existir um defaultItem, não coloca os valores default
    if (!defaultItem) {
      //guarda o primeiro adaptador da lista de adaptadores num estado
      setSelectedAdapter(
        adapters?.find(
          (g: IAdapterConfigurator) => g.id === adaptersOptions[0].value
        )
      );
    }
  }, [adapters]);

  useEffect(() => {
    if (selectedAdapter) setSelectedAdapterDevices(); //vai buscar os devices do adaptador selecionado
  }, [selectedAdapter]);

  //sempre que forem selecionadas ou alteradas streams, envia a informação dos devices e streams para o addchart.
  useEffect(() => {
    setDevicesAndStreamsFunction();
  }, [selectedStreamsIDS.size]);

  //para colocar os devices do adaptador selecionado em estado
  const setSelectedAdapterDevices = () => {
    if (selectedAdapter) {
      setDevicesToList(
        selectedAdapter?.devices.map((d: any) => ({
          label: d.name,
          value: d.id,
        }))
      );
    }
  };

  //envia a informação dos devices e streams para o addchart ou editChart.
  const setDevicesAndStreamsFunction = () => {
    selectedStreamsAux = [];
    if (selectedStreamsAux && selectedStreamsIDS.size > 0) {
      //devolver array com as streams selecionadas completas
      selectedAdapter?.streams.map((stream: IStream) => {
        if (Array.from(selectedStreamsIDS).includes(stream.id)) {
          selectedStreamsAux = [...selectedStreamsAux, stream];
        }
      });
    }
    setSelectedStreams(selectedStreamsAux);
    setDevicesAndStreams(selectedDevice, selectedStreamsAux); //envia a os devices e as streams para o pai, (addChart ou editChart)
  };

  //controla a seleção das chips
  function handleSelectionChanged(id: string) {
    const setOfSelectedStreamsIDs = new Set(selectedStreamsIDS);
    //se ainda não tiver streams, adiciona, se estiverem, ao clicar novamente, remove
    //o bloqueio por unidade está a ser feito no próprio componente das chips
    if (setOfSelectedStreamsIDs.has(id)) {
      setOfSelectedStreamsIDs.delete(id);
    } else {
      setOfSelectedStreamsIDs.add(id);
    }
    setSelectedStreamsIDS(setOfSelectedStreamsIDs);
  }

  const isAdapterSmartParking = (adapter: any) => {
    return (
      adapter?.domain === "mobility" &&
      adapter?.partner?.name === "SmartParkingFunchal"
      // adapter?.partner?.name === "SmartParking"
    );
  };

  function getAdapterStreams(adapter: IAdapterConfigurator | undefined) {
    return isAdapterSmartParking(adapter)
      ? adapter?.streams.filter(
          (stream: IStream) => stream.name !== "park_type" //se for smartParking, não mostra o tipo de estacionamento
        )
      : adapter?.streams;
  }

  function handleChangeDevice(
    e: SyntheticEvent<Element, Event>,
    value: ISelectOption
  ) {
    //se o device tiver sido removido, dá reset ao valor dos devices guardados
    if (!value) {
      setSelectedStreamsIDS(new Set()); //limpa as chips selecionadas
      setSelectedDevice(undefined);
      setAllStreamsDevice(undefined);
      return;
    }

    let device = allDevices?.find(
      (device: IDevice) => device.id === value.value
    );

    let device_ = {
      ...device,
      adapter_domain: selectedAdapter?.domain,
      adapter_partner: selectedAdapter?.partner,
    };

    setSelectedStreamsIDS(new Set()); //limpa as chips selecionadas
    setSelectedDevice(device_ as IDevice);
    setAllStreamsDevice(getAdapterStreams(selectedAdapter));
  }

  // //ao alterar o valor da variavel do adapter do smartParking...
  // useEffect(() => {
  //   let finalAdapters: IAdapterConfigurator[] = adapters;
  //   if (adapterAlreadyChanged) {
  //       let options = setAdaptersToList(finalAdapters);

  //       setSelectedAdapter(
  //         adapters?.find((g: IAdapterConfigurator) => g.id === options[0].value)
  //       );
  //       setAllStreamsDevice([]); //limpa as chips apresentadas e selecionadas
  //       setSelectedStreamsIDS(new Set());

  //   }
  // }, [sParkingF.smartParking]);

  function handleChangeAdapter(e: React.ChangeEvent<HTMLInputElement>) {
    if (!adapterAlreadyChanged) adapterAlreadyChanged = true;
    setSelectedAdapter(
      adapters?.find((g: IAdapterConfigurator) => g.id === e.target.value)
    );
    setAllStreamsDevice([]); //limpa as chips apresentadas e selecionadas
    setSelectedStreamsIDS(new Set());
  }
  return (
    <>
      {devicesToList.length > 0 && allAdaptersToList.length > 0 && (
        <Grid item xs={12}>
          <Typography gutterBottom color="text.black" variant="body1">
            {t("comparativeAnalysis.device", { number: deviceNumber })}
          </Typography>

          <LiveDoubleSelect
            firstItemDefaultValue={
              defaultItem?.device?.adapter_configurators_id
            }
            secondItemDefaultValue={
              defaultItem?.device && {
                label: defaultItem?.device?.name,
                value: defaultItem?.device?.id,
              }
            }
            firstItem={allAdaptersToList}
            secondItem={devicesToList}
            handleChangeFirstItem={handleChangeAdapter}
            handleChangeSecondItem={handleChangeDevice}
          />

          {allStreamsDevice?.map((c: IStream) => (
            <Chip
              sx={{ margin: ".5rem .5rem 0 0" }}
              color="primary"
              key={c.id}
              label={`${t(`streams.${c.name.toLowerCase()}`)} (${c.unit})`}
              onClick={() => handleSelectionChanged(c.id)}
              variant={selectedStreamsIDS.has(c.id) ? "filled" : "outlined"}
              disabled={
                selectedStreams && selectedStreams.length > 0
                  ? selectedStreams[0].unit !== c.unit
                  : false
              }
            />
          ))}
        </Grid>
      )}
    </>
  );
};
export default DoubleSelectDeviceStreams;
