import { IDialogProps } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import CircleIcon from "@mui/icons-material/Circle";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { selectDeviceRealTime } from "store/slices/adapterConfiguratorsSlice";
import { selectDetailsError, selectDetailsLoading, selectMarkerDetails, setMarkerDetails } from "store/slices/mapSlice";
import { updateDeviceInfo } from "utils/data-real-time";
import { formatDate } from "utils/date";
import NoData from "utils/NoData";
import { formattedLocation } from "utils/utils";

import LiveDialogTitle from "./DialogTitle";
import MapEntiyInfoEmpty from "./MapEntiyInfoEmpty";
import MapEntiyInfoItem from "./MapEntiyInfoItem";

const DeviceInfo = ({ open, onClose }: IDialogProps) => {
  const { t } = useTranslation();

  const deviceEventSource = useSelector(selectDeviceRealTime);
  const data = useSelector(selectMarkerDetails);
  const loading = useSelector(selectDetailsLoading);
  const error = useSelector(selectDetailsError);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onShowDetail = () => {
    navigate(`/dashboard/${data.id}`);
  };
  let streamList = <NoData />;
  if (data) {
    if (
      data.adapter_configurators[0].domain === "mobility" &&
      data.adapter_configurators[0].partner__name === "SmartParkingFunchal"
    ) {
      streamList = (
        <>
          {Object.keys(data.last_read_value).map(
            (stream: any, index: number) => (
              <MapEntiyInfoItem
                key={index}
                label={`${t(`streams.${stream}`)}`}
                value={
                  data.last_read_value[stream].value
                    ? t(
                        "typesParking." +
                          stream.toLowerCase() +
                          "." +
                          data.last_read_value[stream].value
                            .toLowerCase()
                            .split(" ")[0]
                      )
                    : "--"
                }
              />
            )
          )}
        </>
      );
    } else {
      streamList = (
        <>
          {Object.keys(data.last_read_value).map(
            (stream: any, index: number) => (
              <MapEntiyInfoItem
                key={index}
                label={`${t(`streams.${stream.toLowerCase()}`)}`}
                value={
                  data.last_read_value[stream].value
                    ? data.last_read_value[stream].value
                    : "--"
                }
              />
            )
          )}
        </>
      );
    }
  }

  useEffect(() => {
    // --- Smart Parking Funchal Devices - SSE
    if (data && deviceEventSource.external_id === data?.external_id) {
      showValuesRealTime(deviceEventSource);
    }
  }, [deviceEventSource]);

  function showValuesRealTime(values: any) {
    const infoDevice = updateDeviceInfo(data, values);
    dispatch(
      setMarkerDetails({
        data: infoDevice,
        loading: false,
        error: false,
      })
    );
  }

  return (
    <Dialog maxWidth="xs" fullWidth open={open}>
      {data && !loading && !error ? (
        <>
          <LiveDialogTitle onClose={onClose}>
            <Grid sx={{ display: "flex", alignItems: "baseline" }}>
              <Typography fontWeight={700} fontSize={18}>
                {data?.name}
              </Typography>
              <CircleIcon
                sx={{ fontSize: 12, marginLeft: 1 }}
                color={data?.is_active ? "success" : "error"}
                titleAccess={
                  data.is_active ? t("common.active") : t("common.inactive")
                }
              />
            </Grid>
          </LiveDialogTitle>
          <DialogContent sx={{ pt: 0 }}>
            {/* <Typography mb={2} variant="h6" m={0}>
              {t(`domains.${data.adapter_configurators[0].domain_devo}`)}
            </Typography> */}

            <Grid container>
              <MapEntiyInfoItem
                label={t(`domains.${data.adapter_configurators[0].domain}`)}
              />
              <MapEntiyInfoItem
                value={formattedLocation(data.geolocation.iconPosition)}
                icon={
                  <LocationOnRoundedIcon
                    color="primary"
                    sx={{ fontSize: "20px" }}
                  />
                }
              />
              <MapEntiyInfoItem
                label={t("map.marker.lastReading")}
                value={`${
                  data.last_read_at
                    ? formatDate(
                        data.last_read_at,
                        t("calendar.dateTimeFullFormat")
                      )
                    : "n/a"
                }`}
                icon={
                  <AccessTimeRoundedIcon
                    color="primary"
                    sx={{ fontSize: "18px" }}
                  />
                }
              />

              <MapEntiyInfoEmpty />
              {streamList}
            </Grid>
          </DialogContent>
          <DialogActions>
            {data.is_active && (
              <Button variant="outlined" color="primary" onClick={onShowDetail}>
                {t("common.showDetail")}
              </Button>
            )}
          </DialogActions>
        </>
      ) : (
        <LiveDialogTitle onClose={onClose}>
          <Typography fontWeight={700} fontSize={18}>
            {loading && t("labelLoading")}
            {error && !loading && t("errors.noAction")}
          </Typography>
        </LiveDialogTitle>
      )}
    </Dialog>
  );
};

export default DeviceInfo;
