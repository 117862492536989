/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "hooks/useErrorHandler";
import {useFeedback} from "@alb/live-lib";
import { getNameAddSchema } from "../../validations";
import { useCreate } from "hooks";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { IPulpColor } from "interfaces";
import { getSelectedModule } from "utils/permissions/getSelectedModule";
import { getUser } from "store/slices/authSlice";
import { useSelector } from "react-redux";
import { InputText, RequiredField } from "@alb/live-lib";
import { RequiredFields } from "components/utils/forms/RequiredFields";

export default function AddPulpColor(props: any) {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const { sendErrorMessage } = useErrorHandler();
  const auth = useSelector(getUser)
  const moduleID = auth && getSelectedModule(auth, 'dados_morfologicos')
  const headers = {
    headers: {
      'MODULE-ID': moduleID
    }
  }

  //pedido para adicionar cor da polpa
  const {
    loading,
    error,
    refetch: createPulpColor,
  } = useCreate<string[]>(ServiceApiUrl.pulpColorsListURL, '', headers);

  useEffect(() => {
    methodsAdd.reset();
  }, [props.handlerClose]);

  //sucesso
  const handlerSuccess = () => {
    props.handlerClose();
    addFeedback({
      message: t("morphologicalData.pulpColors.pulpColorAdded"),
      severity: "success",
    });
    props.updateGet();
  };
  //erro
  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error]);

  const methodsAdd = useForm<IPulpColor>({
    mode: "onChange",
    resolver: yupResolver(getNameAddSchema()),
  });

  const { control } = methodsAdd;


  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IPulpColor> = async (
    payLoad: IPulpColor
  ) => {
    try {
      await createPulpColor({ data: payLoad });
      await handlerSuccess();
    } catch (error) { }
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={props.open}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          component={"span"}
          color="text.black"
          gutterBottom={true}
          variant="h5"
          noWrap
        >
          {t("morphologicalData.pulpColors.pulpColorAdd")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <FormProvider {...methodsAdd}>
          <form
            id="add-user-form"
            onSubmit={methodsAdd.handleSubmit(formSubmitHandler)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RequiredField title={t("morphologicalData.sample.pulpColor")} />
                <InputText
                  control={control}
                  name="name"
                  placeholder={t("morphologicalData.pulpColors.pulpColorPlaceholder")}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <RequiredFields allRequired />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={props.handlerClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!methodsAdd.formState.isValid}
          type={"submit"}
          form={"add-user-form"}
        >
          {t("common.add")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
