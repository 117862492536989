import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectDevicesFilterParams } from "store/slices/adapterConfiguratorsSlice";
import { getSelectedMarkerType } from "store/slices/mapSlice";
import { objectIsEmpty } from "utils";

const useGetFiltersCount = () => {
  let mapDeviceParams = useSelector(selectDevicesFilterParams);
  const selectedType = useSelector(getSelectedMarkerType);

  const [filterCount, setFilterCount] = useState(0);

  useEffect(() => {
    const allFiltersParams = {
      ...mapDeviceParams,
      ...(selectedType !== "all" && { selectedType }),
    };

    Object.keys(allFiltersParams).forEach(
      (key) =>
        allFiltersParams[key] === undefined && delete allFiltersParams[key]
    );
    setFilterCount(
      !objectIsEmpty(allFiltersParams)
        ? Object.keys(allFiltersParams).length
        : 0
    );
  }, [mapDeviceParams, selectedType]);

  return filterCount;
};

export default useGetFiltersCount;
