import { LoadingButton } from "@mui/lab";
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useDelete } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";

import { IModule } from "interfaces/IModule";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { useFeedback } from "@alb/live-lib";

export interface IModulesDelete {
  module: IModule;
  clientID: string;
  open: boolean;
  handlerClose: () => void;
  updateList: () => void;
}

const ModulesDelete = ({
  module,
  clientID,
  open,
  handlerClose,
  updateList,
}: IModulesDelete) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const { sendErrorMessage } = useErrorHandler();

  const {
    loading,
    error,
    refetch: deleteModule,
  } = useDelete<IModule[]>(ServiceApiUrl.clientModule(clientID));

  //sucesso
  const handlerSuccess = () => {
    handlerClose();
    addFeedback({
      message: t("modules.moduleDeleted"),
      severity: "success",
    });
    updateList();
  };
  //erro
  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error]);

  // eliminar módulo
  const handleDeleteModule = async () => {
    const payLoad = {"modules": [module.id]}
    try {
      await deleteModule({ data: payLoad });
      await handlerSuccess();
    } catch {}
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={handlerClose}>
      <DialogTitle>
        <Typography
          component={"span"}
          color="text.black"
          gutterBottom={true}
          variant="h5"
        >
          {`${t("modules.deleteModule")} ${module.name}`}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Typography variant="body1">
          {t("modules.moduleDeleteConfirmation")}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handlerClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="error"
          onClick={handleDeleteModule}
        >
          {t("common.delete")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ModulesDelete;
