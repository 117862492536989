
import { IDialogProps } from "@alb/live-lib";
import DeviceInfo from "./DeviceInfo";

const lookupComponent = {
  device: DeviceInfo,
}

const MarkerInfoComponent = (props: { type: string, dialogProps: IDialogProps, onSuccess?: () => void}) => {
  const Component = lookupComponent[props.type as keyof typeof lookupComponent];

  const handleCloseEdit = () => {
    props.onSuccess && props.onSuccess()
  };
  
  return (
    <Component {...props.dialogProps} onSuccess={handleCloseEdit}/>
  )
}

export default MarkerInfoComponent;