import { InputDateTime, InputText, RequiredField, useFeedback } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RequiredFields } from "components/utils/forms/RequiredFields";
import { useUpdate } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import i18n from "i18n/config";
import { IVegetative } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { convertCmToMm, convertMmToCm_inputNumber, formatDate } from "utils";
import { getSelectedModule } from "utils/permissions/getSelectedModule";

import { getSampleEditVegetativeSchema } from "../validations";

export default function VegetativeDevEdit(props: any) {
	const { t } = useTranslation();
	const { addFeedback } = useFeedback();
	const { sendErrorMessage } = useErrorHandler();
	const auth = useSelector(getUser)
	const moduleID = auth && getSelectedModule(auth, 'dados_morfologicos')
	const headers = {
		headers: {
			'MODULE-ID': moduleID
		}
	}

	const {
		loading,
		error,
		refetch: updateVegetative,
	} = useUpdate<IVegetative[]>(
		ServiceApiUrl.vegetativeURL,
		props.vegetativeDev.id,
		headers
	);

	//sucesso
	const handlerSuccess = () => {
		props.handlerClose();
		addFeedback({ message: t("common.changesSaved"), severity: "success" });
		props.updateGetVegetative();
	};
	//erro
	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);

	useEffect(() => {
		methodsEdit.reset();
	}, [props.handlerClose]);

	const methodsEdit = useForm<IVegetative>({
		defaultValues: {
			registration_date: props.vegetativeDev.registration_date,
			pseudostem_height: convertMmToCm_inputNumber(props.vegetativeDev.pseudostem_height),
			pseudostem_base_diameter: convertMmToCm_inputNumber(props.vegetativeDev.pseudostem_base_diameter),
			pseudostem_middle_third_perimeter: convertMmToCm_inputNumber(props.vegetativeDev.pseudostem_middle_third_perimeter),
			leaves_number: props.vegetativeDev.leaves_number,
		},
		mode: "onChange",
		resolver: yupResolver(getSampleEditVegetativeSchema()),
	});

	const formEditSubmitHandler: SubmitHandler<IVegetative> = async (
		payLoad: IVegetative
	) => {
		if (props.vegetativeDev) {
			payLoad.registration_date = formatDate(
				payLoad.registration_date,
				t("calendar.dateTimeFormatLocalSeconds")
			);
			payLoad.pseudostem_base_diameter = convertCmToMm(
				payLoad.pseudostem_base_diameter
			);
			payLoad.pseudostem_height = convertCmToMm(payLoad.pseudostem_height);
			payLoad.pseudostem_middle_third_perimeter = convertCmToMm(
				payLoad.pseudostem_middle_third_perimeter
			);

			// console.log("payLoad ", payLoad);
			try {
				await updateVegetative({ data: payLoad }); //enviar os dados
				await handlerSuccess();
			} catch { }
		}
	};

	return (
		<>
			<Dialog
				maxWidth="md"
				open={props.open}
				fullWidth={true}
			>
				<DialogTitle>
					<Typography
						component={"span"}
						color="text.black"
						gutterBottom={true}
						variant="h5"
						noWrap
					>
						{t("morphologicalData.vegetativeDev.vegetativeDevEdit")}
					</Typography>
				</DialogTitle>

				<DialogContent>
					<FormProvider {...methodsEdit}>
						<form
							id="edit-form"
							onSubmit={methodsEdit.handleSubmit(formEditSubmitHandler)}
						>
							{props.vegetativeDev && (
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<RequiredField title={t("morphologicalData.registrationDate")} />
										<InputDateTime
											control={methodsEdit.control}
											name="registration_date"
											placeholder={t(
												"morphologicalData.registrationDatePlaceholder"
											)}
											labelBtnCancel={t("common.cancel")}
											labelBtnConfirm={t("common.apply")}
											locale={i18n.language}
											maxDate={new Date()}
										/>
									</Grid>

									<Grid item xs={12}>
										<RequiredField title={t("morphologicalData.vegetativeDev.pseudostemHeight")} />
										<InputText
											control={methodsEdit.control}
											type="number"
											endAdornment={"cm"}
											name="pseudostem_height"
											placeholder={t(
												"morphologicalData.vegetativeDev.pseudostemHeightPlaceholder"
											)}
										/>
									</Grid>

									<Grid item xs={12}>
										<RequiredField title={t("morphologicalData.vegetativeDev.diameterBasePseudostem")} />
										<InputText
											control={methodsEdit.control}
											type="number"
											endAdornment={"cm"}
											name="pseudostem_base_diameter"
											placeholder={t(
												"morphologicalData.vegetativeDev.diameterBasePseudostemPlaceholder"
											)}
										/>
									</Grid>

									<Grid item xs={12}>
										<RequiredField title={t("morphologicalData.vegetativeDev.perimeterMiddleThirdPseudostem")} />
										<InputText
											control={methodsEdit.control}
											type="number"
											endAdornment={"cm"}
											name="pseudostem_middle_third_perimeter"
											placeholder={t(
												"morphologicalData.vegetativeDev.perimeterMiddleThirdPseudostemPlaceholder"
											)}
										/>
									</Grid>

									<Grid item xs={12}>
										<RequiredField title={t("morphologicalData.vegetativeDev.numberLeaves")} />
										<InputText
											control={methodsEdit.control}
											type="number"
											name="leaves_number"
											placeholder={t(
												"morphologicalData.vegetativeDev.numberLeavesPlaceholder"
											)}
										/>
									</Grid>
								</Grid>
							)}
						</form>
					</FormProvider>
					<RequiredFields allRequired />
				</DialogContent>

				<DialogActions>
					<Button
						variant="outlined"
						color="primary"
						onClick={props.handlerClose}
					>
						{t("common.cancel")}
					</Button>

					<LoadingButton
						loading={loading}
						variant="contained"
						color="primary"
						disabled={!methodsEdit.formState.isValid}
						type={"submit"}
						form={"edit-form"}
					>
						{t("common.save")}
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
}
