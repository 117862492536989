/* eslint-disable react-hooks/exhaustive-deps */
import { AppLogo, arrayIsEmpty, ChipStatus, DetailsWidget, FilterSection, LayoutContext, Loading } from "@alb/live-lib";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Grid, Tooltip, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import { getIcons } from "components/map/list/listIcons";
import { useGet, useGetAll } from "hooks";
import { IAdapterConfigurator, ISelectOption } from "interfaces";
import { TMarker } from "interfaces/TMarker";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { selectDeviceRealTime } from "store/slices/adapterConfiguratorsSlice";
import { getDevicesMapDashboard, getSelectedAdapterFilter, getSelectedDevice, getSelectedDeviceId, getSelectedDeviceInfo, setDevice, setDevicesMapDashboard, setOneDeviceMapDashboard, setSelectedAdapterFilter, setSelectedDeviceId, setSelectedDeviceInfo } from "store/slices/dashboardSlice";
import { DoubleSelect } from "styles/components-styles";
import { formatDate } from "utils";
import { DeviceRealTime, updateDeviceInfo, updateDeviceLastRead } from "utils/data-real-time";
import FilterSelect from "utils/FilterSelect";
import NoData from "utils/NoData";
import { formatRoutePath } from "utils/routePath";
import MapDevices from "./DashboardComponents/MapDevices";
import StreamsChartAnalysis from "./DashboardComponents/dashboard-chart-analysis/StreamsChartAnalysis";


const Dashboard = () => {
  const { addHeader } = useContext(LayoutContext);
  const { t } = useTranslation();
  const theme = useTheme();
  let paramsURL = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedAdapter = useSelector(getSelectedAdapterFilter);
  const selectedDeviceId = useSelector(getSelectedDeviceId);
  const selectedDevice = useSelector(getSelectedDevice);
  const selectedDeviceInfo = useSelector(getSelectedDeviceInfo);
  const deviceRealTime = useSelector(selectDeviceRealTime);
  const devicesDashboardMap = useSelector(getDevicesMapDashboard);

  const header = {
    title: t("dashboard.dashboard"),
  };
  const optionsRequest = {
    manual: true,
  };

  // valores das streams para o DetailWidget
  const [streamsDetailWidget, setStreamsDetailWidget] = useState<object[]>([]);
  const [streamsChart, setStreamsCharts] = useState<object[]>([]);
  const [loadingFirstDevice, setLoadingFirstDevice] = useState<boolean>(true);

  //get adapters
  const {
    data: adapterConfigurators,
    refetch: refetchAdapters,
    loading: loadingAdapters,
    error: errorAdaptersRequest,
  } = useGetAll(ServiceApiUrl.adapterConfigurators, undefined, optionsRequest);

  // get last values of selected device
  const { refetch, loading: loadingDeviceDetail } = useGet(
    ServiceApiUrl.devices,
    selectedDeviceId,
    undefined,
    optionsRequest
  );

  // get first device of selected adapter
  const {
    data: firstDevice,
    error: errorFirstDevice,
    refetch: refetchFirstDevice,
  } = useGetAll(ServiceApiUrl.devices, undefined, optionsRequest);

  function getParamsRequest(selectedAdapter: ISelectOption) {
    const params: {
      page: number;
      items: number;
      adapters_id: string;
      device_id?: string;
    } =
      selectedDeviceId && paramsURL.deviceId
        ? {
            adapters_id: selectedAdapter?.value,
            device_id: selectedDeviceId,
            page: 1,
            items: 1,
          }
        : {
            adapters_id: selectedAdapter?.value,
            page: 1,
            items: 1,
          };
    return params;
  }

  useEffect(() => {
    addHeader(header);

    return () => {
      clearSomeFieldsRedux();
      dispatch(setSelectedDeviceId(""));
    };
  }, []);

  useEffect(() => {
    if (paramsURL.deviceId) {
      if (
        paramsURL.deviceId === selectedDeviceId &&
        paramsURL.deviceId === selectedDevice?.id
      ) {
        // buscar a informação para o detalhe do device (widget e gráfico)
        getDeviceDetails();
      } else if (
        paramsURL.deviceId !== selectedDeviceId &&
        adapterConfigurators
      ) {
        // atualizar o device id para ir buscar a informação do device
        dispatch(setSelectedDeviceId(paramsURL.deviceId));
      } else if (!adapterConfigurators) {
        // nao existem adapters logo deve-se ir buscar todas as informações
        refetchAdapters();
      }
    } else {
      refetchAdapters();
    }
  }, [paramsURL.deviceId]);

  useEffect(() => {
    if (adapterConfigurators) {
      if (paramsURL.deviceId) {
        if (selectedDeviceInfo?.id === paramsURL.deviceId) {
          // selecionar o adapter certo
          const adapter = selectedDeviceInfo.adapter_configurators[0];
          if (
            adapterConfigurators?.data.some(
              (elem: IAdapterConfigurator) => elem.id === adapter.id
            )
          ) {
            handleAdapterChange({
              label: adapter.name,
              value: adapter.id,
            });
          }
        } else if (
          selectedDeviceId === paramsURL.deviceId &&
          selectedAdapter.value.length === 0
        ) {
          // buscar e selecionar adapter do device do url
          getAdapterDeviceURL();
        } else {
          // atualizar o device id
          dispatch(setSelectedDeviceId(paramsURL.deviceId));
        }
      } else {
        // colocar adapter de default (o primeiro da lista)
        setDefaultAdapter();
      }
    }
  }, [adapterConfigurators]);

  useEffect(() => {
    // quando se entra e nao ha nenhum device selecionado  ||   quando se altera de adapter
    if (
      (firstDevice?.data.length > 0 && !paramsURL.deviceId) ||
      (firstDevice?.data.length > 0 &&
        paramsURL.deviceId &&
        selectedDeviceInfo?.adapter_configurators[0]?.id !==
          selectedAdapter.value)
    ) {
      if (selectedDeviceId === firstDevice.data[0].id && !paramsURL.deviceId) {
        // este if é quando se clica no sidebar e estamos no primeiro device do primeiro adapter
        onChangeURL(selectedDeviceId);
        dispatch(setDevice(firstDevice.data[0]));
        return;
      } else if (
        selectedDeviceId === firstDevice.data[0].id &&
        paramsURL.deviceId === selectedDeviceId &&
        (selectedDevice?.id !== selectedDeviceId || !selectedDevice)
      ) {
        // quando se entra no dashboard atraves do url/mapa/...
        dispatch(setDevice(firstDevice.data[0]));
        getDeviceDetails();
        return;
      }
      dispatch(setSelectedDeviceId(firstDevice.data[0].id));
      dispatch(setDevice(firstDevice.data[0]));
    }
    if (
      firstDevice?.data.length > 0 &&
      paramsURL.deviceId &&
      selectedDeviceInfo?.adapter_configurators[0]?.id === selectedAdapter.value
    ) {
      // quando se entra no dashboard e ja ha dados do detalhe do device (mas o device selecionado do mapa ainda nao "existe")
      dispatch(setDevice(firstDevice.data[0]));
    }
  }, [firstDevice]);

  useEffect(() => {
    if (
      selectedDeviceId.length > 0 &&
      paramsURL.deviceId !== selectedDeviceId
    ) {
      // alterar url para o device selecionado
      onChangeURL(selectedDeviceId);
    }
    if (
      selectedDeviceId.length > 0 &&
      paramsURL.deviceId === selectedDeviceId &&
      selectedDeviceId !== selectedDevice?.id &&
      adapterConfigurators
    ) {
      // buscar o adapter do device do url
      getAdapterDeviceURL();
    }
    if (
      selectedAdapter.value.length > 0 &&
      paramsURL.deviceId === selectedDeviceId &&
      !selectedDevice
    ) {
      // quando se entra na pagina com url e sem dados
      getFirstDevice(selectedAdapter);
    }
  }, [selectedDeviceId]);

  useEffect(() => {
    if (selectedDeviceInfo) {
      showValuesRealTime(selectedDeviceInfo, deviceRealTime);
    }
  }, [deviceRealTime]);

  // get streams for selected device
  useEffect(() => {
    if (selectedDeviceInfo) {
      const streams = Object.keys(selectedDeviceInfo.last_read_value).map(
        (stream: any) => {
          return {
            name: t("streams." + stream.toLowerCase()),
            value: selectedDeviceInfo.last_read_value[stream].value,
            unit: selectedDeviceInfo.last_read_value[stream].unit,
          };
        }
      );
      setStreamsDetailWidget(streams);
    }
  }, [selectedDeviceInfo]);

  const sendRequestDeviceDetails = async () => {
    await refetch()
      .then((res) => {
        const response = res.data;
        dispatch(setSelectedDeviceInfo(response));
      })
      .catch((error) => {
        dispatch(setSelectedDeviceInfo(null));
      });
  };

  const onChangeURL = (deviceId: string) => {
    const path = formatRoutePath(location, paramsURL, { deviceId });
    navigate(path, { replace: true });
  };

  //vai trocar o estado do select
  const onChangeOptionAdapter = (option: ISelectOption | null) => {
    if (!option) return undefined;
    handleAdapterChange(option);
  };

  const handleAdapterChange = (adapterOption: ISelectOption) => {
    dispatch(setDevice(null));
    dispatch(setDevicesMapDashboard([]));
    // buscar primeiro device do adaptador
    getFirstDevice(adapterOption);
    // atualizar selectedAdapter
    dispatch(setSelectedAdapterFilter(adapterOption));
    // buscar e atualizar streams para gráfico
    handleStreamsChart(adapterOption.value);
  };

  const handleStreamsChart = (adapterID: string) => {
    setStreamsCharts([]);
    const selectedAdpt = adapterConfigurators?.data?.find(
      (adapter: IAdapterConfigurator) => adapter.id === adapterID
    );
    setStreamsCharts(selectedAdpt.streams);
  };

  const setDefaultAdapter = () => {
    // mostrar o primeiro adapter
    handleAdapterChange({
      label: adapterConfigurators?.data[0].name,
      value: adapterConfigurators?.data[0].id,
    });
  };

  const getDeviceDetails = () => {
    // buscar info de detalhe do device selecionado
    sendRequestDeviceDetails();
    setLoadingFirstDevice(false); // este loading é por causa dos 2 loadings/pedidos seguidos para evitar aparecer a mensagem noData
    setStreamsDetailWidget([]);
  };

  const getFirstDevice = (adapterOption: ISelectOption) => {
    refetchFirstDevice({ params: getParamsRequest(adapterOption) });
    if (!loadingFirstDevice && !loadingDeviceDetail)
      setLoadingFirstDevice(true);
  };

  const clearSomeFieldsRedux = () => {
    dispatch(setSelectedAdapterFilter({ label: "", value: "" }));
    dispatch(setDevice(null));
    dispatch(setDevicesMapDashboard([]));
    dispatch(setSelectedDeviceInfo(null));
  };
  const getAdapterDeviceURL = async () => {
    await refetch()
      .then((res) => {
        const response = res.data;
        const adapter = response.adapter_configurators[0];
        if (
          adapterConfigurators?.data.some(
            (elem: IAdapterConfigurator) => elem.id === adapter.id
          )
        ) {
          handleAdapterChange({
            label: adapter.name,
            value: adapter.id,
          });
        } else {
          clearSomeFieldsRedux();
        }
      })
      .catch((error) => {
        clearSomeFieldsRedux();
      });
  };

  useEffect(() => {
    if (errorFirstDevice) {
      setLoadingFirstDevice(false);
    }
  }, [errorFirstDevice]);

  function showValuesRealTime(device: TMarker, data: DeviceRealTime) {
    // devices for map
    const visibleDevice = devicesDashboardMap.find((device: TMarker) => {
      return device.external_id === data.external_id;
    });
    if (visibleDevice) {
      const infoLastRead = updateDeviceLastRead(visibleDevice, data);
      dispatch(setOneDeviceMapDashboard(infoLastRead));
    }
    // detail widget
    if (device.external_id === data.external_id) {
      const infoDevice = updateDeviceInfo(device, data);
      dispatch(setSelectedDeviceInfo(infoDevice));
    }
  }

  const detailsWidgetData = {
    coordinates: {
      icon: <LocationOnIcon sx={{ fontSize: 18 }} color="primary" />,
      value: `${selectedDeviceInfo?.geolocation.iconPosition[0].toFixed(
        4
      )}, ${selectedDeviceInfo?.geolocation.iconPosition[1].toFixed(4)}`,
    },
    firstRead: {
      label: t("dashboard.firstRead"),
      icon: <AccessTimeIcon sx={{ fontSize: 18 }} color="primary" />,
      value: formatDate(
        selectedDeviceInfo?.first_read_at,
        t("calendar.dateTimeFullFormat")
      ),
    },
    lastRead: {
      label: t("dashboard.lastRecords"),
      icon: <AccessTimeIcon sx={{ fontSize: 18 }} color="primary" />,
      value: formatDate(
        selectedDeviceInfo?.last_read_at,
        t("calendar.dateTimeFullFormat")
      ),
    },
    tableValues: {
      sizeTable: "small",
      lastCellNoSpace: true,
      rowsValues: streamsDetailWidget,
    },
  };

  return (
    <>
      <Grid container mb={2}>
        {selectedAdapter.value.length > 0 && !loadingAdapters && (
          <>
            <Grid container item xs={false} sm={12} md={12}>
              <DoubleSelect>
                <Grid item>
                  <Typography mr={1} variant="h6">
                    {t("dashboard.dataFrom")}
                  </Typography>
                </Grid>
                <Grid item xs={true}>
                  <FilterSection
                    select={
                      <FilterSelect<IAdapterConfigurator>
                        showLabel
                        showBorder
                        key={`select--${selectedAdapter?.label}`}
                        onSelected={onChangeOptionAdapter}
                        selectedValue={selectedAdapter.value}
                        borderColor="rgba(81,191,188,0.5)"
                        sx={{
                          ...(theme.palette.mode === "light" && {
                            backgroundColor: "#ECF8F8",
                          }),
                        }}
                        optionsData={adapterConfigurators}
                      />
                    }
                  ></FilterSection>
                </Grid>
              </DoubleSelect>
            </Grid>
            <Loading show={loadingDeviceDetail || loadingFirstDevice} />
            {!loadingDeviceDetail &&
              !loadingFirstDevice &&
              selectedDeviceId.length > 0 &&
              selectedDevice && (
                <>
                  <Grid
                    container
                    item
                    xs={false}
                    sm={12}
                    md={12}
                    mt={3}
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={false}
                      sm={12}
                      md={selectedDeviceInfo ? 8 : 12}
                    >
                      <MapDevices />
                    </Grid>
                    {selectedDeviceInfo && (
                      <Grid item xs={false} sm={12} md={4}>
                        <DetailsWidget
                          subTitle={t(
                            `domains.${selectedDeviceInfo?.adapter_configurators[0].domain}`
                          )}
                          title={selectedDeviceInfo?.name}
                          avatar={
                            <AppLogo
                              sx={{ width: 60, height: "auto" }}
                              variant="circular"
                              src={getIcons(
                                "device",
                                selectedDeviceInfo?.adapter_configurators[0]
                                  .domain,
                                selectedDeviceInfo?.is_active
                              )}
                              alt={
                                selectedDeviceInfo?.adapter_configurators[0]
                                  .domain
                                  ? t(
                                      `domains.${selectedDeviceInfo?.adapter_configurators[0].domain}`
                                    )
                                  : "device"
                              }
                            />
                          }
                          iconStatus={
                            <IconStatus
                              isActive={selectedDeviceInfo?.is_active}
                            />
                          }
                          data={detailsWidgetData}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid container item xs={false} sm={12} md={12} my={3}>
                    {selectedDeviceInfo &&
                      streamsChart &&
                      streamsChart.length > 0 && (
                        <StreamsChartAnalysis
                          selectedDeviceInfo={selectedDeviceInfo}
                          streamsChart={streamsChart}
                        />
                      )}
                  </Grid>
                </>
              )}
            {((!loadingDeviceDetail &&
              !loadingFirstDevice &&
              selectedDeviceId.length === 0) ||
              errorFirstDevice) && <NoData message="common.noData" />}
          </>
        )}

        {!loadingAdapters &&
          (arrayIsEmpty(adapterConfigurators?.data) ||
            errorAdaptersRequest) && <NoData message="common.noData" />}
        <Loading show={loadingAdapters} />
      </Grid>
    </>
  );
};

export default Dashboard;

const IconStatus = (props: { isActive: boolean }) => {
  const { t } = useTranslation();
  const { isActive } = props;

  let title = `${t("common.statusLabel")}: `;
  title += isActive ? t("common.status.active") : t("common.status.inactive");

  return (
    <Tooltip placement="top" title={title}>
      <div>
        <ChipStatus status={isActive ? "enabled" : "disabled"} />
      </div>
    </Tooltip>
  );
};
