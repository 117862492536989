import { LayoutContext } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import preval from "preval.macro";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { formatDate } from "utils";

import appInfo from "../../package.json";

function About() {
  const { t } = useTranslation();

  var date = preval`module.exports = new Date();`;

  const { addHeader } = useContext(LayoutContext);
  const header = {
    title: t("common.about"),
  }

  useEffect(() => {
    addHeader(header);
  }, [])

  return (
    <>
      <Typography
        sx={{ whiteSpace: "pre-line" }}
        marginTop={4}
        color="text.black"
        variant="body1"
      >
        {t("common.aboutText")}
      </Typography>

      <Stack
        mt={2}
        justifyContent="flex-end"
        direction="row"
        alignItems="flex-end"
      >
        <Typography variant="body1" mr={1}>
          {`v${appInfo.version}`}
        </Typography>
        <Typography variant="caption">
          {` (${formatDate(date, t("calendar.dateFormat"))})`}
        </Typography>
      </Stack>
    </>
  );
}

export default About;
