const _baseApiUrl = process.env.REACT_APP_API_BASE_URL || "";

export const ServiceApiUrl = {
  baseApiUrl: _baseApiUrl,

  loginURL: "/login",
  logoutURL: "/logout",

  adapterListURL: "/adapter-configurators",
  deviceURL: "/devices",
  allDevicesURL: "/adapter-configurators/devices",
  deviceValuesURL: "/devices/values",
  partnerURL: "/partners/",

  userURL: "/users",
  userEditURL: "/users/edit",
  userChangePwdURL: "/users/change-pwd",
  recoverPwdURL: "/users/recover-password",
  resetPwdURL: "/users/reset-password",
  setPwdURL: "/users/set-password",
  // userInviteURL: "/users/invite",
  userInvite: (id: string) => {
    return `/users/${id}/invite`;
  },

  harvestsListURL: "/modules/bananas/harvests",
  placesListURL: "/modules/bananas/places",
  cultivationsListURL: "/modules/bananas/cultivations",
  pulpColorsListURL: "/modules/bananas/pulp-colors",
  varietiesListURL: "/modules/bananas/varieties",
  texturesListURL: "/modules/bananas/textures",

  sampleURL: "/modules/bananas/samples",
  vegetativeURL: "/modules/bananas/samples/vegetatives",
  floweringURL: "/modules/bananas/samples/flowerings",
  harvestURL: "/modules/bananas/samples/harvests",
  samplesDownloadURL: "/modules/bananas/samples/download-data",

  pictureURL: "/modules/bananas/samples/photos",

  validateTokenURL: "/users/validate-token",

  downloadURL: "/download-data",

  tenantURL: "/tenants",
  clientURL: "/clients",

  moduleURL: "/modules",

  accountURL: "/accounts",

  adapterDevices: (id: string) => {
    return `/adapter-configurators/${id}/devices`;
  },

  clientModule: (id: string) => {
    return `/clients/${id}/modules`;
  },

  adapterDownload: (id:string)=> {
    return `/adapter-configurators/${id}/download-data`;
  },

  devices: '/devices',
  streams: '/streams',
  adapterConfigurators: '/adapter-configurators',

};
