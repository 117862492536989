import { mixed, object, string } from "yup";

export const InputsLengths = {
  max: {
    name: 255,
  },
  min: {
    name: 3,
  },
};

export const getModuleSchema = (required: string) =>
  object({
    name: string()
      .min(InputsLengths.min.name)
      .max(InputsLengths.max.name)
      .required(),
    adapter_configurators:
      mixed()
      // .test("required", required, (value) => Array.isArray(value) && value.length > 0),
  });

  export const setModuleSchema = (required: string) =>
  object({
    adapter_configurators:
      mixed()
      // .test("required", required, (value) => Array.isArray(value) && value.length > 0),
  });
