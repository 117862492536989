/* eslint-disable react-hooks/exhaustive-deps */
import { useFeedback } from "@alb/live-lib";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useGet } from "hooks";
import useDidMountEffect from "hooks/useDidMountEffect";
import { useErrorHandler } from "hooks/useErrorHandler";
import { IAdapterConfigurator } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { selectDevicesFilterParams, setDevices, setDevicesFilterParams } from "store/slices/adapterConfiguratorsSlice";
import { getMapBounds, getSelectedMarkerType, getShapeBounds } from "store/slices/mapSlice";
import { arrayIsEmpty, objectIsEmpty } from "utils/utils";

import { IFilters } from "../FilterTabForm";
import { MarkersTypeOptions } from "../utils/GetOptions";

const useMapFilters = (
  adapters: IAdapterConfigurator[],
  publicAPI: boolean
) => {
  const dispatch = useDispatch();
  const { sendErrorMessage } = useErrorHandler();
  const { addFeedback} = useFeedback();
  let mapDeviceParams = useSelector(selectDevicesFilterParams);
  const mapBounds = useSelector(getMapBounds);
  const shapeBounds = useSelector(getShapeBounds);

  const { t } = useTranslation();
  const selectedType = useSelector(getSelectedMarkerType);

  //se existirem formas no mapa, enviar os bounds da forma no params do pedido!
  let deviceParams: any = {
    points: !arrayIsEmpty(shapeBounds)
      ? JSON.stringify(shapeBounds)
      : JSON.stringify(mapBounds),
    ...(mapDeviceParams !== undefined && { ...mapDeviceParams }),
  };
  const { refetch: refetchDevices } = useGet(
    ServiceApiUrl.devices,
    undefined,
    deviceParams,
    {
      manual: true,
    }
  );
  //pedido dos devices
  async function fetchDevicesMarkers() {
    await refetchDevices()
      .then((res) => {
        const response = res.data.data;
        dispatch(setDevices(response));
        success();
      })
      .catch((error) => {
        // console.log(error);
        sendErrorMessage(error);
      });
  }

  const success = () => {
    addFeedback({
      message: t("feedback.success.appliedFilters"),
      severity: "success",
      duration: 1500,
    });
  };

  //sempre que forem detetadas alterações nos params, faz novo pedido
  useDidMountEffect(() => {
    if (!objectIsEmpty(mapDeviceParams)) {
      fetchDevicesMarkers();
    }
  }, [JSON.stringify(mapDeviceParams)]);

  const debounceData = debounce((cb) => {
    cb();
  }, 400);
  //envia os valores novos (filtrados) para os params de cada tipo, dependendo do tipo selecionado
  const dispatchParams = (
    values?: IFilters,
    searchValue?: string,
    clear?: boolean
  ) => {
    //se o tipo for trocado, faz os pedidos novamente (com os params limpos)
    if (clear) {
      fetchAllMarkers();
      return;
    }
    debounceData(() => {
      switch (selectedType) {
        case MarkersTypeOptions.devices:
          dispatchDevices(values, searchValue); //DEVICES
          break;
        default:
          dispatchDevices(values, searchValue);
      }
      // if (values) success();
    });
  };

  //guarda os params dos devices
  const dispatchDevices = (values?: IFilters | any, searchValue?: string) => {
    if (values !== undefined) {
      // se algum estiver a false
      if (
        Object.keys(values?.adapters).some((a: any) => !values?.adapters[a])
      ) {
        //coloca todos os que estão ativos numa variavel
        const activeAdapters = Object.keys(values?.adapters).filter(
          (v: string) => values.adapters[v]
        );
        if (!arrayIsEmpty(activeAdapters)) {
          const selectedAdapters = adapters?.filter((a: any) =>
            activeAdapters.includes(a.name)
          );
          var adaptersIDs = selectedAdapters
            .map((item: any) => {
              return item.id;
            })
            .join(",");
          dispatch(
            setDevicesFilterParams({
              ...mapDeviceParams,
              adapters_id: adaptersIDs || "",
              is_active: values?.deviceStatus?.value,
            })
          );
        }
      } else {
        dispatch(
          setDevicesFilterParams({
            ...mapDeviceParams,
            adapters_id: undefined,
            is_active: values?.deviceStatus?.value as any,
          })
        );
      }
    } else if (searchValue !== undefined) {
      dispatch(
        setDevicesFilterParams({
          ...mapDeviceParams,
          contains: searchValue !== "" ? searchValue : undefined,
        })
      );
    }
  };

  async function fetchAllMarkers() {
    switch (selectedType) {
      case MarkersTypeOptions.devices:
        fetchDevicesMarkers();
        break;
      default:
        fetchDevicesMarkers();
    }
  }

  return dispatchParams;
};

export default useMapFilters;
