import axios from "axios";
import { configure } from "axios-hooks";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { Cookies } from "react-cookie";
import { IAuth } from "interfaces";

const cookies = new Cookies();

const defaultOptions = {
  baseURL: ServiceApiUrl.baseApiUrl,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "*",
    "Access-Control-Allow-Headers": "Content-Type",
  },
  // withCredencials: true
};

// Create instance
export const api = axios.create(defaultOptions);

const cache = false; //new LRUCache({ max: 10 });
configure({ axios: api, cache });

api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Do something with response error
    if (error.response) {
      const status: number = error.response.status; // API Request Status Code
      const errorCode: number = error.response.data[0].error_code; // BE Error Code - see BE Docs
      if (status === 401) {
        if (errorCode === 10006) {
          window.location.replace("/unauthorized");
        }
        //Se for 10013, deve ser redireccionado para o login "Auth Token Expired"
        //Se for 10014, deve ser redireccionado para o login "Missing Token"
        if (errorCode === 10013 || errorCode === 10014) {
          // clear data of store
          localStorage.clear();
          // clear auth cookie
          cookies.remove("auth_token");
          // navigate for login page
          window.location.replace("/login");
        }
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("error.request: ", error.request);
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use(
  (config) => {
    const token: IAuth | null = cookies.get("auth_token");
    if (token) {
			config.headers.setAuthorization("Token " + token);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
