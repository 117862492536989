import { addMethod, date, mixed, number, NumberSchema, object, string } from "yup";

import { arrayIsEmpty, getFileExtension } from "utils";

export const InputsLengths = {
  max: {
    name: 255,
    scope: 255,
  },
  min: {
    name: 3,
    scope: 3,
  },
};

export const FILE_SIZE = 1024 * 1024 * 5; //5 242 880 Bytes // 5MB

export const getSampleAddSchema = () =>
  object({
    name: string()
      .min(InputsLengths.min.name)
      .max(InputsLengths.max.name)
      .required(),
    local: string().required(),
    cultivation: string().required(),
    variety: string().required(),
    harvest: string().required(),
  });

function decimalValidator(number: number) {
  const patternOneDigitAfterComma = /^\d+(\.\d{0,1})?$/;

  return patternOneDigitAfterComma.test(number.toString());
}

// addMethod function
addMethod<NumberSchema>(
  number,
  "decimalValidator",
  function (this, message: string) {
    return this.test("is-decimal", message, (number) => {
      if (number !== undefined) {
        return decimalValidator(number);
      }
      return true;
    });
  }
);

const transformNumber = (_value: any, originalValue: any) => {
  if (originalValue === "") return undefined;
  let val = Number(originalValue.replace(/,/, "."));
  return val;
};
export const getSampleAddVegetativeSchema = () =>
  object({
    registration_date: date().required().max(new Date()),
    //pseudostem_height: number().transform(value => (isNaN(value) ? undefined : value)).required().positive().min(0),
    pseudostem_height: number()
      .transform(transformNumber)
      .decimalValidator()
      .required()
      .positive()
      .min(0),
    pseudostem_base_diameter: number()
      .transform(transformNumber)
      .decimalValidator()
      .required()
      .positive()
      .min(0),
    pseudostem_middle_third_perimeter: number()
      .transform(transformNumber)
      .decimalValidator()
      .required()
      .positive()
      .min(0),
    leaves_number: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required()
      .integer()
      .min(0),
  });
export const getSampleEditVegetativeSchema = () =>
  object({
    registration_date: date().required().max(new Date()),
    pseudostem_height: number()
      // .transform(transformNumber)
      .decimalValidator()
      .required()
      .positive()
      .min(0),
    pseudostem_base_diameter: number()
      // .transform(transformNumber)
      .decimalValidator()
      .required()
      .positive()
      .min(0),
    pseudostem_middle_third_perimeter: number()
      // .transform(transformNumber)
      .decimalValidator()
      .required()
      .positive()
      .min(0),
    leaves_number: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required()
      .integer()
      .min(0),
  });

export const getSampleAddFloweringSchema = () =>
  object({
    registration_date: date().required().max(new Date()),
    flowering_days: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required()
      .integer()
      .min(0),
    bunch_fruits_number: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required()
      .integer()
      .min(0),
    stem_length: number()
      .transform(transformNumber)
      .decimalValidator()
      .required()
      .positive()
      .min(0),
  });
export const getSampleEditFloweringSchema = () =>
  object({
    registration_date: date().required().max(new Date()),
    flowering_days: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required()
      .integer()
      .min(0),
    bunch_fruits_number: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required()
      .integer()
      .min(0),
    stem_length: number()
      // .transform(transformNumber)
      .decimalValidator()
      .required()
      .positive()
      .min(0),
  });

export const getSampleAddHarvestSchema = () =>
  object({
    registration_date: date().required().max(new Date()),
    crop_cycle_days: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required()
      .integer()
      .min(0),
    pedicels_number: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required()
      .integer()
      .min(0),
    fruits_number: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required()
      .integer()
      .min(0),
    fruit_length: number()
      .transform(transformNumber)
      .decimalValidator()
      .required()
      .positive()
      .min(0),
    fruit_diameter: number()
      .transform(transformNumber)
      .decimalValidator()
      .required()
      .positive()
      .min(0),
    fruit_weight: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required()
      .integer()
      .min(0),
    fruit_peel_thickness: number()
      .transform(transformNumber)
      .decimalValidator()
      .required()
      .positive()
      .min(0),
    pulp_color_maturity: string().required(),
    flesh_texture: string().required(),
  });
export const getSampleEditHarvestSchema = () =>
  object({
    registration_date: date().required().max(new Date()),
    crop_cycle_days: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required()
      .integer()
      .min(0),
    pedicels_number: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required()
      .integer()
      .min(0),
    fruits_number: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required()
      .integer()
      .min(0),
    fruit_length: number()
      // .transform(transformNumber)
      .decimalValidator()
      .required()
      .positive()
      .min(0),
    fruit_diameter: number()
      // .transform(transformNumber)
      .decimalValidator()
      .required()
      .positive()
      .min(0),
    fruit_weight: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required()
      .integer()
      .min(0),
    fruit_peel_thickness: number()
      // .transform(transformNumber)
      .decimalValidator()
      .required()
      .positive()
      .min(0),
    pulp_color_maturity: string().required(),
    flesh_texture: string().required(),
  });
export function checkIfFilesAreTooBig(files?: [File]): boolean {
  let valid = true;
  // console.log('files', files)
  if (!arrayIsEmpty(files)) {
    files?.forEach((file) => {
      if (file.size > FILE_SIZE) {
        valid = false;
      }
    });
  }
  return valid;
}

const SUPPORTED_FORMATS = ["png", "jpg", "jpeg"];
export const getSampleAddPictureSchema = (
  required: string,
  fileSize: string,
  fileFormat: string
) =>
  object().shape({
    registration_date: date().required().max(new Date()),
    files_upload: mixed()
      .required()
      // .test("required", required, (value) => {
      //   return value && value.length;
      // })
      // .test("fileSize", fileSize, checkIfFilesAreTooBig),
      .test({
        message: fileFormat, // "Please provide a supported file type",
        test: (file: any, context) => {
          if (file && file.length > 0) {
            for (let i = 0; i < file.length; i++) {
              const isValid = SUPPORTED_FORMATS.includes(getFileExtension(file[i].name));
              if (!isValid) context?.createError();
              return isValid;
            }
          }
        },
      }),
  });

export const getNameAddSchema = () =>
  object({
    name: string()
      .min(InputsLengths.min.name)
      .max(InputsLengths.max.name)
      .required(),
  });

export const getNameEditSchema = () =>
  object({
    name: string()
      .min(InputsLengths.min.name)
      .max(InputsLengths.max.name)
      .required(),
  });
