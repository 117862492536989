/* eslint-disable react-hooks/exhaustive-deps */
import { LayoutContext, Loading, TableHeadCell, TableHeadOrder, TableHeadSort } from "@alb/live-lib";
import AddIcon from "@mui/icons-material/Add";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import { Button, Grid, IconButton, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import AccountsAdd from "components/utils/accounts/AccountsAdd";
import AccountsDelete from "components/utils/accounts/AccountsDelete";
import { ChipStatus } from "components/utils/ChipStatus";
import { useGetAll } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useHasPermissions } from "hooks/usePermissions";
import { IAccount, IApiResponse } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { TableLink } from "styles/components-styles";
import { formatDate, formatOrderBy, PERMISSIONS, statusColor } from "utils";
import { ROWS_PER_PAGE } from "utils/pagination";
import { CustomTablePagination } from "utils/pagination/CustomTablePagination";
import { formatRoutePath } from "utils/routePath";

import FiltersSection from "./FiltersSection";

export default function Accounts() {
	const { t } = useTranslation();
	const { sendErrorMessage } = useErrorHandler();
	const { hasPermission } = useHasPermissions();
	const user = useSelector(getUser)
	const location = useLocation();
	const navigate = useNavigate();
	let params = useParams();

	const [order, setOrder] = useState<TableHeadOrder>("asc");
	const [orderBy, setOrderBy] = useState<string>("name");

	const canDelete = hasPermission([PERMISSIONS.MANAGEMENT.ACCOUNTS.CUD]);
	const canAdd = hasPermission([PERMISSIONS.MANAGEMENT.ACCOUNTS.CUD]);

	const [page, setPage] = useState(1);
	const [selectedAccount, setSelectedAccount] = useState<IAccount>();
	const [openDialogDelete, setOpenDialogDelete] = useState(false);
	const [openDialogAddAccount, setOpenDialogAddAccount] = useState(false);

	const [filterSearch, setFilterSearch] = useState<string>("");
	const [filterStatus, setFilterStatus] = useState<string>("");

	const handlerDialogDeleteAccount = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		account: IAccount
	) => {
		if (open) {
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setSelectedAccount(account);
		}
		setOpenDialogDelete(!openDialogDelete);
	};
	const onAddClick = () => {
		setOpenDialogAddAccount(true);
	};

	const {
		data: accounts,
		loading,
		error,
		refetch,
	} = useGetAll<IApiResponse<IAccount>>(ServiceApiUrl.accountURL, {
		page: page,
		items: ROWS_PER_PAGE,
		order_by: formatOrderBy(order, orderBy),
		active: filterStatus,
		contains: filterSearch.length >= 3 ? filterSearch : "",
	});

	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);

	//quando for feito novo pedido dos dados, atualiza os dados do account selecionado
	useEffect(() => {
		if (accounts?.data) {
			let chosen = accounts?.data.filter((u) => u.id === selectedAccount?.id);
			if (chosen) setSelectedAccount(chosen[0]); //troca o account pelo escolhido na tabela
		}
	}, [accounts?.data]);

	//para fazer nova chamada dos accounts
	const updateAccounts = () => {
		refetch();
	};

	//Trocar página da listagem
	const handleChangePage = (
		newPage: number
	) => {
		setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
	};

	const handleViewClick = (
		event: React.MouseEvent<HTMLElement>,
		id: string
	) => {
		const path = formatRoutePath(location, params, { id });
		navigate(path);
	};

	const tableHeads: TableHeadCell[] = [
		{
			id: "name",
			label: t("common.name"),
		},
		{
			id: "is_active",
			label: t("common.statusLabel"),
		},
		{
			id: "created_at",
			label: t("common.createdAt"),
		},
		{
			id: "updated_at",
			label: t("common.updatedAt"),
		},
		{
			id: "action_cell",
			label: "",
			notSortable: true,
		},
	];

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: string
	) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const { addHeader } = useContext(LayoutContext)
	const header = {
		title: t("lists.accountsList"),
		action: canAdd && (
			<Grid item>
				<Button
					color="primary"
					onClick={onAddClick}
					variant="contained"
					startIcon={<AddIcon />}
				>
					{t("accounts.addAccount")}
				</Button>
			</Grid>
		)
	}

	useEffect(() => {
		addHeader(header)
	}, [])

	return (
		<>

			<FiltersSection setPage={setPage} setFilterSearch={setFilterSearch} setFilterStatus={setFilterStatus} />

			<Loading show={loading} />

			{!accounts?.data.length && !loading && (
				<Typography mt={2} variant="body1">
					{t("accounts.noAccounts")}
				</Typography>
			)}

			{accounts && accounts?.data.length > 0 && !loading && (
				<>
					<TableContainer>
						<TableLink>
							<TableHeadSort
								headCells={tableHeads}
								onRequestSort={handleRequestSort}
								order={order}
								orderBy={orderBy}
							/>
							<TableBody>
								{accounts?.data?.map((a: IAccount, index: number) => (
									<TableRow
										onClick={(e) => handleViewClick(e, a.id)}
										key={a.id}
									>
										<TableCell>
											{a.name}
											{a.admin && (
												<AdminPanelSettingsIcon
													sx={{ verticalAlign: "middle" }}
													fontSize="small"
													color={"primary"}
													titleAccess={t("accounts.adminLabel")}
												/>
											)}
										</TableCell>
										<TableCell>
											<ChipStatus
												label={
													a.is_active
														? t("common.active")
														: t("common.inactive")
												}
												color={
													a.is_active
														? statusColor("active")
														: statusColor("non-active")
												}
											/>
										</TableCell>
										<TableCell>
											{formatDate(
												a.created_at,
												t("calendar.dateTimeFormatLocal")
											)}
										</TableCell>
										<TableCell>
											{formatDate(
												a.updated_at,
												t("calendar.dateTimeFormatLocal")
											)}
										</TableCell>

										<TableCell sx={{ textAlign: "right" }}>
											{canDelete && (
												<IconButton
													title={t("common.delete")}
													aria-label="delete"
													onClick={(
														e: React.MouseEvent<HTMLButtonElement, MouseEvent>
													) => {
														handlerDialogDeleteAccount(true, e, a);
													}}
													sx={{ padding: 0 }}
												>
													<DeleteIcon />
												</IconButton>
											)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</TableLink>
					</TableContainer>
					<CustomTablePagination
						count={accounts?.totalCount}
						page={page}
						totalPages={accounts?.totalPages}
						onPageChange={handleChangePage}

					/>
				</>
			)}
			{canAdd && openDialogAddAccount && user && (
				<AccountsAdd
					clientID={user.client.id}
					open={openDialogAddAccount}
					handlerClose={() => setOpenDialogAddAccount(false)}
					updateList={updateAccounts}
				/>
			)}
			{canDelete && selectedAccount && openDialogDelete && (
				<AccountsDelete
					account={selectedAccount}
					open={openDialogDelete}
					handlerClose={() => setOpenDialogDelete(false)}
					updateList={updateAccounts}
				/>
			)}
		</>
	);
}
