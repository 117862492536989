import { useMemo } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ListItemRow } from './ListItemRow'
import { FixedSizeList as List } from 'react-window'
import { debounce } from '@mui/material/utils'
import { IMapPoint } from '@alb/live-lib'

interface IMarkersList {
  items: IMapPoint[]
  onClick: (item: IMapPoint) => void
  onHover: (item: IMapPoint | null) => void
}

export const MarkersList = ({ items, onClick, onHover }: IMarkersList) => {
  const debounceMouse = useMemo(() => debounce(onHover, 350), [onHover])

  return (
    <AutoSizer style={{ height: '87vh' }}>
      {({ width, height }:any) => (
        <>
          <List
            style={{ height: '100%' }}
            height={height}
            itemCount={items.length}
            itemSize={100}
            width={width}
          >
            {({ index, style }:any) => (
              <ListItemRow
                items={items}
                index={index}
                style={style}
                onClick={onClick}
                onHover={debounceMouse}
              />
            )}
          </List>
        </>
      )}
    </AutoSizer>
  )
}
