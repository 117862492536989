import { IconButton, ImageListItem, ImageListItemBar } from "@mui/material";
import { IPicture } from "interfaces";
import React from "react";
import { downloadImage, formatDate } from "utils";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DownloadIcon from "@mui/icons-material/Download";
import { useTranslation } from "react-i18next";

export interface PhotosListItemProps {
  image: IPicture;
  onDelete: (image: IPicture) => void;
  onSelected: (image: IPicture) => void;
}
function PhotosListItem({ image, onDelete, onSelected }: PhotosListItemProps) {
  const { t } = useTranslation();

  function srcSet(
    image: string,
    width = 300,
    height = 250,
    rows = 1,
    cols = 1
  ) {
    return {
      // src: image,
      src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${width * cols}&h=${
        height * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  function onDownload(image: IPicture) {
    // console.log(image);
    downloadImage(image.url_source, image.id);
  }

  return (
    <ImageListItem key={image.id}>
      <img
        style={{ cursor: "pointer" }}
        {...srcSet(image.thumb_url_source)}
        alt={formatDate(image.registration_date, t("calendar.dateFormat"))}
        loading="lazy"
        onClick={() => {
          onSelected(image);
        }}
      />
      <ImageListItemBar
        sx={{
          background:
            "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
            "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
        }}
        title={formatDate(image.registration_date, t("calendar.dateFormat"))}
        // position="below"
        actionIcon={
          <>
            <IconButton
              sx={{ color: "rgba(255, 255, 255, 1)" }}
              aria-label={`delete ${image.registration_date}`}
              size="small"
              onClick={() => onDownload(image)}
            >
              <DownloadIcon />
            </IconButton>
            <IconButton
              sx={{ color: "rgba(255, 255, 255, 1)" }}
              aria-label={`delete ${image.registration_date}`}
              size="small"
              onClick={() => onDelete(image)}
            >
              <DeleteForeverIcon />
            </IconButton>
          </>
        }
      />
    </ImageListItem>
  );
}

export default PhotosListItem;
