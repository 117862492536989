import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { AuthContext } from "context/auth";
import { useErrorHandler } from "hooks/useErrorHandler";
import { IAuth, ILogin, IRecoverPassword } from "interfaces";
import { AuthService } from "services/AuthServices";
import { setAuth } from "store/slices/authSlice";
import { getUserPermissions } from "utils/permissions/getUserPermissions";

export const AuthContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const { sendErrorMessage } = useErrorHandler();

  const Login = async (
    userData: ILogin,
    callback: Function,
    onError?: Function
  ): Promise<void> => {
    AuthService.Login(userData)
      .then((response) => {
        const userLogin: IAuth = response.data;

        userLogin.roles = getUserPermissions(userLogin.user);
        // set language
        i18n.changeLanguage((userLogin.user.language as string).toLowerCase());

        // set user
        dispatch(setAuth(userLogin));

        callback(userLogin);
      })
      .catch((error) => {
        //console.log("AuthContextProvider:Login:: error", error);
        sendErrorMessage(error);
        onError && onError(error);
      });
  };

  const Logout = async (callback: VoidFunction): Promise<void> => {
    AuthService.Logout()
      .then(() => {
        dispatch({ type: "logout" });
        // window.location.replace("/login");
        callback();
      })
      .catch((error) => {
        // console.log("AuthContextProvider:Logout::error", error);
        sendErrorMessage(error);
      });
  };

  const RecoverPassword = async (
    data: IRecoverPassword,
    callback: VoidFunction
  ): Promise<void> => {
    AuthService.RecoverPassword(data)
      .then(() => {
        callback();
      })
      .catch((error) => {
        sendErrorMessage(error);
      });
  };

  const value = {
    Login,
    Logout,
    RecoverPassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
