import { InputText, RequiredField, useFeedback } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { RequiredFields } from "components/utils/forms/RequiredFields";
import { useUpdate } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { IClient, IClientEditForm } from "interfaces/IClient";
import { ServiceApiUrl } from "services/ServiceApiUrl";

import { formDefaultValues } from "./defaultValues";
import { setClientSchema } from "./validations";

export interface IClientsEdit {
  client: IClient;
  open: boolean;
  handlerClose: () => void;
  updateList: () => void;
}

const ClientsEdit = ({ client, open, handlerClose, updateList }: IClientsEdit) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const { sendErrorMessage } = useErrorHandler();

  //pedido para editar Cliente
  const {
    loading,
    error,
    refetch: editClient,
  } = useUpdate<IClient[]>(ServiceApiUrl.clientURL, client.id);

  const defaultValues = formDefaultValues(client);

  const methodsEdit = useForm<IClientEditForm>({
    mode: "all",
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
    resolver: yupResolver(setClientSchema(t('validations.required'))),
  });

  useEffect(() => {
    methodsEdit.reset();
  }, []);

  // sucesso
  const handlerSuccess = () => {
    handlerClose(); //fecha modal
    addFeedback({
      message: t("common.changesSaved"),
      severity: "success",
    });
    updateList && updateList();
  };
  //erro
  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IClientEditForm> = async (
    payLoad: IClientEditForm
  ) => {
    try {
      await editClient({ data: payLoad });
      await handlerSuccess();
    } catch (error) {}
  };

  // const [module, setModule] = useState<string[]>([]);
  // const moduleChange = (event: SelectChangeEvent<typeof module>) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setModule(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  // };

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <DialogTitle>
        <Typography
          component={"span"}
          color="text.black"
          gutterBottom={true}
          variant="h5"
          noWrap
        >
          {t("clients.editClient")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <FormProvider {...methodsEdit}>
          <form
            id="edit-client-form"
            onSubmit={methodsEdit.handleSubmit(formSubmitHandler)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RequiredField
                  title={t("clients.name")}
                  children={
                    <InputText
                      name="name"
                      placeholder={t("clients.namePlaceholder")}
                      control={methodsEdit.control}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom color="text.black" variant="h6">
                  {t("clients.centerPoint")}
                </Typography>
                <InputText
                  name="center_point"
                  placeholder={t("clients.centerPointPlaceholder")}
                  control={methodsEdit.control}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <RequiredFields />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handlerClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!methodsEdit.formState.isValid}
          type={"submit"}
          form={"edit-client-form"}
        >
          {t("common.save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ClientsEdit;
