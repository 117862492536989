import { Button, CssBaseline, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ReportIcon from '@mui/icons-material/Report';
import { useTranslation } from "react-i18next";

const ErrorHandler = (props: any) => {

  // console.log('props', props);

  const { error } = props;
  const { t } = useTranslation();

  // const [active, setActive] = useState(false)

  // console.log(error);

  return (
    <>
      <CssBaseline />
      <Grid container sx={{ textAlign: 'center' }} >
        <Grid item xs={true} mt={12}>


          <ReportIcon color="error" sx={{ fontSize: 80 }} />


          <Typography mt={2} variant="h3">{t("errors.somethingWentWrong")}</Typography>


          <Typography mb={2} mt={6} component={'span'} variant="h5">{error.name}</Typography>



          <Grid container alignItems="center" rowSpacing={2}>
            <Grid item xs>
            </Grid>

            <Grid mb={0.5} item xs={9}>
              {error.message}
            </Grid>

            <Grid item xs sx={{ textAlign: 'start' }} >
              {/* <Tooltip placement='right' title={t("errors.seeErrorDetails")+''}>
                <IconButton sx={{ marginLeft: '4%' }}
                  className="outlined"
                  color='primary'
                  size="small"
                  onClick={(e: any) => { setActive(!active) }}>
                  {active ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
              </Tooltip> */}
            </Grid>

          </Grid>


          {/* {active &&
            <Typography color="text.secondary" variant="subtitle2">descrição do erro</Typography>
          }*/}
          <Box mt={5}>
            <Button onClick={event => window.location.href = '/'} size="large" variant="contained">{t('errors.goToHome')}</Button>
          </Box>

        </Grid>
      </Grid>
    </>
  );
};

export default ErrorHandler;
