import LiveRecoverPassword from "components/auth/RecoverPassword";
import LiveMainLogin from "components/page/MainLogin";

export default function Recover() {
  return (
    <LiveMainLogin backgroundImage>
      <LiveRecoverPassword />
    </LiveMainLogin>      
  );
}
