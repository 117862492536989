import { formatNumber } from "utils";
import { IReactECharts } from "./ReactECharts";

export const colors = [
  "#51bfbc",
  "#C2CE51",
  //variants of #51bfbc
  "#c08a2d",
  "#ecc255",
  "#be8fd8",
  "#875ba0",
  "#c17982",
  "#efa2ab",
  "#9dd8d6",
  "#2e9492",
  //variants of #C2CE51
  "#5459ba",
  "#8786ed",
  "#d9e1a0",
  "#95a32d",
  "#b961a3",
  "#e68bce",
  "#5bc2e6",
  "#3697ba",
];

export const defaultChartOptions: IReactECharts["option"] = {
  color: colors,
  tooltip: {
    confine: true,
    extraCssText: "white-space:inherit;",
    trigger: "axis",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    valueFormatter: function (value: any) {
      return formatNumber(value);
    },
  },
  legend: {
    bottom: 0,
    icon: 'circle',
    itemGap: 25,
    itemHeight: 12,
    textStyle: {
      fontSize: '12px',
      color: '#333333',
      fontFamily: 'Altice',
    },
  },
  grid: {
    left: "8%",
    right: "8%",
    bottom: "30%",
  },
  xAxis: {
    offset: 10,
    boundaryGap: false,
    axisTick: { show: false},
    type: "category",
    data: [],
  },
  series: [],
  yAxis: [
    {
      axisLabel: {
        formatter: (val: any) => formatNumber(val),
      },
    },
  ],
};
