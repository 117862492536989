/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useDelete } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";

import { IAccount } from "interfaces/IAccount";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { ConfirmDialog, useFeedback } from "@alb/live-lib";

export interface IAccountsDelete {
	account: IAccount;
	open: boolean;
	handlerClose: () => void;
	updateList: () => void;
}

const AccountsDelete = ({
	account,
	open,
	handlerClose,
	updateList,
}: IAccountsDelete) => {
	const { t } = useTranslation();
	const { addFeedback } = useFeedback();
	const { sendErrorMessage } = useErrorHandler();

	const {
		loading,
		error,
		refetch: deleteAccount,
	} = useDelete<IAccount[]>(ServiceApiUrl.accountURL, account.id);

	//sucesso
	const handlerSuccess = () => {
		handlerClose();
		addFeedback({
			message: t("accounts.accountDeleted"),
			severity: "success",
		});
		updateList();
	};
	//erro
	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);

	// eliminar account
	const handleDeleteAccount = async () => {
		try {
			await deleteAccount();
			await handlerSuccess();
		} catch { }
	};

	return (
		<ConfirmDialog
			open={open}
			type="error"
			title={`${t("accounts.deleteAccount")} ${account.name}`}
			message={t("accounts.accountDeleteConfirmation")}
			actionConfirmText={t("common.delete")}
			actionCancelText={t("common.cancel")}
			loading={loading}
			onCancel={handlerClose}
			onConfirm={handleDeleteAccount}
		/>
	);
};

export default AccountsDelete;
