import React from 'react'
import { DialogTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export interface ITitle {
  children?: React.ReactNode;
  onClose?: () => void
}

const LiveDialogTitle = ({ children, onClose }: ITitle) => {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }}>
      {children}
      <IconButton
        aria-label="close"
        onClick={onClose}
        size="small"
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  )
}

export default LiveDialogTitle