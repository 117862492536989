import { getRoles } from "store/slices/authSlice";
import { useSelector } from "react-redux";

export const useHasPermissions= () => {
  const roles = useSelector(getRoles);

  const hasPermission = (permissions: number[]) => {    
   return roles?.find((role:any) => permissions?.includes(role)) ? true : false
  }

  function hasManyPermissions(permissions: number[]) {
    const checkPermissions = permissions.map((p) => {
      return roles.includes(p) ? true : false;
    });

    return checkPermissions.includes(false) ? false : true;
  }

  return { hasPermission , hasManyPermissions }
}

