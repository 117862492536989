import { createSlice, current } from "@reduxjs/toolkit";
import { TMarker } from "interfaces/TMarker";
import { RootState } from "store/store";
import { DeviceRealTime } from "utils/data-real-time";

export interface AdapterConfiguratorsState {
  devices: TMarker[];
  selectedAdapters: string[];
  devicesFilterParams: any;
  deviceRealTime: DeviceRealTime;
}

const initialState: AdapterConfiguratorsState = {
  devices: [],
  selectedAdapters: [],
  devicesFilterParams: {},
  deviceRealTime: {
    external_id: "",
    last_read_at: "",
    last_read_value: {
      park_type: "",
      park_occupied: "",
    },
  },
};

export const adapterConfiguratorsSlice = createSlice({
  name: "adapterConfigurators",
  initialState,
  reducers: {
    devicesbyLocation: (state, { payload }) => {
      const allDevices = state.devices;
      //se não existir payLoad(bounds), torna a colocar os devices antigos
      if (payload.length < 1) {
        if (
          current(state.devicesFilterParams) &&
          current(state.devicesFilterParams).adapters
        )
          if (current(state.selectedAdapters).length > 0) {
            //se existiam filtros de adapters, só pode adicionar os devices com esses adapters)
            const filteredDevices: TMarker[] = [];
            state.devices = filteredDevices; //os devices voltam a ser os que estavam com o filtro ativo
          }
          //se não existirem adapters, devolve todos (significa que não foi aplicado o filtro e continuam todos ativos)
          else {
            state.devices = allDevices;
            return;
          }
        return;
      }
      //se forem enviados bounds, filtra pelos devices atuais
      state.devices = current(state.devices).filter((d: TMarker) =>
        payload.contains(d.geolocation.iconPosition)
      );
    },
    setDevices(state, action) {
      state.devices = action.payload;
    },
    setDevicesFilterParams(state, action) {
      state.devicesFilterParams = action.payload;
    },
    setDeviceRealTime(state, action) {
      state.deviceRealTime = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  devicesbyLocation,
  setDevices,
  setDevicesFilterParams,
  setDeviceRealTime,
} = adapterConfiguratorsSlice.actions;

// Selectors/Getters
export const selectDevices = (state: RootState) =>
  state.adapterConfigurators.devices;
export const selectDeviceRealTime = (state: RootState) =>
  state.adapterConfigurators.deviceRealTime;
export const selectDevicesFilterParams = (state: RootState) =>
  state.adapterConfigurators.devicesFilterParams;

export default adapterConfiguratorsSlice.reducer;
