import { PERMISSIONS } from "./permissions";
import { userRoles } from "./userRoles";

export const adminRoles = [
  ...userRoles,
  PERMISSIONS.MANAGEMENT.VIEW,
  PERMISSIONS.MANAGEMENT.ADAPTERS.VIEW,
  PERMISSIONS.MANAGEMENT.ADAPTERS.CUD,
  PERMISSIONS.MANAGEMENT.USERS.VIEW,
  PERMISSIONS.MANAGEMENT.USERS.CUD,
  PERMISSIONS.MANAGEMENT.ACCOUNTS.VIEW,
  PERMISSIONS.MANAGEMENT.ACCOUNTS.CUD
]
