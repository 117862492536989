export const PERMISSIONS = {
  MAP: {
    VIEW: 10000,
  },
  DASHBOARD: {
    VIEW: 20000,
    BANANASENSE: {
      VIEW: 20100,
    },
    ENOANALYTICS: {
      VIEW: 20200,
    },
    COMPARATIVE_ANALYSIS: {
      VIEW: 20300,
      ADD: 20301
    }
  },
  MY_PROFILE: {
    VIEW: 30000,
    EDIT: 30001,
  },
  MODULES: {
    VIEW: 40000,
    MORPHOLOGICAL_DATA: {
      VIEW: 50000,
      CUD: 50001,
      STAGES: {
        VIEW: 60000,
        ADD: 60001,
        EDIT: 60002,
        DELETE: 50024,
      }
    },
  },
  ABOUT: {
    VIEW: 70000
  },
  MANAGEMENT: {
    VIEW: 80000,
    ADAPTERS: {
      VIEW: 90000,
      CUD: 90001
    },
    USERS: {
      VIEW: 100000,
      CUD: 100001,
    },
    MODULES: {
      VIEW: 110000,
      CUD: 110001,
    },
    ACCOUNTS: {
      VIEW: 120000,
      CUD: 120001,
    }
  },
  ADMINISTRATION: {
    VIEW: 130000,
    CLIENTS: {
      VIEW: 140000,
      CUD: 140001
    }
  }
};
