import { InputAutocomplete, InputText, useFeedback } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useCreate, useErrorHandler, useGetAll } from "hooks";
import { IAdapterConfigurator, IApiResponse, IModuleForm, ISelectOption } from "interfaces";
import { IModule } from "interfaces/IModule";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty } from "utils";

import { getModuleSchema } from "./validations";

export interface IModulesAdd {
  clientID: string;
  open: boolean;
  handlerClose: () => void;
  updateList: () => void;
}

const ModulesAdd = ({ clientID, open, handlerClose, updateList }: IModulesAdd) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const { sendErrorMessage } = useErrorHandler();


  //pedido para criar module
  const {
    loading,
    error,
    refetch: createModule,
  } = useCreate<IModule[]>(ServiceApiUrl.moduleURL);

  const [gatewaysOptions, setGatewaysOptions] = useState<ISelectOption[]>([]);
  // get list of modules
  const { data: allGateways } = useGetAll<IApiResponse<IAdapterConfigurator>>(
    ServiceApiUrl.adapterListURL, { client: clientID }
  );


  useEffect(() => {
    if (allGateways) {
      const options = allGateways?.data?.map((p: IAdapterConfigurator) => ({
        label: p.name,
        value: p.id,
      }));

      setGatewaysOptions(options);
    }
  }, [allGateways]);

  const methodsAdd = useForm<IModuleForm>({
    mode: "all",
    reValidateMode: 'onChange',
    defaultValues: {
      name:"",
      adapter_configurators: []
    },
    resolver: yupResolver(getModuleSchema(t('validations.required'))),
  });

  // sucesso
  const handlerSuccess = () => {
    handlerClose(); //fecha modal
    addFeedback({
      message: t("modules.moduleAdded"),
      severity: "success",
    });
    updateList && updateList();
  };
  //erro
  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error]);

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IModuleForm> = async (
    payLoad: IModuleForm
  ) => {
    // console.log("payLoad ", payLoad);
    try {
      const payLoadAPI = payLoad as unknown as IModuleForm<string>;
      if (clientID) payLoadAPI.client = clientID;
      payLoadAPI.adapter_configurators = payLoad.adapter_configurators.map(
        (a: any) => a.value
      );
      await createModule({ data: payLoadAPI });
      await handlerSuccess();
    } catch (error) { }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <DialogTitle>
        <Typography
          component={"span"}
          color="text.black"
          gutterBottom={true}
          variant="h5"
          noWrap
        >
          {t("modules.addModule")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <FormProvider {...methodsAdd}>
          <form
            id="add-module-form"
            onSubmit={methodsAdd.handleSubmit(formSubmitHandler)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography gutterBottom color="text.black" variant="h6">
                  {t("modules.name")}
                </Typography>
                <InputText
                  control={methodsAdd.control}
                  name="name"
                  placeholder={t("modules.namePlaceholder")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom color="text.black" variant="h6">
                  {t("modules.adapters")}
                </Typography>
                {arrayIsEmpty(gatewaysOptions) && (
                    <Typography mt={2} mb={2} variant="body1">
                    {t("adapters.thereAreNotAdapters")}
                  </Typography>
                )}
                {!arrayIsEmpty(gatewaysOptions) && (
                  <InputAutocomplete
                    name="adapter_configurators"
                    options={gatewaysOptions}
                    multiple
                    control={methodsAdd.control}
                    required
                  />
                )}
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handlerClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!methodsAdd.formState.isValid}
          type={"submit"}
          form={"add-module-form"}
        >
          {t("common.add")}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  );
};

export default ModulesAdd;
