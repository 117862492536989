/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, useTheme, Badge } from "@mui/material";
import { useGet, useUpdate } from "hooks";
import { useContext, useEffect, useRef, useState } from "react";
import {
  DrawOption,
  IActionControl,
  IMap,
  IMapPoint,
  LayoutContext,
  Map,
  TileType,
  getBoundsVisibleArea,
  useFeedback,
} from "@alb/live-lib";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import { LatLngExpression } from "leaflet";
import { arrayIsEmpty } from "utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  TypeMarkerInfo,
  getMapBounds,
  getMarkerDetails,
  getSelectedMarkerType,
  getShapeBounds,
  getSidebarIsOpen,
  selectFiltersTabOpen,
  selectMarkers,
  selectOpenList,
  setBounds,
  setFilterMenuMarkers,
  setFiltersTabOpen,
  setHighlightedListMarker,
  setSelectedMapType,
  setSelectedMarkerType,
  setShapeBounds,
  toggleList,
} from "store/slices/mapSlice";
import {
  devicesbyLocation,
  selectDevices,
  selectDevicesFilterParams,
  setDevices,
  setDevicesFilterParams,
} from "store/slices/adapterConfiguratorsSlice";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import useDidMountEffect from "hooks/useDidMountEffect";
import useMarkers from "hooks/useMarkers";
import { TMarker } from "interfaces/TMarker";
import { useErrorHandler } from "hooks/useErrorHandler";
import { FilterTab } from "components/map/controls/FilterMapTab/FilterTab";
import MarkerInfoComponent from "components/map/map-entity-info/MarkerInfoComponent";
import { MapListContainer } from "components/map/list/MapListContainer";
import { MarkersTypeOptions } from "components/map/controls/FilterMapTab/utils/GetOptions";
import useGetFiltersCount from "components/map/controls/FilterMapTab/hooks/useGetFiltersCount";
import { IUser } from "interfaces";
import { getUser, setUser } from "store/slices/authSlice";

export const IconFilter = (props: { count: number }) => {
  const { count } = props;
  const theme = useTheme();

  return (
    <Badge
      badgeContent={count}
      sx={{
        ".MuiBadge-badge": {
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.common.white,
        },
      }}
    >
      <FilterListRoundedIcon />
    </Badge>
  );
};

function MapPage() {
  const user = useSelector(getUser)
  const { sendErrorMessage } = useErrorHandler();
  const { addFeedback } = useFeedback();
  const [validateScope, setValidateScope] = useState<boolean>(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filterCoordinates, setFilterCoordinates] = useState<
    [LatLngExpression] | []
  >([]);
  const filterTabOpen = useSelector(selectFiltersTabOpen);
  const filterTabOpenRef = useRef<boolean>();
  filterTabOpenRef.current = filterTabOpen;
  const openList = useSelector(selectOpenList);
  const [openFilters, setOpenFilters] = useState(false);
  const [shapeboundsAux, setShapeBoundsAux] = useState(false);
  const markers: IMapPoint[] = useSelector(selectMarkers);
  const { addHeader } = useContext(LayoutContext);
  const filtersCount = useGetFiltersCount();
  const filtersCountRef = useRef<number>(0);
  filtersCountRef.current = filtersCount;
  const { getAllMarkersMap } = useMarkers();
  const mapDeviceParams = useSelector(selectDevicesFilterParams);
  const shapeBounds = useSelector(getShapeBounds);
  const mapBounds = useSelector(getMapBounds);
  const selectedge = useSelector(getSelectedMarkerType);
  const devices: TMarker[] = useSelector(selectDevices);
  const sidebarIsOpen: any = useSelector(getSidebarIsOpen);
  const darkMode = user?.extra_params?.dark_mode

  function returnIcon() {
    return <IconFilter count={filtersCountRef.current} />;
  }
  let actionsCtrl: IActionControl[] = [
    {
      action: () => {
        dispatch(toggleList(false));
        dispatch(setFiltersTabOpen(!filterTabOpenRef.current));
      },
      icon: returnIcon, //é enviado em formato função porque não estava a atualizar o valor do count!
      title: t("map.buttons.filters"),
    },
  ];
  const onCloseFilterTab = () => {
    dispatch(setFiltersTabOpen(false));
  };

  let mapCenter: any;
  if (
    user?.client?.center_point &&
    !arrayIsEmpty(user?.client?.center_point)
  ) {
    mapCenter =
      {
        lat: user?.client?.center_point[0],
        lng: user?.client?.center_point[1],
      } || undefined;
  }
  const [centerMap, setcenterMap] = useState<LatLngExpression | undefined>(
    mapCenter
  );
  const initialZoom = 15;
  const [zoomTest, setzoomTest] = useState<number>(initialZoom);

  const handleZoom = (zoom: number) => {
    setzoomTest(zoom);
  };
  const handleCenter = (coord: LatLngExpression) => {
    setcenterMap(coord);
  };

  useEffect(() => {
    if (!openFilters && filterTabOpen) {
      setOpenFilters(true);
    }
  }, [filterTabOpen]);
  //faz o pedido apenas quando o shapeBounds  alterar
  useDidMountEffect(() => {
    if (arrayIsEmpty(shapeBounds) && shapeboundsAux) {
      setShapeBoundsAux(false);
      fetchAllMarkers();
    }
  }, [shapeBounds, shapeboundsAux]);

  const handleDrawFilter = (filters: [LatLngExpression] | []) => {
    setFilterCoordinates(filters);
    if (
      !arrayIsEmpty(mapBounds) &&
      ((Array.isArray(filters) && arrayIsEmpty(filters)) ||
        Object.keys(filters).length === 0)
    ) {
      setShapeBoundsAux(true);
      dispatch(setShapeBounds([]));
    } else {
      const bounds = getBoundsVisibleArea(filters as any);
      dispatch(setShapeBounds(bounds));
      dispatch(devicesbyLocation(filters));
    }
  };

  const handleBoundsChange = (bounds: number[][]) => {
    dispatch(setBounds(bounds));
  };

  const [markerTypeDetails, setMarkerTypeDetails] = useState<string>("");
  const [openDetails, setOpenDetails] = useState<boolean>(false);

  const handlePointClick = (point: IMapPoint) => {
    dispatch(getMarkerDetails(point.id, point.type as TypeMarkerInfo));
    setMarkerTypeDetails(point.type);
    setOpenDetails(true);
  };

  const [highlightedPoint, setHighlightedPoint] = useState<IMapPoint | null>(
    null
  );

  const handleOnHoverItemList = (item: IMapPoint | null) => {
    setHighlightedPoint((prev:any) => (prev = item?.selected ? item : null));
    dispatch(setHighlightedListMarker(item));
  };

  const mapTile = user?.extra_params?.map_tile_layer || TileType.default;
  //pedido para alterar dados user
  const { error: updateUserError, refetch: updateUser } = useUpdate<IUser>(
    ServiceApiUrl.userURL,
    user?.id
  );

  const extraParamsUSer = user?.extra_params;
  //guarda novo tile nos extra params do user
  const handleChangeTileType = (tile: string) => {
    if (user && tile !== user?.extra_params?.map_tile_layer) {

      const defaultValues = {
        extra_params: {
          ...extraParamsUSer,
          map_tile_layer: tile
        }
      }
      updateUser({ data: defaultValues }).then((res) => {
        const response = res.data;
        dispatch(setUser(response))
      })

      handlerSuccess();
    }
  };
  useEffect(() => {
    if (updateUserError) {
      sendErrorMessage(updateUserError);
    }
  }, [updateUserError]);
  //sucesso
  const handlerSuccess = () => {
    addFeedback({ message: t("common.changesSaved"), severity: "success" });
  };

  let args: IMap = {
    language: user?.language.toLowerCase() || "pt",
    mapTranslations: {
      buttons: {
        layers: t("map.buttons.layers"),
        selectAreas: t("map.buttons.selectAreas"),
        edit: t("map.buttons.edit"),
        remove: t("map.buttons.remove"),
        marker: t("map.buttons.marker"),
        circle: t("map.buttons.circle"),
        rectangle: t("map.buttons.rectangle"),
        polygon: t("map.buttons.polygon"),
        polyline: t("map.buttons.polyline"),
        recenter: t("map.buttons.recenter"),
        delete: t("map.buttons.remove"),
        cancel: t("map.buttons.cancel"),
        close: t("common.close"),
      },
      popups: {
        lastRead: t("map.lastReading"),
        showDetail: t("common.showDetail"),
      },
      date: {
        dateTimeFullFormat: t("calendar.dateTimeFullFormat"),
      },
      leafletDraw: {
        removeShapeConfirmation: t("map.leafletDraw.removeShapeConfirmation"),
        removeShape: t("map.leafletDraw.removeShape"),
        dragToEdit: t("map.leafletDraw.dragToEdit"),
        clickCancelToUndo: t("map.leafletDraw.clickCancelToUndo"),
        clickToRemove: t("map.leafletDraw.clickToRemove"),
        howToDrawCircle: t("map.leafletDraw.howToDrawCircle"),
        howToDrawCircleMarker: t("map.leafletDraw.howToDrawCircleMarker"),
        howToDrawMarker: t("map.leafletDraw.howToDrawMarker"),
        howToDrawPolygon: t("map.leafletDraw.howToDrawPolygon"),
        howToDrawRectangle: t("map.leafletDraw.howToDrawRectangle"),
      },
      markers: {
        active: t("common.active"),
        inactive: t("common.inactive"),
      },
      common: {
        noData: t("common.noData"),
      },
    },
    mapVariant: "page",
    actionsControls: actionsCtrl,
    mapCenterPoint: centerMap,
    initialMapCenter: mapCenter
      ? (Object.values(mapCenter) as LatLngExpression)
      : undefined,
    mapZoom: zoomTest,
    initialZoom: initialZoom,
    mapTileType: mapTile,
    points: markers,
    mapModule: false,
    showZoom: true,
    onZoom: handleZoom,
    onCenter: handleCenter,
    onDrawFilter: handleDrawFilter,
    drawOptions: [DrawOption.rectangle, DrawOption.circle, DrawOption.polygon],
    appDarkThemeMode: darkMode,
    onBounds: handleBoundsChange,
    width: openList || filterTabOpen ? "450px" : "0px",
    onPointClick: handlePointClick,
    highlightPoint: highlightedPoint,
    sidebarIsOpen: sidebarIsOpen,
    onChangeTileType: handleChangeTileType,
  };

  useEffect(() => {
    // se não tiver scope definido, fazer logout...
    // if (!auth?.user?.scope) {
    //   const logout = () => {
    //     Logout(() => {
    //       navigate("/login", { replace: true });
    //     });
    //   };
    //   logout();
    // }else{
    setValidateScope(false);
    addHeader();
    dispatch(setSelectedMarkerType("all"));
    dispatch(setFiltersTabOpen(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      // remove params when left the map
      dispatch(setDevicesFilterParams({}));
      dispatch(setFilterMenuMarkers(""));
      dispatch(setSelectedMapType(null));
    };
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const params = {
    points: !arrayIsEmpty(shapeBounds)
      ? JSON.stringify(shapeBounds)
      : JSON.stringify(mapBounds),
    ...(mapDeviceParams !== undefined && { ...mapDeviceParams }),
  };

  const options = {
    manual: true,
  };

  const { refetch: refetchDevices } = useGet(
    ServiceApiUrl.devices,
    undefined,
    params,
    options
  );

  async function fetchAllMarkers() {
    switch (selectedge) {
      case MarkersTypeOptions.devices:
        fetchDevicesMarkers();
        break;
      default:
        fetchDevicesMarkers();
    }
  }

  useDidMountEffect(() => {
    if (
      !arrayIsEmpty(mapBounds) &&
      // filter is array or object
      ((Array.isArray(filterCoordinates) && arrayIsEmpty(filterCoordinates)) ||
        Object.keys(filterCoordinates).length === 0)
    ) {
      fetchAllMarkers();
    }
  }, [mapBounds]);

  async function fetchDevicesMarkers() {
    await refetchDevices()
      .then((res: any) => {
        const response = res.data.data;
        dispatch(setDevices(response));
      })
      .catch((error: any) => {
        dispatch(setDevices([]));
        sendErrorMessage(error);
      });
  }
  async function getMarkersMap() {
    await getAllMarkersMap();
  }

  useEffect(() => {
    getMarkersMap();
  }, [devices]);

  const updateMarkers = () => {
    fetchAllMarkers();
  };

  return (
    <Grid sx={{ position: "relative", marginTop: "-32px" }}>
      <MapListContainer
        items={markers}
        itemDetails={handlePointClick}
        onHover={handleOnHoverItemList}
      />
      {openFilters && (
        <FilterTab open={filterTabOpen} onClose={onCloseFilterTab} />
      )}

      {!validateScope && mapCenter && <Map {...args} />}

      {openDetails && markerTypeDetails && (
        <MarkerInfoComponent
          type={markerTypeDetails}
          dialogProps={{
            open: openDetails,
            onClose: () => setOpenDetails(false),
          }}
          onSuccess={updateMarkers}
        />
      )}
    </Grid>
  );
}

export default MapPage;
