import { AppLogo, LayoutContext, Loading, ToggleViewButton, TToggle } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import EditIcon from "@mui/icons-material/Edit";
import GroupIcon from "@mui/icons-material/Group";
import LightModeIcon from "@mui/icons-material/LightMode";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ChipStatus } from "components/utils/ChipStatus";
import { useGet, useUpdate } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { IUser } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { setTheme } from "store/slices/appManagementSlice";
import { getUser, setUser } from "store/slices/authSlice";
import { LiveFlexGridBaseline, TypographyWrapIcon } from "styles/components-styles";
import { formatDate, statusColor, THEME_KEY } from "utils";
import { getCookie } from "utils/cookie";

import ChangeClient from "./changeClient";
import ChangePassword from "./changePassword";
import ProfileEdit from "./editProfile";

export default function ProfileSettings() {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const userRedux = useSelector(getUser)

	const [openDialogEditProfile, setOpenDialogEditProfile] = useState(false);
	const [openDialogChangePwd, setOpenDialogChangePwd] = useState(false);
	const [openDialogChangeClient, setOpenDialogChangeClient] = useState(false);

	//pedido dos dados do user (logged user)
	const {
		data: user,
		loading,
		error,
		refetch,
	} = useGet<IUser>(ServiceApiUrl.userURL, userRedux ? userRedux.id : "");

	const { refetch: updateUser } = useUpdate<IUser>(
		ServiceApiUrl.userURL,
		userRedux?.id
	);

	const { sendErrorMessage } = useErrorHandler();
	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);

	useEffect(() => {
		updateUsers();
	}, [userRedux.id]);

	const handlerDialogEditProfileState = () => {
		setOpenDialogEditProfile(!openDialogEditProfile);
	};

	//para fazer novo pedido dos users
	const updateUsers = async () => {
		try {
			await refetch();
		} catch { }
	};

	const showChangePassword = () => {
		// console.log("show change password ...");
		setOpenDialogChangePwd(true);
	};
	const handlerCloseChangePwd = () => {
		// console.log("handler closechange pwd ...");
		setOpenDialogChangePwd(false);
	};
	const showChangeClient = () => {
		// console.log("show change client ...");
		setOpenDialogChangeClient(true);
	};
	const handlerCloseChangeClient = () => {
		// console.log("handler close change client ...");
		setOpenDialogChangeClient(false);
	};

	const { addHeader } = useContext(LayoutContext);
	const header = {
		title: t("user.personalDetails"),
	};
	useEffect(() => {
		addHeader(header);
	}, [i18n.language]);

	//handle theme
	const themeMode = getCookie<string>(THEME_KEY)
	const [mode, setMode] = useState<string>(themeMode);
	const toggleConfig: TToggle[] = [
		{
			value: "light",
			icon: (
				<LightModeIcon titleAccess={t("theme.lightMode")} fontSize="small" />
			),
		},
		{
			value: "dark",
			icon: <DarkModeIcon titleAccess={t("theme.darkMode")} fontSize="small" />,
		},
	];

	const extraParamsUSer = userRedux?.extra_params
	const handleTheme = (
		event: React.MouseEvent<HTMLElement>,
		nemMode: string | null
	) => {
		if (nemMode !== null) {
			setMode(nemMode);
			const defaultValues = {
				extra_params: {
					...extraParamsUSer,
					dark_mode: nemMode === 'dark'
				}
			}
			updateUser({ data: defaultValues }).then((res) => {
				const response = res.data;
				dispatch(setTheme(nemMode)) //save new theme on redux (and localstorage, they are connected)
				dispatch(setUser(response))
			})
		}
	};

	return (
		<>
			{loading && !error && <Loading show={loading} />}
			{user && (
				<>
					<Grid mt={5} container gap={2}>
						<Grid item xs={7}>
							<Card>
								<CardHeader
									title={t("user.personalData")}
									action={
										<Button
											sx={{ mb: 2 }}
											onClick={() => setOpenDialogEditProfile(true)}
											color="primary"
											variant="outlined"
											size="small"
											startIcon={<EditIcon />}
										>
											{t("common.edit")}
										</Button>
									}
								/>
								<CardContent>
									<Grid container justifyContent="flex-start" direction="row">
										<Grid item xs={3}>
											<AppLogo
												src={user.avatar}
												alt={user.first_name}
												variant="rounded"
												sx={{ width: '100%', height: '100%' }}
											/>
										</Grid>

										<Grid pl={2} mt={1} item xs={true}>
											<LiveFlexGridBaseline item xs={12} gap={"0.7rem"}>
												<Typography color="text.black" variant="h6">
													{user.first_name + " " + user.last_name + " "}
												</Typography>

												<ChipStatus
													label={t(`user.status.${user.status}`)}
													color={statusColor(user.status)}
												/>
											</LiveFlexGridBaseline>
											<Grid item xs={12}>
												<Typography color="text.primary" variant="body1">
													{user.username}
												</Typography>
											</Grid>

											<Grid item mt={1} xs={12}>
												<TypographyWrapIcon
													color="text.primary"
													variant="body1">
													<MailOutlineIcon color="disabled" /> {user.email}
												</TypographyWrapIcon>
											</Grid>
											<Grid item xs={12} mt={1}>
												<TypographyWrapIcon
													color="text.primary"
													variant="body1"
												>
													{user.language.toLowerCase() === "pt" ? (
														<>
															<span className="fi fi-rounded fi-sise-md fis fi-pt"></span>
															{t("common.portuguese")}
														</>
													) : (
														<>
															<span className="fi fi-rounded fi-sise-md fis fi-gb"></span>
															{t("common.english")}
														</>
													)}
												</TypographyWrapIcon>
											</Grid>

											<Grid item mt={{ sm: 3, xs: 1 }} xs={12}>
												<Typography variant="body1">
													<strong>{t("user.LastLogin")}: </strong>
													{formatDate(
														user.last_login,
														t("calendar.dateFullFormatOf")
													)}
												</Typography>
											</Grid>
											<Grid item mt={{ sm: 3, xs: 1 }} xs={12}>
												<Typography variant="body1">
													<strong>{t("theme.chooseTheme")} </strong>
												</Typography>
												<ToggleViewButton
													display={mode}
													toggle={toggleConfig}
													onChange={handleTheme}
													sx={{
														height: "35px",
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={true}>
							<Card>
								<CardHeader
									title={t("user.passwordLabel")}
									action={
										<Button
											color="primary"
											onClick={showChangePassword}
											variant="outlined"
											startIcon={<EditIcon />}
											fullWidth
										>
											{t("common.edit")}
										</Button>
									}
								/>
								<CardContent>
									<Typography variant="body1" mb={2}>
										{t("user.passwordInfoText")}
									</Typography>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
					<Grid mt={2} container gap={2}>
						<Grid item xs={7}>
							<Card>
								<CardHeader
									title={t("user.professionalData")}
									action={
										userRedux.is_superadmin && (
											<Button
												sx={{ mb: 2 }}
												onClick={showChangeClient}
												color="primary"
												variant="outlined"
												size="small"
												startIcon={<EditIcon />}
											>
												{t("common.edit")}
											</Button>
										)
									}
								/>
								<CardContent>
									<TypographyWrapIcon variant="body1">
										<AccountTreeIcon color="disabled" />
										{userRedux && userRedux.client.name}
									</TypographyWrapIcon>
									<TypographyWrapIcon variant="body1">
										<GroupIcon color="disabled" />
										{user.is_superadmin
											? t("user.SuperAdministrator")
											: user.is_admin
												? t("user.Administrator")
												: t("user.user")}
									</TypographyWrapIcon>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
					<ProfileEdit
						user={user}
						open={openDialogEditProfile}
						handlerClose={handlerDialogEditProfileState}
						updateGetUsers={updateUsers}
					/>
					<ChangePassword
						user={user}
						open={openDialogChangePwd}
						onClose={handlerCloseChangePwd}
					/>
					{userRedux.is_superadmin && (
						<ChangeClient
							user={user}
							open={openDialogChangeClient}
							onClose={handlerCloseChangeClient}
							updateGetUsers={updateUsers}
						/>
					)}
				</>
			)}
		</>
	);
}
