//material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
//traduçoes
import { useTranslation } from "react-i18next";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IPassword } from "interfaces";
import { useEffect } from "react";
import { useUpdate } from "hooks";

import { ServiceApiUrl } from "services/ServiceApiUrl";
import { useErrorHandler } from "hooks/useErrorHandler";
import { getChangePwdSchema } from "../validations";
import { LoadingButton } from "@mui/lab";
import { InputText, RequiredField, useFeedback } from "@alb/live-lib";
import { RequiredFields } from "components/utils/forms/RequiredFields";

export default function ChangePassword(props: any) {
  //traduções
  const { t } = useTranslation();
  //alert
  const { addFeedback } = useFeedback();

  //########## SERVIÇOS
  const { loading, error, refetch: changePassword } = useUpdate<IPassword>(
    ServiceApiUrl.resetPwdURL,
    // props.user.id
  );

  //para lançar o erro, caso exista.
  const { sendErrorMessage } = useErrorHandler();

  //sucesso
  const handlerSuccess = () => {
    props.onClose(true);
    addFeedback({ message: t("common.changesSaved"), severity: "success" });
  };

  useEffect(() => {
    methodsEdit.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onClose]);

  //########## FORMULÁRIO

  const methodsEdit = useForm<IPassword>({
    mode: "onChange",
    resolver: yupResolver(getChangePwdSchema(t("user.passwordMustMatch"))),
  });

  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // change pwd
  const formEditSubmitHandler: SubmitHandler<IPassword> = async (
    payLoad: IPassword
  ) => {
    if (props.user) {
      try {
        await changePassword({ data: payLoad }); //enviar os dados
        await handlerSuccess();
      } catch {}
    }
  };

  const { control } = methodsEdit;

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle id="alert-dialog-title">
        <Typography
          component={"span"}
          color="text.black"
          gutterBottom={true}
          variant="h5"
          noWrap
        >
          {t("user.changePasswordTitle")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <FormProvider {...methodsEdit}>
          <form
            id="change-pwd-form"
            onSubmit={methodsEdit.handleSubmit(formEditSubmitHandler)}
          >
            {props.user && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <RequiredField title={t("user.currentPasswordLabel")} />
                  <InputText
                    name="old_password"
                    type="password"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RequiredField title={t("user.newPasswordLabel")} />
                  <InputText
                    name="new_password"
                    type="password"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RequiredField title={t("user.confirmNewPasswordLabel")} />
                  <InputText
                    name="confirmNewPassword"
                    type="password"
                    control={control}
                  />
                  <RequiredFields allRequired />
                </Grid>

              </Grid>
            )}
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={props.onClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!methodsEdit.formState.isValid}
          type={"submit"}
          form={"change-pwd-form"}
        >
          {t("common.save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
