import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getRoutePath } from "utils/routePath";
import { useContext, useEffect } from "react";
import { BackToButton, LayoutContext } from "@alb/live-lib";
import { ModuleGridItem } from "utils/ModuleGridItem";


export default function MorphologicalDataAdministration() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();

  function navigateTo(page: string) {
    const path = getRoutePath(location, params);
    //navigate(`/morphologicaldata/administration/${page}`);
    navigate(`${path}/${page}`);
  }

  const { addHeader } = useContext(LayoutContext)
  const header = {
    title: t("morphologicalData.morphologicalData"),
    backTo: (
      <BackToButton
        title={t("goBack")}
        onClick={() => navigate("/management/modules")}
      />
    ),
  }
  useEffect(() => {
    addHeader(header);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Grid container mt={5} mb={1} spacing={2}>
      <ModuleGridItem title={t("morphologicalData.samples")} onClick={() => navigateTo("samples")} />
      <ModuleGridItem title={t("morphologicalData.locations.locations")} onClick={() => navigateTo("locations")} />
      <ModuleGridItem title={t("morphologicalData.cultivations.cultivations")} onClick={() => navigateTo("cultivations")} />
      <ModuleGridItem title={t("morphologicalData.varieties.varieties")} onClick={() => navigateTo("varieties")} />
      <ModuleGridItem title={t("morphologicalData.harvests.harvests")} onClick={() => navigateTo("harvests")} />
      <ModuleGridItem title={t("morphologicalData.pulpColors.pulpColors")} onClick={() => navigateTo("pulpcolors")} />
      <ModuleGridItem title={t("morphologicalData.textures.textures")} onClick={() => navigateTo("textures")} />
      </Grid>
    </>
  );
}
