import useAxios from "axios-hooks";
import { AxiosRequestConfig } from "axios";

export function useDelete<T = any>(
  url: string,
  id?: string,
  othersConfig?: AxiosRequestConfig<any>
) {

  const verifiedUrl = id? url +"/" + id : url
  const config: AxiosRequestConfig = { 
    ...othersConfig,
    url: verifiedUrl,
    method: 'DELETE'
  };
  const [{ data, loading, error }, refetch, cancelRequest] = useAxios<T>(
    config,
    { manual: true }
  );

  return { data, loading, error, refetch, cancelRequest };
}
