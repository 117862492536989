import { createSlice } from '@reduxjs/toolkit'

import { RootState } from 'store/store'
import { THEME_KEY } from 'utils'
import { getCookie, setCookie } from 'utils/cookie'

interface IAppSlice {
  theme: string
}

const initialState: IAppSlice = {
  theme: getCookie<string>(THEME_KEY)
}

const appManagementSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setTheme(state, action) {
      state.theme = action.payload
      setCookie<string>(THEME_KEY, state.theme);
    },
  },
})

export const { setTheme } = appManagementSlice.actions

export const getTheme = (state: RootState) => state.app.theme

export default appManagementSlice.reducer
