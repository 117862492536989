import { ErrorBoundary } from "react-error-boundary";
import { AuthContextProvider } from "providers";
import { AppRoutes } from "routes";
import history from "utils/history_g";
import ErrorHandler from "views/errors/ErrorHandler";

const LivePage = () => {    
  return (
    <ErrorBoundary
      FallbackComponent={ErrorHandler}
      onError={(error, errorInfo) => console.log({ error, errorInfo })}
      onReset={() => {
        // reset the state of your app
        console.log("onReset...");
      }}
    >
        <AuthContextProvider>
          <AppRoutes history={history} />
        </AuthContextProvider>
    </ErrorBoundary>
  );
};

export default LivePage;
