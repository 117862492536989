/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetAll, useUpdate } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { IResetPassword, IUser } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getDefinePwdSchema } from "views/users/validations";
import { ReactComponent as Logo } from "../../images/live_green_logo.svg";
import RecoverImage from "../../images/recover_live_agro.svg";
import LiveFooter from "components/page/footer";
import { InputText, Loading, useFeedback } from "@alb/live-lib";

const LiveRecoverPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [success, setSuccess] = useState<boolean>(false);

  const { addFeedback } = useFeedback();
  const { sendErrorMessage } = useErrorHandler();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") || "";

  const {
    data: user,
    loading,
    error,
  } = useGetAll<IUser>(ServiceApiUrl.validateTokenURL, { token });
  const headersAdd = {
    headers: {
      Token: token,
    },
  };
  const {
    error: errorSetPwd,
    refetch: setPassword,
  } = useUpdate<IResetPassword>(ServiceApiUrl.setPwdURL, "", headersAdd);

  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
      navigateToLogin();
    }
  }, [error]);

  useEffect(() => {
    if (errorSetPwd) {
      sendErrorMessage(errorSetPwd);
    }
  }, [errorSetPwd]);

  useEffect(() => {
    if (success) {
      addFeedback({
        message: t("loginForm.messageSuccessSetPwd"),
        severity: "success",
      });
      navigateToLogin();
    }
  }, [success]);

  const methods = useForm<IResetPassword>({
    mode: "onChange",
    resolver: yupResolver(getDefinePwdSchema(t("user.passwordMustMatch"))),
  });

  // set pwd
  const formSubmitHandler: SubmitHandler<IResetPassword> = async (
    payLoad: IResetPassword
  ) => {
    await setPassword({ data: payLoad }); //enviar os dados
    await setSuccess(true);
  };

  const navigateToLogin = () => navigate("/login", { replace: true });

  const { control } = methods;

  return (
    <>
      <div className="pagesDiv">
        <Container sx={{ paddingTop: 8, flex: " 1" }} maxWidth={"md"}>
          <Loading show={loading}/>
          {user && !error && !loading && (
            <FormProvider {...methods}>
              <form
                id="reset-pwd-form"
                onSubmit={methods.handleSubmit(formSubmitHandler)}
              >
                <Typography variant="h4">
                  {t("loginForm.forgotPasswordTitle")}
                </Typography>

                <Typography
                  mt={1.5}
                  sx={{ whiteSpace: "pre-line" }}
                  variant="subtitle1"
                  color="black"
                >
                  {t("loginForm.resetPasswordText")}
                  <Logo width={"6rem"} />
                </Typography>

                <Grid container mt={9} maxWidth={"sm"}>
                  <Grid item xs={12}>
                    <Typography gutterBottom color="text.black" variant="subtitle2">
                      {t("loginForm.emailLabel")}
                    </Typography>
                    <InputText
                      disabled
                      placeholder={""}
                      name="email"
                      defaultValue={user.email}
                      control={control}
                      />
                  </Grid>

                  <Grid container columnSpacing={2} sm={6}>
                    <Grid item pt={3} xs={12}>
                      <Typography
                        gutterBottom
                        color="text.black"
                        variant="subtitle2"
                        noWrap
                      >
                        {t("loginForm.newPasswordLabel")}
                      </Typography>
                      <InputText
                        placeholder={t("loginForm.passwordPlaceholder")}
                        name="password"
                        type="password"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12} mt={3}>
                      <Typography
                        gutterBottom
                        color="text.black"
                        variant="subtitle2"
                        noWrap
                      >
                        {t("loginForm.confirmPasswordLabel")}
                      </Typography>
                      <InputText
                        placeholder={t("loginForm.confirmPasswordPlaceholder")}
                        name="confirmPassword"
                        type="password"
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  mt={4}
                  sx={{ justifyContent: "start", flexDirection: "row" }}
                >

                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={!methods.formState.isValid}
                    >
                      {t("loginForm.changePassword")}
                    </Button>
                </Grid>
              </form>
            </FormProvider>
          )}
        </Container>

        <LiveFooter backgroundImage={RecoverImage} maxWidth={"lg"} />
      </div>
    </>
  );
};

export default LiveRecoverPassword;
