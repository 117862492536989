import { InputDateTime, InputSelect, InputText, Loading, RequiredField, useFeedback } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RequiredFields } from "components/utils/forms/RequiredFields";
import { useGetAll, useUpdate } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import i18n from "i18n/config";
import { IApiResponse, IHarvest, IPulpColor, ISelectOption, ITexture } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { convertCmToMm, convertMmToCm_inputNumber, formatDate } from "utils";
import { getSelectedModule } from "utils/permissions/getSelectedModule";

import { getSampleEditHarvestSchema } from "../validations";

export default function HarvestMaturationEdit(props: any) {
	const { t } = useTranslation();
	const { addFeedback } = useFeedback();
	const { sendErrorMessage } = useErrorHandler();
	const auth = useSelector(getUser)
	const moduleID = auth && getSelectedModule(auth, 'dados_morfologicos')
	const headers = {
		headers: {
			'MODULE-ID': moduleID
		}
	}

	const {
		loading,
		error,
		refetch: updateHarvest,
	} = useUpdate<IHarvest[]>(ServiceApiUrl.harvestURL, props.harvest.id, headers);

	//pedidos para obter os dados pré-definidos
	const { data: pulpColorsList, loading: loadingPulpColors } = useGetAll<IApiResponse<IPulpColor>>(
		ServiceApiUrl.pulpColorsListURL,
		undefined,
		undefined,
		headers
	);
	const { data: texturesList, loading: loadingTextures } = useGetAll<IApiResponse<ITexture>>(
		ServiceApiUrl.texturesListURL,
		undefined,
		undefined,
		headers
	);

	const [pulpColors, setPulpColors] = useState<ISelectOption[]>();
	useEffect(() => {
		if (pulpColorsList) {
			const pOpts = pulpColorsList?.data?.map((p: IPulpColor) => ({ label: p.name, value: p.id }));
			setPulpColors(pOpts);
		}
	}, [pulpColorsList])

	const [textures, setTextures] = useState<ISelectOption[]>();
	useEffect(() => {
		if (texturesList) {
			const tOpts = texturesList?.data?.map((p: ITexture) => ({ label: p.name, value: p.id }));
			setTextures(tOpts);
		}
	}, [texturesList])

	//sucesso
	const handlerSuccess = () => {
		props.handlerClose();
		addFeedback({ message: t("common.changesSaved"), severity: "success" });
		props.updateGetHarvest();
	};
	//erro
	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);

	useEffect(() => {
		methodsEdit.reset();
	}, [props.handlerClose]);

	const methodsEdit = useForm<IHarvest>({
		defaultValues: {
			registration_date: props.harvest.registration_date,
			pulp_color_maturity: props.harvest.pulp_color_maturity.id,
			flesh_texture: props.harvest.flesh_texture.id,
			crop_cycle_days: props.harvest.crop_cycle_days,
			pedicels_number: props.harvest.pedicels_number,
			fruits_number: props.harvest.fruits_number,
			fruit_length: convertMmToCm_inputNumber(props.harvest.fruit_length),
			fruit_diameter: convertMmToCm_inputNumber(props.harvest.fruit_diameter),
			fruit_weight: props.harvest.fruit_weight,
			fruit_peel_thickness: convertMmToCm_inputNumber(props.harvest.fruit_peel_thickness),
		},
		mode: "onChange",
		resolver: yupResolver(getSampleEditHarvestSchema()),
	});

	const formEditSubmitHandler: SubmitHandler<IHarvest> = async (
		payLoad: IHarvest
	) => {
		if (props.harvest) {
			payLoad.registration_date = formatDate(
				payLoad.registration_date,
				t("calendar.dateTimeFormatLocalSeconds")
			);
			payLoad.fruit_diameter = convertCmToMm(payLoad.fruit_diameter);
			payLoad.fruit_length = convertCmToMm(payLoad.fruit_length);
			payLoad.fruit_peel_thickness = convertCmToMm(payLoad.fruit_peel_thickness);
			try {
				await updateHarvest({ data: payLoad }); //enviar os dados
				await handlerSuccess();
			} catch { }
		}
	};

	return (
		<>
			<Dialog
				maxWidth="md"
				open={props.open}
				fullWidth={true}
			>
				<DialogTitle>
					<Typography
						component={"span"}
						color="text.black"
						gutterBottom={true}
						variant="h5"
						noWrap
					>
						{t("morphologicalData.harvestMatur.harvestMaturationEdit")}
					</Typography>
				</DialogTitle>

				<DialogContent>
					<FormProvider {...methodsEdit}>
						<form
							id="edit-form"
							onSubmit={methodsEdit.handleSubmit(formEditSubmitHandler)}
						>
							{props.harvest && (
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<RequiredField title={t("morphologicalData.registrationDate")} />
										<InputDateTime
											control={methodsEdit.control}
											name="registration_date"
											placeholder={t(
												"morphologicalData.registrationDatePlaceholder"
											)}
											labelBtnCancel={t("common.cancel")}
											labelBtnConfirm={t("common.apply")}
											locale={i18n.language}
											maxDate={new Date()}
										/>
									</Grid>

									<Grid item xs={12}>
										<RequiredField title={t("morphologicalData.harvestMatur.cropCycle")} />
										<InputText
											control={methodsEdit.control}
											type="number"
											name="crop_cycle_days"
											placeholder={t(
												"morphologicalData.harvestMatur.cropCyclePlaceholder"
											)}
										/>
									</Grid>

									<Grid item xs={12}>
										<RequiredField title={t("morphologicalData.harvestMatur.numberHands")} />
										<InputText
											control={methodsEdit.control}
											type="number"
											name="pedicels_number"
											placeholder={t(
												"morphologicalData.harvestMatur.numberHandsPlaceholder"
											)}
										/>
									</Grid>

									<Grid item xs={12}>
										<RequiredField title={t("morphologicalData.harvestMatur.numberFruitsHands")} />
										<InputText
											control={methodsEdit.control}
											type="number"
											name="fruits_number"
											placeholder={t(
												"morphologicalData.harvestMatur.numberFruitsHandsPlaceholder"
											)}
										/>
									</Grid>

									<Grid item xs={12}>
										<RequiredField title={t("morphologicalData.harvestMatur.fruitLength")} />
										<InputText
											control={methodsEdit.control}
											type="number"
											endAdornment={"cm"}
											name="fruit_length"
											placeholder={t(
												"morphologicalData.harvestMatur.fruitLengthPlaceholder"
											)}
										/>
									</Grid>

									<Grid item xs={12}>
										<RequiredField title={t("morphologicalData.harvestMatur.fruitDiameter")} />
										<InputText
											control={methodsEdit.control}
											type="number"
											endAdornment={"cm"}
											name="fruit_diameter"
											placeholder={t(
												"morphologicalData.harvestMatur.fruitDiameterPlaceholder"
											)}
										/>
									</Grid>

									<Grid item xs={12}>
										<RequiredField title={t("morphologicalData.harvestMatur.fruitWeight")} />
										<InputText
											control={methodsEdit.control}
											endAdornment={"g"}
											type="number"
											name="fruit_weight"
											placeholder={t(
												"morphologicalData.harvestMatur.fruitWeightPlaceholder"
											)}
										/>
									</Grid>

									<Grid item xs={12}>
										<RequiredField title={t("morphologicalData.harvestMatur.barkThickness")} />
										<InputText
											control={methodsEdit.control}
											type="number"
											endAdornment={"cm"}
											name="fruit_peel_thickness"
											placeholder={t(
												"morphologicalData.harvestMatur.barkThicknessPlaceholder"
											)}
										/>
									</Grid>

									<Grid item xs={12}>
										<RequiredField title={t("morphologicalData.harvestMatur.PulpColor")} />
										{pulpColors && pulpColors?.length > 0 && (
											<InputSelect
												placeholder={t("morphologicalData.pulpColors.pulpColorPlaceholder")}
												name="pulp_color_maturity"
												control={methodsEdit.control}
												options={pulpColors}
											/>
										)}
										{<Loading show={loadingPulpColors} />}
									</Grid>

									<Grid item xs={12}>
										<RequiredField title={t("morphologicalData.harvestMatur.texture")} />
										{textures && textures?.length > 0 && (
											<InputSelect
												placeholder={t("morphologicalData.textures.texturePlaceholder")}
												name="flesh_texture"
												control={methodsEdit.control}
												options={textures}
											/>
										)}
										{<Loading show={loadingTextures} />}
									</Grid>
								</Grid>
							)}
						</form>
					</FormProvider>
					<RequiredFields allRequired />
				</DialogContent>

				<DialogActions>
					<Button
						variant="outlined"
						color="primary"
						onClick={props.handlerClose}
					>
						{t("common.cancel")}
					</Button>

					<LoadingButton
						loading={loading}
						variant="contained"
						color="primary"
						disabled={!methodsEdit.formState.isValid}
						type={"submit"}
						form={"edit-form"}
					>
						{t("common.save")}
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
}
