import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import LiveLogin from "components/auth/login/login";
import { getUser } from "store/slices/authSlice";

export default function Login() {
  const auth = useSelector(getUser);
  // if authenticated navigate
  if (auth) {
    return <Navigate to="/map" replace />;
  }

  return (
    <LiveLogin />
  );
}
