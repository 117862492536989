import { Grid, IconButton, Typography } from "@mui/material";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { LiveContainer } from "styles/components-styles";

export interface IModuleGridItem {
	title: string;
	onClick: ()=>void
}
export const ModuleGridItem = (params: IModuleGridItem) => {
	const {title, onClick} = params;

	return (
		<Grid item xs={4} minWidth={'min-content'} title={title}>
			<LiveContainer
				onClick={onClick}
				sx={{ cursor: "pointer", display: "flex" }}
				justifyContent="space-between"
				alignItems="center"
			>
				<Typography noWrap variant="h6">{title}</Typography>
				<IconButton color="secondary" size="large">
					<ArrowForwardRoundedIcon />
				</IconButton>
			</LiveContainer>
		</Grid>
	)
}
