import { LayoutContext, Loading, TableHeadCell, TableHeadOrder, TableHeadSort } from "@alb/live-lib";
import DownloadIcon from "@mui/icons-material/Download";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { LoadingButton } from "@mui/lab";
/* eslint-disable react-hooks/exhaustive-deps */
import { ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { format } from "date-fns";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetAll } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useHasPermissions } from "hooks/usePermissions";
import {
	IApiResponse,
	ICultivation,
	ILocation,
	ISamplePath,
	ISelectOption,
} from "interfaces";
import { ISample } from "interfaces/ISample";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { TableLink } from "styles/components-styles";
import { arrayIsEmpty, downloadXls, formatDate, formatOrderBy, PERMISSIONS, SAMPLE_PATH_KEY, setLocalStorage } from "utils";
import { ROWS_PER_PAGE } from "utils/pagination";
import { getSelectedModule } from "utils/permissions/getSelectedModule";
import { formatRoutePath } from "utils/routePath";
import SampleDelete from "./sample/deleteSample";
import SampleEdit from "./sample/editSample";
import { CustomTablePagination } from "utils/pagination/CustomTablePagination";
import { useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";
import FiltersSection from "./FiltersSection";

interface IActionButtonProps {
	handleDownload: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	loadingXls: boolean;
	title: string;
}
function ActionButton({
	handleDownload,
	loadingXls,
	title,
}: IActionButtonProps) {
	return (
		<>
			<LoadingButton
				color="primary"
				onClick={handleDownload}
				loading={loadingXls}
				loadingPosition="start"
				startIcon={<DownloadIcon />}
				variant="contained"
			>
				{title}
			</LoadingButton>
		</>
	);
}

export default function MorphologicalData() {
	const auth = useSelector(getUser)
	const moduleID = auth && getSelectedModule(auth, "dados_morfologicos");
	const { t } = useTranslation();
	const { sendErrorMessage } = useErrorHandler();
	const navigate = useNavigate();
	// params
	let params = useParams();
	const location = useLocation();
	const path = {
		url: location.pathname,
	};
	setLocalStorage<ISamplePath>(SAMPLE_PATH_KEY, path);

	const [order, setOrder] = useState<TableHeadOrder>("asc");
	const [orderBy, setOrderBy] = useState<string>("name");

	const [page, setPage] = useState(1);
	const [openDialogDelete, setOpenDialogDelete] = useState(false);
	const [selectedSample, setSelectedSample] = useState<ISample>();
	const [openDialogEdit, setOpenDialogEdit] = useState(false);

	const [, setLocationsOptions] = useState<ISelectOption[]>([]);
	const [filterLocal, setFilterLocal] = useState<string>("");
	const [filterCultivation,] = useState<string>("");
	const [filterName, setFilterName] = useState<string>("");

	//get locations
	const headers = {
		headers: {
			"MODULE-ID": moduleID,
		},
	};
	const { data: locations } = useGetAll<IApiResponse<ILocation>>(
		ServiceApiUrl.placesListURL,
		"",
		undefined,
		headers
	);

	useEffect(() => {
		if (locations) {
			let localOpts = locations?.data?.map((p: ILocation) => ({
				label: p.name,
				value: p.id,
			}));
			localOpts.unshift({
				label: t("morphologicalData.locations.allLocations"),
				value: "",
			});

			setLocationsOptions(localOpts);
		}
	}, [locations]);

	//get samples
	const {
		data: samples,
		loading,
		error,
		refetch,
	} = useGetAll<IApiResponse<ISample>>(
		ServiceApiUrl.sampleURL,
		{
			page: page,
			items: ROWS_PER_PAGE,
			order_by: formatOrderBy(order, orderBy),
			local: filterLocal,
			cultivation: filterCultivation,
			contains: filterName.length >= 3 ? filterName : "",
		},
		undefined,
		headers
	);

	const {
		data: dataXls,
		error: errorXls,
		loading: loadingXls,
		refetch: refetchXls,
	} = useGetAll<any>(
		ServiceApiUrl.samplesDownloadURL,
		null,
		{
			manual: true,
		},
		{
			responseType: "arraybuffer",
			headers: { "Content-Type": "blob", "MODULE-ID": moduleID },
		}
	);

	//para fazer nova chamada das samples
	const updateSamples = () => {
		refetch();
	};

	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);

	// PERMISSIONS

	const { hasPermission } = useHasPermissions();
	// const canView = usePermissions([PERMISSIONS.MORPHOLOGICAL_DATA.VIEW]);
	const canList = hasPermission([PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.VIEW]);
	const canDelete = hasPermission([PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.CUD]);
	const canEdit = hasPermission([PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.CUD]);

	//Trocar página da listagem
	const handleChangePage = (
		newPage: number
	) => {
		setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
	};

	const options = [
		t("morphologicalData.vegetativeDev.vegetativeDevelopment"),
		t("morphologicalData.floweringFruit.floweringFruiting"),
		t("morphologicalData.harvestMatur.harvestMaturation"),
		t("morphologicalData.pictures"),
	];

	//para gerar dinamicamente um ref para cada row
	const textInputRefs = useRef<any>([]);

	const handleViewClick = (
		event: React.MouseEvent<HTMLElement>,
		id: string
	) => {
		const path = formatRoutePath(location, params, { id });
		navigate(path);
	};

	const handleMenuItemClick = (
		event: React.MouseEvent<HTMLLIElement, MouseEvent>,
		id: string,
		index: number
	) => {
		event.stopPropagation(); //evitar que abra o event do parent (a row)
		// navigate(`/morphologicaldata/samples/${id}/${index}`);
		const path = formatRoutePath(location, params, {
			id,
			idx: index.toString(),
		});
		navigate(path);
	};

	const [open, setOpen] = useState(() => samples?.data?.map((i) => false));

	useEffect(() => {
		setOpen(samples?.data?.map((i) => false));
	}, [samples]);

	const handleToggle = (
		index: number,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		setOpen(open?.map((prevOpen, i) => (i === index ? !prevOpen : false)));
		e.stopPropagation(); //evitar que abra o event do parent (a row)
	};

	const handleClose = (event: Event, index: number) => {
		if (
			textInputRefs.current &&
			textInputRefs.current[index].contains(event.target as HTMLElement)
		) {
			return;
		}
		setOpen(open?.map((o, i) => (i === index ? (o = false) : false)));
	};

	//atualiza o estado do modal de remover
	const handlerDialogDeleteState = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		sample: ISample
	) => {
		if (open === true) {
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setSelectedSample(sample);
		}
		setOpenDialogDelete(!openDialogDelete);
	};

	//atualiza o estado do modal de editar
	const handlerDialogEditState = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		sample: ISample
	) => {
		if (open === true) {
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setSelectedSample(sample);
		}
		setOpenDialogEdit(!openDialogEdit);
	};

	const handleRefetchCSV = async () => {
		try {
			await refetchXls();
		} catch (err) {
			console.log(err);
		}
	};
	const handleDownload = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		e.preventDefault();
		handleRefetchCSV();
	};

	useEffect(() => {
		if (!loadingXls && !errorXls && dataXls) {
			const dt = format(new Date(), "yyyyMMdd_HHmmss");
			downloadXls({
				data: dataXls,
				fileName: `samples_${dt}.xlsx`,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingXls]);

	const tableHeads: TableHeadCell[] = [
		{
			id: "name",
			label: t("morphologicalData.sample.name"),
		},
		{
			id: "created_at",
			label: t("common.createdAt"),
		},
		{
			id: "local",
			label: t("morphologicalData.sample.local"),
		},
		{
			id: "cultivation",
			label: t("morphologicalData.sample.cultivation"),
		},
		{
			id: "action_cell",
			label: "",
			notSortable: true,
		},
	];

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: string
	) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	//pedidos para obter os dados pré-definidos
	const { data: placesList } = useGetAll<IApiResponse<ILocation>>(
		ServiceApiUrl.placesListURL,
		"",
		undefined,
		headers
	);
	const { data: cultivationsList } = useGetAll<IApiResponse<ICultivation>>(
		ServiceApiUrl.cultivationsListURL,
		"",
		undefined,
		headers
	);

	const [places, setPlaces] = useState<ISelectOption[]>();
	useEffect(() => {
		if (placesList) {
			const lOpts = placesList?.data?.map((p: ILocation) => ({
				label: p.name,
				value: p.id,
			}));
			setPlaces(lOpts);
		}
	}, [placesList]);

	const [, setCultivations] = useState<ISelectOption[]>();
	useEffect(() => {
		if (cultivationsList) {
			const cOpts = cultivationsList?.data?.map((p: ICultivation) => ({
				label: p.name,
				value: p.id,
			}));
			setCultivations(cOpts);
		}
	}, [cultivationsList]);

	const { addHeader } = useContext(LayoutContext);
	const header = {
		title: t("morphologicalData.samples"),
		action:
			<ActionButton
				handleDownload={handleDownload}
				loadingXls={loadingXls}
				title={t("morphologicalData.downloadSamples")}
			/>
	}

	useEffect(() => {
		addHeader(header)
	}, [])

	return (
		<>

			<FiltersSection setPage={setPage} setFilterSearch={setFilterName} setFilterLocal={setFilterLocal} placesOptions={places} />
			<Loading show={loading} />

			{samples && arrayIsEmpty(samples?.data) && !loading && (
				<Typography mt={4} mb={2} variant="body1">
					{t("morphologicalData.noSamplesToShow")}
				</Typography>
			)}

			{samples && canList && samples?.data.length > 0 && !loading && (
				<>
					<TableContainer>
						<TableLink>
							<TableHeadSort
								headCells={tableHeads}
								onRequestSort={handleRequestSort}
								order={order}
								orderBy={orderBy}
							/>
							<TableBody>
								{samples?.data.map((s: ISample, index: number) => (
									<TableRow
										onClick={(e) => handleViewClick(e, s.id)}
										key={s.id}
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										<TableCell>{s.name}</TableCell>
										<TableCell>
											{" "}
											{formatDate(s.created_at, t("calendar.dateFormat"))}
										</TableCell>
										<TableCell>{s.local.name}</TableCell>
										<TableCell>{s.cultivation.name}</TableCell>
										<TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
											<span ref={(ref) => (textInputRefs.current[index] = ref)}>
												<IconButton
													sx={{ padding: 0 }}
													onClick={(
														e: React.MouseEvent<HTMLButtonElement, MouseEvent>
													) => handleToggle(index, e)}
													size="small"
													aria-controls={
														open !== undefined && open[index]
															? "split-button-menu"
															: undefined
													}
													aria-expanded={
														open !== undefined && open[index]
															? "true"
															: undefined
													}
													aria-haspopup="menu"
												>
													<MoreVertIcon />
												</IconButton>
											</span>
											{open !== undefined && open[index] && (
												<Popper
													sx={{ zIndex: 1 }}
													open={open !== undefined && open[index]}
													anchorEl={textInputRefs.current[index]}
													role={undefined}
													transition
													disablePortal
												>
													{({ TransitionProps, placement }) => (
														<Grow
															{...TransitionProps}
															style={{
																transformOrigin:
																	placement === "bottom"
																		? "center top"
																		: "center bottom",
															}}
														>
															<Paper>
																<ClickAwayListener
																	onClickAway={(e: Event) => {
																		handleClose(e, index);
																	}}
																>
																	<MenuList
																		id="split-button-menu"
																		autoFocusItem
																	>
																		{options.map((option, index) => (
																			<MenuItem
																				key={option}
																				onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
																					handleMenuItemClick(
																						event,
																						s.id,
																						index
																					)
																				}
																			>
																				{option}
																			</MenuItem>
																		))}
																	</MenuList>
																</ClickAwayListener>
															</Paper>
														</Grow>
													)}
												</Popper>
											)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</TableLink>
					</TableContainer>

					<CustomTablePagination
						count={samples?.totalCount}
						page={page}
						totalPages={samples?.totalPages}
						onPageChange={handleChangePage}

					/>
				</>
			)}

			{selectedSample && (
				<>
					{canDelete && openDialogDelete && (
						<SampleDelete
							sample={selectedSample}
							open={openDialogDelete}
							handlerClose={handlerDialogDeleteState}
							updateGetSamples={updateSamples}
						/>
					)}
					{canEdit && openDialogEdit && (
						<SampleEdit
							sample={selectedSample}
							open={openDialogEdit}
							handlerClose={handlerDialogEditState}
							updateGetSamples={updateSamples}
						/>
					)}
				</>
			)}
		</>
	);
}
