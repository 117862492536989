/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useErrorHandler, useGetAll, useUpdate } from "hooks";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { IClient } from "interfaces/IClient";
import { IApiResponse, ISelectOption, IUser } from "interfaces";
import { RequiredField, useFeedback } from "@alb/live-lib";
import { useDispatch, useSelector } from "react-redux";
import { getUser, setRoles, setUser } from "store/slices/authSlice";
import { RequiredFields } from "components/utils/forms/RequiredFields";

interface IChangeClient {
  user: IUser;
  open: boolean;
  onClose: () => void;
  updateGetUsers: () => void;
}

export default function ChangeClient({
  user,
  open,
  onClose,
  updateGetUsers,
}: IChangeClient) {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const { sendErrorMessage } = useErrorHandler();
  const dispatch = useDispatch();
  const [clients, setClients] = useState<ISelectOption[]>();
  const [selectedClient, setSelectedClient] = useState<IClient>();
  const userRedux = useSelector(getUser)

  useEffect(() => {
    if (userRedux) {
      setSelectedClient(userRedux.client);
    }
  }, [userRedux, onClose]);

  //pedido de todos os clientes
  const { data: dataClients, error: errorClients } = useGetAll<
    IApiResponse<IClient>
  >(ServiceApiUrl.clientURL);

  //pedido de edição de dados do utilizador (enviar cliente novo)
  const {
    loading: updateUserLoading,
    error: updateUserError,
    refetch: updateUser,
  } = useUpdate<IUser>(ServiceApiUrl.userURL, user.id);

  useEffect(() => {
    if (errorClients) {
      sendErrorMessage(errorClients);
    }
  }, [errorClients]);

  useEffect(() => {
    if (dataClients) {
      const clients_ = dataClients?.data?.map((p: IClient) => ({
        label: p.name,
        value: p.id,
      }));
      setClients(clients_);
    }
  }, [dataClients]);

  //sucesso
  const handlerSuccess = () => {
    onClose();
    addFeedback({ message: t("common.changesSaved"), severity: "success" });
  };

  const changeClient = async () => {
    if (user && selectedClient) {
      dispatch({ type: "changeClient" });
      try {
        await updateUser({ data: { client: selectedClient?.id } }).then(
          async (response) => {
            dispatch(setUser(response.data));
            dispatch(setRoles(response.data));
            updateGetUsers();
          }
        );
        await handlerSuccess();
      } catch (error) {sendErrorMessage(updateUserError);}
    }
  };

  const handleClientSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const client = dataClients?.data.find((c) => c.id === e.target.value);
    if (client) setSelectedClient(client);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <DialogTitle id="alert-dialog-title">
        <Typography
          component={"span"}
          color="text.black"
          gutterBottom={true}
          variant="h5"
          noWrap
        >
          {t("user.changeClientTitle")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        {user && selectedClient && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RequiredField title={t("user.visibleClientLabel")}/>

              <TextField
                placeholder={t("user.clientPlaceholder")}
                variant="standard"
                fullWidth
                select
                value={selectedClient?.id ?? user.client.id}
                onChange={handleClientSelectChange}
              >
                {clients?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <RequiredFields allRequired />
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={updateUserLoading}
          variant="contained"
          color="primary"
          form={"change-pwd-form"}
          onClick={changeClient}
        >
          {t("common.save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
