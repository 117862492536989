/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "hooks";
import { useHasPermissions } from "hooks/usePermissions";
import { LayoutContext, PageLayout } from "@alb/live-lib";
import sidebar from "layouts/layout.sidebar";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getRoles, getUser } from "store/slices/authSlice";

interface IRequireAuthParams {
  allowedRoles: number[];
}

const RequireAuth = ({ allowedRoles }: IRequireAuthParams) => {
  const { Logout } = useAuth();
  const user = useSelector(getUser);
  const roles = useSelector(getRoles);
  const location = useLocation();
  const { hasPermission } = useHasPermissions();
  const { t, i18n } = useTranslation();
  const isAllowed = hasPermission(allowedRoles);
  const state = { from: location };
  const { addSidebar } = useContext(LayoutContext);

  const logout = () => {
    Logout(() => {window.location.replace("/login");});
  };

  useEffect(() => {
    if (user) {
      addSidebar(sidebar(t, user, hasPermission, logout, state, false));
    }
  }, [
    i18n.language,
    user?.first_name,
    user?.last_name,
    roles,
  ]);

  return isAllowed ? (
    <>
      <PageLayout
        children={
          <Box sx={{ marginTop: "32px" }}>
            <Outlet />
          </Box>
        }
      />
    </>
    //alguyma coisa ta a limapr o user do redux e ele volta pro loogin
  ) : user ? (
    <Navigate to="/unauthorized" state={state} replace />
  ) : (
    <Navigate to="/login" state={state} replace />
  );
};

export default RequireAuth;
