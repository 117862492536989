import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { I18NEXTLNG_KEY } from "utils";

import yupSetLocale from "./yupLocale";

// Check the localstorage before i18next can
// const storedLang = getLocalStorage<string>(I18NEXT) || "en";
//const storedLang = getCookie<string>(I18NEXTLNG_KEY) || "en";

// change locale
i18n.on("languageChanged", yupSetLocale);

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      fallbackLng: "en",
      debug: true,
			detection: {
				order: ['queryString', 'cookie', 'navigator'],
				caches:['cookie'],
				lookupCookie: I18NEXTLNG_KEY,
			},

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      //lng: storedLang,
    });

export default i18n;
