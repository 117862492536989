import { Cookies } from 'react-cookie';
import { CookieGetOptions, CookieSetOptions } from 'universal-cookie';

const cookies = new Cookies();
const maxAge = 25 * 60 * 60;

export function setCookie<T> (key: string, value: T | null, options?: CookieSetOptions) {
	const _opt = {maxAge, ...options};
  cookies.set(key, value, _opt);
}

export function getCookie<T> (key: string, options?: CookieGetOptions) {
  return cookies.get(key, options) as T;
}

export function removeCookie(key:string, options?: CookieSetOptions) {
	cookies.remove(key, options);
}
