import { BackToButton, EntityInfo, EntityInfoItem, LayoutContext, Loading } from "@alb/live-lib";
import EditIcon from '@mui/icons-material/Edit';
import { Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";

import UsersEdit from "components/utils/users/userEdit";
import { useGet, useHasPermissions } from "hooks";
import { IApiResponse, ISelectOption } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatDate, PERMISSIONS } from "utils";
import FilterSelect from "utils/FilterSelect";
import { formatRoutePath } from "utils/routePath";

import UserPermissions from "./UserPermissions";

const DetailUser = () => {

  let params = useParams();
  const { addHeader } = useContext(LayoutContext)
  const location = useLocation();
  const { t } = useTranslation()
  const { hasPermission } = useHasPermissions();
  const crudPermissions = hasPermission([PERMISSIONS.MANAGEMENT.USERS.CUD]);


  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);

  const handlerDialogEditState = () => {
    setOpenDialogUpdate(false)
  }

  const {
    data: user,
    loading,
    refetch: refetchUser } = useGet(ServiceApiUrl.userURL, params.id);
  const navigate = useNavigate();

  const handleSelectedUser = (option: ISelectOption | null) => {
    if (!option) return undefined;
    const path = formatRoutePath(location, params, {
      id: option.value,
    });

    navigate(path);
  };

  const customOptions = (data: IApiResponse<any>) => {
    return data.data?.map((user: any) => ({
      label:
        user?.first_name && user?.last_name
          ? `${user?.first_name} ${user?.last_name}`
          : user.username,
      value: user.id,
    }));
  };

  useEffect(() => {
    if(user) {
      addHeader({
        title: user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : user.username,
        backTo: (
          <BackToButton
            title={t("goBack")}
            onClick={() => navigate("/management/users")}
          />),
          select: user && (
            <FilterSelect<any>
              apiUrl={ServiceApiUrl.userURL}
              onSelected={handleSelectedUser}
              selectedValue={params?.id}
              customOptions={customOptions}
            />
          ),
          action: crudPermissions && !user.is_superadmin && (
            <Button
              color="primary"
              onClick={() => setOpenDialogUpdate(true)}
              variant="contained"
              startIcon={<EditIcon />}
            >
              {t("user.editUser")}
            </Button>)
      })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return(<>
      {loading && <Loading  show={loading} />}
          {!loading && user && (
            <EntityInfo
              sx={{ mb: 5 }}
              title={t("common.details")}
            >
                  <EntityInfoItem
                label={t("user.name")}
                value={`${user?.first_name} ${user?.last_name}`}
              />
              <EntityInfoItem
                label={t("common.statusLabel")}
                value={t(`user.status.${user.status}`)}
              />

              <EntityInfoItem label={t("user.Username")} value={user.username} />
              <EntityInfoItem
                label={t("user.institutionalEmail")}
                value={user.email}
              />

              <EntityInfoItem
                label={t("user.language")}
                value={t(`${user.language.toLowerCase()}`)}
              />
              <EntityInfoItem
                label={t("user.Administrator")}
                value={user.is_admin ? t("common.yes") : t("common.no")}
              />
              <EntityInfoItem
                label={t("user.SuperAdministrator")}
                value={user.is_superadmin ? t("common.yes") : t("common.no")}
              />
              <EntityInfoItem
                label={t("common.createdAt")}
                value={formatDate(
                  user.created_at,
                  t("calendar.dateTimeFullFormat")
                )}
              />
              <EntityInfoItem
                label={t("common.updatedAt")}
                value={formatDate(
                  user.updated_at,
                  t("calendar.dateTimeFullFormat")
                )}
              />
              <EntityInfoItem
                label={t("user.lastLogin")}
                value={formatDate(
                  user.last_login,
                  t("calendar.dateTimeFullFormat")
                )}
              />

              <EntityInfoItem
                label={t("accounts.account")}
                value={user.account.name}
              />

              {!user.is_superadmin && !user.is_admin && user.permission && (
                <UserPermissions permissions={user.permission} sx={{ mt: 3 }} />
              )}
          </EntityInfo>)}

            {openDialogUpdate && (
            <UsersEdit
              user={user}
              open={openDialogUpdate}
              handlerClose={handlerDialogEditState}
              updateList={refetchUser}
            />
          )}
  </>)}

export default DetailUser;
