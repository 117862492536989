import { boolean, mixed, object, string } from "yup";

export const InputsLengths = {
  max: {
    name: 255,
  },
  min: {
    name: 3,
  },
};

export const getAccountSchema = (required: string) =>
  object({
    name: string()
      .min(InputsLengths.min.name)
      .max(InputsLengths.max.name)
      .required(),
    admin: boolean(),
    adapter_configurators: mixed().test(
      "required",
      required,
      (value) => Array.isArray(value) && value.length > 0
    ),
    // adapter_configurators: mixed().test("required", required, function (value) {
    //   return this.parent.admin ? true : Array.isArray(value) && value.length > 0;
    // }),
  });
