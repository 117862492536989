/* eslint-disable react-hooks/exhaustive-deps */
import { InputAutocomplete, InputCheckbox, InputText, RequiredField, useFeedback } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { RequiredFields } from "components/utils/forms/RequiredFields";
import { useCreate, useErrorHandler, useGetAll } from "hooks";
import { IAdapterConfigurator, IApiResponse, ISelectOption } from "interfaces";
import { IAccount, IAccountForm } from "interfaces/IAccount";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty } from "utils";

import { formDefaultValues } from "./formDefaultValues";
import { getAccountSchema } from "./validations";

export interface IAccountsAdd {
  administration?: boolean;
  clientID: string;
  open: boolean;

  handlerClose: () => void;
  updateList: () => void;
}

const AccountsAdd = ({
  administration,
  clientID,
  open,
  handlerClose,
  updateList,
}: IAccountsAdd) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const { sendErrorMessage } = useErrorHandler();


  //pedido para criar account
  const {
    loading,
    error,
    refetch: createAccount,
  } = useCreate<IAccount[]>(ServiceApiUrl.accountURL);

  const [gatewaysOptions, setGatewaysOptions] = useState<ISelectOption[]>([]);

  // get list of gateways
  const { data: allGateways, refetch } = useGetAll<
    IApiResponse<IAdapterConfigurator>
  >(ServiceApiUrl.adapterListURL, administration ? { client: clientID } : '');

  useEffect(() => {
    if (allGateways) {
      const options = allGateways?.data?.map((p: IAdapterConfigurator) => ({
        label: p.name,
        value: p.id,
      }));

      setGatewaysOptions(options);
    }
  }, [allGateways]);

  const refetchGateways = (params: any) => {
    refetch({ params: { ...params } });
  };

  const defaultValues = formDefaultValues();
  const methodsAdd = useForm<IAccountForm>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(getAccountSchema(t("validations.required"))),
  });

  const { control, setValue } = methodsAdd;

  useEffect(() => {
    methodsAdd.reset();
  }, []);

  // sucesso
  const handlerSuccess = () => {
    handlerClose(); //fecha modal
    addFeedback({
      message: t("accounts.accountAdded"),
      severity: "success",
    });
    updateList && updateList();
  };
  //erro
  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error]);

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IAccountForm> = async (
    payLoad: IAccountForm
  ) => {
    try {
      const payLoadAPI = payLoad as unknown as IAccountForm<string>;
      if (clientID) payLoadAPI.client = clientID;
      payLoadAPI.adapter_configurators = payLoad.adapter_configurators.map(
        (a: ISelectOption) => a.value
      );

      //console.log(payLoad);
      await createAccount({ data: payLoadAPI });
      await handlerSuccess();
    } catch (error) { }
  };

  const onAdminChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    methodsAdd.resetField("adapter_configurators");
    refetchGateways(checked ? { all: true } : { client: clientID });
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} >
      <DialogTitle>
        <Typography
          component={"span"}
          color="text.black"
          gutterBottom={true}
          variant="h5"
          noWrap
        >
          {t("accounts.addAccount")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <FormProvider {...methodsAdd}>
          <form
            id="add-account-form"
            onSubmit={methodsAdd.handleSubmit(formSubmitHandler)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RequiredField title={t("accounts.name")} />
              <InputText
                control={control}
                name="name"
                placeholder={t("accounts.namePlaceholder")}
              />
              </Grid>
              {administration &&
                <Grid item xs={12}>
                  <InputCheckbox
                  control={control}
                  name="admin"
                  setValue={setValue}
                  labelText={t("accounts.adminLabel")}
                  checked={defaultValues.admin}
                  onChange={onAdminChange}
                />
                </Grid>}

              <Grid item xs={12}>
                <Grid sx={{ display: "flex", alignItems: "baseline" }}>
                <RequiredField title={t("accounts.adapters")} />
              </Grid>
              {arrayIsEmpty(gatewaysOptions) && (
                <Typography mt={2} mb={2} variant="body1">
                  {t("adapters.thereAreNotAdapters")}
                </Typography>
              )}
              {!arrayIsEmpty(gatewaysOptions) && (
                <InputAutocomplete
                  name="adapter_configurators"
                  options={gatewaysOptions}
                  multiple
                  control={control}
                  required
                />
              )}
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <RequiredFields allRequired />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handlerClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!methodsAdd.formState.isValid}
          type={"submit"}
          form={"add-account-form"}
        >
          {t("common.add")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AccountsAdd;
