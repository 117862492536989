import L from "leaflet";

import { IUserPermission } from "interfaces/IUserPermission";

import { formatNumber } from "./number";

function getFileExtension(fileName: string): string {

  var idxDot = fileName.lastIndexOf(".") + 1;
  var extFile = fileName.substring(idxDot, fileName.length).toLowerCase() || "";

  return extFile;
}
function objectIsEmpty(obj: Object | null | undefined): boolean {
  const length = Object.keys(obj || {}).length;
  return length === 0;
}
function arrayIsEmpty<T>(array: T | null | undefined): boolean {
  return !array || !Array.isArray(array) || !array.length;
}
function stringIsEmpty(string: string | null | undefined): boolean {
  const length = string?.length;
  return length === 0;
}

function permissionIsSetted(permission: IUserPermission[]): boolean {
  var p = permission.find((f) => f.value !== "" && f.value !== "0000");

  return p !== undefined;
}

function objectHasEmptyArrays(obj: any | null | undefined): boolean {
  if (obj) {
    return Object.keys(obj).every(function (key) {
      var val = obj[key];
      if (Array.isArray(val) && val.length === 0) {
        return true;
      }
      return false;
    });
  } else {
    return true;
  }
}

function statusIsEnabled(status: string) {
  return status === "enabled";
}
function statusIsDisabled(status: string) {
  return status === "disabled";
}

function containsText(text: string, searchText: string) {
  return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
}

function convertMmToCm(value: number) {
  let val = Number(value / 10); // convert mm to cm
  return formatNumber(val.toString());
}
function convertMmToCm_inputNumber(value: number) {
  let val = Number(value / 10); // convert mm to cm
  return val;
}
function convertCmToMm(value: number) {
  let val = Number(value * 10); // convert cm to mm
  return val;
}

function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  const value = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  return formatNumber(value.toString()) + " " + sizes[i];
}

function batteryColor(value: number) {
  const _value = Math.round(value / 6);
  const h = _value * 7.19;
  return `hsla(${h}, 89%, 26%, 1)`;
}

function valueEmptyTo(value?: string | number, to = "--") {
  const _value = value ? value : to;
  return _value;
}

function compareStatusString(status: string, value: string) {
  return status === value;
}

function convertStatusToBoolean(status: string): {
  isActive: boolean;
  isDeleted: boolean;
  isInvited: boolean;
  isEnabled: boolean;
  isDisabled: boolean;
} {
  let _status = {
    isActive: false,
    isDeleted: false,
    isInvited: false,
    isEnabled: false,
    isDisabled: false,
  };
  _status.isActive = compareStatusString(status, "active");
  _status.isDeleted = compareStatusString(status, "deleted");
  _status.isInvited = compareStatusString(status, "invited");
  _status.isDisabled = compareStatusString(status, "non-active");
  return _status;
}

class Debounce {
  private prev: any = null;

  emit(cb: () => void, delay = 300): void {
    if (this.prev) {
      clearTimeout(this.prev);
    }

    this.prev = setTimeout(() => {
      cb();
    }, delay);
  }

  clear(afterClear?: () => void): void {
    if (this.prev) {
      clearTimeout(this.prev);
      if (afterClear) {
        afterClear();
      }
    }
  }
}

type StatusColorOptions =
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning";
interface IStatusColor {
  invited: string;
  "non-active": string;
  active: string;
  deleted: string;
}
function statusColor(status_value: string) {
  const status: IStatusColor = {
    invited: "warning",
    "non-active": "error",
    active: "success",
    deleted: "error",
  };
  return status[status_value as keyof IStatusColor] as StatusColorOptions;
}

function formattedLocation(position: [number, number]) {
  const _latLng = L.latLng(position);

  return `${_latLng.lat.toFixed(5)}, ${_latLng.lng.toFixed(5)}`;
}

export type { StatusColorOptions };

export {
	getFileExtension,
  objectIsEmpty,
  arrayIsEmpty,
  stringIsEmpty,
  objectHasEmptyArrays,
  statusIsEnabled,
  statusIsDisabled,
  containsText,
  convertMmToCm,
  convertMmToCm_inputNumber,
  convertCmToMm,
  formatBytes,
  batteryColor,
  valueEmptyTo,
  Debounce,
  convertStatusToBoolean,
  statusColor,
  permissionIsSetted,
  formattedLocation,
};
