/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useDelete } from "hooks";

import { ServiceApiUrl } from "services/ServiceApiUrl";
import { useEffect } from "react";
import { useErrorHandler } from "hooks/useErrorHandler";
import { IVegetative } from "interfaces";
import { getSelectedModule } from "utils/permissions/getSelectedModule";
import { ConfirmDialog, useFeedback } from "@alb/live-lib";
import { useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";

export default function VegetativeDevDelete(props: any) {

	const { t } = useTranslation();
	const { addFeedback } = useFeedback();
	const { sendErrorMessage } = useErrorHandler();
	const auth = useSelector(getUser)
	const moduleID = auth && getSelectedModule(auth, 'dados_morfologicos')
	const headers = {
		headers: {
			'MODULE-ID': moduleID
		}
	}

	const { loading, error, refetch: deleteVegetative } = useDelete<IVegetative[]>(ServiceApiUrl.vegetativeURL, props.vegetativeDev.id, headers);

	//sucesso
	const handlerSuccess = () => {
		props.handlerClose()
		addFeedback({ message: t("morphologicalData.vegetativeDev.vegetativeDevDeleted"), severity: 'success' })
		props.updateGetVegetative();
	};
	//erro
	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);


	//apaga Vegetative
	const handleDeleteVegetative = async () => {
		try {
			await deleteVegetative()
			await handlerSuccess();
		}
		catch {
		}
	}


	return (
		<ConfirmDialog
			open={props.open}
			type="error"
			title={t("morphologicalData.vegetativeDev.deleteVegetativeDev")}
			message={t('morphologicalData.vegetativeDev.vegetativeDevDeleteConfirmation')}
			actionConfirmText={t("common.delete")}
			actionCancelText={t("common.cancel")}
			loading={loading}
			onCancel={props.handlerClose}
			onConfirm={handleDeleteVegetative}
		/>
	);
}


