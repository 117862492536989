import * as Yup from "yup";

import enShort from "components/utils/YupLocales/src/locales/en/localeShort";
import ptShort from "components/utils/YupLocales/src/locales/pt/localeShort";

export default function yupSetLocale(locale: string = 'en') {
  // console.log("yupSetLocale ", locale);
  switch (locale) {
    case "en":
      Yup.setLocale(enShort);
      break;
    case "pt":
      Yup.setLocale(ptShort);
      break;
    default:
      Yup.setLocale(enShort);
      break;
  }
}
