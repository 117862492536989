import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/Edit";

/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Button } from "@mui/material";
import { format } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { ISplitButtonOption } from "components/utils/SplitButton/SplitButton";
import { useGetAll } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useHasPermissions } from "hooks/usePermissions";
import { IApiResponse, ILocation, ISelectOption, ISamplePath } from "interfaces";
import { ISample } from "interfaces/ISample";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty, downloadXls, PERMISSIONS, setLocalStorage, SAMPLE_PATH_KEY } from "utils";

import SampleAdd from "views/morphologicaldata/sample/addSample";
import SampleDelete from "views/morphologicaldata/sample/deleteSample";
import SampleEdit from "views/morphologicaldata/sample/editSample";

import { getSelectedModule } from "utils/permissions/getSelectedModule";
import { ROWS_PER_PAGE } from "utils/pagination";
import { BackToButton, LayoutContext, Loading } from "@alb/live-lib";
import { CustomTablePagination } from "utils/pagination/CustomTablePagination";
import { useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";
import FiltersSection from "../FiltersSection";
import NoData from "utils/NoData";

export default function SamplesList() {
	const auth = useSelector(getUser)
	const moduleID = auth && getSelectedModule(auth, 'dados_morfologicos')
	const { t } = useTranslation();
	const { sendErrorMessage } = useErrorHandler();
	const navigate = useNavigate();

	// params
	const location = useLocation();
	const path = {
		url: location.pathname
	}
	setLocalStorage<ISamplePath>(
		SAMPLE_PATH_KEY, path
	);

	const [page, setPage] = useState(1);
	const [filterSearch, setFilterSearch] = useState("")
	const [openDialogAdd, setOpenDialogAdd] = useState(false);
	const [openDialogDelete, setOpenDialogDelete] = useState(false);
	const [selectedSample, setSelectedSample] = useState<ISample>();
	const [openDialogEdit, setOpenDialogEdit] = useState(false);

	const [, setLocationsOptions] = useState<ISelectOption[]>([]);
	const [filterLocal,] = useState<string>("");

	// fases da amostra
	const [, setOpenDialogAddVD] = useState(false);
	const [, setOpenDialogAddFF] = useState(false);
	const [, setOpenDialogAddHM] = useState(false);

	const [, setAddOptions] = useState<ISplitButtonOption[]>([]);

	//get locations
	const headers = {
		headers: {
			'MODULE-ID': moduleID
		}
	}
	const { data: locations } = useGetAll<IApiResponse<ILocation>>(
		ServiceApiUrl.placesListURL,
		'',
		undefined,
		headers
	);

	useEffect(() => {
		if (locations) {
			let localOpts = locations?.data?.map((p: ILocation) => ({
				label: p.name,
				value: p.id,
			}));
			localOpts.unshift({
				label: t("morphologicalData.locations.allLocations"),
				value: "",
			});

			setLocationsOptions(localOpts);
		}
	}, [locations]);

	const getParams = () => {
		return {
			page: page,
			items: ROWS_PER_PAGE,
			local: filterLocal,
			...(filterSearch !== "" && { contains: filterSearch }),
		}
	}

	//get samples
	const {
		data: samples,
		loading,
		error,
		refetch,
	} = useGetAll<IApiResponse<ISample>>(
		ServiceApiUrl.sampleURL,
		getParams(),
		undefined,
		headers);

	const {
		data: dataXls,
		error: errorXls,
		loading: loadingXls,
	} = useGetAll<any>(
		ServiceApiUrl.samplesDownloadURL,
		null,
		{
			manual: true,
		},
		{
			responseType: "arraybuffer",
			headers: { "Content-Type": "blob", 'MODULE-ID': moduleID }
		}
	);

	//para fazer nova chamada das samples
	const updateSamples = () => {
		refetch();
	};

	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);

	// PERMISSIONS

	const { hasPermission } = useHasPermissions();
	// const canView = usePermissions([PERMISSIONS.MORPHOLOGICAL_DATA.VIEW]);
	const canList = hasPermission([PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.VIEW]);
	const canAdd = hasPermission([PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.CUD]);
	const canDelete = hasPermission([PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.CUD]);
	const canEdit = hasPermission([PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.CUD]);

	//Trocar página da listagem
	const handleChangePage = (
		newPage: number
	) => {
		setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
	};

	//atualiza o estado do modal de adicionar
	const handlerDialogAddState = () => {
		setOpenDialogAdd(!openDialogAdd);
	};

	const [, setOpen] = useState(() => samples?.data?.map((i) => false));

	useEffect(() => {
		setOpen(samples?.data?.map((i) => false));

		if (!arrayIsEmpty<ISample[]>(samples?.data)) {
			const options: ISplitButtonOption[] = [
				{
					label: t("morphologicalData.vegetativeDev.addVegetativeDev"),
					action: () => setOpenDialogAddVD(true),
				},
				{
					label: t("morphologicalData.floweringFruit.addFloweringFruiting"),
					action: () => setOpenDialogAddFF(true),
				},
				{
					label: t("morphologicalData.harvestMatur.addHarvestMaturation"),
					action: () => setOpenDialogAddHM(true),
				},
				// {
				//   label: t("morphologicalData.picture.addPicture"),
				//   action: optionAction,
				// },
			];
			setAddOptions(options);
		}
	}, [samples]);

	//atualiza o estado do modal de remover
	const handlerDialogDeleteState = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		sample: ISample
	) => {
		if (open === true) {
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setSelectedSample(sample);
		}
		setOpenDialogDelete(!openDialogDelete);
	};

	//atualiza o estado do modal de editar
	const handlerDialogEditState = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		sample: ISample
	) => {
		if (open === true) {
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setSelectedSample(sample);
		}
		setOpenDialogEdit(!openDialogEdit);
	};

	useEffect(() => {
		if (!loadingXls && !errorXls && dataXls) {
			const dt = format(new Date(), "yyyyMMdd_HHmmss");
			downloadXls({
				data: dataXls,
				fileName: `samples_${dt}.xlsx`,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingXls]);

	const { addHeader } = useContext(LayoutContext)
	const header = {
		title: t("morphologicalData.samples"),
		backTo: (
			<BackToButton
				title={t("goBack")}
				onClick={() => navigate("/management/modules/morphologicaldata")}
			/>
		),
		action: canAdd && (
			<Button
				color="primary"
				onClick={() => setOpenDialogAdd(true)}
				variant="contained"
				startIcon={<AddIcon />}
			>
				{t("morphologicalData.sample.addSample")}
			</Button>
		)
	}
	useEffect(() => {
		addHeader(header);
	}, [])

	return (
		<>
			<FiltersSection setPage={setPage} setFilterSearch={setFilterSearch} />
			<Loading show={loading} />

			{samples && canList && samples?.data.length === 0 && !loading && <NoData />}
			{samples && canList && samples?.data.length > 0 && !loading && (
				<>
					<TableContainer>
						<Table
							sx={{ minWidth: 650, marginTop: 2 }}
							aria-label="simple table"
						>
							<TableBody>
								{samples?.data.map((s: ISample, index: number) => (
									<TableRow
										key={s.id}
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										<TableCell>{s.name}</TableCell>

										<TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
											{canEdit && (
												<IconButton
													title={t("common.edit")}
													onClick={(
														e: React.MouseEvent<HTMLButtonElement, MouseEvent>
													) => handlerDialogEditState(true, e, s)}
													sx={{ padding: 0 }}
												>
													<EditIcon />
												</IconButton>
											)}

											{canDelete && (
												<IconButton
													title={t("common.delete")}
													aria-label="delete"
													onClick={(
														e: React.MouseEvent<HTMLButtonElement, MouseEvent>
													) => handlerDialogDeleteState(true, e, s)}
													sx={{ padding: 0 }}
												>
													<DeleteIcon />
												</IconButton>
											)}

										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<CustomTablePagination
						count={samples?.totalCount}
						page={page}
						totalPages={samples?.totalPages}
						onPageChange={handleChangePage}

					/>
				</>
			)}
			{canAdd && (
				<>
					<SampleAdd
						open={openDialogAdd}
						handlerClose={handlerDialogAddState}
						updateGetSamples={updateSamples}
					/>
				</>
			)}

			{selectedSample && (
				<>
					{canDelete && (
						<SampleDelete
							sample={selectedSample}
							open={openDialogDelete}
							handlerClose={handlerDialogDeleteState}
							updateGetSamples={updateSamples}
						/>
					)}
					{canEdit && (
						<SampleEdit
							sample={selectedSample}
							open={openDialogEdit}
							handlerClose={handlerDialogEditState}
							updateGetSamples={updateSamples}
						/>
					)}
				</>
			)}
		</>
	);
}
