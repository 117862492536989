import { imageListItemClasses } from "@mui/material";
import { styled } from "@mui/system";
import { IPicture } from "interfaces";
import React from "react";
import { arrayIsEmpty } from "utils";
import FullScreenImage from "../FullScreenImage/FullScreenImage";
import PhotosListItem from "./PhotosListItem";

/*
xs: "repeat(1, 1fr)",
sm: "repeat(2, 1fr)",
md: "repeat(3, 1fr)",
lg: "repeat(4, 1fr)",
xl: "repeat(5, 1fr)",
*/
const ImageGalleryList = styled("ul")(({ theme }) => ({
  display: "grid",
  padding: 0,
  margin: 0,
  gap: 10,  
  gridAutoRows: "250px",
  [theme.breakpoints.up("xs")]: {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
  [theme.breakpoints.up("xl")]: {
    gridTemplateColumns: "repeat(5, 1fr)",
  },
  [`& .${imageListItemClasses.root}`]: {
    display: "flex",
    flexDirection: "column",
  },

  // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
  transform: "translateZ(0)",
}));

export interface PhotoListProps {
  images: IPicture[];
  onDelete: (image: IPicture) => void;
}

function PhotosList({ images, onDelete }: PhotoListProps) {
  const [showImage, setShowImage] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState<IPicture>();

  function handleOnSelected(image: IPicture) {
    setSelectedImage(image);

    setShowImage(true);
  }
  return (
    <>
      <ImageGalleryList>
        {images &&
          !arrayIsEmpty(images) &&
          images.map((image) => (
            <PhotosListItem
              key={image.id}
              onDelete={onDelete}
              onSelected={handleOnSelected}
              image={image}
            />
          ))}
      </ImageGalleryList>

      <FullScreenImage
        data={selectedImage}
        open={showImage}
        onClose={() => setShowImage(false)}
      />
    </>
  );
}

export default PhotosList;
