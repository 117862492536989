import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { IconButton, ImageListItem, ImageListItemBar, imageListItemClasses, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";

import { arrayIsEmpty, formatBytes } from "utils";

export interface IImageFile extends File {
  preview: string;
}
export interface IImageListPreviewProps {
  images: IImageFile[];
  imageStyle?: {
    width?: string;
    height?: string;
  };
  onDelete: (file: File) => void;
}

export const ImageListPreview: FC<IImageListPreviewProps> = ({
  images,
  imageStyle = {
    width: "120px",
    height: "inherit",
  },
  onDelete,
}) => {

  const theme = useTheme()

  return (
    <Box
      mt={2}
      mb={2}
      sx={{
        display: "grid",
        gridGap: 10,
        gridTemplateColumns: {
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(5, 1fr)",
        },
        [`& .${imageListItemClasses.root}`]: {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      {!arrayIsEmpty(images) &&
        images.map((img, idx) => (
          <ImageListItem key={img.name + idx} sx={imageStyle}>
            <img
              src={img.preview}
              srcSet={img.preview}
              alt={img.name}
              loading="lazy"
              onLoad={() => {
                URL.revokeObjectURL(img.preview);
              }}
            />
            <ImageListItemBar
              sx={{
                background:
                  "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                  "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
              }}
              position="top"
              actionIcon={
                <IconButton
                  sx={{
                    top: "-10px",
                    right: "-15px",
                    background: theme.palette.common.white,
                  }}
                  size="small"
                  color="error"
                  aria-label={`delete ${img.name}`}
                  onClick={() => onDelete(img)}
                >
                  <HighlightOffRoundedIcon />
                </IconButton>
              }
            />
            <ImageListItemBar
              title={formatBytes(img.size)}              
              position="below"
            />
          </ImageListItem>
        ))}
    </Box>
  );
};
