import { IClient, IClientEditForm } from "interfaces/IClient";

export const formDefaultValues = (data: IClient) => {
  const defaultValues: IClientEditForm = {
    // client: {
    name: data.name,
    center_point: JSON.stringify(data.center_point),
    // },
    // account: { name: '', adapter_configurators: [] },
  };
  return defaultValues;
};
