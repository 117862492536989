import { IPagination, TablePagination } from '@alb/live-lib';
import React from 'react'
import { useTranslation } from 'react-i18next';

import { ROWS_PER_PAGE } from './pagination';

export interface ITablePagination {
	count: number;
	page: number;
	totalPages: number;
	itemsPage?: number
	onPageChange: (
		page: number
	) => void;
}

export const CustomTablePagination = ({ count, page, totalPages, itemsPage, onPageChange }: ITablePagination ) => {
	const { t } = useTranslation();

	const tablePaginationProps: IPagination = {
		count: count,
		page: page,
		totalPages: totalPages,
		rowsPerPage: itemsPage ? itemsPage : ROWS_PER_PAGE,
		i18NLabels: {
			page: t("pagination.page"),
			of: t("pagination.of", {numPages:totalPages}),
			totalItems: t("pagination.totalItems", {count:count}),
			inputTitle: t("pagination.inputTitle"),
			validation: {
				required: t("pagination.validations.required"),
				positive: t("pagination.validations.positive"),
				min: t("pagination.validations.min"),
				max: t("pagination.validations.max"),
				integer: t("pagination.validations.integer"),
			}
		},
		onPageChange: onPageChange,
	}

	return (
		<TablePagination {...tablePaginationProps} />
	);
}

