import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import { valueEmptyTo } from "utils";

const RowItem = (props: {
  label: string;
  value?: string | number;
  children?: React.ReactNode;
  // opçao para fazer com que a informação fique totalmente visivel,
  // ou seja redireciona o tamanho da coluna
  fitContent?: boolean;
}) => {
  const { value, label, children, fitContent } = props;
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={3}
      lg={2}
      minWidth={fitContent ? "fit-content" : "unset"}
      paddingRight={fitContent ? 2 : "unset"}
    >
      <Typography variant={"h6"}>
        {label}
      </Typography>

      {!children && <Typography variant="body1">{valueEmptyTo(value)}</Typography>}
      {children}
    </Grid>
  );
};

RowItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node
};

export default RowItem;
