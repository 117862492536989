import { PERMISSIONS } from "./permissions";

export const bananaRoles = [
    PERMISSIONS.DASHBOARD.BANANASENSE.VIEW,
    PERMISSIONS.MODULES.VIEW,
    PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.VIEW,
    PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.CUD,
    PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.VIEW,
    PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.ADD,
    PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.EDIT,
    PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.DELETE,
]