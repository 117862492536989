import { useDispatch, useSelector } from "react-redux";
import { getSelectedMarkerType, setMarkers } from "store/slices/mapSlice";
import { selectDevices } from "store/slices/adapterConfiguratorsSlice";
import { IMapPoint } from "@alb/live-lib";
import { TMarker } from "interfaces/TMarker";
import { MarkersTypeOptions } from "components/map/controls/FilterMapTab/utils/GetOptions";

const useMarkers = () => {
  const dispatch = useDispatch<any>();
  const devices: TMarker[] = useSelector(selectDevices);
  const selectedType = useSelector(getSelectedMarkerType);

  // buscar devices
  function getMarkersDevices() {
    return devices;
  }

  // buscar markers dos modulos do client
  function getMarkersModules() {
    return [];
  }

  // agrupar e atualizar todos os markers
  function getAllMarkersMap() {
    let markers = getMarkersOfSelectedType(selectedType);
    dispatch(setMarkers(markers));
  }

  function getMarkersOfSelectedType(type: string) {
    const devices = getMarkersDevices();
    const modulesMarkers = getMarkersModules();
    let markers: IMapPoint[] = [];
    switch (type) {
      case MarkersTypeOptions.devices:
        markers = [...devices];
        break;
      case MarkersTypeOptions.all:
        markers = [...devices, ...modulesMarkers];
        break;
    }
    return markers;
  }

  return {
    getAllMarkersMap,
    getMarkersOfSelectedType,
  };
};

export default useMarkers;
