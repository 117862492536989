import { useTranslation } from "react-i18next";

import { TypographyWrapIcon } from "styles/components-styles";

export default function UserLanguage(props: { language: string }) {
	const { language } = props;
	const { t } = useTranslation();

	return (
		<TypographyWrapIcon
			color="text.primary"
			variant="body1"
		>
			{language.toLowerCase() === "pt" ? (
				<>
					<span className="fi fi-rounded fi-sise-md fis fi-pt"></span>
					{t("common.portuguese")}
				</>
			) : (
				<>
					<span className="fi fi-rounded fi-sise-md fis fi-gb"></span>
					{t("common.english")}
				</>
			)}
		</TypographyWrapIcon>

	);
}
