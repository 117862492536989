import { DynamicForm, FilterSection, IDynamicInputTemplate, IFormDefinition, TypeDynamicInput } from "@alb/live-lib";
import { Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { ISelectOption } from "interfaces";
import { debounce } from "lodash";
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { stringIsEmpty } from "utils";

interface IDefaultValues {
    name: string | undefined;
    status: string | null;
}

interface IColXTemplate extends IDynamicInputTemplate {
	xsCol: 'auto' | number | boolean
}

const ColXTemplate = ({ inputLabel, children, xsCol }: IColXTemplate) => {

    return (<Grid item xs={xsCol}>
        {inputLabel && <Typography gutterBottom variant="h6">
            {inputLabel}
        </Typography>
        }
        {children}
    </Grid>)
}

const FiltersSection = (
    props: {
        setPage: any,
        setFilterStatus: any,
        setFilterSearch: any
    }) => {

    const { setPage, setFilterSearch, setFilterStatus } = props;

    const [filtersCount, setFiltersCount] = useState<number>(0);
    const [search, setSearch] = useState<string>("");
    const [status, setStatus] = useState<string>("");

    const addFilterCount = () => {
		setFiltersCount((prevValue) => prevValue + 1);
	};
	const removeFilterCount = () => {
		setFiltersCount((prevValue) => {return prevValue > 0 ? prevValue-1 : 0});
	};

    const template = useCallback(
		({ inputLabel, children }: IDynamicInputTemplate) => {
			return <ColXTemplate xsCol={6} inputLabel={inputLabel} children={children} />
		},
		[],
	)

    const debounceChangeName = debounce((cb) => {
		cb();
	}, 350);

    const handleChangeName = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        debounceChangeName(() => {
            setPage(1);
            //se o valor anterior for vazio
            if (stringIsEmpty(search)) {
                addFilterCount(); //adiciona à contagem de filtros
            } else if (stringIsEmpty(e.target.value)) {
                removeFilterCount(); //se o input estiver vazio, remove da contagem de filtros
            }
            setSearch(e.target.value);
        })
    };

    const handleChangeStatus = (
		e: SyntheticEvent<Element, Event>,
		value: ISelectOption
	) => {
		setPage(1);
		//se existir opção selecionada
		if (value && value.value) {
			//se o valor anterior for vazio
			if (stringIsEmpty(status)) {
				addFilterCount(); //adiciona à contagem de filtros
			}
			setStatus(value.value);
		}
		//se não existir opção selecionada
		else {
			removeFilterCount(); //se for removida a opção do select, remove da contagem do filtro
			setStatus("");
		}
	};

    const stateOptionsList = [
        {
          label: t("user.status.activePlural"),
          value: "active",
        },
        {
          label: t("user.status.non-activePlural"),
          value: "inactive",
        },
        {
          label: t("user.status.invitedPlural"),
          value: "invited",
        },
        {
          label: t("user.status.deletedPlural"),
          value: "deleted",
        },
      ]



    const dynamicForm = {
        formId: "form-search",
        inputsDefinition: {
            name: {
				inputType: TypeDynamicInput.text,
				label: t("common.search"),
				placeholder: t("common.typeToSearchPlaceholder"),
				defaultValue: '',
				handleOnChange: handleChangeName,
				template: template,
			},
            status: {
				inputType: TypeDynamicInput.autocomplete,
				label: t("user.state"),
				placeholder: t("user.statePlaceholder"),
				options: stateOptionsList,
				defaultValue: null,
				handleOnChange: handleChangeStatus,
				template: template,
			}
        },
      };

      const defaultValues: IDefaultValues = {
        name: dynamicForm.inputsDefinition.name.defaultValue,
        status: dynamicForm.inputsDefinition.status.defaultValue
    };

      const methodsForm = useForm<IDefaultValues>({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: defaultValues,
      });

    const formDefinition: IFormDefinition = { ...dynamicForm, methodsForm };

    useEffect(() => {
        setFilterSearch(search)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, setSearch])

    useEffect(() => {
        setFilterStatus(status)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, setStatus])

    return(<>
        <FilterSection
            activeFiltersCount={filtersCount}
            filterTitle={t("common.filter")}
        >
            <DynamicForm formDefinition={formDefinition} />
        </FilterSection>
    </>)
}

export default FiltersSection;
