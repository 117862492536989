import { InputSelect, InputText, RequiredField, useFeedback } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
//material
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
//traduçoes
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { RequiredFields } from "components/utils/forms/RequiredFields";
import { useUpdate } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { IUser } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { setUser } from "store/slices/authSlice";

import { getLanguages } from "../languages";
import { getUserProfileSchema } from "../validations";

export default function EditProfile(props: any) {
  //traduções
  const { t, i18n } = useTranslation();
  //alert
  const { addFeedback } = useFeedback();

  const dispatch = useDispatch()

  //########## SERVIÇOS
  const {
    loading,
    error,
    refetch: updateUser,
  } = useUpdate<IUser[]>(ServiceApiUrl.userURL, props.user.id);

  //para lançar o erro, caso exista.
  const { sendErrorMessage } = useErrorHandler();

  //########## FUNÇÕES

  //idiomas para listar no select do formulário do convite
  const langOptions = getLanguages(t);

  //sucesso
  const handlerSuccess = (data: IUser) => {
    props.handlerClose();
    addFeedback({ message: t("common.changesSaved"), severity: "success" });
    props.updateGetUsers();
     // save new personal data in local storage and redux
     dispatch(setUser(data))
  };

  useEffect(() => {
    methodsEdit.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.handlerClose]);

  //########## FORMULÁRIO



  const defaultValues = {
    first_name: props.user.first_name,
    last_name: props.user.last_name,
    username: props.user.username,
    language: props.user.language.toLowerCase(),
  }

  const methodsEdit = useForm<IUser>({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(getUserProfileSchema()),
  });

  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const changeUserLanguage = async (lng: string) => {
    if (props.user.language.toLowerCase() !== lng.toLowerCase()) {
      i18n.changeLanguage(lng.toLowerCase()) // change language
    }
  };

  //edita utilizador
  const formEditSubmitHandler: SubmitHandler<IUser> = async (
    payLoad: IUser
  ) => {
    if (props.user) {
      try {
        await updateUser({ data: payLoad }); //enviar os dados
        await changeUserLanguage(payLoad.language);
        await handlerSuccess(payLoad);
      } catch {}
    }
  };

  const { control, reset } = methodsEdit

  useEffect(() => {
    reset({
      ...defaultValues,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.handlerClose])

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle id="alert-dialog-title">
        <Typography
          component={"span"}
          color="text.black"
          gutterBottom={true}
          variant="h5"
          noWrap
        >
          {t("user.editPersonalData")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <FormProvider {...methodsEdit}>
          <form
            id="edit-user-form"
            onSubmit={methodsEdit.handleSubmit(formEditSubmitHandler)}
          >
            {props.user ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography gutterBottom color="text.black" variant="h6">
                    {t("user.firstName")}
                  </Typography>
                  <InputText name="first_name" control={control} />
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom color="text.black" variant="h6">
                    {t("user.lastName")}
                  </Typography>
                  <InputText name="last_name" control={control} />
                </Grid>
                <Grid item xs={12}>
                  <RequiredField title={t("user.Username")}/>
                  <InputText name="username" control={control} />
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom color="text.black" variant="h6">
                    {t("user.institutionalEmail")}
                  </Typography>
                  <p>{props.user.email}</p>
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom color="text.black" variant="h6">
                    {t("user.language")}
                  </Typography>
                  <InputSelect
                    name="language"
                    options={langOptions}
                    control={control}
                  />
                  <RequiredFields />
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={props.handlerClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!methodsEdit.formState.isValid}
          type={"submit"}
          form={"edit-user-form"}
        >
          {t("common.save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
