import useAxios from "axios-hooks";
import { AxiosRequestConfig } from "axios";

export function useCreate<T = any>(
  url: string,
  id?: string,
  othersConfig?: AxiosRequestConfig<any>) {

    const config: AxiosRequestConfig = { 
      ...othersConfig,
      url: id? url +"/" + id : url,
      method: 'POST'
    };
   const [{ data, loading, error }, refetch, cancelRequest] = useAxios<T>(
    config,
     { manual: true }
   );
 
   return { data, loading, error, refetch, cancelRequest };
}