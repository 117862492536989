import { InputDateTime, InputText, RequiredField, useFeedback } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RequiredFields } from "components/utils/forms/RequiredFields";
import { useUpdate } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import i18n from "i18n/config";
import { IFlowering } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { convertCmToMm, convertMmToCm_inputNumber, formatDate } from "utils";
import { getSelectedModule } from "utils/permissions/getSelectedModule";

import { getSampleEditFloweringSchema } from "../validations";

export default function FloweringFruitingEdit(props: any) {

	const { t } = useTranslation();
	const { addFeedback } = useFeedback();
	const { sendErrorMessage } = useErrorHandler();
	const auth = useSelector(getUser)
	const moduleID = auth && getSelectedModule(auth, 'dados_morfologicos')
	const headers = {
		headers: {
			'MODULE-ID': moduleID
		}
	}


	const { loading, error, refetch: updateFlowering } = useUpdate<IFlowering[]>(
		ServiceApiUrl.floweringURL,
		props.flowering.id,
		headers
	);

	//sucesso
	const handlerSuccess = () => {
		props.handlerClose();
		addFeedback({ message: t("common.changesSaved"), severity: "success" });
		props.updateGetFlowering();
	};
	//erro
	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);


	useEffect(() => {
		methodsEdit.reset();
	}, [props.handlerClose]);

	const methodsEdit = useForm<IFlowering>({
		defaultValues: {
			flowering_days: props.flowering.flowering_days,
			bunch_fruits_number: props.flowering.bunch_fruits_number,
			registration_date: props.flowering.registration_date,
			stem_length: convertMmToCm_inputNumber(props.flowering.stem_length),
		},
		mode: "onChange",
		resolver: yupResolver(getSampleEditFloweringSchema()),
	});


	const formEditSubmitHandler: SubmitHandler<IFlowering> = async (
		payLoad: IFlowering
	) => {
		if (props.flowering) {
			payLoad.registration_date = formatDate(payLoad.registration_date, t('calendar.dateTimeFormatLocalSeconds'))
			payLoad.stem_length = convertCmToMm(payLoad.stem_length);
			try {
				await updateFlowering({ data: payLoad }); //enviar os dados
				await handlerSuccess();
			} catch { }
		}
	};


	return (
		<>
			<Dialog
				maxWidth="md"
				open={props.open}
				fullWidth={true}
			>
				<DialogTitle>
					<Typography
						component={"span"}
						color="text.black"
						gutterBottom={true}
						variant="h5"
						noWrap
					>
						{t('morphologicalData.floweringFruit.floweringFruitingEdit')}
					</Typography>
				</DialogTitle>

				<DialogContent>
					<FormProvider {...methodsEdit}>
						<form
							id="edit-form"
							onSubmit={methodsEdit.handleSubmit(formEditSubmitHandler)}
						>

							{props.flowering && (
								<Grid container spacing={2}>

									<Grid item xs={12}>
										<RequiredField title={t("morphologicalData.registrationDate")} />
										<InputDateTime
											control={methodsEdit.control}
											name="registration_date"
											placeholder={t(
												"morphologicalData.registrationDatePlaceholder"
											)}
											labelBtnCancel={t("common.cancel")}
											labelBtnConfirm={t("common.apply")}
											locale={i18n.language}
											maxDate={new Date()}
										/>
									</Grid>

									<Grid item xs={12}>
										<RequiredField title={t("morphologicalData.floweringFruit.floweringTime")} />
										<InputText
											control={methodsEdit.control}
											type="number"
											name="flowering_days"
											endAdornment={t('common.days')}
											placeholder={t(
												"morphologicalData.floweringFruit.floweringTimePlaceholder"
											)}
										/>
									</Grid>


									<Grid item xs={12}>
										<RequiredField title={t("morphologicalData.floweringFruit.numberFruits")} />
										<InputText
											control={methodsEdit.control}
											type="number"
											name="bunch_fruits_number"
											placeholder={t(
												"morphologicalData.floweringFruit.numberFruitsPlaceholder"
											)}
										/>
									</Grid>


									<Grid item xs={12}>
										<RequiredField title={t("morphologicalData.floweringFruit.peduncleLength")} />
										<InputText
											control={methodsEdit.control}
											type="number"
											endAdornment={'cm'}
											name="stem_length"
											placeholder={t(
												"morphologicalData.floweringFruit.peduncleLengthPlaceholder"
											)}
										/>
									</Grid>

								</Grid>
							)}
						</form>
					</FormProvider>
					<RequiredFields allRequired />
				</DialogContent>

				<DialogActions>
					<Button
						variant="outlined"
						color="primary"
						onClick={props.handlerClose}
					>
						{t("common.cancel")}
					</Button>

					<LoadingButton
						loading={loading}
						variant="contained"
						color="primary"
						disabled={!methodsEdit.formState.isValid}
						type={"submit"}
						form={"edit-form"}
					>
						{t("common.save")}
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
}
