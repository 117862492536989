import { Loading, TableHeadCell, TableHeadOrder, TableHeadSort } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import { Chip, Grid, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useErrorHandler, useGetAll } from "hooks";
import { useHasPermissions } from "hooks/usePermissions";
import { IDevice } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { TableExpanded } from "styles/components-styles";
import { arrayIsEmpty, formatDate, formatOrderBy, PERMISSIONS, statusColor } from "utils";
import { ROWS_PER_PAGE } from "utils/pagination";
import { CustomTablePagination } from "utils/pagination/CustomTablePagination";

interface IGatewayDevices {
  id: string;
}

function Row(props: { row: IDevice; open: boolean; onClick: Function }) {
  const { row, open } = props;
  const { t } = useTranslation();

  return (
    <>
      <TableRow
        className={clsx({"no-pointer":true,
        expanded: open,
        })}
      >
        <TableCell>{row.title}</TableCell>
        <TableCell>
          <Chip
            size="small"
            label={row.is_active ? t("common.active") : t("common.inactive")}
            color={
              row.is_active ? 
              statusColor("active") 
              : 
              statusColor("non-active")
            }
          />
        </TableCell>
        <TableCell>{row.geolocation?.iconPosition.join(", ")}</TableCell>
        <TableCell>
          {row.date[0]
            ? formatDate(row.date[0], t("calendar.dateTimeFormatLocal"))
            : "--"}
        </TableCell>
      </TableRow>
    </>
  );
}

function GatewayDevices({ id }: IGatewayDevices) {
  const { sendErrorMessage } = useErrorHandler();
  const { t } = useTranslation();
  const { hasPermission } = useHasPermissions();

  const [openRow, setOpenRow] = useState("");
  const [order, setOrder] = useState<TableHeadOrder>("asc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const [page, setPage] = useState(1);

  const canList = hasPermission([PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.VIEW]);

  const {
    data: gatewayDevices,
    loading,
    error,
  } = useGetAll<{totalPages: number, totalCount: number, data: IDevice[]}>(
    ServiceApiUrl.devices,
    {
      adapters_id: id,
      order_by: formatOrderBy(order, orderBy),
      page: page,
      items: ROWS_PER_PAGE,
    }
    );

  //erro
  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    newPage: number
  ) => {
    setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
  };

  const tableHeads: TableHeadCell[] = [
    {
      id: "name",
      label: t("common.name"),
    },
    {
      id: "is_active",
      label: t("common.statusLabel"),
    },
    {
      id: "geoLocation",
      label: t("adapters.geoLocation"),
      notSortable: true,
    },
    {
      id: "last_read_at",
      label: t("adapters.lastReadAt"),
    },
  ];

  return (
    <div>
      <Grid
        container
        mt={1}
        alignItems="center"
        direction="row"
        sx={{ justifyContent: "end" }}
      >
        <Grid item xs={true}>
        <Typography variant="h3">
            {t("adapters.devices")}
          </Typography>
        </Grid>
      </Grid>
      <Loading show={loading} />

      {arrayIsEmpty(gatewayDevices?.data) && !loading && canList && (
        <Typography mt={4} mb={2} variant="body1">
          {t("adapters.noDevices")}
        </Typography>
      )}

      {gatewayDevices && !arrayIsEmpty(gatewayDevices?.data) && !loading && (
        <>
          <TableContainer>
            <TableExpanded>
              <TableHeadSort
                headCells={tableHeads}
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
              />
              <TableBody>
                {gatewayDevices?.data.map(
                  (device: IDevice, index: number) => (
                    <Row
                      key={device.id}
                      row={device}
                      open={device.id === openRow}
                      onClick={(id: string) => setOpenRow(id)}
                    />
                  )
                )}
              </TableBody>
            </TableExpanded>
          </TableContainer>
          <CustomTablePagination
						count={gatewayDevices?.totalCount}
						page={page}
						totalPages={gatewayDevices?.totalPages}
						onPageChange={handleChangePage}
					/>
        </>
      )}
    </div>
  );
}

export default GatewayDevices;
