import { Loading } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, Collapse, Grid, IconButton, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import RowItem from "components/utils/Table/RowItem";
import { useErrorHandler, useGetAll } from "hooks";
import { useHasPermissions } from "hooks/usePermissions";
import { IApiResponse, ISample } from "interfaces";
import { IHarvest } from "interfaces/IHarvest";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { TableCellExpanded, TableExpanded, TableRowExpanded } from "styles/components-styles";
import { arrayIsEmpty, convertMmToCm, formatDate, PERMISSIONS } from "utils";
import { ROWS_PER_PAGE } from "utils/pagination";
import { CustomTablePagination } from "utils/pagination/CustomTablePagination";
import { getSelectedModule } from "utils/permissions/getSelectedModule";
import HarvestMaturationAdd from "views/morphologicaldata/harvestMaturation/addHarvestMaturation";
import HarvestMaturationDelete from "views/morphologicaldata/harvestMaturation/deleteHarvestMaturation";
import HarvestMaturationEdit from "views/morphologicaldata/harvestMaturation/editHarvestMaturation";

interface IHarvestMaturation {
	sample: ISample;
}

function Row(props: {
	row: IHarvest;
	open: boolean;
	onClick: Function;
	onEditClick: Function;
	onDeleteClick: Function;
}) {
	const { row, open, onClick, onEditClick, onDeleteClick } = props;
	const { t } = useTranslation();

	const { hasPermission } = useHasPermissions();
	const canDelete = hasPermission([
		PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.DELETE,
	]);
	const canEdit = hasPermission([
		PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.EDIT,
	]);

	return (
		<>
			<TableRow
				className={clsx({ expanded: open })}
				onClick={() => onClick(open ? "" : row.id)}
			>
				<TableCell sx={{ width: 50 }} size="small">
					<IconButton size="small" sx={{ padding: 0 }}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell>
					{formatDate(row.registration_date, t("calendar.dateFormat"))}
				</TableCell>
				<TableCell>{row.user.username}</TableCell>
				<TableCell>{`${row.crop_cycle_days} ${t("common.days")}`}</TableCell>
				<TableCell>{row.pulp_color_maturity.name}</TableCell>
				<TableCell>{row.flesh_texture.name}</TableCell>
				<TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
					{canEdit && (
						<IconButton
							title={t("common.edit")}
							onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
								onEditClick(true, e, row)
							}
							sx={{ padding: 0 }}
						>
							<EditIcon />
						</IconButton>
					)}
					{canDelete && (
						<IconButton
							title={t("common.delete")}
							aria-label="delete"
							onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
								onDeleteClick(true, e, row)
							}
							sx={{ padding: 0 }}
						>
							<DeleteIcon />
						</IconButton>
					)}
				</TableCell>
			</TableRow>
			<TableRowExpanded>
				<TableCellExpanded colSpan={7}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Grid container columns={{ xs: 2, sm: 6, md: 8, lg: 12 }}>
							<RowItem
								label={t("morphologicalData.harvestMatur.numberHands")}
								value={row.pedicels_number}
							/>
							<RowItem
								label={t("morphologicalData.harvestMatur.numberFruitsHands")}
								value={row.fruits_number}
							/>
							<RowItem
								label={t("morphologicalData.harvestMatur.fruitLength")}
								value={`${convertMmToCm(row.fruit_length)} cm`}
							/>
							<RowItem
								label={t("morphologicalData.harvestMatur.fruitDiameter")}
								value={`${convertMmToCm(row.fruit_diameter)} cm`}
							/>
							<RowItem
								label={t("morphologicalData.harvestMatur.fruitWeight")}
								value={`${row.fruit_weight} g`}
							/>
							<RowItem
								label={t("morphologicalData.harvestMatur.barkThickness")}
								value={`${convertMmToCm(row.fruit_peel_thickness)} cm`}
							/>
						</Grid>
					</Collapse>
				</TableCellExpanded>
			</TableRowExpanded>
		</>
	);
}

function HarvestMaturation(props: IHarvestMaturation) {
	const { sample, ...other } = props;
	const { sendErrorMessage } = useErrorHandler();
	const { t } = useTranslation();

	const [page, setPage] = useState(1);
	const [openDialogAddHM, setOpenDialogAddHM] = useState(false);
	const [openDialogDelete, setOpenDialogDelete] = useState(false);
	const [harvestSelected, setHarvestSelected] = useState<IHarvest>();
	const [openDialogEdit, setOpenDialogEdit] = useState(false);

	const [openRow, setOpenRow] = useState("");
	const auth = useSelector(getUser);
	const moduleID = auth && getSelectedModule(auth, "dados_morfologicos");
	const headers = {
		headers: {
			"MODULE-ID": moduleID,
		},
	};
	//get harvestMaturation
	const {
		data: harvestMaturation,
		loading,
		error,
		refetch,
	} = useGetAll<IApiResponse<IHarvest>>(
		ServiceApiUrl.harvestURL,
		{
			sample: sample.id,
			page,
			items: ROWS_PER_PAGE,
		},
		undefined,
		headers
	);

	//erro
	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);

	const updateHarvest = () => {
		refetch();
	};

	const handlerDialogAddHMState = () => {
		setOpenDialogAddHM(!openDialogAddHM);
	};
	const onAddClick = (event: React.MouseEvent<HTMLElement>) => {
		setOpenDialogAddHM(true);
	};

	//atualiza o estado do modal de remover
	const handlerDialogDeleteState = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		harvest: IHarvest
	) => {
		if (open === true) {
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setHarvestSelected(harvest);
		}
		setOpenDialogDelete(!openDialogDelete);
	};

	//atualiza o estado do modal de editar
	const handlerDialogEditState = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		harvest: IHarvest
	) => {
		if (open === true) {
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setHarvestSelected(harvest);
		}
		setOpenDialogEdit(!openDialogEdit);
	};

	const { hasPermission } = useHasPermissions();
	const canList = hasPermission([
		PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.VIEW,
	]);
	const canAdd = hasPermission([
		PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.ADD,
	]);

	//Trocar página da listagem
	const handleChangePage = (newPage: number) => {
		setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
	};

	return (
		<div {...other}>
			<Grid
				container
				mt={1}
				alignItems="center"
				direction="row"
				sx={{ justifyContent: "end" }}
			>
				<Grid item xs={true}>
					<Typography variant="h3">
						{t("morphologicalData.harvestMatur.harvestMaturation")}
					</Typography>
				</Grid>

				{canAdd && (
					<Grid item>
						<Button
							color="primary"
							onClick={onAddClick}
							variant="contained"
							size="medium"
							startIcon={<AddIcon />}
						>
							{t("common.add")}
						</Button>
					</Grid>
				)}
			</Grid>
			<Loading show={loading} />

			{arrayIsEmpty(harvestMaturation?.data) && !loading && canList && (
				<Typography mt={4} mb={2} variant="body1">
					{t("morphologicalData.noRecordsYet")}
				</Typography>
			)}

			{harvestMaturation &&
				!arrayIsEmpty(harvestMaturation?.data) &&
				!loading && (
					<>
						<TableContainer>
							<TableExpanded>
								<TableHead>
									<TableRow>
										<TableCell />
										<TableCell>{t("morphologicalData.date")}</TableCell>
										<TableCell>{t("morphologicalData.responsible")}</TableCell>
										<TableCell>
											{t("morphologicalData.harvestMatur.cropCycle")}
										</TableCell>
										<TableCell>
											{t("morphologicalData.harvestMatur.PulpColor")}
										</TableCell>
										<TableCell>
											{t("morphologicalData.harvestMatur.texture")}
										</TableCell>
										<TableCell />
									</TableRow>
								</TableHead>
								<TableBody>
									{harvestMaturation?.data.map((h: IHarvest, index: number) => (
										<Row
											key={h.id}
											row={h}
											open={h.id === openRow}
											onClick={(id: string) => setOpenRow(id)}
											onEditClick={handlerDialogEditState}
											onDeleteClick={handlerDialogDeleteState}
										/>
									))}
								</TableBody>
							</TableExpanded>
						</TableContainer>

						<CustomTablePagination
							count={harvestMaturation?.totalCount}
							page={page}
							totalPages={harvestMaturation?.totalPages}
							onPageChange={handleChangePage}
						/>
					</>
				)}
			{openDialogAddHM && (
				<HarvestMaturationAdd
					data={sample}
					open={openDialogAddHM}
					onClose={handlerDialogAddHMState}
					onSuccess={updateHarvest}
				/>
			)}
			{harvestSelected && (
				<>
					{openDialogDelete && (
						<HarvestMaturationDelete
							harvest={harvestSelected}
							open={openDialogDelete}
							handlerClose={handlerDialogDeleteState}
							updateGetHarvest={updateHarvest}
						/>
					)}
					{openDialogEdit && (
						<HarvestMaturationEdit
							harvest={harvestSelected}
							open={openDialogEdit}
							handlerClose={handlerDialogEditState}
							updateGetHarvest={updateHarvest}
						/>
					)}
				</>
			)}
		</div>
	);
}

export default HarvestMaturation;
