export const getIcons = (
  type: string,
  subType?: string,
  status?: boolean,
  title?: string
) => {
  if (type === "device") {
    const onOff = status ? "enabled" : "disabled";
    switch (subType?.toLowerCase()) {
      case "basensesoil":
        return require(`assets/images/map/markers/${onOff}/basense/soil.svg`);
      case "basenseweather":
        return require(`assets/images/map/markers/${onOff}/basense/weather.svg`);
      case "soil":
        return require(`assets/images/map/markers/${onOff}/enoanalytics/soil.svg`);
      default:
    }
    return require(`assets/images/map/markers/${onOff}/default.svg`);
  }
};
