import { InputText, Loading, useFeedback } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import LiveFooter from "components/page/footer";
import { useGetAll, useUpdate } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { IUser, IUserConfirmation } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { LiveFlexGridBaseline } from "styles/components-styles";
import { getDefinePwdSchema } from "views/users/validations";

import JoinImage from "../../images/join_live_agro.svg";
import { ReactComponent as Logo } from "../../images/live_green_logo.svg";
import { ReactComponent as LogoCircle } from "../../images/logo.svg";

const LiveUserConfirmInvite = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [success, setSuccess] = useState<boolean>(false);
  const { addFeedback } = useFeedback();

  //para lançar o erro, caso exista.
  const { sendErrorMessage } = useErrorHandler();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") || "";

  const {
    data: user,
    loading,
    error,
  } = useGetAll<IUser>(ServiceApiUrl.validateTokenURL, { token });

  const headersAdd = {
    headers: {
      Token: token,
    },
  };

  const {
    error: errorSetPwd,
    refetch: setPassword,
  } = useUpdate<IUserConfirmation>(ServiceApiUrl.setPwdURL, "", headersAdd);

  useEffect(() => {
    if (errorSetPwd) {
      sendErrorMessage(errorSetPwd);
    }
  }, [errorSetPwd]);

  useEffect(() => {
    if (success) {
      addFeedback({
        message: t("loginForm.messageSuccessConfirmInvitation"),
        severity: "success",
      });
      navigateToLogin();
    }
  }, [success]);

  const methods = useForm<IUserConfirmation>({
    mode: "onChange",
    resolver: yupResolver(getDefinePwdSchema(t("user.passwordMustMatch"))),
  });

  const { control } = methods;

  // set pwd
  const formSubmitHandler: SubmitHandler<IUserConfirmation> = async (
    payLoad: IUserConfirmation
  ) => {
    var passwordSubmit = {
      password: payLoad.password,
    };
    // var editUserSubmit =
    // {
    //   'first_name': payLoad.first_name,
    //   'last_name': payLoad.last_name,
    //   'language': payLoad.language,
    // }
    await setPassword({ data: passwordSubmit });
    // await updateUser({ data: editUserSubmit });
    await setSuccess(true);
  };

  const navigateToLogin = () =>
    navigate("/login", {
      replace: true,
      state: { from: { pathname: "/profile" } },
    });

  return (
    <div className="pagesDiv">
      <Container sx={{ paddingTop: 8, flex: " 1" }} maxWidth={"md"}>
        <Loading show={loading} />
        {error && (
          <Box maxWidth="sm">
            <Box>
              <LogoCircle width={"40%"} />
              <Typography
                sx={{ padding: "3% 0" }}
                color="secondary"
                variant="h4"
              >
                {t("errors.somethingWentWrong")}
              </Typography>

              <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                {t("user.invalidInvitedTokenText")}
              </Typography>
            </Box>
            <Stack marginTop={2} alignItems={"flex-start"}>
              <Button
                color="primary"
                type="button"
                variant="outlined"
                startIcon={<ArrowBackRoundedIcon />}
                onClick={navigateToLogin}
              >
                {t("common.goBack")}
              </Button>
            </Stack>
          </Box>
        )}
        {user && !error && !loading && (
          <Box maxWidth="md">
            <FormProvider {...methods}>
              <form
                id="reset-pwd-form"
                onSubmit={methods.handleSubmit(formSubmitHandler)}
              >
                <Typography variant="h4">{t("loginForm.joinTeam")}</Typography>

                <Typography mt={2} variant="subtitle1">
                  {t("loginForm.messageWelcomeUser")}
                  <Logo width={"6rem"} />
                </Typography>

                <Grid container mt={5} maxWidth={"sm"}>
                  <Grid item xs={12}>
                    <Typography gutterBottom color="text.black" variant="subtitle2">
                      {t("user.name")}
                    </Typography>

                    <InputText
                      disabled
                      name="first_name"
                      placeholder={t("user.namePlaceholder")}
                      defaultValue={user.first_name}
                      control={control}
                    />
                  </Grid>

                  <Grid container columnSpacing={2} sm={6}>
                    <Grid item pt={3} xs={12} >
                      <Typography
                        gutterBottom
                        color="text.black"
                        variant="subtitle2"
                        noWrap
                      >
                        {t("loginForm.passwordLabel")}
                      </Typography>
                      <InputText
                        name="password"
                        type="password"
                        placeholder={t("loginForm.passwordPlaceholder")}
                        control={control}
                      />
                    </Grid>
                    <Grid item pt={3} xs={12} >
                      <Typography
                        gutterBottom
                        color="text.black"
                        variant="subtitle2"
                        noWrap
                      >
                        {t("loginForm.passwordConfirmationLabel")}
                      </Typography>
                      <InputText
                        name="confirmPassword"
                        type="password"
                        placeholder={t("loginForm.passwordConfirmationLabelPlaceholder")}
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  mt={4}
                  maxWidth={"sm"}
                  justifyContent={"flex-start"}
                >
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={!methods.formState.isValid}
                      >
                        {t("user.join")}
                    </Button>
                  </Grid>
                </Grid>
                <LiveFlexGridBaseline gap={"0.3rem"} mt={2}>
                  <Typography variant="body1" color="black">
                    {t("loginForm.alreadyHaveAccount")}
                  </Typography>
                  <Typography
                    color="secondary"
                    variant="body1"
                    className="clickNavigateLogin"
                    onClick={navigateToLogin}
                  >
                    {t("loginForm.clickToEnter")}
                  </Typography>
                </LiveFlexGridBaseline>
              </form>
            </FormProvider>
          </Box>
        )}
      </Container>

      <LiveFooter backgroundImage={JoinImage} maxWidth={"lg"} />
    </div>
  );
};

export default LiveUserConfirmInvite;
