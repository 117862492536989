/* eslint-disable react-hooks/exhaustive-deps */
import {
  InputAutocomplete,
  InputCheckbox,
  InputSelect,
  InputText,
} from "@alb/live-lib";
import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import {
  FormGroup,
  Grid,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import useDidMountEffect from "hooks/useDidMountEffect";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { setDevicesFilterParams } from "store/slices/adapterConfiguratorsSlice";
import {
  getSelectedMarkerType,
  setSelectedMarkerType,
} from "store/slices/mapSlice";
import { arrayIsEmpty } from "utils/utils";

import useMapFilters from "./hooks/useMapFilters";
import { GetOptions } from "./utils/GetOptions";
import { useErrorHandler, useGet } from "hooks";
import { IAdapterConfigurator } from "interfaces";

interface IFiltersForm {
  formId: string;
  publicAPI: boolean;
  trigger?: number;
  applyButtonStatus?: (status: boolean) => void;
}
type IAdaptersKeys = {
  [key: string]: boolean;
};

export interface IFilters {
  type?: string;
  deviceStatus?: any;
  adapters?: IAdaptersKeys;
}
const DividerStyled = styled(Divider)(() => ({
  margin: "30px 0px",
  width: "100%",
}));

let resetEvent: boolean = false;
let firstFormChange: boolean;

export const FilterTabForm = ({ formId, publicAPI }: IFiltersForm) => {
  const { t } = useTranslation();
  const selectedType = useSelector(getSelectedMarkerType);
  const dispatch = useDispatch();
  const { sendErrorMessage } = useErrorHandler();
  const { typeOptions, devicesStatusOptions, MarkersTypeOptions } = GetOptions(
    useSelector,
    useGet,
    t,
    sendErrorMessage,
    publicAPI
  );

  // get list of gateways
  const { data: adapterConfigurators, refetch } = useGet(
    ServiceApiUrl.adapterConfigurators,
    undefined,
    undefined,
    { manual: true }
  );

  useEffect(() => {
    resetEvent = false;
    firstFormChange = false;
      refetch();
  }, []);

  const [formValues, setFormValues] = useState<IFilters>();
  const [checked, setChecked] = useState<{ adapters?: IAdaptersKeys }>();

  const adapterConfigurators_aux = adapterConfigurators?.data
  const dispatchParams = useMapFilters(adapterConfigurators_aux, publicAPI);
  // extract adapter configurator names
  let adaptersNames: string[] = useMemo(
    () =>
      adapterConfigurators_aux
        ?.map((adapter: IAdapterConfigurator) => adapter.name)
        .sort(),
    [adapterConfigurators]
  );
  let adapterschecks = { adapters: {} };
  // default dynamic values for checkboxes
  if (adapterConfigurators_aux) {
    adaptersNames?.map(
      (adapter: string) =>
        (adapterschecks.adapters = {
          ...adapterschecks.adapters,
          [adapter]: true,
        })
    );
  }
  // checkboxes state when filter dialog opens
  useEffect(() => {
    if (adapterConfigurators && !arrayIsEmpty(adapterConfigurators_aux)) {
      setChecked(adapterschecks);
      reset(defaultValues);
    }
  }, [adapterConfigurators]);

  const {
    control: controlSearchAndType,
    reset: resetSearchAndType,
    setValue,
    watch: searchWatchAndType,
  } = useForm<{ type: string; search: string }>({
    defaultValues: { type: MarkersTypeOptions?.all, search: "" },
  });

  const defaultValues = {
    deviceStatus: null,
    ...adapterschecks,
  };

  const filtersForm = useForm<IFilters>({
    defaultValues: defaultValues,
    mode: "onChange",
    reValidateMode: "onChange"
  });
  const {
    control,
    reset,
    watch,
    formState: { isDirty },
  } = filtersForm;


  const handleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatchParams(undefined, e.target.value);
  };
  const markersTypeRedux = useSelector(getSelectedMarkerType);
  const [typeState, setTypeState] = useState<string | string[] | undefined>(
    filtersForm.getValues().type || markersTypeRedux
  );

  // quando se altera no menu dos markers (leftTopComponent) é preciso atualizar os types da tab do filter
  useEffect(() => {
    if (typeState !== markersTypeRedux) {
      setTypeState(markersTypeRedux);
      resetForm();
    }
  }, [markersTypeRedux]);

  const resetForm = () => {
    resetEvent = true; //auxiliar para utilizar no watch
    reset();
    resetSearchAndType();
    dispatch(setDevicesFilterParams({}));
  };
  const typeOnChange = (e: SelectChangeEvent<string[] | string>) => {
    resetForm();
    setTypeState(e.target.value);
    setValue("type", e.target.value as string, { shouldDirty: true });
    dispatch(setSelectedMarkerType(e.target.value));
    if (!firstFormChange) firstFormChange = true; //da primeira vez em que for aplicado algum filtro, troca a variavel para true
  };
  //sempre que o tipo for alterado, limpa os parametros e os pedidos
  useDidMountEffect(() => {
    if (firstFormChange && !onlyOneOptionType)
      dispatchParams(undefined, undefined, true);
  }, [selectedType]);
  //dispatch dos values
  useDidMountEffect(() => {
    if (!firstFormChange && isDirty) firstFormChange = true;
    if (firstFormChange) {
      setTimeout(() => {
          dispatchParams(formValues);
      }, 0);
      // dispatchParams(formValues);
    }
  }, [formValues]);

  //O watch era ativado sempre que havia uma alteração no form, então estava a entrar sempre que os valores alteravam (o pretendido)
  //e sempre que o tipo alterava, porque dá reset, (não queremos isto)
  //então a solução foi usar uma variavel auxiliar (resetEvent) para controlar quando entra no watch pelo reset ao alterar o tipo, ou por alterações no form.
  useEffect(() => {
    const subscription = watch(() => {
      if (!resetEvent) {
        const filtersFormValues = filtersForm.getValues();
        setFormValues(filtersFormValues);
      }
      resetEvent = false;
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  //se o tipo apenas tiver 2 opções, não mostra. é o all e o próprio.
  let onlyOneOptionType = typeOptions.length <= 2;

  //obter os valores dos adapters e devolve os que tem check
  let adaptersValues = watch("adapters");
  let checkedAdapters = Object.entries(adaptersValues as any).filter(
    ([key, value]) => value === true
  );
  const searchValue = searchWatchAndType("search");
  const typeValue = searchWatchAndType("type");
  const activeFields = {
    search: searchValue.replace(/\s/g, "").length > 0 ? searchValue : undefined,
    type: typeValue !== "all" ? typeValue : undefined,
    devices: {
      status: watch("deviceStatus"),
    },
  };

  return (
    <>
      <FormProvider {...filtersForm}>
        <form id={formId}>
          <FormGroup>
            <Grid container mt={3} spacing={1}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="subtitle2">
                  {t("common.search")}
                </Typography>
                <InputText
                  placeholder={t("common.searchByName")}
                  name="search"
                  control={controlSearchAndType}
                  handleOnChange={handleSearch}
                  activeField={activeFields.search}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </Grid>
              {!onlyOneOptionType && (
                <Grid item xs={12}>
                  <Typography gutterBottom variant="subtitle2">
                    {t("common.type")}
                  </Typography>
                  <InputSelect
                    placeholder={t("common.typePlaceholder")}
                    name="type"
                    control={controlSearchAndType}
                    options={typeOptions}
                    handleOnChange={typeOnChange}
                    activeField={activeFields.type}
                  />
                </Grid>
              )}
            </Grid>
            {(typeState !== MarkersTypeOptions.all || onlyOneOptionType) && (
              <>
                <Grid container rowSpacing={1}>
                  <DividerStyled />

                  {(typeState === MarkersTypeOptions.devices ||
                    (typeState === MarkersTypeOptions.all &&
                      onlyOneOptionType)) && (
                    <>
                      <Typography
                        gutterBottom
                        variant="caption"
                        sx={{ color: "#7C7C7C" }}
                      >
                        {t("adapters.devices")}
                      </Typography>

                      {devicesStatusOptions &&
                        devicesStatusOptions.length > 1 && (
                          <Grid item xs={12}>
                            <Typography gutterBottom variant="subtitle2">
                              {t("common.statusLabel")}
                            </Typography>
                            <InputAutocomplete
                              placeholder={t("common.statusPlaceholder")}
                              name="deviceStatus"
                              control={control}
                              options={devicesStatusOptions}
                              activeField={activeFields.devices.status}
                            />
                          </Grid>
                        )}
                      <Grid item xs={12}>
                        <Typography gutterBottom variant="subtitle2">
                          {t("modules.adapters")}
                        </Typography>
                        {adaptersNames?.length > 0 && checked ? (
                          <>
                            {adaptersNames?.map((adapter: string) => (
                              <React.Fragment key={adapter}>
                                <InputCheckbox
                                  key={adapter}
                                  control={control}
                                  name={`adapters.${adapter}`}
                                  labelText={adapter}
                                  setValue={setValue}
                                  checked={
                                    checked.adapters &&
                                    checked.adapters[adapter]
                                  }
                                  disabled={
                                    checkedAdapters.length === 1 &&
                                    checkedAdapters[0][0] === adapter
                                  }
                                />
                                <br />
                              </React.Fragment>
                            ))}
                          </>
                        ) : (
                          <Typography>{t("map.filter.noAdapters")}</Typography>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}
          </FormGroup>
        </form>
      </FormProvider>
    </>
  );
};
