import { ErrorMessage } from "@hookform/error-message";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FC, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FieldErrorMessage } from "../FieldErrorMessage";
import { IImageFile, ImageListPreview } from "./ImageListPreview";

export interface IFileUploadProps {
	name: string;
	accept: {};
	imageStyle?: {
		width?: string;
		height?: string;
	};
}

export const FileUpload: FC<IFileUploadProps> = ({
	name,
	accept,
	imageStyle = {
		width: "180px",
		height: "auto",
	},
}) => {
	const { t } = useTranslation();

	const {
		register,
		unregister,
		setValue,
		watch,
		formState: { errors },
	} = useFormContext();

	const files = watch(name);

	function onDelete(file: File) {
		const res = files.filter((fw: File) => fw !== file);

		setValue(name, res, { shouldValidate: true });
	}

	const onDrop = useCallback(
		(droppedFiles: File[]) => {
			setValue(
				name,
				droppedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				),
				{ shouldValidate: true, shouldDirty: true, shouldTouch: true }
			);
		},
		[setValue, name]
	);
	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept,
		multiple: false,
		// maxFiles: 0
	});
	useEffect(() => {
		register(name);
		return () => {
			unregister(name);
		};
	}, [register, unregister, name]);
	useEffect(() => {
		// Make sure to revoke the data uris to avoid memory leaks, will run on unmount
		return () =>
			files &&
			files.forEach((file: IImageFile) => URL.revokeObjectURL(file.preview));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div {...getRootProps({ className: "dropzone" })}>
				<input {...getInputProps()} {...register(name)} />
				<CloudUploadIcon fontSize="large" />
				<p>{t("common.uploadDragDropMessage")}</p>
				<em>{t("common.uploadOnlyImagesAllowed")}</em>
				<em>{t("common.allowedImages")}</em>
			</div>

			<ErrorMessage
				errors={errors}
				name={name}
				render={({ message }) => (
					<FieldErrorMessage>{message}</FieldErrorMessage>
				)}
			/>

			<aside>
				{!!files && (
					<ImageListPreview
						onDelete={onDelete}
						images={files}
						imageStyle={imageStyle}
					/>
				)}
			</aside>
		</>
	);
};
