import { getDefaultBoxShadow } from '@alb/live-lib'
import { Grid, Table, TableCell, TableRow, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

/************************* MAIN STYLE **************************************/

export const TypographyWrapIcon = styled(Typography)(() => ({
  alignItems: "center",
  display: "flex",
  "& .MuiSvgIcon-root": {
    marginRight: 5,
  },
}));

/************************* GERAL STYLE **************************************/

export const LiveRoundedImg = styled("img")<any>(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: theme.shape.borderRadius,
}));

//caso se queira utilizar 100% de uma grid para colocar vária info lado a lado, sem usar várias grids
export const LiveFlexGridBaseline = styled(Grid)<any>(({ theme }) => ({
  display: "flex",
  //gap: "0.7rem", //definido no próprio componente, porque pode variar
  alignItems: "baseline",
}));

export const LiveContainer = styled(Grid)<any>(({ theme }) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  backgroundColor: theme.palette.background.paper,
  boxShadow: getDefaultBoxShadow(),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
}));

//SELECT DIV FOR 2 SELECTS
//caso se queira utilizar 100% de uma grid para colocar vária info lado a lado, sem usar várias grids
export const DoubleSelect = styled(Grid)<any>(({ theme }) => ({
  display: "flex",
  borderRadius: 20,
  alignItems: "baseline",
  borderColor: theme.palette.text.secondary,
  "&:hover": {
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
  },
  "&.focused": {
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
  },
}));

/************************* TABLE STYLE **************************************/
export const TableLink = styled(Table)(() => ({
  "& .MuiTableRow-root": {
    cursor: "pointer",
  },
  "& .MuiTableRow-root.no-pointer": {
    cursor: "default",
  },
}));

export const TableExpanded = styled(Table)(({ theme }) => ({
  minWidth: "650px",
  marginTop: theme.spacing(5),

  "& .MuiTableHead-root .MuiTableRow-root": {
    backgroundColor: "unset",
  },

  "& .MuiTableBody-root .MuiTableRow-root": {
    cursor: "pointer",
    "&.expanded": {
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.secondary.light
          : theme.palette.greyColor.main,
    },
  },

  "& .MuiTableRow-root.no-pointer": {
    cursor: "default",
  },
}));

export const TableRowExpanded = styled(TableRow)(({ theme }) => ({
  cursor: "default !important",
  "& .MuiTableRow-root": {
    cursor: "default !important",
  },
  "& .MuiTableBody-root .MuiTableRow-root:hover": {
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.common.white
        : theme.palette.secondary.dark,
  },
}));

export const TableCellExpanded = styled(TableCell)(({ theme }) => ({
  paddingLeft: "50px !important",
  paddingTop: "0 !important",
  paddingBottom: "0 !important",
  backgroundColor:
    theme.palette.mode === "light"
      ? theme.palette.secondary.light
      : theme.palette.greyColor.main,
}));
