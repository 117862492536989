import { Box, Button, Grid, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import appInfo from "../../package.json";

function Version() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goBack = () => navigate(-1);
  return (
    <>
      <CssBaseline />
      <Grid container sx={{ textAlign: "center" }}>
        <Grid item xs={true} mt={12}>
          
          <Typography mb={2} mt={6} variant="h5">
            {appInfo.version}
          </Typography>

          <Box mt={10}>
            <Button onClick={goBack} size="large" variant="outlined">
              {t("common.goBack")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Version;
