/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useUpdate } from "hooks";
import {useFeedback} from "@alb/live-lib";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { useErrorHandler } from "hooks/useErrorHandler";
import { getNameEditSchema } from "../../validations";

import { LoadingButton } from "@mui/lab";
import { ILocation } from "interfaces/ILocation";
import { getSelectedModule } from "utils/permissions/getSelectedModule";
import { getUser } from "store/slices/authSlice";
import { useSelector } from "react-redux";
import { InputText, RequiredField } from "@alb/live-lib";
import { RequiredFields } from "components/utils/forms/RequiredFields";

export default function EditLocation(props: any) {

  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const { sendErrorMessage } = useErrorHandler();
  const auth = useSelector(getUser)
  const moduleID = auth && getSelectedModule(auth, 'dados_morfologicos')
  const headers = {
    headers: {
      'MODULE-ID': moduleID
    }
  }



  const { loading, error, refetch: updateLocations } = useUpdate<ILocation>(
    ServiceApiUrl.placesListURL,
    props.location.id,
    headers
  );

  //sucesso
  const handlerSuccess = () => {
    props.handlerClose();
    addFeedback({ message: t("common.changesSaved"), severity: "success" });
    props.updateGet();
  };
  //erro
  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error]);



  useEffect(() => {
    methodsEdit.reset();
  }, [props.handlerClose]);

  const defaultValues = {
    name: props.location.name
  }


  const methodsEdit = useForm<ILocation>({
    mode: "onChange",
    resolver: yupResolver(getNameEditSchema()),
    defaultValues: defaultValues,
  });

  const { control } = methodsEdit;

  const formEditSubmitHandler: SubmitHandler<ILocation> = async (
    payLoad: ILocation
  ) => {
    if (props.location) {
      try {
        await updateLocations({ data: payLoad }); //enviar os dados
        await handlerSuccess();
      } catch { }
    }
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={props.open}
      >
        <DialogTitle>
          <Typography
            component={"span"}
            color="text.black"
            gutterBottom={true}
            variant="h5"
            noWrap
          >
            {t('morphologicalData.locations.locationEdit')}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <FormProvider {...methodsEdit}>
            <form
              id="edit-form"
              onSubmit={methodsEdit.handleSubmit(formEditSubmitHandler)}
            >

              {props.location && (
                <Grid container spacing={2}>


                  <Grid item xs={12}>
                    <RequiredField title={t("morphologicalData.sample.local")} />
                    <InputText
                      control={control}
                      name="name"
                      placeholder={t("morphologicalData.locations.locationPlaceholder")}
                    />
                  </Grid>

                </Grid>
              )}
            </form>
          </FormProvider>
          <RequiredFields allRequired />
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={props.handlerClose}
          >
            {t("common.cancel")}
          </Button>

          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            disabled={!methodsEdit.formState.isValid}
            type={"submit"}
            form={"edit-form"}
          >
            {t("common.save")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
