import { AnyAction, combineReducers } from "@reduxjs/toolkit";
// import { Cookies } from 'react-cookie'
import authReducer from "./slices/authSlice";
import adapterConfiguratorsReducer from "./slices/adapterConfiguratorsSlice";
import mapReducer from "./slices/mapSlice";
import dashboardReducer from "./slices/dashboardSlice";
import appManagementReducer from "./slices/appManagementSlice";
import { Cookies } from 'react-cookie'

const cookies = new Cookies()

const allReducers = {
  auth: authReducer,
  adapterConfigurators: adapterConfiguratorsReducer,
  map: mapReducer,
  dashboard: dashboardReducer,
  app: appManagementReducer,
};

const appReducer = combineReducers(allReducers);

const rootReducer = (
  state: ReturnType<typeof appReducer | any>,
  action: AnyAction
) => {
  if (action.type === "logout") {
    localStorage.clear(); //limpa a localstorage 
    cookies.remove('auth_token')
    // return appReducer(undefined, action);
  }
  if (action.type === "changeClient") {
    const newValuesState = {};
    Object.keys(state).forEach((element) => {
      Object.assign(newValuesState, {
        [element]:
          element === "auth" || element === "app" ? state[element] : undefined,
      });
    });
    state = newValuesState;
  }
  return appReducer(state, action);
};

export default rootReducer;
