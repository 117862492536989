/* eslint-disable react-hooks/exhaustive-deps */
import {
  FormGroup,
  Grid,
  Paper,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { RHookFormCheckboxInputProps } from "components/utils/RHookFormCheckbox";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { arrayIsEmpty } from "utils";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { InputCheckbox } from "@alb/live-lib";
import { IUserPermission } from "interfaces";
interface IUserPermission_ {
  setValue?: any;
  permissions: IUserPermission[];
  edit?: boolean;
  allPermissions?: boolean;
  control?: any;
  methodsAdd?: any;
}

const CRUD = "CRUD";
const CrudName = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  width: theme.spacing(5),
}));
const CrudValue = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(0.5),
  textAlign: "center",
  color: theme.palette.text.secondary,
  width: theme.spacing(5),
  boxShadow: "none",
}));

export default function UserPermissions({
  setValue,
  permissions,
  edit,
  allPermissions,
  control,
}: IUserPermission_): JSX.Element {
  const { t } = useTranslation();


  return (
    <>
      <Grid item xs={12}>
        <Grid container rowGap={2} marginTop={2} columns={{ xs: 12 }}>
          <Grid item xs={12}>
            <>
              <Typography variant="h5" className="bold">
                {t("common.permissions")}
              </Typography>
              {arrayIsEmpty(permissions) && (
                <Typography mt={1} mb={2} variant="body1">
                  {t("user.noUsersPermissions")}
                </Typography>
              )}

              {(permissions && !arrayIsEmpty(permissions)) && (
                <>
                <FormGroup>
                  <Grid container rowGap={1} columns={{ xs: 12 }}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={9}>
                      <Stack direction="row" spacing={1}>
                        {CRUD.split("").map((l, index) => (
                          <Tooltip key={l + '-' + index} title={t(`common.CRUD.${l}`)}>
                            <CrudName>{l}</CrudName>
                          </Tooltip>
                        ))}
                      </Stack>
                    </Grid>
                    {permissions.map((p: IUserPermission, idx: number) => (
                      <Permissions
                        setValue={setValue}
                        control={control}
                        allPermissions={allPermissions}
                        edit={edit}
                        permission={p}
                        key={p.module_id}
                      />
                    ))}
                  </Grid>
                </FormGroup>
                </>
              )}
            </>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

function Permissions(props: {
  setValue?: any;
  permission: IUserPermission;
  edit?: boolean;
  allPermissions?: boolean;
  control?: any;
}) {
  const { permission, edit, allPermissions, control, setValue } = props;


  let inputProps: RHookFormCheckboxInputProps = {
    "data-name": permission.module_id,
    "data-key": "",
  };

  const APICRUDValues = permission.value.split('').map((n:string) => n === '1'? true : false)

  const [isChecked, setIsChecked] = useState<boolean[]>((APICRUDValues)); //armazena estado já com as permissoes iniciais
  const [isCheckedAux, setIsCheckedAux] = useState<boolean[]>([]); //auxiliar para guardar as permissões antes de se selecionar a checkbox do admin/superadmin

  const handleCRUDCheckboxes = (e:ChangeEvent<HTMLInputElement>, i:number) => {
    const CRUDCheck = isChecked.map((value, idx) => idx === i ? !value: value)
    setIsCheckedAux(CRUDCheck)
  }

  useEffect(() => {
    if (allPermissions) {
      setIsChecked(
        new Array('CRUD'.length).fill(allPermissions)
      )
    } else {
      setIsChecked(isCheckedAux.length > 0 ? isCheckedAux : APICRUDValues)
    }
  }, [allPermissions, isCheckedAux])

  return (
    <>
      <Grid item xs={3}>
        <Typography variant="h6">{permission.module_name}</Typography>
      </Grid>
      <Grid item xs={9}>
        <Stack direction="row" spacing={1}>
          {CRUD.split("").map((l: string, i: number) => (
            <Fragment key={l + '-'+ i}>
              <>
                {edit && (
                    <InputCheckbox
                      key={`edit_${permission.module_id}_${l}`}
                      name={`${permission.module_id}_${l}`}
                      checked={isChecked[i]}
                      disabled={allPermissions}
                      control={control}
                      setValue={setValue}
                      onChange={(e) => handleCRUDCheckboxes(e, i)}
                      inputProps={
                        {
                          ...inputProps,
                          "data-key": l,
                        } as unknown as RHookFormCheckboxInputProps
                      }
                    />
                )}
              </>
              {!edit && (
                <CrudValue key={`view_${permission.module_id}_${l}`}>
                  {permission.value.toString().slice(i, i + 1) === "1" ? (
                    <CheckCircleOutlineIcon color="success" />
                  ) : (
                    <HighlightOffIcon color="error" />
                  )}
                </CrudValue>
              )}
            </Fragment>
          ))}
        </Stack>
      </Grid>
    </>
  );
}
