/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";

//NOTA: Utilizar este hook em vez do useEffect apenas quando o useEffect tem dependências e 
//quando só se pretende que corra quando as mesmas alterarem alterarem.
const useDidMountEffect = (func: any, deps: any) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export default useDidMountEffect;
