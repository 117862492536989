import { InputAutocomplete, InputCheckbox, InputText, RequiredField, useFeedback } from "@alb/live-lib";
// import { yupResolver } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { RequiredFields } from "components/utils/forms/RequiredFields";
import { useCreate, useErrorHandler, useGetAll, useHasPermissions } from "hooks";
// import useFeedback from "hooks/useFeedback";
import { IAccount, IApiResponse, IModule, ISelectOption, IUser, IUserAddForm, IUserPermission } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty, PERMISSIONS } from "utils";
import { getLanguages } from "views/users/languages";
import { getUserAddSchema } from "views/users/validations";

import UserPermissions from "./userPermissions";

export interface IUsersAdd {
  clientID?: string;
  open: boolean;
  invite?: boolean;
  handlerClose: () => void;
  updateList: () => void;
  administration?: boolean;
  permissions: IUserPermission[];
  allModules?: IApiResponse<IModule>;
}

const CRUD = "CRUD";

const UsersAdd = ({
  clientID,
  open,
  invite,
  handlerClose,
  updateList,
  administration,
  permissions,
  allModules,
}: IUsersAdd) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const { sendErrorMessage } = useErrorHandler();
  const { hasPermission } = useHasPermissions();
  const langOptions = getLanguages(t);

  const canAdminister = hasPermission([PERMISSIONS.MANAGEMENT.USERS.CUD]);
  const [accountID, setAccountID] = useState<ISelectOption | null>(null);
  const [accountsOptions, setAccountsOptions] = useState<ISelectOption[]>([]);
  const [adminChecked, setAdminChecked] = useState<boolean>(false);
  const [superAdminChecked, setSuperAdminChecked] = useState<boolean>(false);
  const [typeAdminChecked, setTypeAdminChecked] = useState<boolean>(false);

  //create user
  const {
    loading,
    error,
    refetch: createUser,
  } = useCreate<IUser[]>(ServiceApiUrl.userURL);

  let permitionModule: { [permitionId: string]: boolean } = {};
  permissions?.forEach((permission) => {
    CRUD.split("").forEach((c) => {
      const name = `${permission.module_id}_${c}`;
      const obj = { [name]: false };
      permitionModule = { ...permitionModule, ...obj };
    });
  });

  // get list of accounts
  const { data: allAccounts, refetch: listAccounts } = useGetAll<
    IApiResponse<IAccount>
  >(ServiceApiUrl.accountURL, { client: clientID });

  useEffect(() => {
    if (allAccounts) {
      const options = allAccounts?.data?.map((p: IAccount) => ({
        label: p.name,
        value: p.id,
      }));

      resetField("account", { defaultValue: null });

      setAccountsOptions(options);
      // if (!accountID && !arrayIsEmpty(options)) setAccountID(options[0].value);
      if (!arrayIsEmpty(options)) setAccountID(options[0]);
    }
  }, [allAccounts]);

  const defaultValues = {
    account: accountID,
    client: clientID,
    email: "",
    first_name: "",
    last_name: "",
    is_admin: false,
    is_superadmin: false,
    language: langOptions[0],
    permission: [],
    username: "",
    ...permitionModule,
  };

  const methodsAdd = useForm<IUserAddForm>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(getUserAddSchema()),
  });

  const { control, setValue, resetField } = methodsAdd;

  useEffect(() => {
    if (accountID) {
      resetField("account", { defaultValue: accountID });
    }
  }, [accountID]);

  useEffect(() => {
    if (adminChecked) {
      resetField("is_admin", { defaultValue: true });
    } else {
      resetField("is_admin", { defaultValue: false });
    }
  }, [adminChecked]);


  //create user request
  const formSubmitHandler: SubmitHandler<IUserAddForm> = async (
    payLoad: IUserAddForm
  ) => {
    const _payLoad = preparePayload(payLoad);
    try {
      await createUser({ data: _payLoad });
      await handlerSuccess();
    } catch (error) { }
  };

  function preparePayload(payLoad: IUserAddForm) {
    let _payLoad: IUserAddForm = {
      account: payLoad.account,
      email: payLoad.email,
      first_name: payLoad.first_name,
      last_name: payLoad.last_name,
      is_admin: payLoad.is_admin,
			language: (payLoad.language as ISelectOption).value,
      permission: [],
      username: payLoad.username,
    };
    //campos reservados a utilizadores superAdmin, e se for pela administração
    if (canAdminister && administration) {
      _payLoad.client = clientID;
      _payLoad.is_superadmin = payLoad.is_superadmin;
    }
    const perm: IUserPermission[] = [];
    allModules?.data.forEach((m: IModule) => {
      let value: string[] = [];
      CRUD.split("").forEach((c) => {
        const name = `${m.id}_${c}`;
        const t = (payLoad as unknown as any)[name];
        value.push(t ? "1" : "0");
      });
      if (value.join("") !== "0000") {
        perm.push({
          module_id: m.id,
          value: value.join(""),
        });
      }
    });
    typeAdminChecked ? _payLoad.permission = [] : _payLoad.permission = perm;

    _payLoad.account = (typeof payLoad.account === 'string') ? payLoad.account : payLoad.account?.value || '';

    return _payLoad;
  }

  useEffect(() => {
    setSuperAdminChecked(false);
    setAdminChecked(false);
    methodsAdd.reset();
  }, []);

  const handlerSuccess = () => {
    handlerClose();
    addFeedback({
      message: t("user.userAdded"),
      severity: "success",
    });
    updateList && updateList();
  };
  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error]);

  const onAdminChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setAdminChecked(checked);
    setTypeAdminChecked(checked)

    if (checked) listAccounts({ params: { client: clientID, admin: true } });
    else {
      listAccounts({ params: { client: clientID } });
    }
  };

  const onSuperAdminChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setSuperAdminChecked(checked);
    setAdminChecked(checked);
    setTypeAdminChecked(checked)

    if (checked) listAccounts({ params: { superAdmin: true } });
    else {
      listAccounts({ params: { client: clientID } });
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open} >
      <DialogTitle>
        <Typography
          component={"span"}
          color="text.black"
          gutterBottom={true}
          variant="h5"
          noWrap
        >
          {invite ? t("user.inviteUser") : t("user.addUser")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <>
          {permissions && (
            <>
              <FormProvider {...methodsAdd}>
                <form
                  id="add-user-form"
                  onSubmit={methodsAdd.handleSubmit(formSubmitHandler)}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={!administration ? 12 : 6}>
                      <InputCheckbox
                        control={control}
                        setValue={setValue}
                        name={"is_admin"}
                        labelText={t("user.Administrator")}
                        disabled={superAdminChecked}
                        onChange={onAdminChange}
                      />
                    </Grid>
                    {canAdminister && administration && (
                      <Grid item xs={12} sm={6}>
                        <InputCheckbox
                          control={control}
                          setValue={setValue}
                          name={"is_superadmin"}
                          labelText={t("user.SuperAdministrator")}
                          onChange={onSuperAdminChange}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom color="text.black" variant="h6">
                        {t("user.firstName")}
                      </Typography>
                      <InputText
                        control={control}
                        name="first_name"
                        placeholder={t("user.namePlaceholder")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom color="text.black" variant="h6">
                        {t("user.lastName")}
                      </Typography>
                      <InputText
                        control={control}
                        name="last_name"
                        placeholder={t("user.namePlaceholder")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <RequiredField title={t("user.Username")} />
                      <InputText
                        control={control}
                        name="username"
                        placeholder={t("user.namePlaceholder")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <RequiredField title={t("user.institutionalEmail")} />
                      <InputText
                        control={control}
                        name="email"
                        placeholder={t("user.emailPlaceholder")}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom color="text.black" variant="h6">
                        {t("user.language")}
                        <abbr />
                      </Typography>
                      {langOptions && (
                        <InputAutocomplete
                          name="language"
                          options={langOptions}
                          control={control}
                        />
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      {!arrayIsEmpty(accountsOptions) && (
                        <>
                          <RequiredField title={t("accounts.account")} />
                          {!adminChecked && !superAdminChecked && (
                            <InputAutocomplete
                              name="account"
                              options={accountsOptions}
                              control={control}
                            />
                          )}
                        </>
                      )}
                      {typeAdminChecked && (
                        <Typography color="text.primary" variant="body1" sx={{ mt: 3 }}>
                          {accountID?.label}
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      {!typeAdminChecked && permissions && (
                        <UserPermissions
                          control={control}
                          setValue={setValue}
                          edit
                          permissions={permissions}
                          allPermissions={adminChecked || superAdminChecked}
                        />
                      )}
                    </Grid>
                  </Grid>
                </form>
              </FormProvider>
              <RequiredFields />
            </>
          )}
        </>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handlerClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!methodsAdd.formState.isValid}
          type={"submit"}
          form={"add-user-form"}
        >
          {invite ? t("common.invite") : t("common.add")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UsersAdd;
