import { array, boolean, object, ref, string } from "yup";

import { ILoginCustomTranslations } from "interfaces";

export const InputsLengths = {
  max: {
    email: 254,
    firstName: 150,
    lastName: 150,
    userName: 30,
    password: 20,
  },
  min: {
    userName: 3,
    password: 5,
  },
};

const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export const getUserAddSchema = () =>
  object({
    is_superadmin: boolean(),
    is_admin: boolean(),
    email: string().max(InputsLengths.max.email).email().required(),
    first_name: string().max(InputsLengths.max.firstName),
    last_name: string().max(InputsLengths.max.lastName),
    // account: object()
    // .shape({
    //   label: string(),
    //   value: string(),
    //   disabled: bool().optional(),
    // })
    // .nullable()
    // .required(),
    account: object()
    .shape({
        label: string(),
        value: string()
      })
      .when(['is_admin', 'is_superadmin'], {
      is:  (is_admin: boolean, is_superadmin: boolean) => !is_admin && !is_superadmin,
      then: () => object().nullable().required(),
      otherwise: () => object().nullable().notRequired(),
    }),
    username: string()
      .min(InputsLengths.min.userName)
      .max(InputsLengths.max.userName)
      .required(),
  }).shape({
    scope: array().transform((o, obj )=> {
      return obj
    }).min(1)
  });

export const getUserEditSchema = () =>
  object({
    is_superadmin: boolean(),
    is_admin: boolean(),
    first_name: string().max(InputsLengths.max.firstName),
    last_name: string().max(InputsLengths.max.lastName),
    // account: object()
    // .shape({
    //   label: string(),
    //   value: string(),
    //   disabled: bool().optional(),
    // })
    // .nullable()
    // .when('is_admin', {is: (s: boolean) => {console.log(s); return !s ? true : false }, then: object().nullable().required()})
    // // .when(['is_admin', 'is_superadmin'], {is: (s: boolean, a: boolean) => {console.log(s, a); return }, then: object().nullable().required()})
    // ,
    account: object()
    .shape({
        label: string(),
        value: string()
      })
      .when(['is_admin', 'is_superadmin'], {
      is:  (is_admin: boolean, is_superadmin: boolean) => !is_admin && !is_superadmin,
      then: () => object().nullable().required(),
      otherwise: () => object().nullable().notRequired(),
    }),
    username: string()
      .min(InputsLengths.min.userName)
      .max(InputsLengths.max.userName)
      .required(),
  });

export const getChangePwdSchema = (passwordOneOfMessage: string) =>
  object({
    old_password: string()
      .min(InputsLengths.min.password)
      .max(InputsLengths.max.password)
      .required(),
    new_password: string()
      .min(InputsLengths.min.password)
      .max(InputsLengths.max.password)
      .required(),
    confirmNewPassword: string()
      .min(InputsLengths.min.password)
      .max(InputsLengths.max.password)
      .required()
      .oneOf([ref("new_password")], passwordOneOfMessage),
  });

export const getDefinePwdSchema = (passwordOneOfMessage: string) =>
  object({
    password: string()
      .min(InputsLengths.min.password)
      .max(InputsLengths.max.password)
      .required(),
    confirmPassword: string()
      .min(InputsLengths.min.password)
      .max(InputsLengths.max.password)
      .required()
      .oneOf([ref("password")], passwordOneOfMessage),
  });

export const getForgotPwdFormSchema = () =>
  object({
    email: string().max(InputsLengths.max.email).email().required(),
  });

export const getUserProfileSchema = () =>
  object({
    username: string()
      .min(InputsLengths.min.userName)
      .max(InputsLengths.max.userName)
      .required(),
  });

export const getLoginFormSchema = (
  customTranslations: ILoginCustomTranslations
) =>
  object({
    email: string()
      .max(InputsLengths.max.email)
      .email()
      .required(customTranslations.requiredEmail)
      .matches(emailRegex, customTranslations.matchesEmail),
    password: string()
      .required(customTranslations.requiredPassword)
      .max(InputsLengths.max.password)
      .min(InputsLengths.min.password),
  });

