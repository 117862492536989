import { Loading, TableHeadCell, TableHeadOrder, TableHeadSort } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import AddIcon from "@mui/icons-material/Add";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, Collapse, Grid, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AccountsAdd from "components/utils/accounts/AccountsAdd";
import AccountsDelete from "components/utils/accounts/AccountsDelete";
import { ChipStatus } from "components/utils/ChipStatus";
import { useErrorHandler, useGetAll } from "hooks";
import { useHasPermissions } from "hooks/usePermissions";
import { IAdapterConfigurator, IApiResponse } from "interfaces";
import { IAccount } from "interfaces/IAccount";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { TableCellExpanded, TableExpanded, TableRowExpanded } from "styles/components-styles";
import { arrayIsEmpty, formatDate, formatOrderBy, PERMISSIONS, statusColor } from "utils";
import { ROWS_PER_PAGE } from "utils/pagination";
import { CustomTablePagination } from "utils/pagination/CustomTablePagination";

interface IAccountsListTab {
  filters: { clientID: string };
}
function Row(props: {
  row: IAccount;
  open: boolean;
  onClick: Function;
  actionsClick: {
    deleteAction: Function;
  };
}) {
  const { row, open, onClick, actionsClick } = props;
  const { deleteAction } = actionsClick;
  const { t } = useTranslation();

  // PERMISSIONS
  const { hasPermission } = useHasPermissions();
  const canCUD = hasPermission([PERMISSIONS.ADMINISTRATION.CLIENTS.CUD]);

  const showExpandCell = !arrayIsEmpty(row.adapter_configurators);

  return (
    <>
      <TableRow
        className={clsx({ "no-pointer": !showExpandCell, expanded: open })}
        onClick={() => onClick(open ? "" : row.id)}
      >
        <TableCell sx={{ width: 50, verticalAlign: "middle" }} size="small">
          <IconButton size="small" sx={{padding: 0}}>
             {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="flex-end" gap={1}>
              {row.name}
              {row.admin && (
                <AdminPanelSettingsIcon
                  fontSize="small"
                  color={"primary"}
                  titleAccess={t("accounts.adminLabel")}
                />
              )}
            </Stack>
        </TableCell>
        <TableCell>
          <ChipStatus
            label={row.is_active ? t("common.active") : t("common.inactive")}
            color={
              row.is_active ? statusColor("active") : statusColor("non-active")
            }
          />
        </TableCell>
        <TableCell>
          {formatDate(row.created_at, t("calendar.dateTimeFormatLocal"))}
        </TableCell>
        <TableCell>
          {formatDate(row.updated_at, t("calendar.dateTimeFormatLocal"))}
        </TableCell>

        <TableCell align="right">
            <IconButton
              title={t("common.delete")}
              aria-label="delete"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                if(canCUD) {
                  e.stopPropagation()
                  deleteAction(true, e, row);
                }

              }}
              sx={{padding: 0}}
              disabled = {!canCUD}
            >
              <DeleteIcon />
            </IconButton>
        </TableCell>
      </TableRow>
      {showExpandCell && (
        <TableRowExpanded>
          <TableCellExpanded colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <AccountAdapters adapters={row.adapter_configurators} />
            </Collapse>
          </TableCellExpanded>
        </TableRowExpanded>
      )}
    </>
  );
}

function AccountAdapters(props: { adapters: IAdapterConfigurator[] }) {
  const { adapters } = props;
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h5" className="bold">
        {t("lists.adaptersList")}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("common.name")}</TableCell>
              <TableCell>{t("common.statusLabel")}</TableCell>
              <TableCell>{t("common.createdAt")}</TableCell>
              <TableCell>{t("common.updatedAt")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adapters.map((a: IAdapterConfigurator) => (
              <TableRow key={a.id}>
                <TableCell>{a.name}</TableCell>
                <TableCell>
                  <ChipStatus
                    label={
                      a.is_active ? t("common.active") : t("common.inactive")
                    }
                    color={
                      a.is_active
                        ? statusColor("active")
                        : statusColor("non-active")
                    }
                  />
                </TableCell>
                <TableCell>
                  {formatDate(a.created_at, t("calendar.dateTimeFormatLocal"))}
                </TableCell>
                <TableCell>
                  {formatDate(a.updated_at, t("calendar.dateTimeFormatLocal"))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function AccountsListTab({ filters }: IAccountsListTab) {
  const { sendErrorMessage } = useErrorHandler();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<TableHeadOrder>("asc");
  const [orderBy, setOrderBy] = useState<string>("name");

  const {
    data: accounts,
    loading,
    error,
    refetch,
  } = useGetAll<IApiResponse<IAccount>>(ServiceApiUrl.accountURL, {
    client: filters.clientID,
    page: page,
    items: ROWS_PER_PAGE,
    order_by: formatOrderBy(order, orderBy),
  });

  //erro
  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error]);

  //para fazer nova chamada dos accounts
  const updateAccounts = () => {
    refetch();
  };

  const [openRow, setOpenRow] = useState("");

  //erro
  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error]);

  const { hasPermission } = useHasPermissions();
  const canList = hasPermission([PERMISSIONS.ADMINISTRATION.VIEW]);
  const canCUD = hasPermission([PERMISSIONS.ADMINISTRATION.CLIENTS.CUD]);

  const [selectedAccount, setSelectedAccount] = useState<IAccount>();
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  //Trocar página da listagem
  const handleChangePage = (
    newPage: number
  ) => {
    setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
  };

  const [openDialogAddAccount, setOpenDialogAddAccount] = useState(false);
  const onAddClick = () => {
    setOpenDialogAddAccount(true);
  };

  const tableHeads: TableHeadCell[] = [
    {
      id: "expand_cell",
      label: "",
      notSortable: true,
    },
    {
      id: "name",
      label: t("common.name"),
    },
    {
      id: "is_active",
      label: t("common.statusLabel"),
    },
    {
      id: "created_at",
      label: t("common.createdAt"),
    },
    {
      id: "updated_at",
      label: t("common.updatedAt"),
    },
    {
      id: "action_cell",
      label: "",
      notSortable: true,
    },
  ];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div>
      <Grid
        container
        mt={1}
        alignItems="center"
        direction="row"
        sx={{ justifyContent: "end" }}
      >
        <Grid item xs={true}>
        <Typography variant="h3">
            {t("lists.accountsList")}
          </Typography>
        </Grid>
        {canCUD && (
          <Grid item>
            <Button
              color="primary"
              onClick={onAddClick}
              variant="contained"
              size="medium"
              startIcon={<AddIcon />}
            >
              {t("common.add")}
            </Button>
          </Grid>
        )}
      </Grid>
      <Loading show={loading} />

      {error && (
        <Typography mt={4} mb={2} variant="body1">
          {t("accounts.errorLoadingAccounts")}
        </Typography>
      )}

      {arrayIsEmpty(accounts?.data) && !error && !loading && (
        <Typography mt={4} mb={2} variant="body1">
          {t("accounts.noAccounts")}
        </Typography>
      )}

      {accounts && !arrayIsEmpty(accounts?.data) && !loading && canList && (
        <>
          <TableContainer>
            <TableExpanded>
              <TableHeadSort
                headCells={tableHeads}
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
              />

              <TableBody>
                  {accounts.data.map((a, i) =>
                    <Row
                      key={i}
                      row={a}
                      open={a.id === openRow}
                      onClick={(id: string) => setOpenRow(id)}
                      actionsClick={{deleteAction: (id: string) => {
                        setSelectedAccount(a);
                        setOpenDialogDelete(true);
                    } }}
                  />)
                  }
              </TableBody>

            </TableExpanded>
          </TableContainer>

          <CustomTablePagination
            count={accounts?.totalCount}
            page={page}
            totalPages={accounts?.totalPages}
            onPageChange={handleChangePage}

          />

        </>
      )}
      {canCUD && openDialogAddAccount && (
        <AccountsAdd
          administration
          clientID={filters.clientID}
          open={openDialogAddAccount}
          handlerClose={() => setOpenDialogAddAccount(false)}
          updateList={updateAccounts}
        />
      )}
      {canCUD && selectedAccount && openDialogDelete && (
        <AccountsDelete
          account={selectedAccount}
          open={openDialogDelete}
          handlerClose={() => setOpenDialogDelete(false)}
          updateList={updateAccounts}
        />
      )}
    </div>
  );
}

export default AccountsListTab;
