import { useCallback } from "react";

import { SidebarContext } from "context/sidebar";
import useLocalStorage from "hooks/useLocalStorage";
import { SIDEBARSTATE_KEY } from "utils";

export const SidebarContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  //estado da sidebar, atualizado através do localstorage
  const [isOpen, setIsOpen] = useLocalStorage<boolean>(SIDEBARSTATE_KEY,false);

  const toggle = useCallback( () => {
    setIsOpen((prev) => !prev);    
  },[setIsOpen]);

  const contextValue = {
    isOpen,
    toggle,
  };

  return (
    <SidebarContext.Provider value={contextValue}>
      {children}
    </SidebarContext.Provider>
  );
};
