//material
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCreate } from "hooks";

import { IUser } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { useEffect } from "react";
import { useErrorHandler } from "hooks/useErrorHandler";
import { LoadingButton } from "@mui/lab";
import { useFeedback } from "@alb/live-lib";

interface IUserInviteProps {
    open: boolean,
    handlerClose: any,
    updateGetUsers: any,
    user: IUser
}

export default function UserInvite({ open, handlerClose, updateGetUsers, user }: IUserInviteProps) {

    //traduções
    const { t } = useTranslation();
    //alert
    const { addFeedback } = useFeedback();


    //########## SERVIÇOS,
    const { loading, error, refetch: inviteUser } = useCreate<IUser[]>(`${ServiceApiUrl.userInvite(user.id)}`);

    //para lançar o erro, caso exista.
    const { sendErrorMessage } = useErrorHandler();


    //sucesso
    const handlerSuccess = () => {
        handlerClose()
        addFeedback({ message: t("user.userInvited"), severity: 'success' })
        updateGetUsers();
    };

    useEffect(() => {
        if (error) {
            sendErrorMessage(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    //apaga utilizador
    const handleInviteUser = async () => {
        try {
            await inviteUser()
            await handlerSuccess();
        }
        catch {
        }
    }


    return (


        <Dialog
            maxWidth="sm"
            open={open}
            onClose={handlerClose}
        >

            <DialogTitle id="alert-dialog-title">
                <Typography component={'span'} color="text.black" gutterBottom={true} variant="h5" sx={{ fontWeight: 'bold' }} noWrap>
                    {t('user.inviteUser')}
                </Typography>
            </DialogTitle>


            <DialogContent>
                <Typography variant="body1">
                    {t('confirmations.userInviteConfirmation', { user: `${user.first_name} ${user.last_name} (${user.username})` })}
                </Typography>
            </DialogContent>

            <DialogActions>

                <Button
                    variant="outlined"
                    color='primary'
                    onClick={handlerClose}>
                    {t('common.cancel')}
                </Button>

                <LoadingButton
                    loading={loading}
                    variant="contained"
                    color='primary'
                    onClick={handleInviteUser}>
                    {t('common.invite')}
                </LoadingButton>

            </DialogActions>

        </Dialog>

    );
}


