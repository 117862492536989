import LiveForgotPassword from "components/auth/ForgotPassword";
import LiveMainLogin from "components/page/MainLogin";

export default function Forgot() {
  return (
    <LiveMainLogin backgroundImage>
      <LiveForgotPassword  />
    </LiveMainLogin>      
  );
}
