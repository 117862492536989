import { getDefaultBoxShadow } from '@alb/live-lib'
import { TabList } from '@mui/lab'
import { styled } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'

const indicatorSize = '3px'
export const ContainedTabs = styled(TabList)(({ theme }) => ({
  '& .MuiTabs-scrollButtons.Mui-disabled': {
    opacity: '0.3'
  },
  borderBottom: `${indicatorSize} solid rgba(0, 0, 0, 0.08);`,
  '& .MuiButtonBase-root.MuiTab-root': {
    width: '200px',
    textTransform: 'none',
    // color: theme.palette.text.primary,
    marginRight: '4px',
    fontSize: '17px',
    '&.Mui-selected': {
      fontWeight: '700',
      color: theme.palette.text.primary,
    },
    '&:last-of-type': {
      marginRight: '0px',
    },
  },
  '& .MuiTabs-indicator': {
    height: indicatorSize,
  },
}))

export const TabPanelContainer = styled(Grid)<any>(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: getDefaultBoxShadow(),
  borderRadius: 12,
  padding: theme.spacing(3),
}))