import { createSlice, current } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";
import { IMapPoint, MapType } from "@alb/live-lib";
import { api as axios } from "providers/AxiosProvider";
import { RootState } from "store/store";
import { ServiceApiUrl } from "services/ServiceApiUrl";

export interface MapState {
  markers: IMapPoint[];
  markersInCluster: number[];
  markerDetails: any;
  drawingMap: boolean;
  openList: boolean;
  drawnCoords: { type: string; coordinates: any; iconPostion?: any } | null; //armazena temporariamente as coordenadas do desenho feito pelo utilizador para selecionar a área de um evento, na criação de um evento.
  detailsLoading: boolean;
  detailsError: boolean;
  filtersTabOpen: boolean;
  filterMenuMarkers: string;
  bounds: number[][];
  shapeBounds: number[][];
  selectedMarkerType: string;
  selectedMapType: MapType | null;
  highlightedListMarker: IMapPoint | null;
  sidebarIsOpen: boolean;
  fitBounds: boolean;
}

const initialState: MapState = {
  markers: [],
  markersInCluster: [],
  markerDetails: null,
  drawingMap: false,
  openList: false,
  drawnCoords: null,
  detailsLoading: true,
  detailsError: false,
  filtersTabOpen: false,
  filterMenuMarkers: "",
  bounds: [],
  shapeBounds: [],
  selectedMarkerType: "all",
  selectedMapType: null,
  highlightedListMarker: null,
  sidebarIsOpen: false,
  fitBounds: false,
};

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setMarkers: (state, action) => {
      state.markers = action.payload;
    },
    setDrawingMap: (state, action: PayloadAction<boolean>) => {
      state.drawingMap = action.payload;
    },
    setDrawnCoords: (state, action: PayloadAction<any>) => {
      //store drawn coordinates
      state.drawnCoords = action.payload;
    },
    toggleList: (state, action: PayloadAction<boolean>) => {
      state.openList = action.payload;
    },
    setMarkersInCluster: (state, action) => {
      state.markersInCluster = action.payload;
    },
    setMarkerDetails: (state, { payload }) => {
      state.detailsLoading = payload.loading;
      state.detailsError = payload.error;
      state.markerDetails = payload.data;
    },
    clearDetails: (state, { payload }) => {
      state.detailsLoading = true;
      state.markerDetails = payload;
    },
    setFiltersTabOpen: (state, action: PayloadAction<boolean>) => {
      state.filtersTabOpen = action.payload;
    },
    setFilterMenuMarkers: (state, action) => {
      state.filterMenuMarkers = action.payload;
    },
    setBounds(state, action) {
      var sameValue =
        current(state.bounds).length === action.payload.length &&
        current(state.bounds).every(
          (o: any, i) =>
            Object.keys(o).length === Object.keys(action.payload[i]).length &&
            Object.keys(o).every((k) => o[k] === action.payload[i][k])
        );
      //se os bounds novos forem diferentes dos antigos, atualiza o estado dos bounds
      if (!sameValue) {
        state.bounds = action.payload;
      }
    },
    setShapeBounds(state, action) {
      state.shapeBounds = action.payload;
    },
    setSelectedMarkerType(state, action) {
      state.selectedMarkerType = action.payload;
    },
    setSelectedMapType(state, action) {
      state.selectedMapType = action.payload;
    },
    setHighlightedListMarker(state, action) {
      state.highlightedListMarker = action.payload;
    },
    setSidebarIsOpen(state, action) {
      state.sidebarIsOpen = action.payload;
    },
    setFitBounds(state, action) {
      state.fitBounds = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setMarkers,
  setMarkersInCluster,
  setDrawingMap,
  setDrawnCoords,
  toggleList,
  setMarkerDetails,
  clearDetails,
  setFiltersTabOpen,
  setFilterMenuMarkers,
  setBounds,
  setShapeBounds,
  setSelectedMarkerType,
  setSelectedMapType,
  setHighlightedListMarker,
  setSidebarIsOpen,
  setFitBounds,
} = mapSlice.actions;

// api async requests
export enum TypeMarkerInfo {
  device = "devices",
}
export const getMarkerDetails =
  (
    id: string,
    type: TypeMarkerInfo,
    publicAPI: any = { public: false, client: null }
  ): any =>
  async (dispatch: any) => {
    dispatch(clearDetails(null));
    try {
      const valueofType =
        Object.values(TypeMarkerInfo)[
          Object.keys(TypeMarkerInfo).indexOf(type)
        ];
      let res;
      const url = `${ServiceApiUrl[valueofType]}/${id}`;
      res = await axios.get(url);
      if (res && res.status === 200) {
        dispatch(
          setMarkerDetails({
            data: { typeMarker: type, ...res.data },
            loading: false,
            error: false,
          })
        );
      } else {
        dispatch(
          setMarkerDetails({
            data: null,
            loading: false,
            error: true,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        setMarkerDetails({
          data: null,
          loading: false,
          error: true,
        })
      );
      throw new Error(err);
    }
  };

// Selectors/Getters
export const selectOpenList = (state: RootState) => state.map.openList;
export const selectMarkers = (state: RootState) => state.map.markers;
export const selectMarkersInCluster = (state: RootState) =>
  state.map.markersInCluster;
export const selectDrawingMap = (state: RootState) => state.map.drawingMap;
export const selectCoords = (state: RootState) => state.map.drawnCoords;
export const selectMarkerDetails = (state: RootState) =>
  state.map.markerDetails;
export const selectDetailsLoading = (state: RootState) =>
  state.map.detailsLoading;
export const selectDetailsError = (state: RootState) => state.map.detailsError;
export const selectFiltersTabOpen = (state: RootState) =>
  state.map.filtersTabOpen;
export const getFilterMenuMarkers = (state: RootState) =>
  state.map.filterMenuMarkers;
export const getMapBounds = (state: RootState) => state.map.bounds;
export const getShapeBounds = (state: RootState) => state.map.shapeBounds;
export const getSelectedMarkerType = (state: RootState) =>
  state.map.selectedMarkerType;
export const getSelectedMapType = (state: RootState) =>
  state.map.selectedMapType;
export const getHighlightedListMarker = (state: RootState) =>
  state.map.highlightedListMarker;
export const getSidebarIsOpen = (state: RootState) => state.map.sidebarIsOpen;
export const getFitBounds = (state: RootState) => state.map.fitBounds;
export default mapSlice.reducer;
