/* eslint-disable react-hooks/exhaustive-deps */
import { Calendar, dateTimeFormat, Loading, useFeedback } from "@alb/live-lib";
import { Card, CardContent, Grid, SelectChangeEvent, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { subDays } from "date-fns";
import { YAXisComponentOption } from "echarts";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { defaultChartOptions } from "components/charts/ChartDefaultOptions";
import { IReactECharts, LiveECharts as StackedLineChart } from "components/charts/ReactECharts";
import { useGetAll } from "hooks";
import { IStream } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { selectDeviceRealTime } from "store/slices/adapterConfiguratorsSlice";
import { formatDate } from "utils/date";
import { formatNumber } from "utils/number";

import Download from "./download";
import SelectsStreamsChart from "./SelectsStreamsChart";

interface IStreamsAxis {
  streamsChartLeftAxis: IStream[];
  streamsChartRightAxis: IStream[];
}
interface ISelectedItemsAxis {
  leftAxisSelectedItems: string[];
  rightAxisSelectedItems: string[];
}

const StreamsChartAnalysis = ({ selectedDeviceInfo, streamsChart }: any) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { addFeedback } = useFeedback();
  
  const {
    loading: isLoading,
    error,
    refetch,
  } = useGetAll(
    `${ServiceApiUrl.adapterConfigurators}/${selectedDeviceInfo?.adapter_configurators[0].id}/values`,
    undefined,
    {
      manual: true,
    }
  );

  function rangeDefault() {
    let dates = {
      start: new Date(),
      end: new Date(),
    };
    // }
    dates.start = new Date(subDays(new Date(), 7).setHours(0, 0, 0));
    dates.end = new Date();
    return dates;
  }
  const [startDate, setStartDate] = useState<Date>(rangeDefault().start);
  const [endDate, setEndDate] = useState<Date>(rangeDefault().end);
  const [dataChart, setDataChart] = useState<any[]>([]);
  const [chartOptions, setChartOptions] = useState<IReactECharts["option"]>({});

  // SSE - adicionar valores em real time no gráfico
  const deviceRealTime = useSelector(selectDeviceRealTime);
  useEffect(() => {
    if (
      dataChart.length > 0 &&
      selectedDeviceInfo.external_id === deviceRealTime.external_id
    ) {
      const newValues = {
        collect_date: deviceRealTime.last_read_at,
        collection_date: formatDate(
          deviceRealTime.last_read_at,
          t("calendar.dateTimeEndpointsChart")
        ),
        device_id: deviceRealTime.external_id,
      };
      dataChart.push(newValues);
    }
  }, [deviceRealTime]);

  //guarda as streams que estão em cada eixo no gráfico
  const [streamsAxis, setStreamsAxis] = useState<IStreamsAxis>({
    streamsChartLeftAxis: [],
    streamsChartRightAxis: [],
  });
  //guarda as streams selecionadas nos selects
  const [selectedItemsAxis, setSelectedItemsAxis] =
    useState<ISelectedItemsAxis>({
      leftAxisSelectedItems: [],
      rightAxisSelectedItems: [],
    });

  // quando se faz o click no botão aplicar do calendar faz um novo pedido
  const selectedRangeDates = (dates: any) => {
    setStartDate(dates.startDate);
    setEndDate(dates.endDate);
    const params = {
      external_id: selectedDeviceInfo.external_id,
      from: dateTimeFormat(dates.startDate),
      to: dateTimeFormat(dates.endDate),
    };
    sendRequestStreamsChart(params);
  };
  useEffect(() => {
    if (streamsChart) {
      setChartOptions({});
      setStreamsAxis({ streamsChartLeftAxis: [], streamsChartRightAxis: [] });
      setSelectedItemsAxis({
        leftAxisSelectedItems: [],
        rightAxisSelectedItems: [],
      });
    }
  }, [streamsChart]);

  const sendRequestStreamsChart = async (params: any) => {
    const res = await refetch({
      params: { ...params },
    });
    setDataChart(res.data.object || []);
  };

  useEffect(() => {
    if (error) {
      addFeedback({ message: t("errors." + error), severity: "error" });
    }
  }, [error]);

  // sempre que o device altera vai buscar os novos values e dar reset aos dados mostrados no gráfico
  useEffect(() => {
    setStreamsAxis({ ...streamsAxis, streamsChartRightAxis: [] });
    setSelectedItemsAxis({ ...selectedItemsAxis, rightAxisSelectedItems: [] });
    setDataChart([]);

    const params = {
      external_id: selectedDeviceInfo.external_id,
      from: dateTimeFormat(startDate),
      to: dateTimeFormat(endDate),
    };
    sendRequestStreamsChart(params);

    //o primeiro eixo por defeito já vai ter uma stream selecionada, a primeira
    setSelectedItemsAxis({
      ...selectedItemsAxis,
      leftAxisSelectedItems: [streamsChart[0].id],
    });
    //por defeito mostra a primeira stream no gráfico
    setStreamsAxis({ ...streamsAxis, streamsChartLeftAxis: [streamsChart[0]] });
  }, [selectedDeviceInfo]);

  //quando o dataChart ou alguma das streams alterar, recarrega o gráfico
  useEffect(() => {
    if (
      streamsAxis.streamsChartLeftAxis.length > 0 ||
      streamsAxis.streamsChartRightAxis.length > 0
    ) {
      addChartOptions();
    }
  }, [
    dataChart,
    dataChart.length,
    streamsAxis.streamsChartLeftAxis,
    streamsAxis.streamsChartRightAxis,
  ]);

  //controla o valor selecionado no select
  const handleStateSelectChange = (
    event: SelectChangeEvent<string[]>,
    setOpenSelect: Dispatch<SetStateAction<boolean>>,
    axisSide: string
  ) => {
    let items: string = "";
    let axis: string = "";
    let axisChartStreams: IStream[] = [];

    if (axisSide === "left") {
      items = Object.keys(selectedItemsAxis)[0];
      axis = Object.keys(streamsAxis)[0];
      axisChartStreams = streamsAxis.streamsChartLeftAxis;
    } else if (axisSide === "right") {
      items = Object.keys(selectedItemsAxis)[1];
      axis = Object.keys(streamsAxis)[1];
      axisChartStreams = streamsAxis.streamsChartRightAxis;
    }
    if (items !== "" && axis !== "") {
      setSelectedItemsAxis({ ...selectedItemsAxis, [items]: [] });

      let selectedStreams: IStream[] | undefined = [];
      //se não existirem valores selecionados, coloca os array dos valores para o gráfico, vazio
      if (event.target.value.length === 0) {
        setOpenSelect(false); //fecha o select

        setStreamsAxis({ ...streamsAxis, [axis]: [] });
        return;
      }

      //para obter o conteudo completo das streams, e não só o id
      selectedStreams = streamsChart?.filter((s: any) =>
        event.target.value.includes(s.id)
      );

      //se só existir uma stream no gráfico
      if (axisChartStreams.length === 1) {
        //ao selecionar uma nova stream vai verificar se as unidades das duas streams são diferentes.
        let streamWithDiffUnit = selectedStreams?.find(
          (s: IStream) => s.unit !== axisChartStreams[0].unit
        );
        //se existir uma diferente stream com unidade diferente, coloca a que foi adicionada em segundo, troca
        if (streamWithDiffUnit && selectedStreams) {
          setStreamsAxis({
            ...streamsAxis,
            [axis]: [streamWithDiffUnit as IStream],
          }); //coloca no gráfico esta stream, substituindo a outra
          setSelectedItemsAxis({
            ...selectedItemsAxis,
            [items]: [streamWithDiffUnit.id as string],
          }); //seleciona no select
        }
        //se nao existirem diferentes
        else {
          //seleciona a que o utilizador escolheu e adiciona ao gráfico
          if (selectedStreams)
            setStreamsAxis({
              ...streamsAxis,
              [axis]: selectedStreams as IStream[],
            });

          setSelectedItemsAxis({
            ...selectedItemsAxis,
            [items]: event.target.value as string[],
          });
        }
      } else {
        //se já houver mais que uma stream no gráfico, seleciona as que o utilizador escolheu e adiciona-as
        //a ação de adicionar mais do que uma unidade diferente está protegida pelo disabled no menuItems no select
        if (selectedStreams)
          setStreamsAxis({
            ...streamsAxis,
            [axis]: selectedStreams as IStream[],
          });
        setSelectedItemsAxis({
          ...selectedItemsAxis,
          [items]: event.target.value as string[],
        });
      }
    }
  };

  //função que vai adicionar os dados e as opções ao gráfico
  const addChartOptions = useCallback(() => {
    let seriesArray: any[] = [];
    let mapYAxisValues: YAXisComponentOption | YAXisComponentOption[] = [];
    let mapXAxisValues: string | string[] = [];

    if (
      streamsAxis.streamsChartLeftAxis &&
      streamsAxis.streamsChartRightAxis &&
      dataChart.length > 0
    ) {
      //-- X AXIS -- vai buscar as datas das leituras aos objetos
      mapXAxisValues = dataChart.map((v: any) =>
        formatDate(v.collect_date, t("calendar.dateTimeFormatLocal"))
      );
      //se existirem streams selecionadas no primeiro select
      if (streamsAxis.streamsChartLeftAxis.length > 0) {
        //vai adicionar ao array dos dados, as streams do primeiro select
        streamsAxis.streamsChartLeftAxis.forEach((element) => {
          seriesArray = [
            ...seriesArray,
            {
              name:
                streamsAxis.streamsChartLeftAxis && element.unit
                  ? `${t(`streams.${element.name.toLowerCase()}`)} (${
                      element.unit
                    })`
                  : streamsAxis.streamsChartLeftAxis
                  ? `${t(`streams.${element.name.toLowerCase()}`)}`
                  : "",
              data: dataChart.map((s) => s[element?.name]),
              type: "line",
              lineStyle: { width: 1 },
              showSymbol: false,
              step: "",
              areaStyle: null,
            },
          ];
        });

        //vai adicionar o eixo da primeira stream
        mapYAxisValues = [
          {
            name: `${streamsAxis.streamsChartLeftAxis[0].unit}`,
            position: "left",
            type: "value",
            nameLocation: "end",
            nameTextStyle: {
              padding: [0, 30, 0, 0],
            },
            // axisLabel: {
            //   formatter: `{value}`,
            // },
            axisLabel: {
              formatter: (val: any) => formatNumber(val),
            },
            splitLine: {
              lineStyle: {
                color: "#F0F0F0",
              },
            },
            axisLine: {
              lineStyle: {
                color: theme.palette.mode === "light" ? "" : theme.palette.common.white,
              },
            },
          },
        ];
      }

      //se existirem streams selecionadas no segundo select
      if (streamsAxis.streamsChartRightAxis.length > 0) {
        //vai adicionar ao array dos dados, as streams do segundo select
        streamsAxis.streamsChartRightAxis.forEach((element) => {
          seriesArray = [
            ...seriesArray,
            {
              name: `${t(`streams.${element.name.toLowerCase()}`)} (${
                element.unit
              })`,
              yAxisIndex: streamsAxis.streamsChartLeftAxis.length > 0 ? 1 : 0,
              data: dataChart.map((s) => s[element?.name]),
              type: "line",
              showSymbol: false,
              step: "",
              areaStyle: null,
            },
          ];
        });
        //caso existam streams no segundo select, gera o novo eixo
        let YAxisAux: YAXisComponentOption | YAXisComponentOption[];
        YAxisAux = {
          name: `${streamsAxis.streamsChartRightAxis[0].unit}`,
          position: "right",
          nameLocation: "end",
          nameTextStyle: {
            padding: [0, 0, 0, 30],
          },
          type: "value",
          axisLabel: {
            formatter: (val: any) => formatNumber(val),
          },
        };
        //adiciona o novo eixo ao gráfico
        mapYAxisValues = [...mapYAxisValues, YAxisAux];
      }

      //cria as opções do gráfico
      const options: IReactECharts["option"] = {
        ...defaultChartOptions,
        xAxis: {
          ...defaultChartOptions.xAxis,
          ...{
            data: mapXAxisValues,
            axisLine: {
              lineStyle: {
                color: theme.palette.mode === "light" ? "" : theme.palette.common.white,
              },
            },
          },
        },
        yAxis: mapYAxisValues,
        series: seriesArray,
        legend: {
          ...defaultChartOptions.legend,
          ...{
            textStyle: {
              color: theme.palette.text.primary,
              fontFamily: "Altice",
              fontSize: "12px",
            },
          },
        },
        tooltip: {
          ...defaultChartOptions.tooltip,
          ...{
            trigger: "axis",
            formatter: "",
          },
        },
      };
      // //adiciona as opções ao gráfico
      setChartOptions(options);
    }
  }, [
    dataChart,
    streamsAxis.streamsChartLeftAxis,
    streamsAxis.streamsChartRightAxis,
  ]);

  return (
    <Grid container>
      <Grid container item xs={false} sm={12} md={12} mt={4}>
        <Grid item xs={6}>
          <Typography variant="h3" noWrap>
            {t("dashboard.analyticsLabel")}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "end" }}>
          <Calendar
            endDate={endDate}
            labelBtnCancel={t("common.cancel")}
            labelBtnConfirm={t("common.apply")}
            locale={i18n.language}
            onSelectedRange={selectedRangeDates}
            predefinedRanges={{
              labelLast30Days: t("calendar.last30Days"),
              labelLast7Days: t("calendar.last7Days"),
              position: "left",
            }}
            startDate={startDate}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} mt={3}>
        <Card>
          <CardContent>
            {!isLoading && dataChart.length > 0 && (
              <Download
                selectedDeviceInfo={selectedDeviceInfo}
                startDate={startDate}
                endDate={endDate}
              />
            )}
            <Box p={5}>
              <Loading show={isLoading} />
              {!isLoading && dataChart.length === 0 && (
                <Typography variant="body1" align="center">
                  {t("common.noData")}
                </Typography>
              )}
              {!isLoading && dataChart.length > 0 && (
                <>
                  <SelectsStreamsChart
                    allStreams={streamsChart}
                    streamsAxis={streamsAxis}
                    setStreamsAxis={setStreamsAxis}
                    selectedItemsAxis={selectedItemsAxis}
                    setSelectedItemsAxis={setSelectedItemsAxis}
                    handleStateSelectChange={handleStateSelectChange}
                  />

                  {chartOptions &&
                  (streamsAxis?.streamsChartLeftAxis?.length > 0 ||
                    streamsAxis?.streamsChartRightAxis?.length > 0) ? (
                    <StackedLineChart option={chartOptions} />
                  ) : (
                    <Typography mt={4} variant={"body1"}>
                      {t("dashboard.selectAtLeastOneStream")}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default StreamsChartAnalysis;
