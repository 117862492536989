import { Grid } from '@mui/material';
import React from 'react'

const MapEntiyInfoEmpty = () => {
  
  return (
    <Grid item xs={12} marginTop={2} >
      {/* <!-- EMPTY -->       */}
    </Grid>
  )
}

export default MapEntiyInfoEmpty