import { ConfirmDialog, useFeedback } from "@alb/live-lib";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useDelete } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { IFlowering } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { getSelectedModule } from "utils/permissions/getSelectedModule";

export default function FloweringFruitingDelete(props: any) {

	const { t } = useTranslation();
	const { addFeedback } = useFeedback();
	const { sendErrorMessage } = useErrorHandler();
	const auth = useSelector(getUser)
	const moduleID = auth && getSelectedModule(auth, 'dados_morfologicos')
	const headers = {
		headers: {
			'MODULE-ID': moduleID
		}
	}

	const { loading, error, refetch: deleteFlowering } = useDelete<IFlowering[]>(ServiceApiUrl.floweringURL, props.flowering.id, headers);

	//sucesso
	const handlerSuccess = () => {
		props.handlerClose()
		addFeedback({ message: t("morphologicalData.floweringFruit.floweringFruitingDeleted"), severity: 'success' })
		props.updateGetFlowering();
	};
	//erro
	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error]);


	//apaga Flowering
	const handleDeleteFlowering = async () => {
		try {
			await deleteFlowering()
			await handlerSuccess();
		}
		catch {
		}
	}

	return (
		<ConfirmDialog
			open={props.open}
			type="error"
			title={t("morphologicalData.floweringFruit.deletefloweringFruiting")}
			message={t('morphologicalData.floweringFruit.floweringFruitingDeleteConfirmation')}
			actionConfirmText={t("common.delete")}
			actionCancelText={t("common.cancel")}
			loading={loading}
			onCancel={props.handlerClose}
			onConfirm={handleDeleteFlowering}
		/>
	);
}


