import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/Edit";
/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useErrorHandler, useGetAll } from "hooks";
import { useHasPermissions } from "hooks/usePermissions";
import { IApiResponse, ISample } from "interfaces";
import { IFlowering } from "interfaces/IFlowering";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty, convertMmToCm, formatDate, PERMISSIONS } from "utils";
import FloweringFruitingAdd from "views/morphologicaldata/floweringFruiting/addFloweringFruiting";
import FloweringFruitingDelete from "views/morphologicaldata/floweringFruiting/deleteFloweringFruiting";
import FloweringFruitingEdit from "views/morphologicaldata/floweringFruiting/editFloweringFruiting";
import { getSelectedModule } from "utils/permissions/getSelectedModule";
import { ROWS_PER_PAGE } from "utils/pagination";
import { CustomTablePagination } from "utils/pagination/CustomTablePagination";
import { getUser } from "store/slices/authSlice";
import { useSelector } from "react-redux";
import { Loading } from "@alb/live-lib";
interface FloweringFruitingProps {
	sample: ISample;
}

function FloweringFruiting(props: FloweringFruitingProps) {
	const { sample, ...other } = props;
	const { t } = useTranslation();
	const { sendErrorMessage } = useErrorHandler();

	const [page, setPage] = useState(1);
	const [openDialogAddFF, setOpenDialogAddFF] = useState(false);
	const [openDialogDelete, setOpenDialogDelete] = useState(false);
	const [floweringSelected, setFloweringSelected] = useState<IFlowering>();
	const [openDialogEdit, setOpenDialogEdit] = useState(false);
	const auth = useSelector(getUser);
	const moduleID = auth && getSelectedModule(auth, "dados_morfologicos");
	const headers = {
		headers: {
			"MODULE-ID": moduleID,
		},
	};

	//get floweringFruiting
	const {
		data: floweringFruiting,
		loading,
		error,
		refetch,
	} = useGetAll<IApiResponse<IFlowering>>(
		ServiceApiUrl.floweringURL,
		{
			sample: sample.id,
			page,
			items: ROWS_PER_PAGE,
		},
		undefined,
		headers
	);

	//erro
	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);

	const updateFlowering = () => {
		refetch();
	};

	const handlerDialogAddFFState = () => {
		setOpenDialogAddFF(!openDialogAddFF);
	};
	const onAddClick = (event: React.MouseEvent<HTMLElement>) => {
		setOpenDialogAddFF(true);
	};

	//atualiza o estado do modal de remover
	const handlerDialogDeleteState = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		flowering: IFlowering
	) => {
		if (open === true) {
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setFloweringSelected(flowering);
		}
		setOpenDialogDelete(!openDialogDelete);
	};

	//atualiza o estado do modal de editar
	const handlerDialogEditState = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		flowering: IFlowering
	) => {
		if (open === true) {
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setFloweringSelected(flowering);
		}
		setOpenDialogEdit(!openDialogEdit);
	};

	//Trocar página da listagem
	const handleChangePage = (newPage: number) => {
		setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
	};

	const { hasPermission } = useHasPermissions();
	const canList = hasPermission([
		PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.VIEW,
	]);
	const canAdd = hasPermission([
		PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.ADD,
	]);
	const canDelete = hasPermission([
		PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.DELETE,
	]);
	const canEdit = hasPermission([
		PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.EDIT,
	]);

	return (
		<div {...other}>
			<Grid
				container
				mt={1}
				alignItems="center"
				direction="row"
				sx={{ justifyContent: "end" }}
			>
				<Grid item xs={true}>
					<Typography variant="h3">
						{t("morphologicalData.floweringFruit.floweringFruiting")}
					</Typography>
				</Grid>
				{canAdd && (
					<Grid item>
						<Button
							color="primary"
							onClick={onAddClick}
							variant="contained"
							size="medium"
							startIcon={<AddIcon />}
						>
							{t("common.add")}
						</Button>
					</Grid>
				)}
			</Grid>
			<Loading show={loading} />

			{arrayIsEmpty(floweringFruiting?.data) && !loading && canList && (
				<Typography mt={4} mb={2} variant="body1">
					{t("morphologicalData.noRecordsYet")}
				</Typography>
			)}
			{floweringFruiting &&
				!arrayIsEmpty(floweringFruiting?.data) &&
				!loading && (
					<>
						<TableContainer>
							<Table
								sx={{ minWidth: 650, marginTop: 5 }}
								aria-label="FloweringFruiting"
							>
								<TableHead>
									<TableRow>
										<TableCell>{t("morphologicalData.date")}</TableCell>
										<TableCell>{t("morphologicalData.responsible")}</TableCell>
										<TableCell>
											{t("morphologicalData.floweringFruit.floweringTime")}
										</TableCell>
										<TableCell>
											{t("morphologicalData.floweringFruit.numberFruits")}
										</TableCell>
										<TableCell>
											{t("morphologicalData.floweringFruit.peduncleLength")}
										</TableCell>
										<TableCell />
									</TableRow>
								</TableHead>
								<TableBody>
									{floweringFruiting?.data.map((f: IFlowering, index: number) => (
										<TableRow
											// onClick={}
											key={f.id}
											sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
										>
											<TableCell>
												{formatDate(
													f.registration_date,
													t("calendar.dateFormat")
												)}
											</TableCell>
											<TableCell>{f.user.username}</TableCell>
											<TableCell>{`${f.flowering_days} ${t(
												"common.days"
											)}`}</TableCell>
											<TableCell>{f.bunch_fruits_number}</TableCell>
											<TableCell>{convertMmToCm(f.stem_length)} cm</TableCell>
											<TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
												{canEdit && (
													<IconButton
														title={t("common.edit")}
														onClick={(
															e: React.MouseEvent<HTMLButtonElement, MouseEvent>
														) => handlerDialogEditState(true, e, f)}
														sx={{ padding: 0 }}
													>
														<EditIcon />
													</IconButton>
												)}
												{canDelete && (
													<IconButton
														title={t("common.delete")}
														aria-label="delete"
														onClick={(
															e: React.MouseEvent<HTMLButtonElement, MouseEvent>
														) => handlerDialogDeleteState(true, e, f)}
														sx={{ padding: 0 }}
													>
														<DeleteIcon />
													</IconButton>
												)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						<CustomTablePagination
							count={floweringFruiting?.totalCount}
							page={page}
							totalPages={floweringFruiting?.totalPages}
							onPageChange={handleChangePage}
						/>
					</>
				)}
			{openDialogAddFF && (
				<FloweringFruitingAdd
					data={sample}
					open={openDialogAddFF}
					onClose={handlerDialogAddFFState}
					onSuccess={updateFlowering}
				/>
			)}
			{floweringSelected && (
				<>
					{openDialogDelete && (
						<FloweringFruitingDelete
							flowering={floweringSelected}
							open={openDialogDelete}
							handlerClose={handlerDialogDeleteState}
							updateGetFlowering={updateFlowering}
						/>
					)}
					{openDialogEdit && (
						<FloweringFruitingEdit
							flowering={floweringSelected}
							open={openDialogEdit}
							handlerClose={handlerDialogEditState}
							updateGetFlowering={updateFlowering}
						/>
					)}
				</>
			)}
		</div>
	);
}

export default FloweringFruiting;
