/* eslint-disable react-hooks/exhaustive-deps */
import { LayoutContext, Loading, TableHeadCell, TableHeadOrder, TableHeadSort } from "@alb/live-lib";
import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ChipStatus } from "components/utils/ChipStatus";
import { useErrorHandler, useGetAll } from "hooks";
import { IApiResponse } from "interfaces";
import { IAdapterConfigurator } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatDate, formatOrderBy, statusColor } from "utils";
import { ROWS_PER_PAGE } from "utils/pagination";
import { CustomTablePagination } from "utils/pagination/CustomTablePagination";
import { formatRoutePath } from "utils/routePath";

import FiltersSection from "./FiltersSection";

export default function Gateways() {
	const { t } = useTranslation();
	const { sendErrorMessage } = useErrorHandler();

	const [page, setPage] = useState(1);

	const [order, setOrder] = useState<TableHeadOrder>("asc");
	const [orderBy, setOrderBy] = useState<string>("name");

	const [selectedGateway, setSelectedGateway] =
		useState<IAdapterConfigurator>();

	const [filterSearch, setFilterSearch] = useState<string>("");
	const [filterStatus, setFilterStatus] = useState<string>("");

	const {
		data: gateways,
		loading,
		error,
	} = useGetAll<IApiResponse<IAdapterConfigurator>>(
		ServiceApiUrl.adapterListURL,
		{
			page: page,
			items: ROWS_PER_PAGE,
			order_by: formatOrderBy(order, orderBy),
			active: filterStatus,
			contains: filterSearch.length >= 3 ? filterSearch : "",
		}
	);

	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);

	useEffect(() => {
		if (gateways?.data) {
			let chosen = gateways?.data.filter((g) => g.id === selectedGateway?.id);
			if (chosen) setSelectedGateway(chosen[0]);
		}
	}, [gateways?.data]);

	const handleChangePage = (
		newPage: number
	) => {
		setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
	};

	const location = useLocation();
	const navigate = useNavigate();
	let params = useParams();

	const handleViewClick = (
		event: React.MouseEvent<HTMLElement>,
		id: string
	) => {
		const path = formatRoutePath(location, params, { id });
		navigate(path);
	};

	const tableHeads: TableHeadCell[] = [
		{
			id: "name",
			label: t("adapters.name"),
		},
		{
			id: "is_active",
			label: t("adapters.status"),
		},
		{
			id: "domain",
			label: t("adapters.domain"),
		},
		{
			id: "updated_at",
			label: t("adapters.updatedAt"),
		},
	];

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: string
	) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const { addHeader } = useContext(LayoutContext);
	const header = {
		title: t("sidebar.menu.adapters")
	}

	useEffect(() => {
		addHeader(header)
	}, [])

	return (
		<>

			<FiltersSection setPage={setPage} setFilterSearch={setFilterSearch} setFilterStatus={setFilterStatus} />
			<Loading show={loading} />

			{!gateways?.data.length && !loading && (
				<Typography mt={2} variant="body1">
					{t("adapters.thereAreNotAdapters")}
				</Typography>
			)}

			{gateways && gateways?.data.length > 0 && !loading && (
				<>
					<TableContainer>
						<Table>
							<TableHeadSort
								headCells={tableHeads}
								onRequestSort={handleRequestSort}
								order={order}
								orderBy={orderBy}
							/>
							<TableBody>
								{gateways?.data?.map(
									(u: IAdapterConfigurator, index: number) => (
										<TableRow
											onClick={(e) => handleViewClick(e, u.id)}
											key={u.id}
											sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
										>
											<TableCell>{u.name}</TableCell>
											<TableCell>
												<ChipStatus
													label={
														u.is_active
															? t("common.active")
															: t("common.inactive")
													}
													color={
														u.is_active
															? statusColor("active")
															: statusColor("non-active")
													}
												/>
											</TableCell>
											<TableCell>{u.domain}</TableCell>
											<TableCell>
												{formatDate(u.updated_at, t("calendar.dateFormat"))}
											</TableCell>
										</TableRow>
									)
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<CustomTablePagination
						count={gateways?.totalCount}
						page={page}
						totalPages={gateways?.totalPages}
						onPageChange={handleChangePage}

					/>
				</>
			)}
		</>
	);
}
