/* eslint-disable react-hooks/exhaustive-deps */

import { TFunction } from "i18next";
import { EqualityFn } from "react-redux";

export enum MarkersTypeOptions {
  all = "all",
  devices = "devices",
}

export function GetOptions(
  useSelector: <TState = unknown, Selected = unknown>(
    selector: (state: TState) => Selected,
    equalityFn?: EqualityFn<Selected> | undefined
  ) => Selected,
  useGet: any,
  t: TFunction,
  showError: (error: any) => void,
  publicAPI: boolean
) {

  const typeOptions = [
    {
      label: t("map.filter.types.all"),
      value: MarkersTypeOptions.all,
    },
    {
      label: t("map.filter.types.devices"),
      value: MarkersTypeOptions.devices,
    },
  ];

  const devicesStatusOptions = [
    {
      label: t("common.active"),
      value: "True",
    },
    {
      label: t("common.inactive"),
      value: "False",
    },
  ];

  return {
    typeOptions,
    devicesStatusOptions,
    MarkersTypeOptions,
  };
}
