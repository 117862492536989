import { InputAutocomplete, InputText, RequiredField, useFeedback } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { RequiredFields } from "components/utils/forms/RequiredFields";
import { useCreate, useGetAll } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { IAdapterConfigurator, IApiResponse, ISelectOption } from "interfaces";
import { IClient, IClientForm } from "interfaces/IClient";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty } from "utils";

import { getClientSchema } from "./validations";

export interface IClientsAdd {
  open: boolean;
  handlerClose: () => void;
  updateList: () => void;
}

const ClientsAdd = ({ open, handlerClose, updateList }: IClientsAdd) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const { sendErrorMessage } = useErrorHandler();


  //pedido para criar Cliente
  const {
    loading,
    error,
    refetch: createClient,
  } = useCreate<IClient[]>(ServiceApiUrl.clientURL);

  const methodsAdd = useForm<IClientForm>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      client: { name: "", center_point: [] },
      account: { name: "", adapter_configurators: [] },
    },
    resolver: yupResolver(getClientSchema(t("validations.required"))),
  });

  useEffect(() => {
    methodsAdd.reset();
  }, []);

  const [gatewaysOptions, setGatewaysOptions] = useState<ISelectOption[]>([]);

  // get list of gateways
  const { data: allGateways } = useGetAll<IApiResponse<IAdapterConfigurator>>(
    ServiceApiUrl.adapterListURL,
    { all: true }
  );

  useEffect(() => {
    if (allGateways) {
      const options = allGateways?.data?.map((p: IAdapterConfigurator) => ({
        label: p.name,
        value: p.id,
      }));

      setGatewaysOptions(options);
    }
  }, [allGateways]);

  // sucesso
  const handlerSuccess = () => {
    handlerClose(); //fecha modal
    addFeedback({
      message: t("clients.clientAdded"),
      severity: "success",
    });
    updateList && updateList();
  };
  //erro
  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error]);

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IClientForm> = async (
    payLoad: IClientForm
  ) => {
    const idsAdapters = payLoad.account.adapter_configurators.map((element) => {
      return element.value;
    });
    const payLoadAPI = JSON.parse(JSON.stringify(payLoad));
    payLoadAPI.account.adapter_configurators = idsAdapters;
    payLoadAPI.account.admin = true;
    try {
      await createClient({ data: payLoadAPI });
      await handlerSuccess();
    } catch (error) {}
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open}>
      <DialogTitle>
        <Typography
          component={"span"}
          color="text.black"
          gutterBottom={true}
          variant="h5"
          noWrap
        >
          {t("clients.addClient")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <FormProvider {...methodsAdd}>
          <form
            id="add-client-form"
            onSubmit={methodsAdd.handleSubmit(formSubmitHandler)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RequiredField
                  title={t("clients.name")}
                  children={
                    <InputText
                      name="client.name"
                      placeholder={t("clients.namePlaceholder")}
                      control={methodsAdd.control}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h6">
                  {t("clients.centerPoint")}
                </Typography>
                <InputText
                  name="client.center_point"
                  placeholder={t("clients.centerPointPlaceholder")}
                  control={methodsAdd.control}
                />
              </Grid>
              <Grid item xs={12}>
                <fieldset>
                  <legend style={{fontFamily: "Altice"}}>{t("accounts.account")}</legend>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <RequiredField
                        title={t("accounts.name")}
                        children={
                          <InputText
                            name="account.name"
                            placeholder={t("accounts.namePlaceholder")}
                            control={methodsAdd.control}
                          />
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <RequiredField
                        title={t("accounts.adapters")}
                        children={
                          !arrayIsEmpty(gatewaysOptions) && (
                            <InputAutocomplete
                              name="account.adapter_configurators"
                              options={gatewaysOptions}
                              multiple
                              control={methodsAdd.control}
                              required
                            />
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </fieldset>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <RequiredFields />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handlerClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!methodsAdd.formState.isValid}
          type={"submit"}
          form={"add-client-form"}
        >
          {t("common.add")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ClientsAdd;
