import { PERMISSIONS } from "./permissions";

export const userRoles = [
    // PERMISSIONS.USERS.LIST,
    PERMISSIONS.MAP.VIEW,
    PERMISSIONS.DASHBOARD.VIEW,    
    PERMISSIONS.MY_PROFILE.VIEW,
    PERMISSIONS.MY_PROFILE.EDIT,
    PERMISSIONS.DASHBOARD.COMPARATIVE_ANALYSIS.VIEW,
    PERMISSIONS.DASHBOARD.COMPARATIVE_ANALYSIS.ADD,
    PERMISSIONS.ABOUT.VIEW,
    // PERMISSIONS.GATEWAYS.VIEW,
    // PERMISSIONS.GATEWAYS.LIST,
  ];