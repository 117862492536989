/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useUpdate } from "hooks";
import {useFeedback} from "@alb/live-lib";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { useErrorHandler } from "hooks/useErrorHandler";
import { getNameEditSchema } from "../../validations";

import { LoadingButton } from "@mui/lab";
import { ITexture } from "interfaces";
import { getSelectedModule } from "utils/permissions/getSelectedModule";
import { useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";
import { InputText, RequiredField } from "@alb/live-lib";
import { RequiredFields } from "components/utils/forms/RequiredFields";

export default function EditTexture(props: any) {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const { sendErrorMessage } = useErrorHandler();
  const auth = useSelector(getUser)
  const moduleID = auth && getSelectedModule(auth, 'dados_morfologicos')
  const headers = {
    headers: {
      'MODULE-ID': moduleID
    }
  }

  const {
    loading,
    error,
    refetch: updateTextures,
  } = useUpdate<ITexture>(ServiceApiUrl.texturesListURL, props.item.id, headers);

  //sucesso
  const handlerSuccess = () => {
    props.handlerClose();
    addFeedback({ message: t("common.changesSaved"), severity: "success" });
    props.updateGet();
  };
  //erro
  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error]);

  useEffect(() => {
    methodsEdit.reset();
  }, [props.handlerClose]);

  const defaultValues = {
    name: props.item.name
  }

  const methodsEdit = useForm<ITexture>({
    defaultValues: defaultValues,
    mode: "onChange",
    resolver: yupResolver(getNameEditSchema()),
  });

  const { control } = methodsEdit;

  const formEditSubmitHandler: SubmitHandler<ITexture> = async (
    payLoad: ITexture
  ) => {
    if (props.item) {
      try {
        await updateTextures({ data: payLoad }); //enviar os dados
        await handlerSuccess();
      } catch { }
    }
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={props.open}
      >
        <DialogTitle>
          <Typography
            component={"span"}
            color="text.black"
            gutterBottom={true}
            variant="h5"
            noWrap
          >
            {t("morphologicalData.textures.textureEdit")}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <FormProvider {...methodsEdit}>
            <form
              id="edit-form"
              onSubmit={methodsEdit.handleSubmit(formEditSubmitHandler)}
            >
              {props.item && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <RequiredField title={t("morphologicalData.sample.texture")} />
                    <InputText
                      control={control}
                      name="name"
                      placeholder={t("morphologicalData.textures.texturePlaceholder")}
                    />
                  </Grid>
                </Grid>
              )}
            </form>
          </FormProvider>
          <RequiredFields allRequired />
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={props.handlerClose}
          >
            {t("common.cancel")}
          </Button>

          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            disabled={!methodsEdit.formState.isValid}
            type={"submit"}
            form={"edit-form"}
          >
            {t("common.save")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
