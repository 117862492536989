import { ILogin, IRecoverPassword, IAuth } from "interfaces";
import { api } from "providers/AxiosProvider";
import { ServiceApiUrl } from "./ServiceApiUrl";

const AuthService = {  
  
  async Login(payLoad: ILogin) {
    const { status, data} = await api.post<IAuth>(ServiceApiUrl.loginURL, payLoad);    
    return {status, data};
  },
  
  async Logout() {        
    const { status } = await api.post(ServiceApiUrl.logoutURL);
    return {status };
  },

  async RecoverPassword(user:IRecoverPassword) {        
    const { status, data } = await api.post(ServiceApiUrl.recoverPwdURL, user);
    return {status, data };
  },

};

export { AuthService };
