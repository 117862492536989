export interface IDownloadFileProps {
  data: any;
  fileName: string;
  fileType?: string;
}
interface IDownloadFile extends IDownloadFileProps {
  addStartEncodeString?: boolean;
}

const downloadFile = ({
  data,
  fileName,
  fileType,
  addStartEncodeString = false,
}: IDownloadFile) => {
  // Create a blob with the data we want to download as a file
  // const blob = new Blob([data]);
  // const blob = new Blob([data], { type: `${fileType};charset=utf-8;` });

  let blobPart = [data];
  if (addStartEncodeString) blobPart = ["\ufeff", data];

  const blob = new Blob(blobPart, {
    type: `${fileType};charset=utf-8;`,
  });

  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

const downloadCsv = ({ data, fileName, fileType }: IDownloadFileProps) => {
  downloadFile({
    data,
    fileName,
    fileType: fileType || "text/csv",
    addStartEncodeString: true,
  });
};

const downloadXls = ({ data, fileName, fileType }: IDownloadFileProps) => {
  // fileType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // "application/octet-stream", //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
  downloadFile({
    data,
    fileName,
    fileType:
      fileType ||
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    addStartEncodeString: false,
  });
};

const downloadImage = (url: string, name: string) => {
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement("a");
  a.download = name;
  a.href = url;
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export { downloadCsv, downloadXls, downloadImage };
