/* eslint-disable react-hooks/exhaustive-deps */
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";

type TOption = {
  label: string;
  value: string;
};
interface ILiveToogleButton {
  defaultValue?: string;
  firstOption: TOption;
  secondOption: TOption;
  getValue(type: string): void;
}

export default function LiveToogleButton({
  defaultValue,
  firstOption,
  secondOption,
  getValue,
}: ILiveToogleButton) {
  const [view, setView] = useState(
    defaultValue ? defaultValue : firstOption.value
  );

  const handleChangeChartType = (
    event: React.MouseEvent<HTMLElement>,
    newChartOption: string
  ) => {
    if (newChartOption) setView(newChartOption);
  };

  useEffect(() => {
    getValue(view);
  }, [view]);

  return (
    <ToggleButtonGroup value={view} exclusive onChange={handleChangeChartType}>
      <ToggleButton value={firstOption.value} aria-label={firstOption.value}>
        {firstOption.label}
      </ToggleButton>
      <ToggleButton value={secondOption.value} aria-label={secondOption.value}>
        {secondOption.label}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
