/* eslint-disable react-hooks/exhaustive-deps */
import { LayoutContext, Loading, TableHeadCell, TableHeadOrder, TableHeadSort } from "@alb/live-lib";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, ClickAwayListener, Grid, Grow, IconButton, MenuItem, MenuList, Paper, Popper, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ChipStatus } from "components/utils/ChipStatus";
import { useGetAll } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useHasPermissions } from "hooks/usePermissions";
import { IApiResponse } from "interfaces";
import { IClient } from "interfaces/IClient";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { TableLink } from "styles/components-styles";
import { formatDate, formatOrderBy, PERMISSIONS, statusColor } from "utils";
import NoData from "utils/NoData";
import { ROWS_PER_PAGE } from "utils/pagination";
import { CustomTablePagination } from "utils/pagination/CustomTablePagination";
import { formatRoutePath } from "utils/routePath";

import ClientsAdd from "./ClientsAdd";
import ClientsDelete from "./ClientsDelete";
import ClientsEdit from "./ClientsEdit";
import FiltersSection from "./FiltersSection";

const ClientsList = () => {
  //traduções
  const { t } = useTranslation();
  const { sendErrorMessage } = useErrorHandler();
  const { hasPermission } = useHasPermissions();
  const [page, setPage] = useState(1);

  const navigate = useNavigate();
  // params
  let params = useParams();
  const location = useLocation();

  const [order, setOrder] = useState<TableHeadOrder>("asc");
  const [orderBy, setOrderBy] = useState<string>("name");

  const [ filterSearch, setFilterSearch ] = useState<string>("");
  const [ filterStatus, setFilterstatus ] = useState<string>("");

  const [selectedClient, setSelectedClient] = useState<IClient>();

  const options = [
    t("lists.modulesList"),
    t("lists.usersList"),
    t("lists.adaptersList"),
    t("lists.accountsList"),
  ];

  const getParams = () => {
    return {
      page: page,
      items: ROWS_PER_PAGE,
      order_by: formatOrderBy(order, orderBy),
      ...(filterSearch !== "" && { contains: filterSearch }),
			...(filterStatus !== "" && { active: filterStatus }),
    }
  }

  //########## SERVIÇOS
  //get clients
  const {
    data: clients,
    loading,
    error,
    refetch,
  } = useGetAll<IApiResponse<IClient>>(ServiceApiUrl.clientURL, getParams());

  //erro
  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error]);

  //para controlar os modais
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  const [open, setOpen] = useState<boolean[]>();

  // PERMISSIONS
  const canAdd = hasPermission([PERMISSIONS.ADMINISTRATION.CLIENTS.CUD]);
  const canView = hasPermission([PERMISSIONS.ADMINISTRATION.CLIENTS.VIEW]);
  const canDelete = hasPermission([PERMISSIONS.ADMINISTRATION.CLIENTS.CUD]);
  const canEdit = hasPermission([PERMISSIONS.ADMINISTRATION.CLIENTS.CUD]);

  useEffect(() => {
    setOpen(clients?.data?.map((i) => false));
  }, [clients]);

  //para fazer nova chamada dos Clientes
  const updateClients = () => {
    refetch();
  };

  //Trocar página da listagem
  const handleChangePage = (
    newPage: number
  ) => {
    setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
  };

  //para gerar dinamicamente um ref para cada row
  const rowRefs = useRef<any>([]);

  const handleViewClick = (
    event: React.MouseEvent<HTMLElement>,
    id: string
  ) => {
    if (!canView) return;

    const path = formatRoutePath(location, params, { id, idx: "1" });
    navigate(path);
  };

  //atualiza o estado do modal de adicionar
  const handlerDialogAddClient = () => {
    setOpenDialogAdd(!openDialogAdd);
  };

  //atualiza o estado do modal de remover
  const handlerDialogDeleteClient = (
    open = false,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    client: IClient
  ) => {
    if (open) {
      e.stopPropagation(); //evitar que abra o event do parent (a row)
      setSelectedClient(client);
    }
    setOpenDialogDelete(!openDialogDelete);
  };

  //atualiza o estado do modal de editar
  const handlerDialogEditClient = (
    open = false,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    client: IClient
  ) => {
    if (open) {
      e.stopPropagation(); //evitar que abra o event do parent (a row)
      setSelectedClient(client);
    }
    setOpenDialogEdit(!openDialogEdit);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    id: string,
    index: number
  ) => {
    event.stopPropagation(); //evitar que abra o event do parent (a row)
    const path = formatRoutePath(location, params, {
      id,
      idx: index.toString(),
    });
    navigate(path);
  };

  const handleToggle = (
    index: number,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setOpen(open?.map((prevOpen, i) => (i === index ? !prevOpen : false)));
    e.stopPropagation(); //evitar que abra o event do parent (a row)
  };

  const handleClose = (event: Event, index: number) => {
    if (
      rowRefs.current &&
      rowRefs.current[index].contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(open?.map((o, i) => (i === index ? (o = false) : false)));
  };

  const tableHeads: TableHeadCell[] = [
    {
      id: "name",
      label: t("clients.name"),
    },
    {
      id: "is_active",
      label: t("clients.status"),
    },
    {
      id: "updated_at",
      label: t("common.updatedAt"),
    },
    {
      id: "action_cell",
      label: "",
      notSortable: true,
    },
  ];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const { addHeader } = useContext(LayoutContext);
  const header = {
      title: t("lists.clientsList"),
      action: canAdd && (
          <Grid item>
            <Button
              color="primary"
              onClick={() => setOpenDialogAdd(true)}
              variant="contained"
              startIcon={<AddIcon />}
            >
              {t("clients.addClient")}
            </Button>
          </Grid>
        )

  }

  useEffect(()=> {
    addHeader(header)
  }, [])

  return (
    <>
      <FiltersSection setFilterSearch={setFilterSearch} setFilterStatus={setFilterstatus} setPage={setPage} />
      <Loading show={loading} />
      {clients && clients?.data.length > 0 && !loading ? (
        <>

          <TableContainer>
            <TableLink>
              <TableHeadSort
                headCells={tableHeads}
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
              />
              <TableBody>
                {clients?.data?.map((client: IClient, index: number) => (
                  <TableRow
                    onClick={(e) => handleViewClick(e, client.id)}
                    key={client.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{client.name}</TableCell>
                    <TableCell>
                      <ChipStatus
                        label={
                          client.is_active
                            ? t("common.active")
                            : t("common.inactive")
                        }
                        color={
                          client.is_active
                            ? statusColor("active")
                            : statusColor("non-active")
                        }
                      />
                    </TableCell>
                    <TableCell>
                      {formatDate(client.updated_at, t("calendar.dateFormat"))}
                    </TableCell>
                    <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                      {canEdit && (
                        <IconButton
                          title={t("common.edit")}
                          onClick={(
                            e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                          ) => handlerDialogEditClient(true, e, client)}
                          sx={{padding: 0}}
                        >
                          <EditIcon />
                        </IconButton>
                      )}

                      {canDelete && (
                        <IconButton
                          title={t("common.delete")}
                          onClick={(
                            e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                          ) => handlerDialogDeleteClient(true, e, client)}
                          sx={{padding: 0}}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}

                      {canView && (
                        <>
                          <span ref={(ref) => (rowRefs.current[index] = ref)}>
                            <IconButton
                              sx={{padding: 0}}
                              onClick={(
                                e: React.MouseEvent<
                                  HTMLButtonElement,
                                  MouseEvent
                                >
                              ) => handleToggle(index, e)}
                              size="small"
                              aria-controls={
                                open !== undefined && open[index]
                                  ? "split-button-menu"
                                  : undefined
                              }
                              aria-expanded={
                                open !== undefined && open[index]
                                  ? "true"
                                  : undefined
                              }
                              aria-haspopup="menu"
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </span>
                          {open !== undefined && open[index] && (
                            <Popper
                              sx={{ zIndex: 1 }}
                              open={open !== undefined && open[index]}
                              anchorEl={rowRefs.current[index]}
                              role={undefined}
                              transition
                              disablePortal
                            >
                              {({ TransitionProps, placement }) => (
                                <Grow
                                  {...TransitionProps}
                                  style={{
                                    transformOrigin:
                                      placement === "bottom"
                                        ? "center top"
                                        : "center bottom",
                                  }}
                                >
                                  <Paper>
                                    <ClickAwayListener
                                      onClickAway={(e: Event) => {
                                        handleClose(e, index);
                                      }}
                                    >
                                      <MenuList
                                        id="split-button-menu"
                                        autoFocusItem
                                      >
                                        {options.map((option, index) => (
                                          <MenuItem
                                            key={option}
                                            onClick={(event) =>
                                              handleMenuItemClick(
                                                event,
                                                client.id,
                                                index+1
                                              )
                                            }
                                          >
                                            {option}
                                          </MenuItem>
                                        ))}
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableLink>
          </TableContainer>

          <CustomTablePagination
            count={clients?.totalCount}
            page={page}
            totalPages={clients?.totalPages}
            onPageChange={handleChangePage}

          />

        </>
      ) : !loading && <NoData />}

      {canAdd && openDialogAdd && (
        <ClientsAdd
          open={openDialogAdd}
          handlerClose={handlerDialogAddClient}
          updateList={updateClients}
        />
      )}

      {canEdit && selectedClient && openDialogEdit && (
        <ClientsEdit
          client={selectedClient}
          open={openDialogEdit}
          handlerClose={() => setOpenDialogEdit(false)}
          updateList={updateClients}
        />
      )}

      {canDelete && selectedClient && openDialogDelete && (
        <ClientsDelete
          client={selectedClient}
          open={openDialogDelete}
          handlerClose={() => setOpenDialogDelete(false)}
          updateList={updateClients}
        />
      )}
    </>
  );
};

export default ClientsList;
