/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useDelete } from "hooks";

import { ServiceApiUrl } from "services/ServiceApiUrl";
import { useEffect } from "react";
import { useErrorHandler } from "hooks/useErrorHandler";
import { IDialogProps, IPicture } from "interfaces";
import { getSelectedModule } from "utils/permissions/getSelectedModule";
import { ConfirmDialog, useFeedback } from "@alb/live-lib";
import { useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";

export default function PictureDelete({
	data: picture,
	open,
	onClose,
	onSuccess,
}: IDialogProps<IPicture>) {
	const { t } = useTranslation();
	const { addFeedback } = useFeedback();
	const { sendErrorMessage } = useErrorHandler();
	const auth = useSelector(getUser)
	const moduleID = auth && getSelectedModule(auth, 'dados_morfologicos')
	const headers = {
		headers: {
			'MODULE-ID': moduleID
		}
	}

	const {
		loading,
		error,
		refetch: deletePicture,
	} = useDelete<IPicture[]>(ServiceApiUrl.pictureURL, picture.id, headers);

	//sucesso
	const handlerSuccess = () => {
		onClose && onClose();
		addFeedback({
			message: t("morphologicalData.picture.pictureDeleted"),
			severity: "success",
		});
		onSuccess && onSuccess();
	};
	//erro
	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);

	//apaga fotografia
	const handleDeletePicture = async () => {
		try {
			await deletePicture()
			await handlerSuccess();
		} catch { }
	};

	const handlerClose = () => {
		onClose && onClose();
	}

	return (
		<ConfirmDialog
			open={open}
			type="error"
			title={t('morphologicalData.picture.deletePicture')}
			message={t("morphologicalData.picture.pictureDeleteConfirmation")}
			actionConfirmText={t("common.delete")}
			actionCancelText={t("common.cancel")}
			loading={loading}
			onCancel={handlerClose}
			onConfirm={handleDeletePicture}
		/>
	);
}
