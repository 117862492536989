import { CssBaseline, Grid } from "@mui/material";
import logincss from "../../styles/scss/components/login.module.scss";
import { ReactComponent as LoginImage } from "../../images/login_live_agro.svg";

interface ILiveMainLogin {
  children:React.ReactNode,
  backgroundImage?: boolean,
}

const LiveMainLogin = ({children, backgroundImage} : ILiveMainLogin) => {
  return (
    <>
      <CssBaseline />
      <Grid container alignItems="stretch" sx={{height: "100%"}}>
        <Grid item  xs={true} >
          {children}
        </Grid>
        {!backgroundImage &&
          <Grid item xs={0} md={6} className={logincss.loginImageDiv}>
            <LoginImage className={logincss.loginImage} />
          </Grid>
        }
      </Grid>
    </>
  );
};

export default LiveMainLogin;
