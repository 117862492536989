import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { IDialogProps, IPicture } from "interfaces";
import React from "react";
import { useTranslation } from "react-i18next";
import { downloadImage, formatDate } from "utils";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import DownloadIcon from "@mui/icons-material/Download";
import { Box } from "@mui/system";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose?: () => void;
  onFullScreen?: () => void;
  onDownload?: () => void;
}
const ImageDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, onFullScreen, onDownload, ...other } = props;

  const [fullScreen, setFullScreen] = React.useState(false);

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    onFullScreen && onFullScreen();
  };

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      <Box display="flex" alignItems={"center"} justifyContent={"flex-end"}>
        <Box flexGrow={1} ml={2}>
          {children}
        </Box>
        <Box>
          <IconButton onClick={onDownload}>
            <DownloadIcon />
          </IconButton>
          {onFullScreen && (
            <IconButton onClick={handleFullScreen}>
              {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          )}
          {onClose && (
            <IconButton onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </DialogTitle>
  );
};


const FullScreenImage = ({
  data: image,
  open,
  onClose,
}: IDialogProps<IPicture | undefined>) => {
  const { t } = useTranslation();

  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  // const [fullWidth, setFullWidth] = React.useState(true);
  const [fullScreen, setFullScreen] = React.useState(false);

  const onFullScreen = () => {
    // setFullWidth(!fullScreen);
    setMaxWidth(!fullScreen ? undefined : "lg");

    setFullScreen(!fullScreen);
  };

  const handleOnClose = () => {
    // setFullWidth(true);
    setMaxWidth("lg");

    setFullScreen(false);

    onClose && onClose();
  };

  const onDownload = () => {
    image && downloadImage(image.url_source, image.id);
  };

  if (!image) return <></>;

  return (
    <Dialog
      fullScreen={fullScreen}
      // fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={handleOnClose}
    >
      <ImageDialogTitle
        id={image.id}
        onClose={handleOnClose}
        onFullScreen={onFullScreen}
        onDownload={onDownload}
      >
        {formatDate(image.registration_date, t("calendar.dateFormat"))}
      </ImageDialogTitle>
      <DialogContent>
        <Box display="flex" alignItems={"center"} justifyContent={"center"}>          
          <img
            style={{width: fullScreen ? 'unset' : '100%'}}
            src={image.url_source}
            alt={formatDate(image.registration_date, t("calendar.dateFormat"))}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FullScreenImage;
