import LockIcon from "@mui/icons-material/Lock";
import { Box, Button, Grid, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Unauthorized = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const goLogin = () => {
    dispatch({ type: "logout" });
    window.location.replace("/login");
  };

  return (
    <>
      <CssBaseline />
      <Grid container sx={{ textAlign: "center" }}>
        <Grid item xs={true} mt={12}>
          <LockIcon color="error" sx={{ fontSize: 80 }} />

          <Typography mt={2} variant="h3">
            {t("common.unauthorizedTitle")}
          </Typography>

          <Typography mb={2} mt={6} variant="h5">
            {t("common.unauthorizedDescription")}
          </Typography>

          <Box mt={10}>
            <Button onClick={goBack} size="large" variant="outlined">
              {t("common.goBack")}
            </Button>
            <Button
              onClick={goLogin}
              size="large"
              variant="outlined"
              style={{
                margin: "5px",
              }}
            >
              {t("goLogin")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default Unauthorized;
