import { LayoutContext, Loading, TableHeadCell, TableHeadOrder, TableHeadSort } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from '@mui/icons-material/Edit'
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { Button, IconButton, Stack, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";

import { ChipStatus } from "components/utils/ChipStatus";
import UsersAdd from "components/utils/users/userCreate";
import UsersDelete from "components/utils/users/userDelete";
import UsersEdit from "components/utils/users/userEdit";
import { useGetAll } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useHasPermissions } from "hooks/usePermissions";
import { IApiResponse, IModule, IUserPermission } from "interfaces";
import { IUser } from "interfaces/IUser";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { TableLink } from "styles/components-styles";
import { formatDate, formatOrderBy, PERMISSIONS, statusColor } from "utils";
import { ROWS_PER_PAGE } from "utils/pagination";
import { CustomTablePagination } from "utils/pagination/CustomTablePagination";
import { formatRoutePath } from "utils/routePath";

import UserInvite from "./inviteUser";
import UserReactivate from "./reactivateUser";
import FiltersSection from "./userProfile/FiltersSection";

export default function Users() {
	const { t } = useTranslation();
	const { sendErrorMessage } = useErrorHandler();
	const { hasPermission } = useHasPermissions();

	const [order, setOrder] = useState<TableHeadOrder>("asc");
	const [orderBy, setOrderBy] = useState<string>("full_name");

	const crudPermissions = hasPermission([PERMISSIONS.MANAGEMENT.USERS.CUD]);

	const [page, setPage] = useState(1);
	//guardar o user selecionado da tabela, pelo utilizador, para mostrar detalhes
	const [selectedUser, setSelectedUser] = useState<IUser>();
	//para controlar o modal de convidar
	const [openDialogAdd, setOpenDialogAdd] = useState(false);
	//para controlar o modal de confirmação delete
	const [openDialogDelete, setOpenDialogDelete] = useState(false);
	//para controlar o modal de editar
	const [openDialogEdit, setOpenDialogEdit] = useState(false);
	//para controlar o modal de confirmação invite user
	const [openDialogInvite, setOpenDialogInvite] = useState(false);
	const [openDialogReactivate, setOpenDialogReactivate] = useState(false);

	const [filterSearch, setFilterSearch] = useState<string>("");
	const [filterStatus, setFilterStatus] = useState<string>("");

	//get users
	const {
		data: users,
		loading,
		error,
		refetch,
	} = useGetAll<IApiResponse<IUser>>(ServiceApiUrl.userURL, GetParams());

	function orderByAPI() {
		let _orderBy = formatOrderBy(order, orderBy);
		if (orderBy === "full_name") {
			_orderBy = ["first_name", "last_name"]
				.map((o) => (order === "desc" ? "-" + o : o))
				.join(",");
		}
		return _orderBy;
	}

	function GetParams() {
		var params: {
			page: number;
			items: number;
			order_by: string;
			contains: string;
			[key: string]: string | unknown;
		} = {
			page: page,
			items: ROWS_PER_PAGE,
			order_by: orderByAPI(),
			contains: filterSearch.length >= 3 ? filterSearch : "",
		};
		if (filterStatus !== "") {
			params["status"] = filterStatus;
		}
		return params;
	}

	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);

	//quando for feito novo pedido dos dados, atualiza os dados do utilizador selecionado
	useEffect(() => {
		if (users?.data) {
			let chosen = users?.data.filter((u) => u.id === selectedUser?.id);
			if (chosen) setSelectedUser(chosen[0]); //troca o user pelo escolhido na tabela
		}
	}, [users?.data]);

	const { data: allModules, refetch: fetchModules } = useGetAll<IApiResponse<IModule>>(
		ServiceApiUrl.moduleURL,
		'',
		{ manual: true }
	);
	useEffect(() => {
		if (crudPermissions) {
			fetchModules();
		}
	}, [crudPermissions])

	const [permissions, setPermissions] = useState<IUserPermission[]>([]);
	useEffect(() => {
		if (allModules) {
			const perm = allModules?.data.map((m: IModule) => ({
				module_name: m.name,
				module_id: m.id,
				value: "0000",
			}));
			setPermissions(perm);
		}

	}, [allModules]);

	//atualiza o estado do modal de adicionar
	const handlerDialogAddState = () => {
		setOpenDialogAdd(!openDialogAdd);
	};

	//atualiza o estado do modal de editar
	const handlerDialogEditState = () => {
		setOpenDialogEdit(!openDialogEdit);
	};
	//atualiza o estado do modal de remover
	const handlerDialogDeleteState = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		user: IUser
	) => {
		if (open === true) {
			//nesta situação, chega um evento
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setSelectedUser(user); //troca o user pelo escolhido
		}
		setOpenDialogDelete(!openDialogDelete);
	};
	//atualiza o estado do modal de invite
	const handlerDialogInvite = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		user: IUser
	) => {
		if (open === true) {
			//nesta situação, chega um evento
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setSelectedUser(user); //troca o user pelo escolhido
		}
		setOpenDialogInvite(!openDialogInvite);
	};
	const handlerDialogReactivate = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		user: IUser
	) => {
		if (open === true) {
			//nesta situação, chega um evento
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setSelectedUser(user); //troca o user pelo escolhido
		}
		setOpenDialogReactivate(!openDialogReactivate);
	};
	//para fazer nova chamada dos users
	const updateUsers = () => {
		refetch();
	};
	//Trocar página da listagem
	const handleChangePage = (
		newPage: number
	) => {
		setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
	};

	var tableHeads: TableHeadCell[] = [{
		id: "full_name",
		label: t("user.name"),
	},
	{
		id: "username",
		label: t("user.Username"),
	},
	{
		id: "status",
		label: t("user.state"),
	},
	{
		id: 'account',
		label: t("accounts.account")
	}
]

	crudPermissions ? tableHeads = [
		...tableHeads,
		{
			id: "created_at",
			label: t("user.createdAt"),
		},
		{
			id: "updated_at",
			label: t("user.updatedAt"),
		},
		{
			id: "action_cell",
			label: "",
			notSortable: true,
		},
	] : tableHeads = [
		...tableHeads
	];

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: string
	) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const { addHeader } = useContext(LayoutContext);
	const header = {
		title: t("lists.usersList"),
		action: crudPermissions && (
			<Button
				color="primary"
				onClick={() => setOpenDialogAdd(true)}
				variant="contained"
				startIcon={<AddIcon />}
			>
				{t("user.inviteUser")}
			</Button>)
	}

	useEffect(() => {
		addHeader(header);
	}, [])

	const params = useParams()
	const location = useLocation()
	const navigate = useNavigate()

	const handleRowClick = (u: IUser) => {
		navigate(
			formatRoutePath(location, params, {
				id: u.id,
			})
		)
	}

	return (
		<>

			<FiltersSection setFilterSearch={setFilterSearch} setFilterStatus={setFilterStatus} setPage={setPage} />
			<Loading show={loading} />

			{!users?.data.length && !loading && (
				<Typography mt={2} variant="body1">
					{t("user.noUsers")}
				</Typography>
			)}

			{users && users?.data.length > 0 && !loading && (
				<>
					<TableContainer>
						<TableLink>
							<TableHeadSort
								headCells={tableHeads}
								onRequestSort={handleRequestSort}
								order={order}
								orderBy={orderBy}
							/>
							<TableBody>
								{users?.data?.map((u: IUser, index: number) => (
									<TableRow
										onClick={() => handleRowClick(u)}
										key={u.id}
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										<TableCell>{`${u.first_name} ${u.last_name}`}</TableCell>
										<TableCell>
											<Stack direction="row" alignItems="flex-end" gap={1}>
												{u.username}
												{(u.is_superadmin || u.is_admin) && (
													<SupervisorAccountIcon
														fontSize="small"
														color={u.is_superadmin ? "primary" : "inherit"}
														titleAccess={
															u.is_superadmin
																? t("user.SuperAdministrator")
																: u.is_admin
																	? t("user.Administrator")
																	: ""
														}
													/>
												)}
											</Stack>
										</TableCell>
										<TableCell>
											<ChipStatus
												label={t(`user.status.${u.status}`)}
												color={statusColor(u.status)}
											/>
										</TableCell>
										<TableCell>
											{u.account?.name}
										</TableCell>
										{crudPermissions && (<TableCell>
											{formatDate(u.created_at, t("calendar.dateFormat"))}
										</TableCell>
										)}
										{crudPermissions && (
											<TableCell>
												{u.updated_at !== null
													? formatDate(
														u.updated_at,
														t("calendar.dateTimeFormatLocal")
													)
													: "--"}
											</TableCell>
										)}
										<TableCell sx={{ textAlign: "right" }}>
											<IconButton
												title={t("user.editUser")}
												// disabled={u.is_active === true} //ativar caso não seja permitido apagar utilizadores ativos
												aria-label="update"
												onClick={(
													e: React.MouseEvent<HTMLButtonElement, MouseEvent>
												) => {
													e.stopPropagation()

													if (crudPermissions) {
														setSelectedUser(u);
														handlerDialogEditState()
													}
												}}
												sx={{ padding: 0 }}
												disabled={!(crudPermissions && u.status !== "deleted" && !u.is_superadmin)}
											>
												<EditIcon />
											</IconButton>

											<IconButton
												title={t("user.disableUser")}
												// disabled={u.is_active === true} //ativar caso não seja permitido apagar utilizadores ativos
												aria-label="delete"
												onClick={(
													e: React.MouseEvent<HTMLButtonElement, MouseEvent>
												) => (crudPermissions && u.status !== "deleted") ? handlerDialogDeleteState(true, e, u) : () => { }}
												sx={{ padding: 0 }}
												disabled={!(crudPermissions && u.status !== "deleted")}
											>
												<DeleteIcon />
											</IconButton>

											{/*<IconButton
                          title={t("user.reactivateUser")}
                          onClick={(
                            e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                          ) => !(crudPermissions && u.status === "inactives") ? handlerDialogReactivate(true, e, u) : () => {}}
                          sx={{padding: 0}}
                          disabled = {!(crudPermissions && u.status === "inactives")}
                        >
                          <SettingsBackupRestoreIcon />
                          </IconButton>*/}

											<IconButton
												title={t("user.reinviteUser")}
												onClick={(
													e: React.MouseEvent<HTMLButtonElement, MouseEvent>
												) => (crudPermissions && (!u.last_pwd_update || u.status === "deleted")) ? handlerDialogInvite(true, e, u) : () => { }}
												sx={{ padding: 0 }}
												disabled={
													!(crudPermissions && (!u.last_pwd_update || u.status === "deleted"))
												}
											>
												<EmailRoundedIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</TableLink>
					</TableContainer>
					<CustomTablePagination
						count={users?.totalCount}
						page={page}
						totalPages={users?.totalPages}
						onPageChange={handleChangePage}

					/>
				</>
			)}

			{crudPermissions && openDialogAdd && (
				<UsersAdd
					permissions={permissions}
					allModules={allModules}
					invite
					open={openDialogAdd}
					handlerClose={handlerDialogAddState}
					updateList={updateUsers}
				/>
			)}

			{selectedUser && (
				<>
					{crudPermissions && openDialogEdit && (
						<UsersEdit
							user={selectedUser}
							open={openDialogEdit}
							handlerClose={handlerDialogEditState}
							updateList={updateUsers}
						/>
					)}
					{crudPermissions && openDialogDelete && (
						<UsersDelete
							user={selectedUser}
							open={openDialogDelete}
							handlerClose={handlerDialogDeleteState}
							updateList={updateUsers}
						/>
					)}
					{crudPermissions && openDialogInvite && (
						<UserInvite
							user={selectedUser}
							open={openDialogInvite}
							handlerClose={handlerDialogInvite}
							updateGetUsers={updateUsers}
						/>
					)}

					{crudPermissions && openDialogReactivate && (
						<UserReactivate
							user={selectedUser}
							open={openDialogReactivate}
							handlerClose={handlerDialogReactivate}
							updateGetUsers={updateUsers}
						/>
					)}
				</>
			)}
		</>
	);
}
