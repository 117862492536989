import { InputAutocomplete, InputText, useFeedback } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useGetAll, useUpdate } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { IAdapterConfigurator, IApiResponse, IModuleForm, ISelectOption } from "interfaces";
import { IModule } from "interfaces/IModule";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty } from "utils";

import { setModuleSchema } from "./validations";

export interface IModulesEdit {
	module: IModule;
	clientID: string;
	open: boolean;
	handlerClose: () => void;
	updateList: () => void;
}

const ModulesEdit = ({ module, clientID, open, handlerClose, updateList }: IModulesEdit) => {
	const { t } = useTranslation();
	const { addFeedback } = useFeedback();
	const { sendErrorMessage } = useErrorHandler();

	//pedido para criar module
	const {
		loading,
		error,
		refetch: editModule,
	} = useUpdate<IModule[]>(ServiceApiUrl.moduleURL, module.id);

	const [gatewaysOptions, setGatewaysOptions] = useState<ISelectOption[]>([]);
	// get list of modules
	const { data: allGateways } = useGetAll<IApiResponse<IAdapterConfigurator>>(
		ServiceApiUrl.adapterListURL, { client: clientID }
	);
	useEffect(() => {
		if (allGateways) {
			const options = allGateways?.data?.map((p: IAdapterConfigurator) => ({
				label: p.name,
				value: p.id,
			}));

			setGatewaysOptions(options);
		}
	}, [allGateways]);

	const gateway = module.adapter_configurators.map((p: IAdapterConfigurator) => {
		return { label: p.name, value: p.id }
	}) || []
	const methodsAdd = useForm<IModuleForm>({
		mode: "all",
		reValidateMode: 'onChange',
		defaultValues: {
      client: clientID,
      adapter_configurators: gateway,
      name: module.name,
    },
		resolver: yupResolver(setModuleSchema(t('validations.required'))),
	});

	useEffect(() => {
		methodsAdd.reset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// sucesso
	const handlerSuccess = () => {
		handlerClose(); //fecha modal
		addFeedback({
			message: t("modules.moduleEdited"),
			severity: "success",
		});
		updateList && updateList();
	};
	//erro
	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error, sendErrorMessage]);

	//ao submeter o formulário
	const formSubmitHandler: SubmitHandler<IModuleForm> = async (
		payLoad: IModuleForm
	) => {
		// console.log("payLoad ", payLoad);
		try {
			const payLoadAPI = payLoad as unknown as IModuleForm<string>;
			if (clientID) payLoadAPI.client = clientID;
			payLoadAPI.adapter_configurators = payLoad.adapter_configurators.map(
				(a: any) => a.value
			);
			await editModule({ data: payLoad });
			await handlerSuccess();
		} catch (error) { }
	};

	return (
		<Dialog maxWidth="sm" fullWidth open={open}>
			<DialogTitle>
				<Typography
					component={"span"}
					color="text.black"
					gutterBottom={true}
					variant="h5"
					noWrap
				>
					{t("modules.editModule")}
				</Typography>
			</DialogTitle>

			<DialogContent>
				<FormProvider {...methodsAdd}>
					<form
						id="edit-module-form"
						onSubmit={methodsAdd.handleSubmit(formSubmitHandler)}
					>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography gutterBottom color="text.black" variant="h6">
									{t("modules.name")}
								</Typography>
								<InputText
									disabled
                  control={methodsAdd.control}
                  name="name"
                  placeholder={t("modules.namePlaceholder")}
                />
							</Grid>
							<Grid item xs={12}>
								<Typography gutterBottom color="text.black" variant="h6">
									{t("modules.adapters")}
								</Typography>

								{!arrayIsEmpty(gatewaysOptions) && (
									<InputAutocomplete
										name="adapter_configurators"
										options={gatewaysOptions}
										multiple
										control={methodsAdd.control}
										required
									/>
								)}
							</Grid>
						</Grid>
					</form>
				</FormProvider>
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" color="primary" onClick={handlerClose}>
					{t("common.cancel")}
				</Button>

				<LoadingButton
					loading={loading}
					variant="contained"
					color="primary"
					disabled={!methodsAdd.formState.isValid}
					type={"submit"}
					form={"edit-module-form"}
				>
					{t("common.save")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default ModulesEdit;
