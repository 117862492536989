/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useDelete } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { IUser } from "interfaces";
import { ConfirmDialog, useFeedback } from "@alb/live-lib";

export interface IUsersDelete {
	user: IUser;
	open: boolean;
	handlerClose: any;
	updateList: () => void;
}

const UsersDelete = ({
	user,
	open,
	handlerClose,
	updateList,
}: IUsersDelete) => {


	const { t } = useTranslation();
	const { addFeedback } = useFeedback();
	const { sendErrorMessage } = useErrorHandler();

	const {
		loading,
		error,
		refetch: deleteUser,
	} = useDelete<IUser[]>(ServiceApiUrl.userURL, user.id);

	//sucesso
	const handlerSuccess = () => {
		handlerClose();
		addFeedback({
			message: t("user.userDeleted"),
			severity: "success",
		});
		updateList();
	};
	//erro
	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);

	// eliminar user
	const handleDeleteUser = async () => {
		try {
			await deleteUser();
			await handlerSuccess();
		} catch { }
	};

	return (
		<ConfirmDialog
			open={open}
			type="error"
			title={`${t("user.deleteUser")} ${user.username}`}
			message={t("confirmations.userDeleteConfirmation")}
			actionConfirmText={t("common.delete")}
			actionCancelText={t("common.cancel")}
			loading={loading}
			onCancel={handlerClose}
			onConfirm={handleDeleteUser}
		/>
	);
};

export default UsersDelete;
