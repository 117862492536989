import LiveUserConfirmInvite from "components/auth/UserConfirmInvite";
import LiveMainLogin from "components/page/MainLogin";

const Invite = () => {
  return (
    <LiveMainLogin backgroundImage>
      <LiveUserConfirmInvite  />
    </LiveMainLogin>
  );
 
};

export default Invite;
