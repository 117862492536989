import { useLayoutEffect, useState } from "react";
import { Navigate, Route, Router, Routes } from "react-router-dom";

import { PERMISSIONS } from "utils";
import About from "views/About";
import AccountsDataView from "views/accounts/details";
import Accounts from "views/accounts/list";
import ComparativeAnalysis from "views/comparativeAnalysis/ComparativeAnalysis";
import Dashboard from "views/dashboard/Dashboard";
import NotFound from "views/errors/NotFoundPage";
import GatewaysDataView from "views/gateways/details";
import Gateways from "views/gateways/list";
import Login from "views/login";
import Forgot from "views/login/forgot";
import Recover from "views/login/recover";
import ClientsList from "views/management/clients/ClientsList";
import ClientsView from "views/management/clients/ClientsView";
import Modules from "views/management/management";
import Map from "views/map/MapPage";
import CultivationsList from "views/morphologicaldata/administration/cultivations/list";
import HarvestsList from "views/morphologicaldata/administration/harvests/list";
import LocationsList from "views/morphologicaldata/administration/locations/list";
import MorphologicalDataAdministration from "views/morphologicaldata/administration/morphologicalDataAdministration";
import PulpColorsList from "views/morphologicaldata/administration/pulpColors/list";
import SamplesList from "views/morphologicaldata/administration/samples/list";
import TexturesList from "views/morphologicaldata/administration/textures/list";
import VarietiesList from "views/morphologicaldata/administration/varieties/list";
import MorphologicalData from "views/morphologicaldata/list";
import MorphologicalDataView from "views/morphologicaldata/view";
import Unauthorized from "views/Unauthorized";
import DetailUser from "views/users/detailUser";
import Invite from "views/users/invite";
import Users from "views/users/list";
import ProfileSettings from "views/users/userProfile/profileSettings"
import Version from "views/Version";

import RequireAuth from "./AuthRoute";

export const AppRoutes = (props: any) => {
	const [state, setState] = useState({
		action: props.history.action,
		location: props.history.location,
	});

	useLayoutEffect(() => props.history.listen(setState), [props.history]);

	return (
		<Router
			{...props}
			location={state.location}
			navigationType={state.action}
			navigator={props.history}
		>
			<Routes>
				<Route element={<RequireAuth allowedRoles={[PERMISSIONS.MAP.VIEW]} />}>
					<Route path="/map" element={<Map />} />
				</Route>

				<Route element={<RequireAuth allowedRoles={[PERMISSIONS.DASHBOARD.VIEW]} />}>
					<Route path="/dashboard/" element={<Dashboard />}>
						<Route path=":deviceId" element={<Dashboard />} />
					</Route>
				</Route>

				<Route element={<RequireAuth allowedRoles={[PERMISSIONS.DASHBOARD.COMPARATIVE_ANALYSIS.VIEW]} />}>
					<Route path="/comparativeanalysis/" element={<ComparativeAnalysis />} />
				</Route>

				<Route element={<RequireAuth allowedRoles={[PERMISSIONS.MANAGEMENT.VIEW, PERMISSIONS.MANAGEMENT.ADAPTERS.VIEW]} />}>
					<Route path="/management/adapters" element={<Gateways />} />
					<Route path="/management/adapters/:id" element={<GatewaysDataView />} />
					<Route path="/management/adapters/:id/:idx" element={<GatewaysDataView />} />
				</Route>

				<Route element={<RequireAuth allowedRoles={[PERMISSIONS.MANAGEMENT.VIEW, PERMISSIONS.MANAGEMENT.ACCOUNTS.VIEW]} />}>
					<Route path="/management/accounts" element={<Accounts />} />
					<Route path="/management/accounts/:id" element={<AccountsDataView />} />
					<Route path="/management/accounts/:id/:idx" element={<AccountsDataView />} />
				</Route>

				<Route element={<RequireAuth allowedRoles={[PERMISSIONS.MANAGEMENT.VIEW]} />}>
					<Route path="/management/users" element={<Users />} />
					<Route path="/management/users/:id" element={<DetailUser />} />
				</Route>

				<Route element={<RequireAuth allowedRoles={[PERMISSIONS.MANAGEMENT.VIEW, PERMISSIONS.MANAGEMENT.MODULES.VIEW]} />}>
					<Route path="/management/modules" element={<Modules />} />
				</Route>

				<Route element={<RequireAuth allowedRoles={[PERMISSIONS.MANAGEMENT.VIEW, PERMISSIONS.MANAGEMENT.MODULES.VIEW, PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.VIEW]} />} >
					<Route path="/management/modules/morphologicaldata" element={<MorphologicalDataAdministration />} />
					<Route path="/management/modules/morphologicaldata/locations" element={<LocationsList />} />
					<Route path="/management/modules/morphologicaldata/cultivations" element={<CultivationsList />} />
					<Route path="/management/modules/morphologicaldata/varieties" element={<VarietiesList />} />
					<Route path="/management/modules/morphologicaldata/harvests" element={<HarvestsList />} />
					<Route path="/management/modules/morphologicaldata/pulpcolors" element={<PulpColorsList />} />
					<Route path="/management/modules/morphologicaldata/textures" element={<TexturesList />} />
					<Route path="/management/modules/morphologicaldata/samples" element={<SamplesList />} />
				</Route>

				<Route element={<RequireAuth allowedRoles={[PERMISSIONS.ADMINISTRATION.VIEW]} />}>
					<Route path="/administration/clients" element={<ClientsList />} />
					<Route path="/administration/clients/:id" element={<ClientsView />} />
					<Route path="/administration/clients/:id/:idx" element={<ClientsView />} />
				</Route>

				<Route element={<RequireAuth allowedRoles={[PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.VIEW]} />}>
					<Route path="/morphologicaldata/samples" element={<MorphologicalData />} />
					<Route path="/morphologicaldata/samples/:id" element={<MorphologicalDataView />} />
					<Route path="/morphologicaldata/samples/:id/:idx" element={<MorphologicalDataView />} />
				</Route>

				<Route element={<RequireAuth allowedRoles={[PERMISSIONS.MY_PROFILE.VIEW]} />}>
					<Route path="/profile" element={<ProfileSettings />} />
				</Route>

				<Route element={<RequireAuth allowedRoles={[PERMISSIONS.ABOUT.VIEW]} />}>
					<Route path="/about" element={<About />} />
				</Route>

				<Route path="/login" element={<Login />} />
				<Route path="/forgot" element={<Forgot />} />

				<Route path="/users/recover" element={<Recover />} />
				<Route path="/users/invite" element={<Invite />} />

				<Route path="/unauthorized" element={<Unauthorized />} />

				<Route path="/v" element={<Version />} />

				<Route path="*" element={<NotFound />} />
				<Route path="/" element={<Navigate to={"/map"} replace />} />
			</Routes>
		</Router>
	);
};
