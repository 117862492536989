import { InputSelect, InputText, Loading, RequiredField, useFeedback } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RequiredFields } from "components/utils/forms/RequiredFields";
import { useCreate, useGetAll } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { IApiResponse, ICultivation, IHarvestType, ILocation, ISelectOption, IVariety } from "interfaces";
import { ISample } from "interfaces/ISample";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { getSelectedModule } from "utils/permissions/getSelectedModule";

import { getSampleAddSchema } from "../validations";

export default function SampleAdd(props: any) {
	const { t } = useTranslation();
	const { addFeedback } = useFeedback();
	const { sendErrorMessage } = useErrorHandler();
	const auth = useSelector(getUser)
	const moduleID = auth && getSelectedModule(auth, 'dados_morfologicos')
	const headers = {
		headers: {
			'MODULE-ID': moduleID
		}
	}

	//pedido para adicionar sample
	const {
		loading,
		error,
		refetch: createSample,
	} = useCreate<ISample[]>(ServiceApiUrl.sampleURL, '', headers);

	//pedidos para obter os dados pré-definidos
	const { data: placesList, loading: loadingPlaces } = useGetAll<IApiResponse<ILocation>>(
		ServiceApiUrl.placesListURL,
		'',
		undefined,
		headers
	);
	const { data: cultivationsList, loading: loadingCultivations } = useGetAll<IApiResponse<ICultivation>>(
		ServiceApiUrl.cultivationsListURL,
		'',
		undefined,
		headers
	);
	const { data: varietiesList, loading: loadingVarieties } = useGetAll<IApiResponse<IVariety>>(
		ServiceApiUrl.varietiesListURL,
		'',
		undefined,
		headers
	);
	const { data: harvestsList, loading: loadingHarvests } = useGetAll<IApiResponse<IHarvestType>>(
		ServiceApiUrl.harvestsListURL,
		'',
		undefined,
		headers
	);

	const [places, setPlaces] = useState<ISelectOption[]>();
	useEffect(() => {
		if (placesList) {
			const lOpts = placesList?.data?.map((p: ILocation) => ({ label: p.name, value: p.id }));
			setPlaces(lOpts);
		}
	}, [placesList])

	const [cultivations, setCultivations] = useState<ISelectOption[]>();
	useEffect(() => {
		if (cultivationsList) {
			const cOpts = cultivationsList?.data?.map((p: ICultivation) => ({ label: p.name, value: p.id }));
			setCultivations(cOpts);
		}
	}, [cultivationsList])

	const [varieties, setVarieties] = useState<ISelectOption[]>();
	useEffect(() => {
		if (varietiesList) {
			const vOpts = varietiesList?.data?.map((p: IVariety) => ({ label: p.name, value: p.id }));
			setVarieties(vOpts);
		}
	}, [varietiesList])

	const [harvests, setHarvests] = useState<ISelectOption[]>();
	useEffect(() => {
		if (harvestsList) {
			const hOpts = harvestsList?.data?.map((p: IHarvestType) => ({ label: p.name, value: p.id }));
			setHarvests(hOpts);
		}
	}, [harvestsList])

	useEffect(() => {
		methodsAdd.reset();
	}, [props.handlerClose]);

	//sucesso
	const handlerSuccess = () => {
		props.handlerClose();
		addFeedback({
			message: t("morphologicalData.sample.sampleAdded"),
			severity: "success",
		});
		props.updateGetSamples();
	};
	//erro
	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);

	const methodsAdd = useForm<ISample>({
		mode: "onChange",
		defaultValues: { local: '', cultivation: '', variety: '', harvest: '' },
		resolver: yupResolver(getSampleAddSchema()),
	});

	const { control } = methodsAdd;

	//ao submeter o formulário
	const formSubmitHandler: SubmitHandler<ISample> = async (
		payLoad: ISample
	) => {
		try {
			await createSample({ data: payLoad });
			await handlerSuccess();
		} catch (error) { }
	};

	return (
		<Dialog maxWidth="md" open={props.open} fullWidth={true}>
			<DialogTitle id="alert-dialog-title">
				<Typography
					component={"span"}
					color="text.black"
					gutterBottom={true}
					variant="h5"
					noWrap
				>
					{t("morphologicalData.sample.addSample")}
				</Typography>
			</DialogTitle>

			<DialogContent>
				<FormProvider {...methodsAdd}>
					<form
						id="add-user-form"
						onSubmit={methodsAdd.handleSubmit(formSubmitHandler)}
					>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<RequiredField title={t("morphologicalData.sample.name")} />
								<InputText
									control={control}
									name="name"
									placeholder={t("morphologicalData.sample.namePlaceholder")}
								/>
							</Grid>

							<Grid item xs={12}>
								<RequiredField title={t("morphologicalData.sample.local")} />
								{places && places?.length > 0 && (
									<InputSelect
										placeholder={t("morphologicalData.sample.localPlaceholder")}
										name="local"
										control={methodsAdd.control}
										options={places}
									/>
								)}
								{<Loading show={loadingPlaces} />}
							</Grid>

							<Grid item xs={12}>
								<RequiredField title={t("morphologicalData.sample.cultivation")} />
								{cultivations && cultivations?.length > 0 && (
									<InputSelect
										placeholder={t("morphologicalData.sample.cultivationPlaceholder")}
										name="cultivation"
										control={methodsAdd.control}
										options={cultivations}
									/>
								)}
								{<Loading show={loadingCultivations} />}
							</Grid>
							<Grid item xs={12}>
								<RequiredField title={t("morphologicalData.sample.variety")} />
								{varieties && varieties?.length > 0 && (
									<InputSelect
										placeholder={t("morphologicalData.sample.varietyPlaceholder")}
										name="variety"
										control={methodsAdd.control}
										options={varieties}
									/>
								)}
								{<Loading show={loadingVarieties} />}
							</Grid>
							<Grid item xs={12}>
								<RequiredField title={t("morphologicalData.sample.harvest")} />
								{harvests && harvests?.length > 0 && (
									<InputSelect
										placeholder={t("morphologicalData.sample.harvestPlaceholder")}
										name="harvest"
										control={methodsAdd.control}
										options={harvests}
									/>
								)}
								{<Loading show={loadingHarvests} />}
							</Grid>
						</Grid>
					</form>
				</FormProvider>
				<RequiredFields allRequired />
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" color="primary" onClick={props.handlerClose}>
					{t("common.cancel")}
				</Button>

				<LoadingButton
					loading={loading}
					variant="contained"
					color="primary"
					disabled={!methodsAdd.formState.isValid}
					type={"submit"}
					form={"add-user-form"}
				>
					{t("common.add")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}
