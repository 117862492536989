// let addDays = (date: Date | string | number, days: number) => { format };

import { format } from "date-fns";
import { enGB, pt } from "date-fns/locale";

import { getCookie } from "./cookie";
import { I18NEXTLNG_KEY } from "./localStorageKeys";

export function getLocale(){
	const storedLang = getCookie<string>(I18NEXTLNG_KEY) || "en";

  const locales = {en: enGB, pt};
  const locale = locales[storedLang as keyof typeof locales] || enGB;

  return locale;
}

export function formatDate(date: Date | number | string | string[] | undefined, formatStr = "PP") {
	if (!date)  return '--';

  const dt = date && Array.isArray(date) ? new Date(date[0]) : date ? new Date(date) : new Date();
  return format(dt, formatStr, {
    locale: getLocale()
  });
}

export function getLocaleMonth(month: number){
  return getLocale().localize?.month(month); // { width: 'abbreviated' }
}

export function nowDateTime(formatStr = "yyyy/MM/dd HH:mm"): string{
  const  dt = new Date();
  return format(dt, formatStr, {
    locale: getLocale()
  });
}


