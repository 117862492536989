import { BackToButton, EntityInfo, EntityInfoItem, LayoutContext, Loading } from "@alb/live-lib";
import { TabContext, TabPanel } from "@mui/lab";
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Tab, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useErrorHandler, useGet, useGetAll } from "hooks";
import { IApiResponse, IPartner, ISelectOption } from "interfaces";
import { IAdapterConfigurator } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { ContainedTabs, TabPanelContainer } from "styles/css/components";
import { formatDate } from "utils";
import FilterSelect from "utils/FilterSelect";
import { formatRoutePath } from "utils/routePath";

import GatewayDevices from "./GatewayDevices";
import GatewayStreams from "./GatewayStreams";

export default function GatewaysDataView() {
  let params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { sendErrorMessage } = useErrorHandler();
  const theme = useTheme();

  const [, setAdaptersOptions] = useState<ISelectOption[]>([]);
  const [value, setValue] = useState("1");
  const [paramID, setParamID] = useState<string>(params.id || "");

  // o params.id está a ser colocado num estado porque se for enviado diretamente o params.id
  // para as tabs, a prop id atualiza duas vezes nos componentes dentro dos tabuladores,
  // causando pedidos repetidos e cancelados
  useEffect(() => {
    if (params.id) setParamID(params.id);
  }, [params.id]);

  const {
    data: gateway,
    loading,
    error,
  } = useGet<IAdapterConfigurator>(ServiceApiUrl.adapterListURL, params.id);

  const { data: allAdapters } = useGetAll<IApiResponse<IAdapterConfigurator>>(
    ServiceApiUrl.adapterListURL,
    null
  );
  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error]);

  useEffect(() => {
    if (params.idx) {
      const index = Number(params.idx) +1;
      if (index >= 0 && index <= 1) setValue(index.toString());
    }
  }, [params.idx]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue.toString());
  };

  const { data: client, error: clientError } = useGetAll<IPartner>(
    ServiceApiUrl.partnerURL,
    gateway?.partner ? gateway?.partner : ""
  );

  useEffect(() => {
    if (clientError) {
      sendErrorMessage(clientError);
    }
  }, [clientError]);

  //create adapters option for select
  useEffect(() => {
    if (allAdapters) {
      const options = allAdapters?.data?.map((p: IAdapterConfigurator) => ({
        label: p.name,
        value: p.id,
      }));
      const filteredOptions = options.filter((opt:ISelectOption) => { return opt.value !== params.id });
      setAdaptersOptions(filteredOptions);
    }
  }, [allAdapters, params.id]);

  //handle select change
  const handleSelectedAccount = (selectedOption: any) => {
    const path = formatRoutePath(location, params, {
      id: selectedOption.value,
      idx: params.idx,
    });
    navigate(path);
  };

  const customOptions = (data: IApiResponse<any>) => {
    return data.data?.map((c: any) => ({
      label: c.name,
      value: c.id,
    }));
  };

  const { addHeader } = useContext(LayoutContext);
  const header = {
    title: gateway ? gateway.name : "",
    backTo: (
      <BackToButton
        title={t("goBack")}
        onClick={() => navigate("/management/adapters")}
      />
    ),
    select: gateway ? <FilterSelect<any>
                          apiUrl={ServiceApiUrl.adapterListURL}
                          onSelected={handleSelectedAccount}
                          selectedValue={params?.id}
                          customOptions={customOptions}
                        /> : <></>,
  }
  useEffect(() => {
    addHeader(header);
  }, [])
  useEffect(() => {
    addHeader(header)
  }, [gateway])

  return (
    <>
      <Loading show={loading} />

      {gateway && !loading && (
        <>
          <EntityInfo title={t("morphologicalData.details")}>
            <>
              <EntityInfoItem
                label={t("morphologicalData.sample.name")}
                value={gateway.name}
              />
              <EntityInfoItem
                label={t("adapters.status")}
                value={
                  gateway.is_active ? t("common.active") : t("common.inactive")
                }
              />
              <EntityInfoItem
                label={t("adapters.description")}
                value={gateway.description}
              />
              <EntityInfoItem
                label={t("adapters.protectedIngestion")}
                value={
                  gateway.protected_ingestion
                    ? t("common.active")
                    : t("common.inactive")
                }
              />
              <EntityInfoItem
                label={t("adapters.devoDomain")}
                value={gateway.domain_devo}
              />
              <EntityInfoItem
                label={t("adapters.domain")}
                value={gateway.domain}
              />
              <EntityInfoItem
                label={t("adapters.devoClient")}
                value={gateway.partner_devo}
              />

              <EntityInfoItem
                label={t("adapters.client")}
                value={client?.name}
              />

              <EntityInfoItem
                label={t("adapters.createdAt")}
                value={formatDate(
                  gateway.created_at,
                  t("calendar.dateTimeFormatLocal")
                )}
              />
              <EntityInfoItem
                label={t("adapters.updatedAt")}
                value={formatDate(
                  gateway.updated_at,
                  t("calendar.dateTimeFormatLocal")
                )}
              />
            </>
          </EntityInfo>

          <Box
            mt={4}
            sx={{ borderBottom: 0, borderColor: theme.palette.secondary.main }}
          >

            <TabContext value={value}>
              <ContainedTabs indicatorColor="secondary" onChange={handleChange} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                <Tab label={t("adapters.devices")} value="1" />
                <Tab label={t("adapters.streams")} value="2" />
              </ContainedTabs>

              <TabPanel sx={{ padding: 0, paddingTop: 2 }} value="1">
                <TabPanelContainer>
                  {paramID && <GatewayDevices id={paramID} />}
                </TabPanelContainer>
              </TabPanel>
              <TabPanel sx={{ padding: 0, paddingTop: 2 }} value="2">
              <TabPanelContainer>
                  {paramID && <GatewayStreams id={paramID} />}
                </TabPanelContainer>
              </TabPanel>

            </TabContext>
          </Box>
        </>
      )}
    </>
  );
}
