import { useFeedback } from "@alb/live-lib";
import { useTranslation } from "react-i18next";

export const useErrorHandler = () => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();


  const sendErrorMessage = (err: any) => {
		if (err?.code === "ERR_CANCELED") return;
		let msg = t("errors.unknow");

    try {
      msg = err?.response?.data.map((err: any, index: number) => (
        <span key={`err_${err.error_code}_${index}`}>
          {t(`errors.${err.error_code}`) + "\n"} <br />
        </span>
      ));
    } catch (error: any) {
      msg = `${msg} (${err.message || ""})`;
    }

    return addFeedback({ message: msg || err.message, severity: "error" });
  };

  return { sendErrorMessage };
};
