import { arrayIsEmpty, IMap, IMapPoint, Map, SearchBar, TileType, ToggleViewButton, TToggle } from "@alb/live-lib";
import { getDefaultBoxShadow } from '@alb/live-lib'
import { Map as MapIcon, ViewList } from "@mui/icons-material";
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Paper } from "@mui/material";
import { debounce } from "lodash";
import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

import { useGet } from "hooks";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { getDevicesMapDashboard, getMapBoundsDashboard, getSelectedAdapterFilter, getSelectedDevice, getSelectedDeviceId, getTextSearchBar, setBounds, setDevice, setDevicesMapDashboard, setSelectedDeviceId, setTextSearchBar } from "store/slices/dashboardSlice";
import { getFitBounds, setFitBounds } from "store/slices/mapSlice";

import ListDevices from "./ListDevices";

const MapDevices = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const textSearch = useSelector(getTextSearchBar); //text from searchbar
  const user = useSelector(getUser)
  const mapTile = user?.extra_params?.map_tile_layer || TileType.default;
  const mapBounds = useSelector(getMapBoundsDashboard);
  const fitBounds = useSelector(getFitBounds);

  // toggle display state
  const [display, setDisplay] = useState("map");
  const handleDisplay = (
    event: React.MouseEvent<HTMLElement>,
    newDisplay: string | null
  ) => {
    if (newDisplay !== null) {
      setDisplay(newDisplay);
    }
  };
  // toggle button configuration
  const toggleConfig: TToggle[] = [
    {
      value: "map",
      icon: <MapIcon fontSize="small" />,
    },
    {
      value: "list",
      icon: <ViewList fontSize="small" />,
    },
  ];

  const [argsMap, setArgsMap] = useState<IMap | null>(null);
  const [prevBounds, setPrevBounds] = useState<number[][] | undefined>(
    mapBounds
  );
  const initialZoom = 15;
  const selectedDeviceID = useSelector(getSelectedDeviceId);
  const selectedAdapter = useSelector(getSelectedAdapterFilter);
  const markers: IMapPoint[] = useSelector(getDevicesMapDashboard);
  const selectedDevice = useSelector(getSelectedDevice);
  const darkMode = user ? user?.extra_params?.dark_mode : false;

  const options = {
    manual: true,
  };

  const { refetch: refetchDevices } = useGet(
    ServiceApiUrl.devices,
    undefined,
    getParamsRequest(),
    options
  );

  interface OrderParams {
    adapters_id: any;
    points?: any;
  }

  function getParamsRequest() {
    var params1: OrderParams = {
      points: JSON.stringify(mapBounds),
      adapters_id: selectedAdapter.value,
      ...(textSearch?.length > 0 && { contains: textSearch }),
    };
    if (textSearch) {
      delete params1["points"];
      return params1;
    }
    return params1;
  }

  // atualizar devices e texto do SearchBar
  function setTextSearch(text: SetStateAction<string>) {
    dispatch(setFitBounds(true)); //avisa o mapa para centrar os markers
    debounceData(() => {
      dispatch(setTextSearchBar(text));
    });
    if(text.length === 0 && argsMap) {
      setArgsMap((prevArgs) => {
        return {
          ...prevArgs as IMap,
          idMap: uuid(),
        }
      })
    }
  }
  //quando o texto sofrer update do texto do search, faz novamente pedido
  const debounceData = debounce((cb) => {
    cb();
  }, 400);
  useEffect(() => {
    fetchDevicesMarkers();
  }, [textSearch]);

  async function fetchDevicesMarkers() {
    if (display === "map" && !arrayIsEmpty(mapBounds)) {
      await refetchDevices()
        .then((res) => {
          const response = res.data.data;
          dispatch(setDevicesMapDashboard(response));
        })
        .catch((error) => {
          dispatch(setDevicesMapDashboard([]));
        });
    }
  }

  function arraysEqual(a1: any, a2: any) {
    return JSON.stringify(a1) === JSON.stringify(a2);
  }
  useEffect(() => {
    if (mapBounds && !arraysEqual(prevBounds, mapBounds)) {
      fetchDevicesMarkers();
    } else if(textSearch.length > 0 && arraysEqual(prevBounds, mapBounds)) {
      // quando se altera de display e o textSearch esta com valores
      fetchDevicesMarkers();
    }
  }, [mapBounds, display]);

  function getHighlightDevice(markers: IMapPoint[]) {
    let selectedDeviceInfo = markers.find(
      (item: IMapPoint) => item.id === selectedDeviceID
    );
    if (selectedDeviceInfo) {
      selectedDeviceInfo = {
        ...selectedDeviceInfo,
        selected: true,
      };
    }
    return selectedDeviceInfo;
  }

  useEffect(() => {
    if (selectedDevice) {
      const positionDefault =
        textSearch.length === 0 ||
        (textSearch.length > 0 && markers.length === 0)
          ? selectedDevice.geolocation.iconPosition
          : markers[0].geolocation.iconPosition;

      setArgsMap({
        ...defaultArgs,
        mapCenterPoint: positionDefault,
        initialMapCenter: positionDefault, //centro do mapa inicial, estático
        points: markers,
        highlightPoint: getHighlightDevice(markers),
      });
    }
  }, [markers, selectedDevice]);

  useEffect(() => {
    return () => {
      if (textSearch.length > 0) dispatch(setTextSearchBar(""));
      if (fitBounds) dispatch(setFitBounds(false));
      dispatch(setBounds([]));
    };
  }, []);

  const onClickIconMap = (device: IMapPoint) => {
    dispatch(setDevice(device));
    dispatch(setSelectedDeviceId(device.id));
  };

  const handleBoundsChange = (bounds: number[][]) => {
    setPrevBounds(bounds);
    dispatch(setBounds(bounds));
  };

  const handleChangeFitBounds = (fitBoundsValue: boolean) => {
    fitBoundsValue !== fitBounds && dispatch(setFitBounds(fitBoundsValue));
  };

  const translationsMap = {
    buttons: {
      layers: t("map.buttons.layers"),
      recenter: t("map.buttons.recenter"),
      cancel: t("map.buttons.cancel"),
      close: t("common.close"),
    },
    popups: {
      lastRead: t("map.lastReading"),
      showDetail: t("common.showDetail"),
    },
    date: {
      dateTimeFullFormat: t("calendar.dateTimeFullFormat"),
    },
    markers: {
      active: t("common.active"),
      inactive: t("common.inactive"),
    },
    common: {
      noData: t("common.noData"),
    },
  };

  const defaultArgs = {
    mapZoom: initialZoom,
    initialZoom: initialZoom,
    mapTileType: mapTile,
    appDarkThemeMode: darkMode,
    mapVariant: "component" as "component",
    mapModule: false,
    showZoom: true,
    language: user?.language.toLowerCase() || "pt",
    mapTranslations: translationsMap,
    onBounds: handleBoundsChange,
    onPointClick: onClickIconMap,
    searchBarText: textSearch,
    mapFitBounds: fitBounds,
    onChangeFitBounds: handleChangeFitBounds,
  };

  return (
    <Paper
      sx={{
        minWidth: "100%",
        height: 420,
        boxShadow: getDefaultBoxShadow(),
        position: "relative",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          zIndex: 500,
          paddingTop: 1,
          paddingRight: 1,
          position: "absolute",
          width: "50%",
          right: 0,
        }}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item xs={10} lg={7}>
          <SearchBar
            placeholder={t("dashboard.searchDevice")}
            setTerm={setTextSearch}
            term={textSearch}
            tooltipClearText={t("common.clear")}
            tooltipSearchText={t("common.search")}
          />
        </Grid>
        <Grid item>
          <ToggleViewButton
            display={display}
            toggle={toggleConfig}
            onChange={handleDisplay}
          />
        </Grid>
      </Grid>
      {display === "map" && (
        <Box className="map-component">{argsMap && <Map {...argsMap} />}</Box>
      )}
      {display === "list" && <ListDevices />}
    </Paper>
  );
};

export default MapDevices;
