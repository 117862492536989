import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useGetAll } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useHasPermissions } from "hooks/usePermissions";
import { IApiResponse, IVariety } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { PERMISSIONS } from "utils";
import { getSelectedModule } from "utils/permissions/getSelectedModule";

import AddVariety from "./add";
import DeleteVariety from "./delete";
import EditVariety from "./edit";
import { ROWS_PER_PAGE } from "utils/pagination";
import { useNavigate } from "react-router-dom";
import { BackToButton, LayoutContext, Loading } from "@alb/live-lib";
import { CustomTablePagination } from "utils/pagination/CustomTablePagination";
import { useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";
import FiltersSection from "../FiltersSection";
import NoData from "utils/NoData";

export default function VarietiesList() {
  const { t } = useTranslation();
  const { sendErrorMessage } = useErrorHandler();

  const [page, setPage] = useState(1);
  const [ filterSearch, setFilterSearch ] = useState("")
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [selected, setSelectedItem] = useState<IVariety>();
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const auth = useSelector(getUser)
  const moduleID = auth && getSelectedModule(auth, "dados_morfologicos");
  const headers = {
    headers: {
      "MODULE-ID": moduleID,
    },
  };

  const getParams = () => {
    return {
      page: page,
      items: ROWS_PER_PAGE,
      ...(filterSearch !== "" && { contains: filterSearch }),
    }
  }
  //get varieties
  const {
    data: varieties,
    loading,
    error,
    refetch,
  } = useGetAll<IApiResponse<IVariety>>(
    ServiceApiUrl.varietiesListURL,
    getParams(),
    undefined,
    headers
  );

  //para fazer nova chamada das variedades
  const updateData = () => {
    refetch();
  };

  //erro
  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // PERMISSIONS
  const { hasPermission } = useHasPermissions();

  const canList = hasPermission([PERMISSIONS.MANAGEMENT.MODULES.VIEW]);
  const canAdd = hasPermission([PERMISSIONS.MANAGEMENT.MODULES.CUD]);
  const canDelete = hasPermission([PERMISSIONS.MANAGEMENT.MODULES.CUD]);
  const canEdit = hasPermission([PERMISSIONS.MANAGEMENT.MODULES.CUD]);

  //Trocar página da listagem
  const handleChangePage = (
    newPage: number
  ) => {
    setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
  };

  //atualiza o estado do modal de adicionar
  const handlerDialogAddState = () => {
    setOpenDialogAdd(!openDialogAdd);
  };

  //atualiza o estado do modal de remover
  const handlerDialogDeleteState = (
    open = false,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: IVariety
  ) => {
    if (open === true) {
      e.stopPropagation(); //evitar que abra o event do parent (a row)
      setSelectedItem(item);
    }
    setOpenDialogDelete(!openDialogDelete);
  };

  //atualiza o estado do modal de editar
  const handlerDialogEditState = (
    open = false,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: IVariety
  ) => {
    if (open === true) {
      e.stopPropagation(); //evitar que abra o event do parent (a row)
      setSelectedItem(item);
    }
    setOpenDialogEdit(!openDialogEdit);
  };

  const navigate = useNavigate();
  const { addHeader } = useContext(LayoutContext)
  const header = {
    title: t("morphologicalData.varieties.varieties"),
    backTo: (
      <BackToButton
        title={t("goBack")}
        onClick={() => navigate("/management/modules/morphologicaldata")}
      />
    ),
    action: canAdd && (
          <Button
          color="primary"
          onClick={() => setOpenDialogAdd(true)}
          variant="contained"
          startIcon={<AddIcon />}
        >
          {t("morphologicalData.varieties.varietyAdd")}
        </Button>
      )

  }
  useEffect(() => {
    addHeader(header);
  	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <FiltersSection setPage={setPage} setFilterSearch={setFilterSearch} />
      <Loading show={loading}/>

      {varieties && canList && varieties?.data.length === 0 && !loading && <NoData />}
      {varieties && canList && varieties?.data.length > 0 && !loading && (
        <>
          <TableContainer>
            <Table
              sx={{ minWidth: 650, marginTop: 2 }}
              aria-label="simple table"
            >
              <TableBody>
                {varieties?.data.map((item: IVariety, index: number) => (
                  <TableRow
                    key={item.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                      {canEdit && (
                        <IconButton
                          title={t("common.edit")}
                          onClick={(
                            e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                          ) => handlerDialogEditState(true, e, item)}
                          sx={{padding: 0}}
                        >
                          <EditIcon />
                        </IconButton>
                      )}

                      {canDelete && (
                        <IconButton
                          title={t("common.delete")}
                          aria-label="delete"
                          onClick={(
                            e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                          ) => handlerDialogDeleteState(true, e, item)}
                          sx={{padding: 0}}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomTablePagination
            count={varieties?.totalCount}
            page={page}
            totalPages={varieties?.totalPages}
            onPageChange={handleChangePage}

          />
        </>
      )}
      <AddVariety
        open={openDialogAdd}
        handlerClose={handlerDialogAddState}
        updateGet={updateData}
      />
      {selected && (
        <>
          <DeleteVariety
            item={selected}
            open={openDialogDelete}
            handlerClose={handlerDialogDeleteState}
            updateGet={updateData}
          />
          <EditVariety
            item={selected}
            open={openDialogEdit}
            handlerClose={handlerDialogEditState}
            updateGet={updateData}
          />
        </>
      )}
    </>
  );
}
