interface IClientName {
  banana: string;
  eno: string;
}

export const CLIENTS = {
  BANANA: 'BaSense',
  ENO: 'EnoAnalytics',
}

function fixClientName(name: string): string {  
  const clientsNames: IClientName = {
    banana: "BaSense",
    eno: "EnoAnalytics"
  };
  return clientsNames[name as keyof IClientName];
}

export default fixClientName;
