/* eslint-disable react-hooks/exhaustive-deps */
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Tab, useTheme } from "@mui/material";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FloweringFruiting from "components/morphologicaldata/FloweringFruiting";
import HarvestMaturation from "components/morphologicaldata/HarvestMaturation";
import Pictures from "components/morphologicaldata/Pictures";
import VegetativeDevelopment from "components/morphologicaldata/VegetativeDevelopment";
import { useErrorHandler, useGet, useGetAll } from "hooks";
import { useHasPermissions } from "hooks/usePermissions";
import { IApiResponse, ISamplePath, ISelectOption } from "interfaces";
import { ISample } from "interfaces/ISample";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getLocalStorage, PERMISSIONS, SAMPLE_PATH_KEY } from "utils";
import { getSelectedModule } from "utils/permissions/getSelectedModule";
import { formatRoutePath } from "utils/routePath";
import SampleEdit from "./sample/editSample";
import { BackToButton, EntityInfo, EntityInfoItem, LayoutContext, Loading } from "@alb/live-lib";
import { TabContext, TabPanel } from "@mui/lab";
import { ContainedTabs, TabPanelContainer } from "styles/css/components";
import FilterSelect from "utils/FilterSelect";
import { useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";

const checkURL = (path: ISamplePath | null) => {
  return path?.url?.includes("management") ? true : false;
};

const checkBackPage = (path: ISamplePath | null) => {
  let back: { label: string; url: string };
  if (checkURL(path)) {
    back = {
      label: t("morphologicalData.samples"),
      url: "/management/modules/morphologicalData/samples",
    };
    return back;
  } else {
    back = {
      label: t("morphologicalData.morphologicalData"),
      url: "/morphologicaldata/samples",
    };
    return back;
  }
};

export default function MorphologicalDataView() {
  // params
  let params = useParams();
  const location = useLocation();
  const prevUrl = getLocalStorage<ISamplePath>(SAMPLE_PATH_KEY);
  const theme = useTheme();
  const { hasPermission } = useHasPermissions();
  // PERMISSIONS
  // const canEdit = hasPermission([PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.CUD]);
  const canEditSample = hasPermission([PERMISSIONS.MANAGEMENT.MODULES.CUD]);

  //traduções
  const { t } = useTranslation();
  const { sendErrorMessage } = useErrorHandler();
  const navigate = useNavigate();
  // console.log('navigate---', navigate(-1))

  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [, setSamplesOptions] = useState<ISelectOption[]>([]);
    const auth = useSelector(getUser)
  const moduleID = auth && getSelectedModule(auth, "dados_morfologicos");
  const headers = {
    headers: {
      "MODULE-ID": moduleID,
    },
  };

  const {
    data: sample,
    loading,
    error,
    refetch,
  } = useGet<ISample>(
    ServiceApiUrl.sampleURL,
    params.id,
    undefined,
    undefined,
    headers
  );

  const { data: allSamples, refetch: refetchSamples } = useGetAll<
    IApiResponse<ISample>
  >(ServiceApiUrl.sampleURL, null, { manual: false }, headers);

  //para fazer nova chamada das samples
  const updateSamples = () => {
    refetch(); //atualiza a sample
    refetchSamples(); //atualiza as opções
  };

  useEffect(() => {
    if (allSamples) {
      const options = allSamples?.data?.map((p: ISample) => ({
        label: p.name,
        value: p.id,
      }));
      // remove id from options
      const filteredOptions = options.filter((opt: ISelectOption) => {
        return opt.value !== params.id;
      });
      filteredOptions.sort((a, b) => a.label.localeCompare(b.label))
      setSamplesOptions(filteredOptions);
    }
  }, [allSamples, params.id]);

  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const [value, setValue] = useState("1");

  useEffect(() => {
    if (params.idx) {
      const index = Number(params.idx) + 1;
      if (index > 0 && index <= 4) setValue(index.toString());
    }
  }, [params.idx]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue.toString());
  };

  //atualiza o estado do modal de editar
  const handlerDialogEditState = () => {
    setOpenDialogEdit(!openDialogEdit);
  };

  const handleSelectedSample = (selectedOption: any) => {
    // console.log('handleSelectedSample::: selectedOption', selectedOption, location.pathname, path);
    const path = formatRoutePath(location, params, {
      id: selectedOption.value,
      idx: "1",
    });
    navigate(path);
  };

  const customOptions = (data: IApiResponse<any>) => {
    return data.data?.map((c: any) => ({
      label: c.name,
      value: c.id,
    }));
  };

  const { addHeader } = useContext(LayoutContext);
  const header = {
    title: sample ? sample.name : "",
    backTo: (
      <BackToButton
        title={t("goBack")}
        onClick={() => navigate(checkBackPage(prevUrl).url)}
      />
    ),
    select: sample ? <FilterSelect<any>
              apiUrl={ServiceApiUrl.sampleURL}
              onSelected={handleSelectedSample}
              selectedValue={params?.id}
              customOptions={customOptions}
            /> : <></>,
    action:
      checkURL(prevUrl) &&
      canEditSample && (
        <>
          <Button
            color="primary"
            onClick={handlerDialogEditState}
            variant="contained"
            startIcon={<EditIcon />}
          >
            {t("common.edit")}
          </Button>
        </>
      )

  }

  useEffect(()=> {
    addHeader(header)
  }, [])

  useEffect(()=> {
    addHeader(header)
  }, [sample])

  return (
    <>
      <Loading show={loading} />

      {sample && !loading && (
        <>
          <EntityInfo title={t("morphologicalData.details")}>
            <>
              <EntityInfoItem
                label={t("morphologicalData.sample.name")}
                value={sample.name}
              />
              <EntityInfoItem
                label={t("morphologicalData.sample.local")}
                value={sample.local.name}
              />
              <EntityInfoItem
                label={t("morphologicalData.sample.cultivation")}
                value={sample.cultivation.name}
              />
              <EntityInfoItem
                label={t("morphologicalData.sample.variety")}
                value={sample.variety.name}
              />
              <EntityInfoItem
                label={t("morphologicalData.sample.harvest")}
                value={sample.harvest.name}
              />
            </>
          </EntityInfo>

          <Box
            mt={4}
            sx={{ borderBottom: 0, borderColor: theme.palette.secondary.main }}
          >

            <TabContext value={value}>
              <ContainedTabs indicatorColor="secondary" onChange={handleChange} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                <Tab label={t("morphologicalData.vegetativeDev.vegetativeDevelopment")} value="1" />
                <Tab label={t("morphologicalData.floweringFruit.floweringFruiting")} value="2" />
                <Tab label={t("morphologicalData.harvestMatur.harvestMaturation")} value="3" />
                <Tab label={t("morphologicalData.pictures")} value="4" />
              </ContainedTabs>

              <TabPanel sx={{ padding: 0, paddingTop: 2 }} value="1">
                <TabPanelContainer>
                  {params.id && <VegetativeDevelopment sample={sample} />}
                </TabPanelContainer>
              </TabPanel>
              <TabPanel sx={{ padding: 0, paddingTop: 2 }} value="2">
                <TabPanelContainer>
                  {params.id && <FloweringFruiting sample={sample} />}
                </TabPanelContainer>
              </TabPanel>
              <TabPanel sx={{ padding: 0, paddingTop: 2 }} value="3">
                <TabPanelContainer>
                  {params.id && <HarvestMaturation sample={sample} />}
                </TabPanelContainer>
              </TabPanel>
              <TabPanel sx={{ padding: 0, paddingTop: 2 }} value="4">
                <TabPanelContainer>
                  {params.id && <Pictures sample={sample} />}
                </TabPanelContainer>
              </TabPanel>
            </TabContext>

          </Box>
        </>
      )}

      {sample && openDialogEdit && (
        <SampleEdit
          sample={sample}
          open={openDialogEdit}
          handlerClose={handlerDialogEditState}
          updateGetSamples={updateSamples}
        />
      )}
    </>
  );
}
