import { Loading, TableHeadCell, TableHeadOrder, TableHeadSort } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Chip, Collapse, Grid, IconButton, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import clsx from 'clsx';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import RowItem from "components/utils/Table/RowItem";
import { useErrorHandler, useGetAll } from "hooks";
import { useHasPermissions } from "hooks/usePermissions";
import { IStream } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { TableCellExpanded, TableExpanded, TableRowExpanded } from "styles/components-styles";
import { arrayIsEmpty, formatDate, formatOrderBy, PERMISSIONS, statusColor } from "utils";

interface IGatewayStreams {
  id: string;
}

function Row(props: { row: IStream; open: boolean; onClick: Function }) {
  const { row, open, onClick } = props;
  const { t } = useTranslation();

  return (
    <>
      <TableRow
      className={clsx({ expanded: open })}
      onClick={() => onClick(open ? "" : row.id)}>
        <TableCell sx={{width: 50 }} size="small">
          <IconButton size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {t(`streams.${row.name}`)}
        </TableCell>
        <TableCell>
          <Chip
            size="small"
            label={row.is_active ? t('common.active') : t('common.inactive')}
            color={
              row.is_active ? statusColor('active') : statusColor('non-active')
            }
          />
        </TableCell>
        <TableCell>{row.unit}</TableCell>
        <TableCell>
          {formatDate(row.created_at, t('calendar.dateTimeFormatLocal'))}
        </TableCell>
      </TableRow>
      <TableRowExpanded>
        <TableCellExpanded colSpan={7}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container columns={{ xs: 2, sm: 6, md: 8, lg: 12 }}>
              <RowItem label={t("adapters.id")} value={row.id} />
              <RowItem label={t("adapters.category")} value={row.category.name} />
              <RowItem
                label={t("adapters.description")}
                value={row.description}
              />
              <RowItem
                label={t("adapters.updatedAt")}
                value={formatDate(
                  row.updated_at,
                  t("calendar.dateTimeFormatLocal")
                )}
              />
            </Grid>
          </Collapse>
        </TableCellExpanded>
      </TableRowExpanded>
    </>
  );
}

function GatewayStreams({ id }: IGatewayStreams) {
  const { sendErrorMessage } = useErrorHandler();
  const { t } = useTranslation();

  

  const [openRow, setOpenRow] = useState("");
  const [order, setOrder] = useState<TableHeadOrder>("asc");
  const [orderBy, setOrderBy] = useState<string>("name");

  const {
    data: gatewayStreams,
    loading,
    error,
  } = useGetAll<{totalPages: number, totalCount: number, data: IStream[]}>(
    ServiceApiUrl.streams,
    {
      adapters_id: id,
      order_by: formatOrderBy(order, orderBy),
    }
    );

  //erro
  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error]);

  const { hasPermission } = useHasPermissions();
  const canList = hasPermission([PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.VIEW]);

  const tableHeads: TableHeadCell[] = [
    {
      id: "expand_cell",
      label: "",
      notSortable: true,
    },
    {
      id: "name",
      label: t("common.name"),
    },
    {
      id: "is_active",
      label: t("common.statusLabel"),
    },
    {
      id: "unit",
      label: t("adapters.unit"),
      notSortable: true,
    },
    {
      id: "created_at",
      label: t("common.createdAt"),
    },
  ];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div>
      <Grid
        container
        mt={1}
        alignItems="center"
        direction="row"
        sx={{ justifyContent: "end" }}
      >
        <Grid item xs={true}>
        <Typography variant="h3">
            {t("adapters.streams")}
          </Typography>
        </Grid>
      </Grid>
      <Loading show={loading} />

      {arrayIsEmpty(gatewayStreams?.data) && !loading && canList && (
        <Typography mt={4} mb={2} variant="body1">
          {t("adapters.noStreams")}
        </Typography>
      )}

      {gatewayStreams && !arrayIsEmpty(gatewayStreams?.data) && !loading && (
        <>
          <TableContainer>
            <TableExpanded>
              <TableHeadSort
                headCells={tableHeads}
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
              />
              <TableBody>
                {gatewayStreams?.data.map(
                  (stream: IStream, index: number) => (
                    <Row
                      key={stream.id}
                      row={stream}
                      open={stream.id === openRow}
                      onClick={(id: string) => setOpenRow(id)}
                    />
                  )
                )}
              </TableBody>
            </TableExpanded>
          </TableContainer>
        </>
      )}
    </div>
  );
}

export default GatewayStreams;
