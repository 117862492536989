import { CLIENTS } from "components/utils/Client";
import { IUser } from "interfaces";
import { arrayIsEmpty } from "utils/utils";
import { adminRoles } from "./adminRoles";
import { bananaRoles } from "./bananaRoles";
import { PERMISSIONS } from "./permissions";
import { superUserRoles } from "./superAdminRoles";
import { userRoles } from "./userRoles";

export const getUserPermissions = (userLogin: IUser) => {

  function checkPermissions(valuePermi: string ){
    let permissionsArray: number[] = [];
    let arr: Array<string> = valuePermi.split("");
    arr.map((value:string, index:number) => {
      switch (true) {
      case value === '1' && index === 0:
        permissionsArray = [...permissionsArray, PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.ADD]
      break;
      case value === '1' && index === 1:
        permissionsArray = [...permissionsArray, PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.VIEW]
      break;
      case value === '1' && index === 2:
        permissionsArray = [...permissionsArray, PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.EDIT]
      break;
      case value === '1' && index === 3:
        permissionsArray = [...permissionsArray, PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.DELETE]
      break;
      default:
        break;
      }
    return permissionsArray
    })
  return permissionsArray
}

  let permissions = userRoles;
  if (userLogin.is_admin) permissions = adminRoles;
  if (userLogin.is_superadmin) permissions = superUserRoles;

  var bananaModules = ['dados_morfologicos'] //TODO: verificar que abordagem adotar aqui, para não ficar estático

  var idAdminOrSuperAdmin = (userLogin?.is_admin || userLogin?.is_superadmin)

  if (userLogin.client) {

    var userHasModules = (!arrayIsEmpty(userLogin?.client.modules))
    var userHasModulesAndPermissions = ((!arrayIsEmpty(userLogin?.client.modules) && (!arrayIsEmpty(userLogin?.permission))))


    // se um cliente normal tiver valores nos módulos e nas permissões
    if (userHasModulesAndPermissions) {
      permissions = [...permissions, PERMISSIONS.MODULES.VIEW];
    }
    //se o cliente for admin/super admin e tiver valores nos módulos
    if ((idAdminOrSuperAdmin && userHasModules)) {
      permissions = [...permissions,
        PERMISSIONS.MODULES.VIEW, 
        PERMISSIONS.MANAGEMENT.MODULES.VIEW,
        PERMISSIONS.MANAGEMENT.MODULES.CUD,];
    }
    if (!idAdminOrSuperAdmin) {
      permissions = [...permissions,
        PERMISSIONS.MANAGEMENT.VIEW,
        PERMISSIONS.MANAGEMENT.USERS.VIEW
      ];
    }

    if (userLogin.client.name === CLIENTS.BANANA) {
      //se for um admin ou superadmin fica com as permissões todas
      if (idAdminOrSuperAdmin) {
        permissions = [...permissions, ...bananaRoles];
      }
      //se for um user normal, tem de verificar se tem permissões e se o read do value é igual a 1
      else {
        if(userLogin?.permission.length > 0) {
          const module = userLogin?.permission.filter(p => p.module_name === bananaModules[0])
          if (module[0].module_name === bananaModules[0] &&
            !arrayIsEmpty(userLogin?.permission)) {
            const addPermissions =  checkPermissions(module[0].value)
            permissions = [...permissions, ...addPermissions,
            PERMISSIONS.DASHBOARD.BANANASENSE.VIEW,
            PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.VIEW,
            PERMISSIONS.MODULES.MORPHOLOGICAL_DATA.STAGES.VIEW];
          }
        }
      }
    }
    else if (userLogin.client.name === CLIENTS.ENO) {
      permissions = [...permissions, PERMISSIONS.DASHBOARD.ENOANALYTICS.VIEW];
    }
    else {
      if (!idAdminOrSuperAdmin) {
        permissions = permissions.filter(item => item !== PERMISSIONS.DASHBOARD.VIEW);
      }
        
    }
  }


  return permissions;
};
