import { AxiosRequestConfig } from "axios";
import useAxios from "axios-hooks";
import { IFetchDataOptions } from "interfaces";

export function useGetAll<T = any>(
  url: string,
  params?: any,
  options?: IFetchDataOptions,
  othersConfig?: AxiosRequestConfig<any>
) {
  const config: AxiosRequestConfig = {
    ...othersConfig,
    url,
    params: { ...params } 
  };

  const [{ data, loading, error }, refetch, cancelRequest] = useAxios<T>(
    config,
    { manual: options?.manual }
  );

  return { data, loading, error, refetch, cancelRequest };
}
