/* eslint-disable react-hooks/exhaustive-deps */
import { LayoutContext } from "@alb/live-lib";
import { Grid } from "@mui/material";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ModuleGridItem } from "utils/ModuleGridItem";
import { getRoutePath } from "utils/routePath";

export default function Modules() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();

  function navigateTo(page: string) {
    const path = getRoutePath(location, params);
    navigate(`${path}/${page}`);
  }

  const { addHeader } = useContext(LayoutContext);
  const header = {
    title: t("sidebar.menu.modules")
  }

  useEffect(() => {
    addHeader(header)
  }, [])

  return (
    <>
      <Grid container mt={5} spacing={2} mb={1}>
       <ModuleGridItem title={t("morphologicalData.morphologicalData")} onClick={() => navigateTo("morphologicalData")} />
      </Grid>
    </>
  );
}
