import { Loading, TableHeadCell, TableHeadOrder, TableHeadSort } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/Edit";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { Button, Collapse, Grid, IconButton, Stack, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ChipStatus } from "components/utils/ChipStatus";
import RowItem from "components/utils/Table/RowItem";
import UsersAdd from "components/utils/users/userCreate";
import UsersDelete from "components/utils/users/userDelete";
import UsersEdit from "components/utils/users/userEdit";
import UserLanguage from "components/utils/users/userLanguage";
import UserPermissions from "components/utils/users/userPermissions";
import { useErrorHandler, useGetAll } from "hooks";
import { useHasPermissions } from "hooks/usePermissions";
import { IApiResponse, IModule, IUser, IUserPermission } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { TableCellExpanded, TableExpanded, TableRowExpanded } from "styles/components-styles";
import { arrayIsEmpty, formatDate, formatOrderBy, PERMISSIONS, statusColor } from "utils";
import { ROWS_PER_PAGE } from "utils/pagination";
import { CustomTablePagination } from "utils/pagination/CustomTablePagination";
import UserInvite from "views/users/inviteUser";

interface IUsersListTab {
	filters: { clientID: string };
}

function Row(props: {
	row: IUser;
	open: boolean;
	onClick: Function;
	actionsClick: {
		editAction: Function;
		deleteAction: Function;
		inviteAction: Function;
	};
}) {
	const { row, open, onClick, actionsClick } = props;
	const { editAction, deleteAction, inviteAction } = actionsClick;
	const { t } = useTranslation();

	// PERMISSIONS
	const { hasPermission } = useHasPermissions();
	const canDelete = hasPermission([PERMISSIONS.MANAGEMENT.USERS.CUD]);
	const canEdit = hasPermission([PERMISSIONS.MANAGEMENT.USERS.CUD]);

	return (
		<>
			<TableRow
				className={clsx({ expanded: open })}
				onClick={() => onClick(open ? "" : row.id)}
			>
				<TableCell sx={{ width: 50 }} size="small">
					<IconButton size="small" sx={{ padding: 0 }}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell>{`${row.first_name} ${row.last_name}`}</TableCell>
				<TableCell>
					<Stack direction="row" alignItems="flex-end" gap={1}>
						{row.username}
						{(row.is_superadmin || row.is_admin) && (
							<SupervisorAccountIcon
								fontSize="small"
								color={row.is_superadmin ? "primary" : "inherit"}
								titleAccess={
									row.is_superadmin
										? t("user.SuperAdministrator")
										: row.is_admin
											? t("user.Administrator")
											: ""
								}
							/>
						)}
					</Stack>
				</TableCell>
				<TableCell>
					<ChipStatus
						label={t(`user.status.${row.status}`)}
						color={statusColor(row.status)}
					/>
				</TableCell>
				<TableCell>
					{row.account?.name}
				</TableCell>
				<TableCell>
					{formatDate(row.created_at, t("calendar.dateTimeFormatLocal"))}
				</TableCell>
				<TableCell>
					{formatDate(row.updated_at, t("calendar.dateTimeFormatLocal"))}
				</TableCell>

				<TableCell sx={{ textAlign: "right" }}>

					<IconButton
						title={t("common.edit")}
						onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
							if (canEdit) {
								e.stopPropagation()
								editAction(true, e, row);
							}

						}}
						sx={{ padding: 0 }}
						disabled={canEdit ? false : true}
					>
						<EditIcon />
					</IconButton>

					<IconButton
						title={t("common.delete")}
						aria-label="delete"
						onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
							if (canDelete) {
								e.stopPropagation();
								deleteAction(true, e, row);
							}

						}}
						sx={{ padding: 0 }}
						disabled={canDelete ? false : true}
					>
						<DeleteIcon />
					</IconButton>
					<IconButton
						title={t("user.reinviteUser")}
						onClick={(
							e: React.MouseEvent<HTMLButtonElement, MouseEvent>
						) => {
							if ((!row.last_pwd_update || row.status === "deleted")) {
								e.stopPropagation()
								inviteAction(true, e, row)
							}

						}}
						sx={{ padding: 0 }}
						disabled={(!row.last_pwd_update || row.status === "deleted") ? false : true}
					>
						<EmailRoundedIcon />
					</IconButton>

				</TableCell>
			</TableRow>
			<TableRowExpanded>
				<TableCellExpanded colSpan={7}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<UserInformation user={row} />
					</Collapse>
				</TableCellExpanded>
			</TableRowExpanded>
		</>
	);
}

function UserInformation(props: { user: IUser }) {
	const { user } = props;
	const { t } = useTranslation();

	return (
		<>
			<Grid container rowGap={2} columns={{ xs: 2, sm: 6, md: 8, lg: 12 }}>
				<RowItem label={t("common.userName")} value={user.username} />
				<RowItem label={t("common.email")} value={user.email} fitContent />
				<RowItem
					label={t("user.Administrator")}
					value={user.is_admin ? t("common.yes") : t("common.no")}
				/>
				<RowItem
					label={t("user.SuperAdministrator")}
					value={user.is_superadmin ? t("common.yes") : t("common.no")}
				/>
				<RowItem label={t("user.language")}>
					<UserLanguage language={user.language} />
				</RowItem>
				<RowItem label={t("accounts.account")} value={user.account?.name} />
			</Grid>
			{!user.is_superadmin && !user.is_admin && user.permission && (
				<Grid container rowGap={2} marginTop={2} columns={{ xs: 12 }}>
					<UserPermissions permissions={user.permission} />
				</Grid>
			)}
		</>
	);
}

function UsersListTab({ filters }: IUsersListTab) {
	const { sendErrorMessage } = useErrorHandler();
	const { t } = useTranslation();

	const [page, setPage] = useState(1);
	const [order, setOrder] = useState<TableHeadOrder>("asc");
	const [orderBy, setOrderBy] = useState<string>("full_name");

	const {
		data: users,
		loading,
		error,
		refetch,
	} = useGetAll<IApiResponse<IUser>>(ServiceApiUrl.userURL, {
		client: filters.clientID,
		page: page,
		items: ROWS_PER_PAGE,
		order_by: orderByAPI(),
	});

	function orderByAPI() {
		let _orderBy = formatOrderBy(order, orderBy);
		if (orderBy === "full_name") {
			_orderBy = ["first_name", "last_name"]
				.map((o) => (order === "desc" ? "-" + o : o))
				.join(",");
		}
		return _orderBy;
	}

	const { data: allModules } = useGetAll<IApiResponse<IModule>>(
		ServiceApiUrl.moduleURL,
		{ client: filters.clientID }
	);
	const [permissions, setPermissions] = useState<IUserPermission[]>([]);
	useEffect(() => {
		if (allModules) {
			const perm = allModules?.data.map((m: IModule) => ({
				module_name: m.name,
				module_id: m.id,
				value: "0000",
			}));
			setPermissions(perm);
		}
	}, [allModules]);

	const [openRow, setOpenRow] = useState("");

	//erro
	useEffect(() => {
		if (error) {
			sendErrorMessage(error);
		}
	}, [error]);

	//para fazer nova chamada dos Users
	const updateUsers = () => {
		refetch();
	};

	const { hasPermission } = useHasPermissions();
	const canAdd = hasPermission([PERMISSIONS.MANAGEMENT.USERS.CUD]);
	const canList = hasPermission([PERMISSIONS.MANAGEMENT.USERS.VIEW]);
	const canEdit = hasPermission([PERMISSIONS.MANAGEMENT.USERS.CUD]);
	const canDelete = hasPermission([PERMISSIONS.MANAGEMENT.USERS.CUD]);

	const [selectedUser, setSelectedUser] = useState<IUser>();
	const [openDialogEdit, setOpenDialogEdit] = useState(false);
	const [openDialogDelete, setOpenDialogDelete] = useState(false);
	const [openDialogInvite, setOpenDialogInvite] = useState(false);

	//atualiza o estado do user a desactivar
	const handlerDialogDeleteUser = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		user: IUser
	) => {
		if (open) {
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setSelectedUser(user);
		}
		setOpenDialogDelete(!openDialogDelete);
	};

	//reconvidar utilizador
	const handlerDialogInvite = (open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		user: IUser) => {
		if (open === true) {
			//nesta situação, chega um evento
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setSelectedUser(user); //troca o user pelo escolhido
		}
		setOpenDialogInvite(!openDialogInvite);
	}

	//atualiza o estado do user de editar
	const handlerDialogEditUser = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		user: IUser
	) => {
		if (open) {
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setSelectedUser(user);
		}
		setOpenDialogEdit(!openDialogEdit);
	};
	//Trocar página da listagem
	const handleChangePage = (
		newPage: number
	) => {
		setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
	};

	const [openDialogAddUser, setOpenDialogAddUser] = useState(false);
	const onAddClick = () => {
		setOpenDialogAddUser(true);
	};

	const tableHeads: TableHeadCell[] = [
		{
			id: "expand_cell",
			label: "",
			notSortable: true,
		},
		{
			id: "full_name",
			label: t("common.name"),
		},
		{
			id: "username",
			label: t("user.Username"),
		},
		{
			id: "status",
			label: t("common.statusLabel"),
		},
		{
			id: "account",
			label: t("accounts.account"),
		},
		{
			id: "created_at",
			label: t("common.createdAt"),
		},
		{
			id: "last_login",
			label: t("common.updatedAt"),
		},
		{
			id: "action_cell",
			label: "",
			notSortable: true,
		},
	];

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: string
	) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	return (
		<div>
			<Grid
				container
				mt={1}
				alignItems="center"
				direction="row"
				sx={{ justifyContent: "end" }}
			>
				<Grid item xs={true}>
					<Typography variant="h3">
						{t("lists.usersList")}
					</Typography>
				</Grid>
				{canAdd && (
					<Grid item>
						<Button
							color="primary"
							onClick={onAddClick}
							variant="contained"
							size="medium"
							startIcon={<AddIcon />}
						>
							{t("common.add")}
						</Button>
					</Grid>
				)}
			</Grid>
			<Loading show={loading} />

			{arrayIsEmpty(users?.data) && !loading && canList && (
				<Typography mt={4} mb={2} variant="body1">
					{t("user.noUsers")}
				</Typography>
			)}

			{users && !arrayIsEmpty(users?.data) && !loading && (
				<>
					<TableContainer>
						<TableExpanded>
							<TableHeadSort
								headCells={tableHeads}
								onRequestSort={handleRequestSort}
								order={order}
								orderBy={orderBy}
							/>
							<TableBody>
								{users?.data?.map((user: IUser, index: number) => (
									<Row
										key={user.id}
										row={user}
										open={user.id === openRow}
										onClick={(id: string) => setOpenRow(id)}
										actionsClick={{
											editAction: handlerDialogEditUser,
											deleteAction: handlerDialogDeleteUser,
											inviteAction: handlerDialogInvite
										}}
									/>
								))}
							</TableBody>
						</TableExpanded>
					</TableContainer>

					<CustomTablePagination
						count={users?.totalCount}
						page={page}
						totalPages={users?.totalPages}
						onPageChange={handleChangePage}

					/>
				</>
			)}

			{canAdd && openDialogAddUser && (
				<UsersAdd
					allModules={allModules}
					administration
					permissions={permissions}
					clientID={filters.clientID}
					open={openDialogAddUser}
					handlerClose={() => setOpenDialogAddUser(false)}
					updateList={updateUsers}
				/>
			)}

			{canDelete && selectedUser && openDialogDelete && (
				<UsersDelete
					user={selectedUser}
					open={openDialogDelete}
					handlerClose={() => setOpenDialogDelete(false)}
					updateList={updateUsers}
				/>
			)}

			{canEdit && selectedUser && openDialogEdit && (
				<UsersEdit
					administration
					user={selectedUser}
					clientID={filters.clientID}
					open={openDialogEdit}
					handlerClose={() => setOpenDialogEdit(false)}
					updateList={updateUsers}
				/>
			)}
			{selectedUser && openDialogInvite && (
				<UserInvite
					user={selectedUser}
					open={openDialogInvite}
					handlerClose={handlerDialogInvite}
					updateGetUsers={updateUsers}
				/>
			)}
		</div>
	);
}

export default UsersListTab;
