import { IItemList, IMapPoint } from "@alb/live-lib";
import { createSlice } from "@reduxjs/toolkit";
import { ISelectOption } from "interfaces";
import { TMarker } from "interfaces/TMarker";
import { RootState } from "store/store";

interface IDashboard {
  selectedDevice: TMarker | null;
  selectedAdapter: ISelectOption;
  devicesSelectedAdapter: TMarker[];
  devicesMapList: IItemList[];
  deviceDefaultMapList: IItemList;
  textSearchBar: string;
  selectedDeviceId: string;
  bounds?: number[][];
  devices: TMarker[];
  deviceInfo: any;
}

const initialState: IDashboard = {
  selectedDevice: null,
  selectedAdapter: {
    label: "",
    value: "",
  },
  devicesSelectedAdapter: [],
  devicesMapList: [],
  deviceDefaultMapList: {
    title: "",
    idItem: ""
  },
  textSearchBar: "",
  selectedDeviceId: "",
  bounds: [],
  devices: [],
  deviceInfo: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDevice(state, action) {
      state.selectedDevice = action.payload;
    },
    setSelectedDeviceId(state, action) {
      state.selectedDeviceId = action.payload;
    },
    setSelectedAdapterFilter(state, action) {
      state.selectedAdapter = action.payload;
    },
    setDevicesAdapter(state, action) {
      state.devicesSelectedAdapter = action.payload;
      state.devicesMapList = action.payload.map((device: IMapPoint) => {
        return {
          title: device.title,
          idItem: device.id,
        };
      });
    },
    setDeviceDefaultMapList(state, action) {
      state.deviceDefaultMapList = action.payload;
    },
    setTextSearchBar(state, action) {
      state.textSearchBar = action.payload;
    },
    setBounds(state, action) {
      state.bounds = action.payload;
    },
    setDevicesMapDashboard(state, action) {
      state.devices = action.payload;
    },
    setSelectedDeviceInfo(state, action) {
      state.deviceInfo = action.payload;
    },
    setOneDeviceMapDashboard(state, action) {
      state.devices = state.devices.map((dev) => {
        if (dev.external_id === action.payload.external_id) {
          dev = action.payload;
        }
        return dev;
      });
    },
  },
});

export const {
  setDevice,
  setSelectedAdapterFilter,
  setDevicesAdapter,
  setDeviceDefaultMapList,
  setTextSearchBar,
  setSelectedDeviceId,
  setBounds,
  setDevicesMapDashboard,
  setSelectedDeviceInfo,
  setOneDeviceMapDashboard,
} = dashboardSlice.actions;

export const getSelectedDevice = (state: RootState) =>
  state.dashboard.selectedDevice;
export const getSelectedAdapterFilter = (state: RootState) =>
  state.dashboard.selectedAdapter;
export const getDevicesSelectedAdapter = (state: RootState) =>
  state.dashboard.devicesSelectedAdapter;
export const getDeviceDefaultMapList = (state: RootState) =>
  state.dashboard.deviceDefaultMapList;
export const getTextSearchBar = (state: RootState) =>
  state.dashboard.textSearchBar;
export const getSelectedDeviceId = (state: RootState) =>
  state.dashboard.selectedDeviceId;
export const getMapBoundsDashboard = (state: RootState) =>
  state.dashboard.bounds;
export const getDevicesMapDashboard = (state: RootState) =>
  state.dashboard.devices;
export const getSelectedDeviceInfo = (state: RootState) =>
  state.dashboard.deviceInfo;
export default dashboardSlice.reducer;
