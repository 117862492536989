import { CssBaseline, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

interface ILiveFooter {
    maxWidth?: string
    backgroundImage?: string
}

const LiveFooter = ({ maxWidth, backgroundImage }: ILiveFooter) => {

    const { t } = useTranslation();

    return (
        <>

            <CssBaseline />

            <Grid container alignItems="flex-end" justifyContent="center" className={'footerDiv'}>

                <img src={backgroundImage} alt={t('footer.RecoverPasswordBackgroundImage')} className={'backgroundImg'} />

                <Grid maxWidth={maxWidth} className='footer' >
                    <Box>
                        {/* TODO: colocar os links respetivos */}
                        {/* <Typography variant='subtitle2' className="bold">                            
                            <a href=""> <span>{t('footer.about')}</span></a> |
                            <a href=""> <span>{t('footer.contacts')}</span></a>
                        </Typography > */}
                        <Typography variant='subtitle2' color='black' mt={1}>
                            {t('footer.copy', { year: new Date().getFullYear() })}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default LiveFooter;
