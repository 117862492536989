/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import LiveToogleButton from "../toogle-button/LiveToggleButton";
import DoubleSelectDeviceStreams from "../double-select-device-streams/DoubleSelectDeviceStreams";
import CloseIcon from "@mui/icons-material/Close";
import { IAdapterConfigurator, IDevice, ISelectedDeviceAndStreams, ISelectedDevicesAndOptions, IStream } from "interfaces";

interface IAddChart {
  open: boolean;
  handlerClose(): void;
  getSubmitedData(data: ISelectedDevicesAndOptions): void;
  adapters: IAdapterConfigurator[];
}

const AddChart = ({
  open,
  handlerClose,
  getSubmitedData,
  adapters,
}: IAddChart) => {
  const { t } = useTranslation();
  const unique_id = uuid();

  const [chartType, setChartType] = useState("");
  function getChartType(type: string) {
    setChartType(type);
  }
  //guarda os devices e as streams selecionadas
  const [firstItemSelected, setFirstItemSelected] =
    useState<ISelectedDeviceAndStreams>({
      device: undefined,
      streams: [],
    });
  const [secondItemSelected, setSecondItemSelected] =
    useState<ISelectedDeviceAndStreams>({
      device: undefined,
      streams: [],
    });


  //função que vai devolver os devices e as streams do filho (selects)
  function getFirstDevicesAndStreams(device: IDevice, streams: IStream[]) {
    setFirstItemSelected({
      ...firstItemSelected,
      device: device,
      streams: streams,
    });
  }
  function getSecondDevicesAndStreams(device: IDevice, streams: IStream[]) {
    setSecondItemSelected({
      ...secondItemSelected,
      device: device,
      streams: streams,
    });
  }

  // //quando for clicado para adicionar gráfico, envia os dados para a comparativeAnalysis
  const formSubmitHandler = () => {
    getSubmitedData({
      firstItem: firstItemSelected,
      secondItem: secondItemSelected,
      chartType,
      id: unique_id,
    });
    handlerClose();
  };

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={open}>
        <DialogTitle id="alert-dialog-title-add-chart">
          <Typography
            component={"span"}
            gutterBottom={true}
            variant="h5"
            noWrap
          >
            {t("comparativeAnalysis.addChart")}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handlerClose}
            size="small"
            sx={{
              position: "absolute",
              right: 10,
              top: 15,
            }}
            title={t("common.close")}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            {adapters.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="body1">
                    {t("comparativeAnalysis.chartType")}
                  </Typography>

                  <LiveToogleButton
                    firstOption={{
                      label: t("comparativeAnalysis.barChart"),
                      value: "bar",
                    }}
                    secondOption={{
                      label: t("comparativeAnalysis.lineChart"),
                      value: "line",
                    }}
                    getValue={getChartType}
                  />
                </Grid>

                {adapters && (
                  <>
                    <DoubleSelectDeviceStreams
                      deviceNumber="1"
                      adapters={adapters}
                      setDevicesAndStreams={getFirstDevicesAndStreams}
                    />

                    <DoubleSelectDeviceStreams
                      deviceNumber="2"
                      adapters={adapters}
                      setDevicesAndStreams={getSecondDevicesAndStreams}
                    />
                  </>
                )}
              </>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={handlerClose}>
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            disableElevation
            disabled={
              !(
                firstItemSelected.streams.length > 0 &&
                secondItemSelected.streams.length > 0
              )
            }
            onClick={formSubmitHandler}
          >
            {t("common.add")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default AddChart;
