import { createContext } from 'react';

import { ILogin, IRecoverPassword } from 'interfaces';

interface IAuthContextData {
  Login: (data: ILogin, callback: Function, onError?: Function) => Promise<any>;
  Logout: (callback: VoidFunction) => void;
  RecoverPassword: (data:IRecoverPassword, callback: VoidFunction) => void;
}

export const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);
