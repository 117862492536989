/* eslint-disable react-hooks/exhaustive-deps */
import { useFeedback } from "@alb/live-lib";
import { InputAutocomplete, InputCheckbox, InputText, RequiredField } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { RequiredFields } from "components/utils/forms/RequiredFields";
import UserPermissions from "components/utils/users/userPermissions";
import { useErrorHandler, useGetAll, useHasPermissions, useUpdate } from "hooks";
import { IApiResponse, ISelectOption, IUser, IUserAddForm } from "interfaces";
import { IAccount } from "interfaces/IAccount";
import { IUserPermission } from "interfaces/IUserPermission";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty, PERMISSIONS } from "utils";
import { getLanguages } from "views/users/languages";
import { getUserEditSchema } from "views/users/validations";

export interface IUsersEdit {
  user: IUser;
  clientID?: string;
  open: boolean;
  handlerClose: () => void;
  updateList: () => void;
  administration?: boolean;
}

const CRUD = "CRUD";

const UsersEdit = ({
  user,
  clientID,
  open,
  handlerClose,
  updateList,
  administration,
}: IUsersEdit) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const { sendErrorMessage } = useErrorHandler();
  const { hasPermission } = useHasPermissions();
  const langOptions = getLanguages(t);

  const canAdminister = hasPermission([PERMISSIONS.MANAGEMENT.USERS.CUD]);

  const [accountID, setAccountID] = useState<ISelectOption | null>(null);
  const [accountsOptions, setAccountsOptions] = useState<ISelectOption[]>([]);
  const [adminChecked, setAdminChecked] = useState<boolean>(false);
  const [superAdminChecked, setSuperAdminChecked] = useState<boolean>(false);
  const [typeAdminChecked, setTypeAdminChecked] = useState<boolean>(false);
  //pedido para editar user
  const {
    loading,
    error,
    refetch: editUser,
  } = useUpdate<IUser[]>(ServiceApiUrl.userURL, user.id);

  let permitionModule: { [permitionId: string]: boolean } = {};
  if (user.permission) {
    user.permission?.forEach((permission) => {
      CRUD.split("").forEach((c) => {
        const name = `${permission.module_id}_${c}`;
        const obj = { [name]: false };
        permitionModule = { ...permitionModule, ...obj };
      });
    });
  }

  const selectedLang = langOptions.filter((obj) => {
    return obj.value === user?.language;
  });

  const defaultValues = {
    account: accountID,
    client: user.client?.id,
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    is_admin: user.is_admin,
    is_superadmin: user.is_superadmin,
    language: selectedLang.length === 0 ? langOptions[0] : selectedLang[0],
    permission: [],
    username: user.username,
    ...permitionModule,
  };

  const methodsEdit = useForm<IUserAddForm>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(getUserEditSchema()),
  });

  const { control, setValue, resetField } = methodsEdit;

  useEffect(() => {
    // methodsEdit.reset();
  }, []);

  useEffect(() => {
    if (accountID) {
      resetField("account", { defaultValue: accountID });
    }
  }, [accountID]);

  // sucesso
  const handlerSuccess = () => {
    handlerClose(); //fecha modal
    addFeedback({
      message: t("user.userEdited"),
      severity: "success",
    });
    updateList && updateList();
  };

  const onAdminChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setAdminChecked(checked);
    setTypeAdminChecked(checked);
    if (checked) {
      listAccounts({ params: { client: clientID, admin: true } });
    }
    else {
      listAccounts({ params: { client: clientID } });
    }
  };

  // get list of accounts
  const { data: allAccounts, refetch: listAccounts } = useGetAll<
    IApiResponse<IAccount>
  >(ServiceApiUrl.accountURL, { client: clientID, admin: typeAdminChecked });
  useEffect(() => {
    if (allAccounts) {
      const options = allAccounts?.data?.map((p: IAccount) => ({
        label: p.name,
        value: p.id,
      }));
      setAccountsOptions(options);
      // let userAccount = user.account
      // if(typeAdminChecked) {
      //   userAccount = {name: options[0].label, id: options[0].value}
      // }
      // setAccountID({
      //   label: userAccount?.name ? userAccount?.name : options[0].label,
      //   value: userAccount?.id ? userAccount?.id : options[0].value,
      // })
      if (user?.account) {
        setAccountID({
          label: user.account.name,
          value: user.account.id,
        })
      } else {
        setAccountID({
          label: options[0].label,
          value: options[0].value,
        })
      }
    }
    if (error) {
      sendErrorMessage(error);
    }
  }, [allAccounts, error]);

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IUserAddForm> = async (
    payLoad: IUserAddForm
  ) => {
    const _payLoad = preparePayload(payLoad);
    try {
      await editUser({ data: _payLoad });
      await handlerSuccess();
    } catch (error) { }
  };

  function preparePayload(payLoad: IUserAddForm) {
    let _payLoad: IUserAddForm = {
      // account: payLoad.account,
      email: user.email,
      first_name: payLoad.first_name,
      last_name: payLoad.last_name,
      is_admin: payLoad.is_admin,
      language: (payLoad.language as ISelectOption).value,
      permission: [],
      username: payLoad.username,
    };
    //campos reservados a utilizadores superAdmin, e se for pela administração
    if (canAdminister && administration) {
      _payLoad.client = clientID;
      _payLoad.is_superadmin = payLoad.is_superadmin;
    }
    const perm: IUserPermission[] = [];
    user.permission.forEach((m) => {
      let value: string[] = [];
      CRUD.split("").forEach((c) => {
        const name = `${m.module_id}_${c}`;
        const t = (payLoad as unknown as any)[name];
        value.push(t ? "1" : "0");
      });

      if (value.join("") !== "0000") {
        perm.push({
          module_id: m.module_id,
          value: value.join(""),
        });
      }
    });
    typeAdminChecked
      ? (_payLoad.permission = [])
      : (_payLoad.permission = perm);

    _payLoad.account = accountID?.value

    return _payLoad;
  }

  //vai colocar por defeito as permissões que já são do user e colocar nos estados
  useEffect(() => {
    if (user) {
      setAdminChecked(user.is_admin);
      setSuperAdminChecked(user.is_superadmin);
      setTypeAdminChecked(user.is_admin || user.is_superadmin);
      setAccountID({ label: user?.account?.name || '', value: user?.account?.id || '' })
    }
  }, []);

  const handleChangeAccount = (
    e: SyntheticEvent<Element, Event>,
    value: ISelectOption | ISelectOption
  ) => {
    setAccountID(value)
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open}>
      <DialogTitle>
        <Typography
          component={"span"}
          color="text.black"
          gutterBottom={true}
          variant="h5"
          noWrap
        >
          {t("user.editUser")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <FormProvider {...methodsEdit}>
          <form
            id="edit-user-form"
            onSubmit={methodsEdit.handleSubmit(formSubmitHandler)}
          >
            <Grid container spacing={2}>
              {/* {canAdminister && administration && ( */}
              <Grid item xs={12} sm={!administration ? 12 : 6}>
                <InputCheckbox
                  control={control}
                  checked={adminChecked}
                  setValue={setValue}
                  labelText={t("user.Administrator")}
                  name={"is_admin"}
                  disabled={user.is_superadmin}
                  onChange={onAdminChange}
                ></InputCheckbox>
              </Grid>
              {canAdminister && administration && (
                <Grid item xs={12} sm={6}>
                  <InputCheckbox
                    control={control}
                    setValue={setValue}
                    checked={superAdminChecked}
                    disabled={true}
                    labelText={t("user.SuperAdministrator")}
                    name={"is_superadmin"}
                  ></InputCheckbox>
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <Typography gutterBottom color="text.black" variant="h6">
                  {t("user.firstName")}
                </Typography>
                <InputText
                  control={control}
                  name="first_name"
                  placeholder={t("user.namePlaceholder")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography gutterBottom color="text.black" variant="h6">
                  {t("user.lastName")}
                </Typography>
                <InputText
                  control={control}
                  name="last_name"
                  placeholder={t("user.namePlaceholder")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RequiredField title={t("user.Username")} />
                <InputText
                  control={control}
                  name="username"
                  placeholder={t("user.namePlaceholder")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RequiredField title={t("user.institutionalEmail")} />
                {/*<InputText
                  control={control}
                  name="email"
                  placeholder={t("user.emailPlaceholder")}
                />*/}
                <Typography mt={1} color="text.primary" variant="body1">
                    {user.email}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography gutterBottom color="text.black" variant="h6">
                  {t("user.language")}
                  <abbr />
                </Typography>
                {langOptions && (
                  <InputAutocomplete
                    name="language"
                    options={langOptions}
                    control={control}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <RequiredField title={t("accounts.account")} />
                {!typeAdminChecked && !arrayIsEmpty(accountsOptions) && (
                  <InputAutocomplete
                    name="account"
                    options={accountsOptions}
                    control={control}
                    handleOnChange={handleChangeAccount}
                  />
                )}
                {typeAdminChecked && (
                  <Typography mt={1} color="text.primary" variant="body1">
                    {accountID?.label}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                {!typeAdminChecked && (
                  <UserPermissions
                    control={control}
                    setValue={setValue}
                    edit
                    permissions={user.permission || []}
                    allPermissions={adminChecked || superAdminChecked}
                  />
                )}
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <RequiredFields />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handlerClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!methodsEdit.formState.isValid}
          type={"submit"}
          form={"edit-user-form"}
        >
          {t("common.save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UsersEdit;
